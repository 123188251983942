// language.js

const languages = [
    "Asami",
    "Bengali",
    "Bodo",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Marathi",
    "Nepali",
    "Odia",
    "Punjabi",
    "Santali",
    "Sanskrit",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu"
];
const defaultSkills = [
    'Analytical Skills',
    'Forecasting',
    'Risk management',
    'Negotiation',
    'Team Management',
    'Communication',
    'Capital allocation',
    'Change Management',
    'Treasury Management',
    'Accounting',
    'Process improvement',
    'Risk Assessment',
    'Market Research',
    'Portfolio Analysis',
    'Securitization',
    'Structured Finance',
    'Regulatory Compliance',
    'Data Analytics',
    'Strategic thinking',
    'Financial analysis'
];
const industrySector = [
    'Community, personal & Social Services',
    'Manufacturing (Food stuffs)',
    'Manufacturing (Textiles)',
    'Business Services',
    'Manufacturing (Machinery & Equipments)',
    'Finance',
    'Transport, storage and Communications',
    'Electricity, Gas & Water companies',
    'Real Estate and Renting',
    'Trading'
];
const eduDefSkill = [
    "Accounting",
    "Taxation",
    "Financial Reporting",
    "Auditing",
    "Business Law",
    "Budgeting",
    "Corporate Finance",
    "Financial Analysis",
    "Economics",
    "Communication Skills"
];
const certDefSkill = [
    "Financial Reporting",
    "Auditing",
    "Taxation",
    "Financial Management",
    "Corporate Law",
    "Strategic Cost Management",
    "Risk Assessment",
    "Financial Statement Analysis",
    "Business Advisory",
    "IFRS/Ind AS Accounting Standards"
];
const workDefSkill = [
    "Financial reporting",
    "Regulatory compliance",
    "Budgeting",
    "Financial analysis",
    "Leadership",
    "Risk management",
    "Accounting",
    "Internal controls",
    "Strategic planning",
    "Cost management",
    "Communication",
    "Team management",
    "Financial forecasting",
    "Tax compliance",
    "Auditing",
    "ERP systems",
    "Data analysis",
    "Process improvement",
    "Decision-making",
    "Ethics"
];

export { languages, defaultSkills, industrySector, eduDefSkill, certDefSkill, workDefSkill };
