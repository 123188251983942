const clusteredData = [
    {
        "name": "Ahmedabad Urban Agglomeration",
        "value": "Ahmedabad, Gandhinagar, Bopal, Naroda, Sarkhej"
    },
    {
        "name": "Bengaluru Urban Agglomeration",
        "value": "Bengaluru, Yelahanka, Whitefield, Kengeri, Hoskote"
    },
    {
        "name": "Bhopal Urban Agglomeration",
        "value": "Bhopal, Mandideep, Sehore"
    },
    {
        "name": "Chennai Metropolitan Area (CMA)",
        "value": "Chennai, Tambaram, Avadi, Kanchipuram, Sriperumbudur"
    },
    {
        "name": "Delhi NCR",
        "value": "Delhi, Gurgaon, Gurugram, Noida, Ghaziabad, Faridabad, Greater Noida, New Delhi"
    },
    {
        "name": "Hyderabad Metropolitan Region",
        "value": "Hyderabad, Secunderabad, Gachibowli, Kukatpally, Shamshabad, Medchal"
    },
    {
        "name": "Jaipur Metropolitan Area",
        "value": "Jaipur, Sanganer, Amer, Bassi"
    },
    {
        "name": "Kolkata Metropolitan Area (KMA)",
        "value": "Kolkata, Howrah, Bidhannagar, Rajarhat, Baranagar, South Dumdum"
    },
    {
        "name": "Lucknow-Kanpur Region",
        "value": "Lucknow, Kanpur, Unnao, Kakori, Barabanki"
    },
    {
        "name": "Metros",
        "value": "Delhi NCR, Mumbai, Kolkata, Chennai, Bengaluru, Hyderabad, Ahmedabad, Pune"
    },
    {
        "name": "Mumbai Metropolitan Region (MMR)",
        "value": "Mumbai, Navi Mumbai, Thane, Kalyan, Dombivli, Panvel, Vasai-Virar, Palghar"
    },
    {
        "name": "Patna Metropolitan Region",
        "value": "Patna, Hajipur, Danapur, Fatuha"
    },
    {
        "name": "Pune Metropolitan Region (PMR)",
        "value": "Pune, Pimpri-Chinchwad, Talegaon, Chakan, Lonavala, Hinjawadi"
    },
    {
        "name": "Tier 1 cities",
        "value": "Delhi NCR, Mumbai, Bengaluru, Hyderabad, Chennai, Kolkata, Pune, Ahmedabad, Surat, Jaipur"
    }
];
const cleanDataOld = [
    {
        "name": "Adilabad",
        "value": "Adilabad"
    },
    {
        "name": "Agra",
        "value": "Agra"
    },
    {
        "name": "Ahmed Nagar",
        "value": "Ahmed Nagar"
    },
    {
        "name": "Ahmedabad",
        "value": "Ahmedabad"
    },
    {
        "name": "Aizawl",
        "value": "Aizawl"
    },
    {
        "name": "Ajmer",
        "value": "Ajmer"
    },
    {
        "name": "Akola",
        "value": "Akola"
    },
    {
        "name": "Alappuzha",
        "value": "Alappuzha"
    },
    {
        "name": "Aligarh",
        "value": "Aligarh"
    },
    {
        "name": "Alirajpur",
        "value": "Alirajpur"
    },
    {
        "name": "Allahabad",
        "value": "Allahabad"
    },
    {
        "name": "Almora",
        "value": "Almora"
    },
    {
        "name": "Alwar",
        "value": "Alwar"
    },
    {
        "name": "Ambala",
        "value": "Ambala"
    },
    {
        "name": "Ambedkar Nagar",
        "value": "Ambedkar Nagar"
    },
    {
        "name": "Amravati",
        "value": "Amravati"
    },
    {
        "name": "Amreli",
        "value": "Amreli"
    },
    {
        "name": "Amritsar",
        "value": "Amritsar"
    },
    {
        "name": "Anand",
        "value": "Anand"
    },
    {
        "name": "Ananthapur",
        "value": "Ananthapur"
    },
    {
        "name": "Ananthnag",
        "value": "Ananthnag"
    },
    {
        "name": "Angul",
        "value": "Angul"
    },
    {
        "name": "Anuppur",
        "value": "Anuppur"
    },
    {
        "name": "Araria",
        "value": "Araria"
    },
    {
        "name": "Ariyalur",
        "value": "Ariyalur"
    },
    {
        "name": "Arwal",
        "value": "Arwal"
    },
    {
        "name": "Ashok Nagar",
        "value": "Ashok Nagar"
    },
    {
        "name": "Auraiya",
        "value": "Auraiya"
    },
    {
        "name": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "name": "Aurangabad(BH)",
        "value": "Aurangabad(BH)"
    },
    {
        "name": "Azamgarh",
        "value": "Azamgarh"
    },
    {
        "name": "Bagalkot",
        "value": "Bagalkot"
    },
    {
        "name": "Bageshwar",
        "value": "Bageshwar"
    },
    {
        "name": "Bagpat",
        "value": "Bagpat"
    },
    {
        "name": "Bahraich",
        "value": "Bahraich"
    },
    {
        "name": "Balaghat",
        "value": "Balaghat"
    },
    {
        "name": "Balangir",
        "value": "Balangir"
    },
    {
        "name": "Baleswar",
        "value": "Baleswar"
    },
    {
        "name": "Ballia",
        "value": "Ballia"
    },
    {
        "name": "Balrampur",
        "value": "Balrampur"
    },
    {
        "name": "Banaskantha",
        "value": "Banaskantha"
    },
    {
        "name": "Banda",
        "value": "Banda"
    },
    {
        "name": "Bandipur",
        "value": "Bandipur"
    },
    {
        "name": "Bangalore",
        "value": "Bangalore"
    },
    {
        "name": "Bangalore Rural",
        "value": "Bangalore Rural"
    },
    {
        "name": "Banka",
        "value": "Banka"
    },
    {
        "name": "Bankura",
        "value": "Bankura"
    },
    {
        "name": "Banswara",
        "value": "Banswara"
    },
    {
        "name": "Barabanki",
        "value": "Barabanki"
    },
    {
        "name": "Baramulla",
        "value": "Baramulla"
    },
    {
        "name": "Baran",
        "value": "Baran"
    },
    {
        "name": "Bardhaman",
        "value": "Bardhaman"
    },
    {
        "name": "Bareilly",
        "value": "Bareilly"
    },
    {
        "name": "Bargarh",
        "value": "Bargarh"
    },
    {
        "name": "Barmer",
        "value": "Barmer"
    },
    {
        "name": "Barnala",
        "value": "Barnala"
    },
    {
        "name": "Barpeta",
        "value": "Barpeta"
    },
    {
        "name": "Barwani",
        "value": "Barwani"
    },
    {
        "name": "Bastar",
        "value": "Bastar"
    },
    {
        "name": "Basti",
        "value": "Basti"
    },
    {
        "name": "Bathinda",
        "value": "Bathinda"
    },
    {
        "name": "Beed",
        "value": "Beed"
    },
    {
        "name": "Begusarai",
        "value": "Begusarai"
    },
    {
        "name": "Belgaum",
        "value": "Belgaum"
    },
    {
        "name": "Bellary",
        "value": "Bellary"
    },
    {
        "name": "Betul",
        "value": "Betul"
    },
    {
        "name": "Bhadrak",
        "value": "Bhadrak"
    },
    {
        "name": "Bhagalpur",
        "value": "Bhagalpur"
    },
    {
        "name": "Bhandara",
        "value": "Bhandara"
    },
    {
        "name": "Bharatpur",
        "value": "Bharatpur"
    },
    {
        "name": "Bharuch",
        "value": "Bharuch"
    },
    {
        "name": "Bhavnagar",
        "value": "Bhavnagar"
    },
    {
        "name": "Bhilwara",
        "value": "Bhilwara"
    },
    {
        "name": "Bhind",
        "value": "Bhind"
    },
    {
        "name": "Bhiwani",
        "value": "Bhiwani"
    },
    {
        "name": "Bhojpur",
        "value": "Bhojpur"
    },
    {
        "name": "Bhopal",
        "value": "Bhopal"
    },
    {
        "name": "Bidar",
        "value": "Bidar"
    },
    {
        "name": "Bijapur(CGH)",
        "value": "Bijapur(CGH)"
    },
    {
        "name": "Bijapur(KAR)",
        "value": "Bijapur(KAR)"
    },
    {
        "name": "Bijnor",
        "value": "Bijnor"
    },
    {
        "name": "Bikaner",
        "value": "Bikaner"
    },
    {
        "name": "Bilaspur (HP)",
        "value": "Bilaspur (HP)"
    },
    {
        "name": "Bilaspur(CGH)",
        "value": "Bilaspur(CGH)"
    },
    {
        "name": "Birbhum",
        "value": "Birbhum"
    },
    {
        "name": "Bishnupur",
        "value": "Bishnupur"
    },
    {
        "name": "Bokaro",
        "value": "Bokaro"
    },
    {
        "name": "Bongaigaon",
        "value": "Bongaigaon"
    },
    {
        "name": "Boudh",
        "value": "Boudh"
    },
    {
        "name": "Budaun",
        "value": "Budaun"
    },
    {
        "name": "Budgam",
        "value": "Budgam"
    },
    {
        "name": "Bulandshahr",
        "value": "Bulandshahr"
    },
    {
        "name": "Buldhana",
        "value": "Buldhana"
    },
    {
        "name": "Bundi",
        "value": "Bundi"
    },
    {
        "name": "Burhanpur",
        "value": "Burhanpur"
    },
    {
        "name": "Buxar",
        "value": "Buxar"
    },
    {
        "name": "Cachar",
        "value": "Cachar"
    },
    {
        "name": "Chamba",
        "value": "Chamba"
    },
    {
        "name": "Chamoli",
        "value": "Chamoli"
    },
    {
        "name": "Champawat",
        "value": "Champawat"
    },
    {
        "name": "Champhai",
        "value": "Champhai"
    },
    {
        "name": "Chamrajnagar",
        "value": "Chamrajnagar"
    },
    {
        "name": "Chandauli",
        "value": "Chandauli"
    },
    {
        "name": "Chandel",
        "value": "Chandel"
    },
    {
        "name": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "name": "Chandrapur",
        "value": "Chandrapur"
    },
    {
        "name": "Changlang",
        "value": "Changlang"
    },
    {
        "name": "Chatra",
        "value": "Chatra"
    },
    {
        "name": "Chennai",
        "value": "Chennai"
    },
    {
        "name": "Chhatarpur",
        "value": "Chhatarpur"
    },
    {
        "name": "Chhindwara",
        "value": "Chhindwara"
    },
    {
        "name": "Chickmagalur",
        "value": "Chickmagalur"
    },
    {
        "name": "Chikkaballapur",
        "value": "Chikkaballapur"
    },
    {
        "name": "Chitradurga",
        "value": "Chitradurga"
    },
    {
        "name": "Chitrakoot",
        "value": "Chitrakoot"
    },
    {
        "name": "Chittoor",
        "value": "Chittoor"
    },
    {
        "name": "Chittorgarh",
        "value": "Chittorgarh"
    },
    {
        "name": "Churachandpur",
        "value": "Churachandpur"
    },
    {
        "name": "Churu",
        "value": "Churu"
    },
    {
        "name": "Coimbatore",
        "value": "Coimbatore"
    },
    {
        "name": "Cooch Behar",
        "value": "Cooch Behar"
    },
    {
        "name": "Cuddalore",
        "value": "Cuddalore"
    },
    {
        "name": "Cuddapah",
        "value": "Cuddapah"
    },
    {
        "name": "Cuttack",
        "value": "Cuttack"
    },
    {
        "name": "Dadra & Nagar Haveli",
        "value": "Dadra & Nagar Haveli"
    },
    {
        "name": "Dahod",
        "value": "Dahod"
    },
    {
        "name": "Dakshina Kannada",
        "value": "Dakshina Kannada"
    },
    {
        "name": "Daman",
        "value": "Daman"
    },
    {
        "name": "Damoh",
        "value": "Damoh"
    },
    {
        "name": "Dantewada",
        "value": "Dantewada"
    },
    {
        "name": "Darbhanga",
        "value": "Darbhanga"
    },
    {
        "name": "Darjiling",
        "value": "Darjiling"
    },
    {
        "name": "Darrang",
        "value": "Darrang"
    },
    {
        "name": "Datia",
        "value": "Datia"
    },
    {
        "name": "Dausa",
        "value": "Dausa"
    },
    {
        "name": "Davangere",
        "value": "Davangere"
    },
    {
        "name": "Debagarh",
        "value": "Debagarh"
    },
    {
        "name": "Dehradun",
        "value": "Dehradun"
    },
    {
        "name": "Deoghar",
        "value": "Deoghar"
    },
    {
        "name": "Deoria",
        "value": "Deoria"
    },
    {
        "name": "Dewas",
        "value": "Dewas"
    },
    {
        "name": "Dhalai",
        "value": "Dhalai"
    },
    {
        "name": "Dhamtari",
        "value": "Dhamtari"
    },
    {
        "name": "Dhanbad",
        "value": "Dhanbad"
    },
    {
        "name": "Dhar",
        "value": "Dhar"
    },
    {
        "name": "Dharmapuri",
        "value": "Dharmapuri"
    },
    {
        "name": "Dharwad",
        "value": "Dharwad"
    },
    {
        "name": "Dhemaji",
        "value": "Dhemaji"
    },
    {
        "name": "Dhenkanal",
        "value": "Dhenkanal"
    },
    {
        "name": "Dholpur",
        "value": "Dholpur"
    },
    {
        "name": "Dhubri",
        "value": "Dhubri"
    },
    {
        "name": "Dhule",
        "value": "Dhule"
    },
    {
        "name": "Dibang Valley",
        "value": "Dibang Valley"
    },
    {
        "name": "Dibrugarh",
        "value": "Dibrugarh"
    },
    {
        "name": "Dimapur",
        "value": "Dimapur"
    },
    {
        "name": "Dindigul",
        "value": "Dindigul"
    },
    {
        "name": "Dindori",
        "value": "Dindori"
    },
    {
        "name": "Diu",
        "value": "Diu"
    },
    {
        "name": "Doda",
        "value": "Doda"
    },
    {
        "name": "Dumka",
        "value": "Dumka"
    },
    {
        "name": "Dungarpur",
        "value": "Dungarpur"
    },
    {
        "name": "Durg",
        "value": "Durg"
    },
    {
        "name": "East Champaran",
        "value": "East Champaran"
    },
    {
        "name": "East Garo Hills",
        "value": "East Garo Hills"
    },
    {
        "name": "East Godavari",
        "value": "East Godavari"
    },
    {
        "name": "East Kameng",
        "value": "East Kameng"
    },
    {
        "name": "East Khasi Hills",
        "value": "East Khasi Hills"
    },
    {
        "name": "East Midnapore",
        "value": "East Midnapore"
    },
    {
        "name": "East Nimar",
        "value": "East Nimar"
    },
    {
        "name": "East Siang",
        "value": "East Siang"
    },
    {
        "name": "East Sikkim",
        "value": "East Sikkim"
    },
    {
        "name": "East Singhbhum",
        "value": "East Singhbhum"
    },
    {
        "name": "Ernakulam",
        "value": "Ernakulam"
    },
    {
        "name": "Erode",
        "value": "Erode"
    },
    {
        "name": "Etah",
        "value": "Etah"
    },
    {
        "name": "Etawah",
        "value": "Etawah"
    },
    {
        "name": "Faizabad",
        "value": "Faizabad"
    },
    {
        "name": "Faridabad",
        "value": "Faridabad"
    },
    {
        "name": "Faridkot",
        "value": "Faridkot"
    },
    {
        "name": "Farrukhabad",
        "value": "Farrukhabad"
    },
    {
        "name": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "name": "Fatehgarh Sahib",
        "value": "Fatehgarh Sahib"
    },
    {
        "name": "Fatehpur",
        "value": "Fatehpur"
    },
    {
        "name": "Fazilka",
        "value": "Fazilka"
    },
    {
        "name": "Firozabad",
        "value": "Firozabad"
    },
    {
        "name": "Firozpur",
        "value": "Firozpur"
    },
    {
        "name": "Gadag",
        "value": "Gadag"
    },
    {
        "name": "Gadchiroli",
        "value": "Gadchiroli"
    },
    {
        "name": "Gajapati",
        "value": "Gajapati"
    },
    {
        "name": "Gandhi Nagar",
        "value": "Gandhi Nagar"
    },
    {
        "name": "Ganganagar",
        "value": "Ganganagar"
    },
    {
        "name": "Ganjam",
        "value": "Ganjam"
    },
    {
        "name": "Garhwa",
        "value": "Garhwa"
    },
    {
        "name": "Gariaband",
        "value": "Gariaband"
    },
    {
        "name": "Gautam Buddha Nagar",
        "value": "Gautam Buddha Nagar"
    },
    {
        "name": "Gaya",
        "value": "Gaya"
    },
    {
        "name": "Ghaziabad",
        "value": "Ghaziabad"
    },
    {
        "name": "Ghazipur",
        "value": "Ghazipur"
    },
    {
        "name": "Giridh",
        "value": "Giridh"
    },
    {
        "name": "Goalpara",
        "value": "Goalpara"
    },
    {
        "name": "Godda",
        "value": "Godda"
    },
    {
        "name": "Golaghat",
        "value": "Golaghat"
    },
    {
        "name": "Gonda",
        "value": "Gonda"
    },
    {
        "name": "Gondia",
        "value": "Gondia"
    },
    {
        "name": "Gopalganj",
        "value": "Gopalganj"
    },
    {
        "name": "Gorakhpur",
        "value": "Gorakhpur"
    },
    {
        "name": "Gulbarga",
        "value": "Gulbarga"
    },
    {
        "name": "Gumla",
        "value": "Gumla"
    },
    {
        "name": "Guna",
        "value": "Guna"
    },
    {
        "name": "Guntur",
        "value": "Guntur"
    },
    {
        "name": "Gurdaspur",
        "value": "Gurdaspur"
    },
    {
        "name": "Gurugram",
        "value": "Gurugram"
    },
    {
        "name": "Gwalior",
        "value": "Gwalior"
    },
    {
        "name": "Hailakandi",
        "value": "Hailakandi"
    },
    {
        "name": "Hamirpur",
        "value": "Hamirpur"
    },
    {
        "name": "Hamirpur(HP)",
        "value": "Hamirpur(HP)"
    },
    {
        "name": "Hanumangarh",
        "value": "Hanumangarh"
    },
    {
        "name": "Harda",
        "value": "Harda"
    },
    {
        "name": "Hardoi",
        "value": "Hardoi"
    },
    {
        "name": "Haridwar",
        "value": "Haridwar"
    },
    {
        "name": "Hassan",
        "value": "Hassan"
    },
    {
        "name": "Hathras",
        "value": "Hathras"
    },
    {
        "name": "Haveri",
        "value": "Haveri"
    },
    {
        "name": "Hazaribag",
        "value": "Hazaribag"
    },
    {
        "name": "Hingoli",
        "value": "Hingoli"
    },
    {
        "name": "Hisar",
        "value": "Hisar"
    },
    {
        "name": "Hooghly",
        "value": "Hooghly"
    },
    {
        "name": "Hoshangabad",
        "value": "Hoshangabad"
    },
    {
        "name": "Hoshiarpur",
        "value": "Hoshiarpur"
    },
    {
        "name": "Howrah",
        "value": "Howrah"
    },
    {
        "name": "Hyderabad",
        "value": "Hyderabad"
    },
    {
        "name": "Idukki",
        "value": "Idukki"
    },
    {
        "name": "Imphal East",
        "value": "Imphal East"
    },
    {
        "name": "Imphal West",
        "value": "Imphal West"
    },
    {
        "name": "Indore",
        "value": "Indore"
    },
    {
        "name": "Jabalpur",
        "value": "Jabalpur"
    },
    {
        "name": "Jagatsinghapur",
        "value": "Jagatsinghapur"
    },
    {
        "name": "Jaintia Hills",
        "value": "Jaintia Hills"
    },
    {
        "name": "Jaipur",
        "value": "Jaipur"
    },
    {
        "name": "Jaisalmer",
        "value": "Jaisalmer"
    },
    {
        "name": "Jajapur",
        "value": "Jajapur"
    },
    {
        "name": "Jalandhar",
        "value": "Jalandhar"
    },
    {
        "name": "Jalaun",
        "value": "Jalaun"
    },
    {
        "name": "Jalgaon",
        "value": "Jalgaon"
    },
    {
        "name": "Jalna",
        "value": "Jalna"
    },
    {
        "name": "Jalor",
        "value": "Jalor"
    },
    {
        "name": "Jalpaiguri",
        "value": "Jalpaiguri"
    },
    {
        "name": "Jammu",
        "value": "Jammu"
    },
    {
        "name": "Jamnagar",
        "value": "Jamnagar"
    },
    {
        "name": "Jamtara",
        "value": "Jamtara"
    },
    {
        "name": "Jamui",
        "value": "Jamui"
    },
    {
        "name": "Janjgir-champa",
        "value": "Janjgir-champa"
    },
    {
        "name": "Jashpur",
        "value": "Jashpur"
    },
    {
        "name": "Jaunpur",
        "value": "Jaunpur"
    },
    {
        "name": "Jehanabad",
        "value": "Jehanabad"
    },
    {
        "name": "Jhabua",
        "value": "Jhabua"
    },
    {
        "name": "Jhajjar",
        "value": "Jhajjar"
    },
    {
        "name": "Jhalawar",
        "value": "Jhalawar"
    },
    {
        "name": "Jhansi",
        "value": "Jhansi"
    },
    {
        "name": "Jharsuguda",
        "value": "Jharsuguda"
    },
    {
        "name": "Jhujhunu",
        "value": "Jhujhunu"
    },
    {
        "name": "Jind",
        "value": "Jind"
    },
    {
        "name": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "name": "Jorhat",
        "value": "Jorhat"
    },
    {
        "name": "Junagadh",
        "value": "Junagadh"
    },
    {
        "name": "Jyotiba Phule Nagar",
        "value": "Jyotiba Phule Nagar"
    },
    {
        "name": "K.V.Rangareddy",
        "value": "K.V.Rangareddy"
    },
    {
        "name": "Kachchh",
        "value": "Kachchh"
    },
    {
        "name": "Kaimur (Bhabua)",
        "value": "Kaimur (Bhabua)"
    },
    {
        "name": "Kaithal",
        "value": "Kaithal"
    },
    {
        "name": "Kalahandi",
        "value": "Kalahandi"
    },
    {
        "name": "Kamrup",
        "value": "Kamrup"
    },
    {
        "name": "Kanchipuram",
        "value": "Kanchipuram"
    },
    {
        "name": "Kandhamal",
        "value": "Kandhamal"
    },
    {
        "name": "Kangra",
        "value": "Kangra"
    },
    {
        "name": "Kanker",
        "value": "Kanker"
    },
    {
        "name": "Kannauj",
        "value": "Kannauj"
    },
    {
        "name": "Kannur",
        "value": "Kannur"
    },
    {
        "name": "Kanpur Dehat",
        "value": "Kanpur Dehat"
    },
    {
        "name": "Kanpur Nagar",
        "value": "Kanpur Nagar"
    },
    {
        "name": "Kanyakumari",
        "value": "Kanyakumari"
    },
    {
        "name": "Kapurthala",
        "value": "Kapurthala"
    },
    {
        "name": "Karaikal",
        "value": "Karaikal"
    },
    {
        "name": "Karauli",
        "value": "Karauli"
    },
    {
        "name": "Karbi Anglong",
        "value": "Karbi Anglong"
    },
    {
        "name": "Kargil",
        "value": "Kargil"
    },
    {
        "name": "Karim Nagar",
        "value": "Karim Nagar"
    },
    {
        "name": "Karimganj",
        "value": "Karimganj"
    },
    {
        "name": "Karnal",
        "value": "Karnal"
    },
    {
        "name": "Karur",
        "value": "Karur"
    },
    {
        "name": "Kasargod",
        "value": "Kasargod"
    },
    {
        "name": "Kathua",
        "value": "Kathua"
    },
    {
        "name": "Katihar",
        "value": "Katihar"
    },
    {
        "name": "Katni",
        "value": "Katni"
    },
    {
        "name": "Kaushambi",
        "value": "Kaushambi"
    },
    {
        "name": "Kawardha",
        "value": "Kawardha"
    },
    {
        "name": "Kendrapara",
        "value": "Kendrapara"
    },
    {
        "name": "Kendujhar",
        "value": "Kendujhar"
    },
    {
        "name": "Khagaria",
        "value": "Khagaria"
    },
    {
        "name": "Khammam",
        "value": "Khammam"
    },
    {
        "name": "Khandwa",
        "value": "Khandwa"
    },
    {
        "name": "Khargone",
        "value": "Khargone"
    },
    {
        "name": "Kheda",
        "value": "Kheda"
    },
    {
        "name": "Kheri",
        "value": "Kheri"
    },
    {
        "name": "Khorda",
        "value": "Khorda"
    },
    {
        "name": "Khunti",
        "value": "Khunti"
    },
    {
        "name": "Kinnaur",
        "value": "Kinnaur"
    },
    {
        "name": "Kiphire",
        "value": "Kiphire"
    },
    {
        "name": "Kishanganj",
        "value": "Kishanganj"
    },
    {
        "name": "Kodagu",
        "value": "Kodagu"
    },
    {
        "name": "Koderma",
        "value": "Koderma"
    },
    {
        "name": "Kohima",
        "value": "Kohima"
    },
    {
        "name": "Kokrajhar",
        "value": "Kokrajhar"
    },
    {
        "name": "Kolar",
        "value": "Kolar"
    },
    {
        "name": "Kolasib",
        "value": "Kolasib"
    },
    {
        "name": "Kolhapur",
        "value": "Kolhapur"
    },
    {
        "name": "Kolkata",
        "value": "Kolkata"
    },
    {
        "name": "Kollam",
        "value": "Kollam"
    },
    {
        "name": "Koppal",
        "value": "Koppal"
    },
    {
        "name": "Koraput",
        "value": "Koraput"
    },
    {
        "name": "Korba",
        "value": "Korba"
    },
    {
        "name": "Koriya",
        "value": "Koriya"
    },
    {
        "name": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kottayam",
        "value": "Kottayam"
    },
    {
        "name": "Kozhikode",
        "value": "Kozhikode"
    },
    {
        "name": "Krishna",
        "value": "Krishna"
    },
    {
        "name": "Krishnagiri",
        "value": "Krishnagiri"
    },
    {
        "name": "Kulgam",
        "value": "Kulgam"
    },
    {
        "name": "Kullu",
        "value": "Kullu"
    },
    {
        "name": "Kupwara",
        "value": "Kupwara"
    },
    {
        "name": "Kurnool",
        "value": "Kurnool"
    },
    {
        "name": "Kurukshetra",
        "value": "Kurukshetra"
    },
    {
        "name": "Kurung Kumey",
        "value": "Kurung Kumey"
    },
    {
        "name": "Kushinagar",
        "value": "Kushinagar"
    },
    {
        "name": "Lahul & Spiti",
        "value": "Lahul & Spiti"
    },
    {
        "name": "Lakhimpur",
        "value": "Lakhimpur"
    },
    {
        "name": "Lakhisarai",
        "value": "Lakhisarai"
    },
    {
        "name": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "name": "Lalitpur",
        "value": "Lalitpur"
    },
    {
        "name": "Latehar",
        "value": "Latehar"
    },
    {
        "name": "Latur",
        "value": "Latur"
    },
    {
        "name": "Lawngtlai",
        "value": "Lawngtlai"
    },
    {
        "name": "Leh",
        "value": "Leh"
    },
    {
        "name": "Lohardaga",
        "value": "Lohardaga"
    },
    {
        "name": "Lohit",
        "value": "Lohit"
    },
    {
        "name": "Longleng",
        "value": "Longleng"
    },
    {
        "name": "Lower Dibang Valley",
        "value": "Lower Dibang Valley"
    },
    {
        "name": "Lower Subansiri",
        "value": "Lower Subansiri"
    },
    {
        "name": "Lucknow",
        "value": "Lucknow"
    },
    {
        "name": "Ludhiana",
        "value": "Ludhiana"
    },
    {
        "name": "Lunglei",
        "value": "Lunglei"
    },
    {
        "name": "Madhepura",
        "value": "Madhepura"
    },
    {
        "name": "Madhubani",
        "value": "Madhubani"
    },
    {
        "name": "Madurai",
        "value": "Madurai"
    },
    {
        "name": "Mahabub Nagar",
        "value": "Mahabub Nagar"
    },
    {
        "name": "Maharajganj",
        "value": "Maharajganj"
    },
    {
        "name": "Mahasamund",
        "value": "Mahasamund"
    },
    {
        "name": "Mahe",
        "value": "Mahe"
    },
    {
        "name": "Mahendragarh",
        "value": "Mahendragarh"
    },
    {
        "name": "Mahesana",
        "value": "Mahesana"
    },
    {
        "name": "Mahoba",
        "value": "Mahoba"
    },
    {
        "name": "Mainpuri",
        "value": "Mainpuri"
    },
    {
        "name": "Malappuram",
        "value": "Malappuram"
    },
    {
        "name": "Malda",
        "value": "Malda"
    },
    {
        "name": "Malkangiri",
        "value": "Malkangiri"
    },
    {
        "name": "Mammit",
        "value": "Mammit"
    },
    {
        "name": "Mandi",
        "value": "Mandi"
    },
    {
        "name": "Mandla",
        "value": "Mandla"
    },
    {
        "name": "Mandsaur",
        "value": "Mandsaur"
    },
    {
        "name": "Mandya",
        "value": "Mandya"
    },
    {
        "name": "Mansa",
        "value": "Mansa"
    },
    {
        "name": "Marigaon",
        "value": "Marigaon"
    },
    {
        "name": "Mathura",
        "value": "Mathura"
    },
    {
        "name": "Mau",
        "value": "Mau"
    },
    {
        "name": "Mayurbhanj",
        "value": "Mayurbhanj"
    },
    {
        "name": "Medak",
        "value": "Medak"
    },
    {
        "name": "Medinipur",
        "value": "Medinipur"
    },
    {
        "name": "Meerut",
        "value": "Meerut"
    },
    {
        "name": "Mirzapur",
        "value": "Mirzapur"
    },
    {
        "name": "Moga",
        "value": "Moga"
    },
    {
        "name": "Mohali",
        "value": "Mohali"
    },
    {
        "name": "Mokokchung",
        "value": "Mokokchung"
    },
    {
        "name": "Mon",
        "value": "Mon"
    },
    {
        "name": "Moradabad",
        "value": "Moradabad"
    },
    {
        "name": "Morena",
        "value": "Morena"
    },
    {
        "name": "Muktsar",
        "value": "Muktsar"
    },
    {
        "name": "Mumbai",
        "value": "Mumbai"
    },
    {
        "name": "Munger",
        "value": "Munger"
    },
    {
        "name": "Murshidabad",
        "value": "Murshidabad"
    },
    {
        "name": "Muzaffarnagar",
        "value": "Muzaffarnagar"
    },
    {
        "name": "Muzaffarpur",
        "value": "Muzaffarpur"
    },
    {
        "name": "Mysore",
        "value": "Mysore"
    },
    {
        "name": "Nabarangapur",
        "value": "Nabarangapur"
    },
    {
        "name": "Nadia",
        "value": "Nadia"
    },
    {
        "name": "Nagaon",
        "value": "Nagaon"
    },
    {
        "name": "Nagapattinam",
        "value": "Nagapattinam"
    },
    {
        "name": "Nagaur",
        "value": "Nagaur"
    },
    {
        "name": "Nagpur",
        "value": "Nagpur"
    },
    {
        "name": "Nainital",
        "value": "Nainital"
    },
    {
        "name": "Nalanda",
        "value": "Nalanda"
    },
    {
        "name": "Nalbari",
        "value": "Nalbari"
    },
    {
        "name": "Nalgonda",
        "value": "Nalgonda"
    },
    {
        "name": "Namakkal",
        "value": "Namakkal"
    },
    {
        "name": "Nanded",
        "value": "Nanded"
    },
    {
        "name": "Nandurbar",
        "value": "Nandurbar"
    },
    {
        "name": "Narayanpur",
        "value": "Narayanpur"
    },
    {
        "name": "Narmada",
        "value": "Narmada"
    },
    {
        "name": "Narsinghpur",
        "value": "Narsinghpur"
    },
    {
        "name": "Nashik",
        "value": "Nashik"
    },
    {
        "name": "Navsari",
        "value": "Navsari"
    },
    {
        "name": "Nawada",
        "value": "Nawada"
    },
    {
        "name": "Nawanshahr",
        "value": "Nawanshahr"
    },
    {
        "name": "Nayagarh",
        "value": "Nayagarh"
    },
    {
        "name": "Neemuch",
        "value": "Neemuch"
    },
    {
        "name": "Nellore",
        "value": "Nellore"
    },
    {
        "name": "New Delhi",
        "value": "New Delhi"
    },
    {
        "name": "Nicobar",
        "value": "Nicobar"
    },
    {
        "name": "Nilgiris",
        "value": "Nilgiris"
    },
    {
        "name": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "name": "North 24 Parganas",
        "value": "North 24 Parganas"
    },
    {
        "name": "North And Middle Andaman",
        "value": "North And Middle Andaman"
    },
    {
        "name": "North Cachar Hills",
        "value": "North Cachar Hills"
    },
    {
        "name": "North Dinajpur",
        "value": "North Dinajpur"
    },
    {
        "name": "North Goa",
        "value": "North Goa"
    },
    {
        "name": "North Sikkim",
        "value": "North Sikkim"
    },
    {
        "name": "North Tripura",
        "value": "North Tripura"
    },
    {
        "name": "Nuapada",
        "value": "Nuapada"
    },
    {
        "name": "Osmanabad",
        "value": "Osmanabad"
    },
    {
        "name": "Pakur",
        "value": "Pakur"
    },
    {
        "name": "Palakkad",
        "value": "Palakkad"
    },
    {
        "name": "Palamau",
        "value": "Palamau"
    },
    {
        "name": "Pali",
        "value": "Pali"
    },
    {
        "name": "Panch Mahals",
        "value": "Panch Mahals"
    },
    {
        "name": "Panchkula",
        "value": "Panchkula"
    },
    {
        "name": "Panipat",
        "value": "Panipat"
    },
    {
        "name": "Panna",
        "value": "Panna"
    },
    {
        "name": "Papum Pare",
        "value": "Papum Pare"
    },
    {
        "name": "Parbhani",
        "value": "Parbhani"
    },
    {
        "name": "Patan",
        "value": "Patan"
    },
    {
        "name": "Pathanamthitta",
        "value": "Pathanamthitta"
    },
    {
        "name": "Pathankot",
        "value": "Pathankot"
    },
    {
        "name": "Patiala",
        "value": "Patiala"
    },
    {
        "name": "Patna",
        "value": "Patna"
    },
    {
        "name": "Pauri Garhwal",
        "value": "Pauri Garhwal"
    },
    {
        "name": "Perambalur",
        "value": "Perambalur"
    },
    {
        "name": "Peren",
        "value": "Peren"
    },
    {
        "name": "Phek",
        "value": "Phek"
    },
    {
        "name": "Pilibhit",
        "value": "Pilibhit"
    },
    {
        "name": "Pithoragarh",
        "value": "Pithoragarh"
    },
    {
        "name": "Pondicherry",
        "value": "Pondicherry"
    },
    {
        "name": "Poonch",
        "value": "Poonch"
    },
    {
        "name": "Porbandar",
        "value": "Porbandar"
    },
    {
        "name": "Prakasam",
        "value": "Prakasam"
    },
    {
        "name": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "name": "Pudukkottai",
        "value": "Pudukkottai"
    },
    {
        "name": "Pulwama",
        "value": "Pulwama"
    },
    {
        "name": "Pune",
        "value": "Pune"
    },
    {
        "name": "Puri",
        "value": "Puri"
    },
    {
        "name": "Purnia",
        "value": "Purnia"
    },
    {
        "name": "Puruliya",
        "value": "Puruliya"
    },
    {
        "name": "Raebareli",
        "value": "Raebareli"
    },
    {
        "name": "Raichur",
        "value": "Raichur"
    },
    {
        "name": "Raigarh",
        "value": "Raigarh"
    },
    {
        "name": "Raigarh(MH)",
        "value": "Raigarh(MH)"
    },
    {
        "name": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raisen",
        "value": "Raisen"
    },
    {
        "name": "Rajauri",
        "value": "Rajauri"
    },
    {
        "name": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rajkot",
        "value": "Rajkot"
    },
    {
        "name": "Rajnandgaon",
        "value": "Rajnandgaon"
    },
    {
        "name": "Rajsamand",
        "value": "Rajsamand"
    },
    {
        "name": "Ramanagar",
        "value": "Ramanagar"
    },
    {
        "name": "Ramanathapuram",
        "value": "Ramanathapuram"
    },
    {
        "name": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Rampur",
        "value": "Rampur"
    },
    {
        "name": "Ranchi",
        "value": "Ranchi"
    },
    {
        "name": "Ratlam",
        "value": "Ratlam"
    },
    {
        "name": "Ratnagiri",
        "value": "Ratnagiri"
    },
    {
        "name": "Rayagada",
        "value": "Rayagada"
    },
    {
        "name": "Reasi",
        "value": "Reasi"
    },
    {
        "name": "Rewa",
        "value": "Rewa"
    },
    {
        "name": "Rewari",
        "value": "Rewari"
    },
    {
        "name": "Ri Bhoi",
        "value": "Ri Bhoi"
    },
    {
        "name": "Rohtak",
        "value": "Rohtak"
    },
    {
        "name": "Rohtas",
        "value": "Rohtas"
    },
    {
        "name": "Ropar",
        "value": "Ropar"
    },
    {
        "name": "Rudraprayag",
        "value": "Rudraprayag"
    },
    {
        "name": "Rupnagar",
        "value": "Rupnagar"
    },
    {
        "name": "Sabarkantha",
        "value": "Sabarkantha"
    },
    {
        "name": "Sagar",
        "value": "Sagar"
    },
    {
        "name": "Saharanpur",
        "value": "Saharanpur"
    },
    {
        "name": "Saharsa",
        "value": "Saharsa"
    },
    {
        "name": "Sahibganj",
        "value": "Sahibganj"
    },
    {
        "name": "Saiha",
        "value": "Saiha"
    },
    {
        "name": "Salem",
        "value": "Salem"
    },
    {
        "name": "Samastipur",
        "value": "Samastipur"
    },
    {
        "name": "Sambalpur",
        "value": "Sambalpur"
    },
    {
        "name": "Sangli",
        "value": "Sangli"
    },
    {
        "name": "Sangrur",
        "value": "Sangrur"
    },
    {
        "name": "Sant Kabir Nagar",
        "value": "Sant Kabir Nagar"
    },
    {
        "name": "Sant Ravidas Nagar",
        "value": "Sant Ravidas Nagar"
    },
    {
        "name": "Saran",
        "value": "Saran"
    },
    {
        "name": "Satara",
        "value": "Satara"
    },
    {
        "name": "Satna",
        "value": "Satna"
    },
    {
        "name": "Sawai Madhopur",
        "value": "Sawai Madhopur"
    },
    {
        "name": "Sehore",
        "value": "Sehore"
    },
    {
        "name": "Senapati",
        "value": "Senapati"
    },
    {
        "name": "Seoni",
        "value": "Seoni"
    },
    {
        "name": "Seraikela-kharsawan",
        "value": "Seraikela-kharsawan"
    },
    {
        "name": "Serchhip",
        "value": "Serchhip"
    },
    {
        "name": "Shahdol",
        "value": "Shahdol"
    },
    {
        "name": "Shahjahanpur",
        "value": "Shahjahanpur"
    },
    {
        "name": "Shajapur",
        "value": "Shajapur"
    },
    {
        "name": "Sheikhpura",
        "value": "Sheikhpura"
    },
    {
        "name": "Sheohar",
        "value": "Sheohar"
    },
    {
        "name": "Sheopur",
        "value": "Sheopur"
    },
    {
        "name": "Shimla",
        "value": "Shimla"
    },
    {
        "name": "Shimoga",
        "value": "Shimoga"
    },
    {
        "name": "Shivpuri",
        "value": "Shivpuri"
    },
    {
        "name": "Shopian",
        "value": "Shopian"
    },
    {
        "name": "Shrawasti",
        "value": "Shrawasti"
    },
    {
        "name": "Sibsagar",
        "value": "Sibsagar"
    },
    {
        "name": "Siddharthnagar",
        "value": "Siddharthnagar"
    },
    {
        "name": "Sidhi",
        "value": "Sidhi"
    },
    {
        "name": "Sikar",
        "value": "Sikar"
    },
    {
        "name": "Simdega",
        "value": "Simdega"
    },
    {
        "name": "Sindhudurg",
        "value": "Sindhudurg"
    },
    {
        "name": "Singrauli",
        "value": "Singrauli"
    },
    {
        "name": "Sirmaur",
        "value": "Sirmaur"
    },
    {
        "name": "Sirohi",
        "value": "Sirohi"
    },
    {
        "name": "Sirsa",
        "value": "Sirsa"
    },
    {
        "name": "Sitamarhi",
        "value": "Sitamarhi"
    },
    {
        "name": "Sitapur",
        "value": "Sitapur"
    },
    {
        "name": "Sivaganga",
        "value": "Sivaganga"
    },
    {
        "name": "Siwan",
        "value": "Siwan"
    },
    {
        "name": "Solan",
        "value": "Solan"
    },
    {
        "name": "Solapur",
        "value": "Solapur"
    },
    {
        "name": "Sonapur",
        "value": "Sonapur"
    },
    {
        "name": "Sonbhadra",
        "value": "Sonbhadra"
    },
    {
        "name": "Sonipat",
        "value": "Sonipat"
    },
    {
        "name": "Sonitpur",
        "value": "Sonitpur"
    },
    {
        "name": "South 24 Parganas",
        "value": "South 24 Parganas"
    },
    {
        "name": "South Andaman",
        "value": "South Andaman"
    },
    {
        "name": "South Dinajpur",
        "value": "South Dinajpur"
    },
    {
        "name": "South Garo Hills",
        "value": "South Garo Hills"
    },
    {
        "name": "South Goa",
        "value": "South Goa"
    },
    {
        "name": "South Sikkim",
        "value": "South Sikkim"
    },
    {
        "name": "South Tripura",
        "value": "South Tripura"
    },
    {
        "name": "Srikakulam",
        "value": "Srikakulam"
    },
    {
        "name": "Srinagar",
        "value": "Srinagar"
    },
    {
        "name": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Sundergarh",
        "value": "Sundergarh"
    },
    {
        "name": "Supaul",
        "value": "Supaul"
    },
    {
        "name": "Surat",
        "value": "Surat"
    },
    {
        "name": "Surendra Nagar",
        "value": "Surendra Nagar"
    },
    {
        "name": "Surguja",
        "value": "Surguja"
    },
    {
        "name": "Tamenglong",
        "value": "Tamenglong"
    },
    {
        "name": "Tapi",
        "value": "Tapi"
    },
    {
        "name": "Tarn Taran",
        "value": "Tarn Taran"
    },
    {
        "name": "Tawang",
        "value": "Tawang"
    },
    {
        "name": "Tehri Garhwal",
        "value": "Tehri Garhwal"
    },
    {
        "name": "Thane",
        "value": "Thane"
    },
    {
        "name": "Thanjavur",
        "value": "Thanjavur"
    },
    {
        "name": "The Dangs",
        "value": "The Dangs"
    },
    {
        "name": "Theni",
        "value": "Theni"
    },
    {
        "name": "Thiruvananthapuram",
        "value": "Thiruvananthapuram"
    },
    {
        "name": "Thoubal",
        "value": "Thoubal"
    },
    {
        "name": "Thrissur",
        "value": "Thrissur"
    },
    {
        "name": "Tikamgarh",
        "value": "Tikamgarh"
    },
    {
        "name": "Tinsukia",
        "value": "Tinsukia"
    },
    {
        "name": "Tirap",
        "value": "Tirap"
    },
    {
        "name": "Tiruchirappalli",
        "value": "Tiruchirappalli"
    },
    {
        "name": "Tirunelveli",
        "value": "Tirunelveli"
    },
    {
        "name": "Tiruvallur",
        "value": "Tiruvallur"
    },
    {
        "name": "Tiruvannamalai",
        "value": "Tiruvannamalai"
    },
    {
        "name": "Tiruvarur",
        "value": "Tiruvarur"
    },
    {
        "name": "Tonk",
        "value": "Tonk"
    },
    {
        "name": "Tuensang",
        "value": "Tuensang"
    },
    {
        "name": "Tumkur",
        "value": "Tumkur"
    },
    {
        "name": "Tuticorin",
        "value": "Tuticorin"
    },
    {
        "name": "Udaipur",
        "value": "Udaipur"
    },
    {
        "name": "Udham Singh Nagar",
        "value": "Udham Singh Nagar"
    },
    {
        "name": "Udhampur",
        "value": "Udhampur"
    },
    {
        "name": "Udupi",
        "value": "Udupi"
    },
    {
        "name": "Ujjain",
        "value": "Ujjain"
    },
    {
        "name": "Ukhrul",
        "value": "Ukhrul"
    },
    {
        "name": "Umaria",
        "value": "Umaria"
    },
    {
        "name": "Una",
        "value": "Una"
    },
    {
        "name": "Unnao",
        "value": "Unnao"
    },
    {
        "name": "Upper Siang",
        "value": "Upper Siang"
    },
    {
        "name": "Upper Subansiri",
        "value": "Upper Subansiri"
    },
    {
        "name": "Uttara Kannada",
        "value": "Uttara Kannada"
    },
    {
        "name": "Uttarkashi",
        "value": "Uttarkashi"
    },
    {
        "name": "Vadodara",
        "value": "Vadodara"
    },
    {
        "name": "Vaishali",
        "value": "Vaishali"
    },
    {
        "name": "Valsad",
        "value": "Valsad"
    },
    {
        "name": "Varanasi",
        "value": "Varanasi"
    },
    {
        "name": "Vellore",
        "value": "Vellore"
    },
    {
        "name": "Vidisha",
        "value": "Vidisha"
    },
    {
        "name": "Villupuram",
        "value": "Villupuram"
    },
    {
        "name": "Virudhunagar",
        "value": "Virudhunagar"
    },
    {
        "name": "Visakhapatnam",
        "value": "Visakhapatnam"
    },
    {
        "name": "Vizianagaram",
        "value": "Vizianagaram"
    },
    {
        "name": "Warangal",
        "value": "Warangal"
    },
    {
        "name": "Wardha",
        "value": "Wardha"
    },
    {
        "name": "Washim",
        "value": "Washim"
    },
    {
        "name": "Wayanad",
        "value": "Wayanad"
    },
    {
        "name": "West Champaran",
        "value": "West Champaran"
    },
    {
        "name": "West Garo Hills",
        "value": "West Garo Hills"
    },
    {
        "name": "West Godavari",
        "value": "West Godavari"
    },
    {
        "name": "West Kameng",
        "value": "West Kameng"
    },
    {
        "name": "West Khasi Hills",
        "value": "West Khasi Hills"
    },
    {
        "name": "West Midnapore",
        "value": "West Midnapore"
    },
    {
        "name": "West Nimar",
        "value": "West Nimar"
    },
    {
        "name": "West Siang",
        "value": "West Siang"
    },
    {
        "name": "West Sikkim",
        "value": "West Sikkim"
    },
    {
        "name": "West Singhbhum",
        "value": "West Singhbhum"
    },
    {
        "name": "West Tripura",
        "value": "West Tripura"
    },
    {
        "name": "Wokha",
        "value": "Wokha"
    },
    {
        "name": "Yadgir",
        "value": "Yadgir"
    },
    {
        "name": "Yamuna Nagar",
        "value": "Yamuna Nagar"
    },
    {
        "name": "Yavatmal",
        "value": "Yavatmal"
    },
    {
        "name": "Zunhebotto",
        "value": "Zunhebotto"
    }
];
const cleanData = [
    {
        "name": "Abbigeri",
        "value": "Abbigeri"
    },
    {
        "name": "Abdullahnagar",
        "value": "Abdullahnagar"
    },
    {
        "name": "Abhayapuri",
        "value": "Abhayapuri"
    },
    {
        "name": "Abhia",
        "value": "Abhia"
    },
    {
        "name": "Abhwar",
        "value": "Abhwar"
    },
    {
        "name": "Abiramam",
        "value": "Abiramam"
    },
    {
        "name": "Ablu",
        "value": "Ablu"
    },
    {
        "name": "Abohar",
        "value": "Abohar"
    },
    {
        "name": "Abu",
        "value": "Abu"
    },
    {
        "name": "Achaljamu",
        "value": "Achaljamu"
    },
    {
        "name": "Achampet",
        "value": "Achampet"
    },
    {
        "name": "Achankovil",
        "value": "Achankovil"
    },
    {
        "name": "Acharipallam",
        "value": "Acharipallam"
    },
    {
        "name": "Achchampeta",
        "value": "Achchampeta"
    },
    {
        "name": "Achchippatti",
        "value": "Achchippatti"
    },
    {
        "name": "Achhnera",
        "value": "Achhnera"
    },
    {
        "name": "Adalaj",
        "value": "Adalaj"
    },
    {
        "name": "Adalhat",
        "value": "Adalhat"
    },
    {
        "name": "Adalpur",
        "value": "Adalpur"
    },
    {
        "name": "Adamankottai",
        "value": "Adamankottai"
    },
    {
        "name": "Adampur",
        "value": "Adampur"
    },
    {
        "name": "Addanki",
        "value": "Addanki"
    },
    {
        "name": "Adesar",
        "value": "Adesar"
    },
    {
        "name": "Adigappadi",
        "value": "Adigappadi"
    },
    {
        "name": "Adigaratti",
        "value": "Adigaratti"
    },
    {
        "name": "Adigoppula",
        "value": "Adigoppula"
    },
    {
        "name": "Adilabad",
        "value": "Adilabad"
    },
    {
        "name": "Adivala",
        "value": "Adivala"
    },
    {
        "name": "Adiyakkamangalam",
        "value": "Adiyakkamangalam"
    },
    {
        "name": "Adoni",
        "value": "Adoni"
    },
    {
        "name": "Adra",
        "value": "Adra"
    },
    {
        "name": "Adukam",
        "value": "Adukam"
    },
    {
        "name": "Aduru",
        "value": "Aduru"
    },
    {
        "name": "Advi Devalpalli",
        "value": "Advi Devalpalli"
    },
    {
        "name": "Afzala",
        "value": "Afzala"
    },
    {
        "name": "Afzalpur",
        "value": "Afzalpur"
    },
    {
        "name": "Agadallanka",
        "value": "Agadallanka"
    },
    {
        "name": "Agadi",
        "value": "Agadi"
    },
    {
        "name": "Agar",
        "value": "Agar"
    },
    {
        "name": "Agaram",
        "value": "Agaram"
    },
    {
        "name": "Agarpur",
        "value": "Agarpur"
    },
    {
        "name": "Agartala",
        "value": "Agartala"
    },
    {
        "name": "Aginiparru",
        "value": "Aginiparru"
    },
    {
        "name": "Agiripalle",
        "value": "Agiripalle"
    },
    {
        "name": "Agra",
        "value": "Agra"
    },
    {
        "name": "Ahirauliya",
        "value": "Ahirauliya"
    },
    {
        "name": "Ahiro",
        "value": "Ahiro"
    },
    {
        "name": "Ahmadabad",
        "value": "Ahmadabad"
    },
    {
        "name": "Ahmadnagar",
        "value": "Ahmadnagar"
    },
    {
        "name": "Ahmadpur",
        "value": "Ahmadpur"
    },
    {
        "name": "Ahmedabad",
        "value": "Ahmedabad"
    },
    {
        "name": "Ahor",
        "value": "Ahor"
    },
    {
        "name": "Ahwa",
        "value": "Ahwa"
    },
    {
        "name": "Aikaranad",
        "value": "Aikaranad"
    },
    {
        "name": "Ainapur",
        "value": "Ainapur"
    },
    {
        "name": "Aivanallur",
        "value": "Aivanallur"
    },
    {
        "name": "Aiyampuzha",
        "value": "Aiyampuzha"
    },
    {
        "name": "Aiyappan Kovil",
        "value": "Aiyappan Kovil"
    },
    {
        "name": "Aizawl",
        "value": "Aizawl"
    },
    {
        "name": "Ajaigarh",
        "value": "Ajaigarh"
    },
    {
        "name": "Ajas",
        "value": "Ajas"
    },
    {
        "name": "Ajjampur",
        "value": "Ajjampur"
    },
    {
        "name": "Ajjanahalli",
        "value": "Ajjanahalli"
    },
    {
        "name": "Ajjipuram",
        "value": "Ajjipuram"
    },
    {
        "name": "Ajmer",
        "value": "Ajmer"
    },
    {
        "name": "Ajnala",
        "value": "Ajnala"
    },
    {
        "name": "Ajodhya",
        "value": "Ajodhya"
    },
    {
        "name": "Akalapura",
        "value": "Akalapura"
    },
    {
        "name": "Akalgarh",
        "value": "Akalgarh"
    },
    {
        "name": "Akambadam",
        "value": "Akambadam"
    },
    {
        "name": "Akanavaritota",
        "value": "Akanavaritota"
    },
    {
        "name": "Akbarpur",
        "value": "Akbarpur"
    },
    {
        "name": "Akhnur",
        "value": "Akhnur"
    },
    {
        "name": "Akividu",
        "value": "Akividu"
    },
    {
        "name": "Akkattettar",
        "value": "Akkattettar"
    },
    {
        "name": "Aklera",
        "value": "Aklera"
    },
    {
        "name": "Aklvidu",
        "value": "Aklvidu"
    },
    {
        "name": "Akola",
        "value": "Akola"
    },
    {
        "name": "Akora",
        "value": "Akora"
    },
    {
        "name": "Akot",
        "value": "Akot"
    },
    {
        "name": "Alagappapuram",
        "value": "Alagappapuram"
    },
    {
        "name": "Alagarai",
        "value": "Alagarai"
    },
    {
        "name": "Alamadi",
        "value": "Alamadi"
    },
    {
        "name": "Alamnagar",
        "value": "Alamnagar"
    },
    {
        "name": "Alampalaiyam",
        "value": "Alampalaiyam"
    },
    {
        "name": "Alampur",
        "value": "Alampur"
    },
    {
        "name": "Alampur Gonpura",
        "value": "Alampur Gonpura"
    },
    {
        "name": "Alamuru",
        "value": "Alamuru"
    },
    {
        "name": "Aland",
        "value": "Aland"
    },
    {
        "name": "Alandatte",
        "value": "Alandatte"
    },
    {
        "name": "Alanganallur",
        "value": "Alanganallur"
    },
    {
        "name": "Alangayam",
        "value": "Alangayam"
    },
    {
        "name": "Alangudi",
        "value": "Alangudi"
    },
    {
        "name": "Alangulam",
        "value": "Alangulam"
    },
    {
        "name": "Alasandigutta",
        "value": "Alasandigutta"
    },
    {
        "name": "Alattur",
        "value": "Alattur"
    },
    {
        "name": "Alauli",
        "value": "Alauli"
    },
    {
        "name": "Alawalpur",
        "value": "Alawalpur"
    },
    {
        "name": "Alawandi",
        "value": "Alawandi"
    },
    {
        "name": "Alayaman",
        "value": "Alayaman"
    },
    {
        "name": "Alengad",
        "value": "Alengad"
    },
    {
        "name": "Alewah",
        "value": "Alewah"
    },
    {
        "name": "Algun",
        "value": "Algun"
    },
    {
        "name": "Aliganj",
        "value": "Aliganj"
    },
    {
        "name": "Aligarh",
        "value": "Aligarh"
    },
    {
        "name": "Alipur",
        "value": "Alipur"
    },
    {
        "name": "Alipur Duar",
        "value": "Alipur Duar"
    },
    {
        "name": "Aliyabad",
        "value": "Aliyabad"
    },
    {
        "name": "Allagadda",
        "value": "Allagadda"
    },
    {
        "name": "Allahdurg",
        "value": "Allahdurg"
    },
    {
        "name": "Allahpur",
        "value": "Allahpur"
    },
    {
        "name": "Alleppey",
        "value": "Alleppey"
    },
    {
        "name": "Allinagaram",
        "value": "Allinagaram"
    },
    {
        "name": "Allur",
        "value": "Allur"
    },
    {
        "name": "Alluru",
        "value": "Alluru"
    },
    {
        "name": "Almel",
        "value": "Almel"
    },
    {
        "name": "Almora",
        "value": "Almora"
    },
    {
        "name": "Alnavar",
        "value": "Alnavar"
    },
    {
        "name": "Alta",
        "value": "Alta"
    },
    {
        "name": "Alukkuli",
        "value": "Alukkuli"
    },
    {
        "name": "Alur",
        "value": "Alur"
    },
    {
        "name": "Alwa Tirunagari",
        "value": "Alwa Tirunagari"
    },
    {
        "name": "Alwal",
        "value": "Alwal"
    },
    {
        "name": "Alwar",
        "value": "Alwar"
    },
    {
        "name": "Alwarkurichchi",
        "value": "Alwarkurichchi"
    },
    {
        "name": "Alwaye",
        "value": "Alwaye"
    },
    {
        "name": "Amaha",
        "value": "Amaha"
    },
    {
        "name": "Amala",
        "value": "Amala"
    },
    {
        "name": "Amalapuram",
        "value": "Amalapuram"
    },
    {
        "name": "Amalner",
        "value": "Amalner"
    },
    {
        "name": "Amangal",
        "value": "Amangal"
    },
    {
        "name": "Amanganj",
        "value": "Amanganj"
    },
    {
        "name": "Amarapuuram",
        "value": "Amarapuuram"
    },
    {
        "name": "Amaravati",
        "value": "Amaravati"
    },
    {
        "name": "Amarchinta",
        "value": "Amarchinta"
    },
    {
        "name": "Amari",
        "value": "Amari"
    },
    {
        "name": "Amarpatan",
        "value": "Amarpatan"
    },
    {
        "name": "Amarpur",
        "value": "Amarpur"
    },
    {
        "name": "Amarpura",
        "value": "Amarpura"
    },
    {
        "name": "Amarwa Khurd",
        "value": "Amarwa Khurd"
    },
    {
        "name": "Amarwara",
        "value": "Amarwara"
    },
    {
        "name": "Amauna",
        "value": "Amauna"
    },
    {
        "name": "Amayan",
        "value": "Amayan"
    },
    {
        "name": "Amba Icharua",
        "value": "Amba Icharua"
    },
    {
        "name": "Ambajogai",
        "value": "Ambajogai"
    },
    {
        "name": "Ambala",
        "value": "Ambala"
    },
    {
        "name": "Ambalanur",
        "value": "Ambalanur"
    },
    {
        "name": "Ambalavayal",
        "value": "Ambalavayal"
    },
    {
        "name": "Ambara",
        "value": "Ambara"
    },
    {
        "name": "Ambasamudram",
        "value": "Ambasamudram"
    },
    {
        "name": "Ambattur",
        "value": "Ambattur"
    },
    {
        "name": "Ambatturai",
        "value": "Ambatturai"
    },
    {
        "name": "Ambhua",
        "value": "Ambhua"
    },
    {
        "name": "Ambikapur",
        "value": "Ambikapur"
    },
    {
        "name": "Amgachia",
        "value": "Amgachia"
    },
    {
        "name": "Amha",
        "value": "Amha"
    },
    {
        "name": "Amiawar",
        "value": "Amiawar"
    },
    {
        "name": "Amingaon",
        "value": "Amingaon"
    },
    {
        "name": "Amingarh",
        "value": "Amingarh"
    },
    {
        "name": "Aminpur",
        "value": "Aminpur"
    },
    {
        "name": "Amjhar",
        "value": "Amjhar"
    },
    {
        "name": "Amjhera",
        "value": "Amjhera"
    },
    {
        "name": "Amla",
        "value": "Amla"
    },
    {
        "name": "Ammanabrolu",
        "value": "Ammanabrolu"
    },
    {
        "name": "Ammapettai",
        "value": "Ammapettai"
    },
    {
        "name": "Ammavarikuppam",
        "value": "Ammavarikuppam"
    },
    {
        "name": "Ammur",
        "value": "Ammur"
    },
    {
        "name": "Amodei",
        "value": "Amodei"
    },
    {
        "name": "Amrabad",
        "value": "Amrabad"
    },
    {
        "name": "Amravati",
        "value": "Amravati"
    },
    {
        "name": "Amreli",
        "value": "Amreli"
    },
    {
        "name": "Amritsar",
        "value": "Amritsar"
    },
    {
        "name": "Amroha",
        "value": "Amroha"
    },
    {
        "name": "Amsin",
        "value": "Amsin"
    },
    {
        "name": "Amta",
        "value": "Amta"
    },
    {
        "name": "Amtala",
        "value": "Amtala"
    },
    {
        "name": "Amudalapalle",
        "value": "Amudalapalle"
    },
    {
        "name": "Amudalavalasa",
        "value": "Amudalavalasa"
    },
    {
        "name": "Amwa Majhar",
        "value": "Amwa Majhar"
    },
    {
        "name": "Anaikal",
        "value": "Anaikal"
    },
    {
        "name": "Anaiyur",
        "value": "Anaiyur"
    },
    {
        "name": "Anakapalle",
        "value": "Anakapalle"
    },
    {
        "name": "Anakaputtur",
        "value": "Anakaputtur"
    },
    {
        "name": "Anamalais",
        "value": "Anamalais"
    },
    {
        "name": "Anand",
        "value": "Anand"
    },
    {
        "name": "Anandapuram",
        "value": "Anandapuram"
    },
    {
        "name": "Anandnagar",
        "value": "Anandnagar"
    },
    {
        "name": "Anandpur",
        "value": "Anandpur"
    },
    {
        "name": "Ananipalle",
        "value": "Ananipalle"
    },
    {
        "name": "Anantapalle",
        "value": "Anantapalle"
    },
    {
        "name": "Anantapur",
        "value": "Anantapur"
    },
    {
        "name": "Anantarazupeta",
        "value": "Anantarazupeta"
    },
    {
        "name": "Anantasagaram",
        "value": "Anantasagaram"
    },
    {
        "name": "Anantavur",
        "value": "Anantavur"
    },
    {
        "name": "Anantpur",
        "value": "Anantpur"
    },
    {
        "name": "Anavatti",
        "value": "Anavatti"
    },
    {
        "name": "Andal",
        "value": "Andal"
    },
    {
        "name": "Andanappettai",
        "value": "Andanappettai"
    },
    {
        "name": "Andhana",
        "value": "Andhana"
    },
    {
        "name": "Andhra Tharhi",
        "value": "Andhra Tharhi"
    },
    {
        "name": "Andipalaiyam",
        "value": "Andipalaiyam"
    },
    {
        "name": "Andippatti",
        "value": "Andippatti"
    },
    {
        "name": "Andiyappanur",
        "value": "Andiyappanur"
    },
    {
        "name": "Andurkonam",
        "value": "Andurkonam"
    },
    {
        "name": "Anekal",
        "value": "Anekal"
    },
    {
        "name": "Angadikkal Tekkekara",
        "value": "Angadikkal Tekkekara"
    },
    {
        "name": "Angalakuduru Malepalle",
        "value": "Angalakuduru Malepalle"
    },
    {
        "name": "Angalakurichchi",
        "value": "Angalakurichchi"
    },
    {
        "name": "Angallu",
        "value": "Angallu"
    },
    {
        "name": "Angamali",
        "value": "Angamali"
    },
    {
        "name": "Anghad",
        "value": "Anghad"
    },
    {
        "name": "Angichettippalaiyam",
        "value": "Angichettippalaiyam"
    },
    {
        "name": "Angul",
        "value": "Angul"
    },
    {
        "name": "Angwali",
        "value": "Angwali"
    },
    {
        "name": "Anjad",
        "value": "Anjad"
    },
    {
        "name": "Anjangaon",
        "value": "Anjangaon"
    },
    {
        "name": "Anjarkandi",
        "value": "Anjarkandi"
    },
    {
        "name": "Anjehalli",
        "value": "Anjehalli"
    },
    {
        "name": "Anjukulippatti",
        "value": "Anjukulippatti"
    },
    {
        "name": "Anjuna",
        "value": "Anjuna"
    },
    {
        "name": "Anjur",
        "value": "Anjur"
    },
    {
        "name": "Ankalgi",
        "value": "Ankalgi"
    },
    {
        "name": "Ankireddikuntapalem",
        "value": "Ankireddikuntapalem"
    },
    {
        "name": "Ankli",
        "value": "Ankli"
    },
    {
        "name": "Ankola",
        "value": "Ankola"
    },
    {
        "name": "Annakattumula",
        "value": "Annakattumula"
    },
    {
        "name": "Annakunnu",
        "value": "Annakunnu"
    },
    {
        "name": "Annamalainagar",
        "value": "Annamalainagar"
    },
    {
        "name": "Annaram",
        "value": "Annaram"
    },
    {
        "name": "Anndevarapeta",
        "value": "Anndevarapeta"
    },
    {
        "name": "Annigeri",
        "value": "Annigeri"
    },
    {
        "name": "Annur",
        "value": "Annur"
    },
    {
        "name": "Antardipa",
        "value": "Antardipa"
    },
    {
        "name": "Antargangi",
        "value": "Antargangi"
    },
    {
        "name": "Antarvedi",
        "value": "Antarvedi"
    },
    {
        "name": "Antarvedipalem",
        "value": "Antarvedipalem"
    },
    {
        "name": "Antri",
        "value": "Antri"
    },
    {
        "name": "Antur",
        "value": "Antur"
    },
    {
        "name": "Anuppampattu",
        "value": "Anuppampattu"
    },
    {
        "name": "Anuppur",
        "value": "Anuppur"
    },
    {
        "name": "Anupshahr",
        "value": "Anupshahr"
    },
    {
        "name": "Aphaur",
        "value": "Aphaur"
    },
    {
        "name": "Apparaopeta",
        "value": "Apparaopeta"
    },
    {
        "name": "Appukkudal",
        "value": "Appukkudal"
    },
    {
        "name": "Arachchalur",
        "value": "Arachchalur"
    },
    {
        "name": "Arajpur",
        "value": "Arajpur"
    },
    {
        "name": "Arakere",
        "value": "Arakere"
    },
    {
        "name": "Arakkal",
        "value": "Arakkal"
    },
    {
        "name": "Arakkapadi",
        "value": "Arakkapadi"
    },
    {
        "name": "Aralam",
        "value": "Aralam"
    },
    {
        "name": "Aramangalam",
        "value": "Aramangalam"
    },
    {
        "name": "Arambagh",
        "value": "Arambagh"
    },
    {
        "name": "Arambakkam",
        "value": "Arambakkam"
    },
    {
        "name": "Aranda",
        "value": "Aranda"
    },
    {
        "name": "Arani",
        "value": "Arani"
    },
    {
        "name": "Arantangi",
        "value": "Arantangi"
    },
    {
        "name": "Aranya Kalan",
        "value": "Aranya Kalan"
    },
    {
        "name": "Araria",
        "value": "Araria"
    },
    {
        "name": "Arasur",
        "value": "Arasur"
    },
    {
        "name": "Aravakkurichchi",
        "value": "Aravakkurichchi"
    },
    {
        "name": "Aravankara",
        "value": "Aravankara"
    },
    {
        "name": "Aravelli",
        "value": "Aravelli"
    },
    {
        "name": "Arcot",
        "value": "Arcot"
    },
    {
        "name": "Arda",
        "value": "Arda"
    },
    {
        "name": "Areraj",
        "value": "Areraj"
    },
    {
        "name": "Arimalam",
        "value": "Arimalam"
    },
    {
        "name": "Ariyalur",
        "value": "Ariyalur"
    },
    {
        "name": "Arkonam",
        "value": "Arkonam"
    },
    {
        "name": "Arma",
        "value": "Arma"
    },
    {
        "name": "Armur",
        "value": "Armur"
    },
    {
        "name": "Arni",
        "value": "Arni"
    },
    {
        "name": "Arni ka Khera",
        "value": "Arni ka Khera"
    },
    {
        "name": "Arniya",
        "value": "Arniya"
    },
    {
        "name": "Aroali",
        "value": "Aroali"
    },
    {
        "name": "Arohi",
        "value": "Arohi"
    },
    {
        "name": "Aron",
        "value": "Aron"
    },
    {
        "name": "Arrah",
        "value": "Arrah"
    },
    {
        "name": "Arrapalli",
        "value": "Arrapalli"
    },
    {
        "name": "Arsali",
        "value": "Arsali"
    },
    {
        "name": "Arsikere",
        "value": "Arsikere"
    },
    {
        "name": "Arttuvattala",
        "value": "Arttuvattala"
    },
    {
        "name": "Arugollu",
        "value": "Arugollu"
    },
    {
        "name": "Arukutti",
        "value": "Arukutti"
    },
    {
        "name": "Arumbakkam",
        "value": "Arumbakkam"
    },
    {
        "name": "Arumbavur",
        "value": "Arumbavur"
    },
    {
        "name": "Arumuganeri",
        "value": "Arumuganeri"
    },
    {
        "name": "Aruppukkottai",
        "value": "Aruppukkottai"
    },
    {
        "name": "Arutla",
        "value": "Arutla"
    },
    {
        "name": "Aruvapalam",
        "value": "Aruvapalam"
    },
    {
        "name": "Aruvikkara",
        "value": "Aruvikkara"
    },
    {
        "name": "Arwal",
        "value": "Arwal"
    },
    {
        "name": "Asafabad",
        "value": "Asafabad"
    },
    {
        "name": "Asahni",
        "value": "Asahni"
    },
    {
        "name": "Asakapalle",
        "value": "Asakapalle"
    },
    {
        "name": "Asan",
        "value": "Asan"
    },
    {
        "name": "Asansol",
        "value": "Asansol"
    },
    {
        "name": "Asara",
        "value": "Asara"
    },
    {
        "name": "Asarganj",
        "value": "Asarganj"
    },
    {
        "name": "Asarhi",
        "value": "Asarhi"
    },
    {
        "name": "Ashoknagar",
        "value": "Ashoknagar"
    },
    {
        "name": "Ashta",
        "value": "Ashta"
    },
    {
        "name": "Ashwaraopeta",
        "value": "Ashwaraopeta"
    },
    {
        "name": "Assamannur",
        "value": "Assamannur"
    },
    {
        "name": "Asthal Bohar",
        "value": "Asthal Bohar"
    },
    {
        "name": "Asthanwan",
        "value": "Asthanwan"
    },
    {
        "name": "Asudapuram",
        "value": "Asudapuram"
    },
    {
        "name": "Aswapuram",
        "value": "Aswapuram"
    },
    {
        "name": "Atari",
        "value": "Atari"
    },
    {
        "name": "Atarra",
        "value": "Atarra"
    },
    {
        "name": "Atchampeta",
        "value": "Atchampeta"
    },
    {
        "name": "Athar",
        "value": "Athar"
    },
    {
        "name": "Atharga",
        "value": "Atharga"
    },
    {
        "name": "Athiringal",
        "value": "Athiringal"
    },
    {
        "name": "Athni",
        "value": "Athni"
    },
    {
        "name": "Atlapadu",
        "value": "Atlapadu"
    },
    {
        "name": "Atmakur",
        "value": "Atmakur"
    },
    {
        "name": "Atmakuru",
        "value": "Atmakuru"
    },
    {
        "name": "Atner",
        "value": "Atner"
    },
    {
        "name": "Atru",
        "value": "Atru"
    },
    {
        "name": "Attanur",
        "value": "Attanur"
    },
    {
        "name": "Attappampatti",
        "value": "Attappampatti"
    },
    {
        "name": "Attibele",
        "value": "Attibele"
    },
    {
        "name": "Attili",
        "value": "Attili"
    },
    {
        "name": "Attimarappatti",
        "value": "Attimarappatti"
    },
    {
        "name": "Attingal",
        "value": "Attingal"
    },
    {
        "name": "Attippattu",
        "value": "Attippattu"
    },
    {
        "name": "Attur",
        "value": "Attur"
    },
    {
        "name": "Atturkuppam",
        "value": "Atturkuppam"
    },
    {
        "name": "Auhar Sheikh",
        "value": "Auhar Sheikh"
    },
    {
        "name": "Aul",
        "value": "Aul"
    },
    {
        "name": "Aurad",
        "value": "Aurad"
    },
    {
        "name": "Aurad Shahjahani",
        "value": "Aurad Shahjahani"
    },
    {
        "name": "Aurahi",
        "value": "Aurahi"
    },
    {
        "name": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "name": "Auria",
        "value": "Auria"
    },
    {
        "name": "Auta",
        "value": "Auta"
    },
    {
        "name": "Avadattur",
        "value": "Avadattur"
    },
    {
        "name": "Avadi",
        "value": "Avadi"
    },
    {
        "name": "Avalepalli",
        "value": "Avalepalli"
    },
    {
        "name": "Avalpundurai",
        "value": "Avalpundurai"
    },
    {
        "name": "Avalurpet",
        "value": "Avalurpet"
    },
    {
        "name": "Avanashipalaiyam",
        "value": "Avanashipalaiyam"
    },
    {
        "name": "Avanigadda",
        "value": "Avanigadda"
    },
    {
        "name": "Avaniyapuram",
        "value": "Avaniyapuram"
    },
    {
        "name": "Avidha",
        "value": "Avidha"
    },
    {
        "name": "Avitanallur",
        "value": "Avitanallur"
    },
    {
        "name": "Awankh",
        "value": "Awankh"
    },
    {
        "name": "Awantipur",
        "value": "Awantipur"
    },
    {
        "name": "Ayanavelikulangara Tekku",
        "value": "Ayanavelikulangara Tekku"
    },
    {
        "name": "Ayanikkad",
        "value": "Ayanikkad"
    },
    {
        "name": "Ayas",
        "value": "Ayas"
    },
    {
        "name": "Ayikudi",
        "value": "Ayikudi"
    },
    {
        "name": "Ayinikkad",
        "value": "Ayinikkad"
    },
    {
        "name": "Ayirapuram",
        "value": "Ayirapuram"
    },
    {
        "name": "Ayirurpara",
        "value": "Ayirurpara"
    },
    {
        "name": "Aymangala",
        "value": "Aymangala"
    },
    {
        "name": "Ayodhyapattanam",
        "value": "Ayodhyapattanam"
    },
    {
        "name": "Ayyagarpet",
        "value": "Ayyagarpet"
    },
    {
        "name": "Ayyampalaiyam",
        "value": "Ayyampalaiyam"
    },
    {
        "name": "Ayyampettai",
        "value": "Ayyampettai"
    },
    {
        "name": "Azamnagar",
        "value": "Azamnagar"
    },
    {
        "name": "Azhikkal",
        "value": "Azhikkal"
    },
    {
        "name": "Azhikkod",
        "value": "Azhikkod"
    },
    {
        "name": "Azizpur Chande",
        "value": "Azizpur Chande"
    },
    {
        "name": "Baba Bakala",
        "value": "Baba Bakala"
    },
    {
        "name": "Babai",
        "value": "Babai"
    },
    {
        "name": "Babai Kalan",
        "value": "Babai Kalan"
    },
    {
        "name": "Babhanganwa",
        "value": "Babhanganwa"
    },
    {
        "name": "Babhangaon",
        "value": "Babhangaon"
    },
    {
        "name": "Babhangawan",
        "value": "Babhangawan"
    },
    {
        "name": "Babhani Bholwa",
        "value": "Babhani Bholwa"
    },
    {
        "name": "Babhantoli",
        "value": "Babhantoli"
    },
    {
        "name": "Babhniyawan",
        "value": "Babhniyawan"
    },
    {
        "name": "Babhnoul",
        "value": "Babhnoul"
    },
    {
        "name": "Babra",
        "value": "Babra"
    },
    {
        "name": "Babura",
        "value": "Babura"
    },
    {
        "name": "Bachchannapet",
        "value": "Bachchannapet"
    },
    {
        "name": "Bachhauta",
        "value": "Bachhauta"
    },
    {
        "name": "Bachhraon",
        "value": "Bachhraon"
    },
    {
        "name": "Bachra",
        "value": "Bachra"
    },
    {
        "name": "Bada Barabil",
        "value": "Bada Barabil"
    },
    {
        "name": "Badagabettu",
        "value": "Badagabettu"
    },
    {
        "name": "Badami",
        "value": "Badami"
    },
    {
        "name": "Badanahatti",
        "value": "Badanahatti"
    },
    {
        "name": "Badantola",
        "value": "Badantola"
    },
    {
        "name": "Badarpur",
        "value": "Badarpur"
    },
    {
        "name": "Badarwas",
        "value": "Badarwas"
    },
    {
        "name": "Badepalli",
        "value": "Badepalli"
    },
    {
        "name": "Badiadka",
        "value": "Badiadka"
    },
    {
        "name": "Badkulla",
        "value": "Badkulla"
    },
    {
        "name": "Badnawar",
        "value": "Badnawar"
    },
    {
        "name": "Badnor",
        "value": "Badnor"
    },
    {
        "name": "Badru Khan",
        "value": "Badru Khan"
    },
    {
        "name": "Badshahpur",
        "value": "Badshahpur"
    },
    {
        "name": "Baduria",
        "value": "Baduria"
    },
    {
        "name": "Baduriatola",
        "value": "Baduriatola"
    },
    {
        "name": "Badurpalle",
        "value": "Badurpalle"
    },
    {
        "name": "Badvel",
        "value": "Badvel"
    },
    {
        "name": "Bag",
        "value": "Bag"
    },
    {
        "name": "Bagaha",
        "value": "Bagaha"
    },
    {
        "name": "Bagahi",
        "value": "Bagahi"
    },
    {
        "name": "Bagalkot",
        "value": "Bagalkot"
    },
    {
        "name": "Bagalur",
        "value": "Bagalur"
    },
    {
        "name": "Bagalvad",
        "value": "Bagalvad"
    },
    {
        "name": "Bagasra",
        "value": "Bagasra"
    },
    {
        "name": "Bagaura",
        "value": "Bagaura"
    },
    {
        "name": "Bagchini",
        "value": "Bagchini"
    },
    {
        "name": "Bagepalli",
        "value": "Bagepalli"
    },
    {
        "name": "Bageshwar",
        "value": "Bageshwar"
    },
    {
        "name": "Bagewadi",
        "value": "Bagewadi"
    },
    {
        "name": "Bagha Purana",
        "value": "Bagha Purana"
    },
    {
        "name": "Baghambarpur",
        "value": "Baghambarpur"
    },
    {
        "name": "Baghant",
        "value": "Baghant"
    },
    {
        "name": "Baghauni",
        "value": "Baghauni"
    },
    {
        "name": "Baghduma",
        "value": "Baghduma"
    },
    {
        "name": "Baghmari",
        "value": "Baghmari"
    },
    {
        "name": "Baghmaria",
        "value": "Baghmaria"
    },
    {
        "name": "Baghpat",
        "value": "Baghpat"
    },
    {
        "name": "Baghra",
        "value": "Baghra"
    },
    {
        "name": "Bagli",
        "value": "Bagli"
    },
    {
        "name": "Bagnan",
        "value": "Bagnan"
    },
    {
        "name": "Bagor",
        "value": "Bagor"
    },
    {
        "name": "Bagra",
        "value": "Bagra"
    },
    {
        "name": "Bagrinagar",
        "value": "Bagrinagar"
    },
    {
        "name": "Bagula",
        "value": "Bagula"
    },
    {
        "name": "Bahadarpur",
        "value": "Bahadarpur"
    },
    {
        "name": "Bahadurganj",
        "value": "Bahadurganj"
    },
    {
        "name": "Bahadurpur",
        "value": "Bahadurpur"
    },
    {
        "name": "Bahagalpur",
        "value": "Bahagalpur"
    },
    {
        "name": "Baharampur",
        "value": "Baharampur"
    },
    {
        "name": "Baharu",
        "value": "Baharu"
    },
    {
        "name": "Baheri",
        "value": "Baheri"
    },
    {
        "name": "Bahraigh",
        "value": "Bahraigh"
    },
    {
        "name": "Bahutal",
        "value": "Bahutal"
    },
    {
        "name": "Baihar",
        "value": "Baihar"
    },
    {
        "name": "Baikatpur",
        "value": "Baikatpur"
    },
    {
        "name": "Baikunthapur",
        "value": "Baikunthapur"
    },
    {
        "name": "Baikunthpur",
        "value": "Baikunthpur"
    },
    {
        "name": "Bail-Hongal",
        "value": "Bail-Hongal"
    },
    {
        "name": "Baindur",
        "value": "Baindur"
    },
    {
        "name": "Bairagnia",
        "value": "Bairagnia"
    },
    {
        "name": "Bairi Chak",
        "value": "Bairi Chak"
    },
    {
        "name": "Bairia",
        "value": "Bairia"
    },
    {
        "name": "Bairiya",
        "value": "Bairiya"
    },
    {
        "name": "Bairo",
        "value": "Bairo"
    },
    {
        "name": "Baisa",
        "value": "Baisa"
    },
    {
        "name": "Baisuhalli",
        "value": "Baisuhalli"
    },
    {
        "name": "Baj Baj",
        "value": "Baj Baj"
    },
    {
        "name": "Bajala",
        "value": "Bajala"
    },
    {
        "name": "Bajpe",
        "value": "Bajpe"
    },
    {
        "name": "Bajwara",
        "value": "Bajwara"
    },
    {
        "name": "Bakarpur Ogairah",
        "value": "Bakarpur Ogairah"
    },
    {
        "name": "Bakharia",
        "value": "Bakharia"
    },
    {
        "name": "Bakhri",
        "value": "Bakhri"
    },
    {
        "name": "Bakhtawarpur",
        "value": "Bakhtawarpur"
    },
    {
        "name": "Bakhtiyarpur",
        "value": "Bakhtiyarpur"
    },
    {
        "name": "Bakwa",
        "value": "Bakwa"
    },
    {
        "name": "Baladharmaram",
        "value": "Baladharmaram"
    },
    {
        "name": "Balagam",
        "value": "Balagam"
    },
    {
        "name": "Balakrishnanpatti",
        "value": "Balakrishnanpatti"
    },
    {
        "name": "Balangir",
        "value": "Balangir"
    },
    {
        "name": "Balarampur",
        "value": "Balarampur"
    },
    {
        "name": "Balarampuram",
        "value": "Balarampuram"
    },
    {
        "name": "Balasamudram",
        "value": "Balasamudram"
    },
    {
        "name": "Balasore",
        "value": "Balasore"
    },
    {
        "name": "Balaungi",
        "value": "Balaungi"
    },
    {
        "name": "Baldeogarh",
        "value": "Baldeogarh"
    },
    {
        "name": "Balehonnur",
        "value": "Balehonnur"
    },
    {
        "name": "Balha",
        "value": "Balha"
    },
    {
        "name": "Balhapur",
        "value": "Balhapur"
    },
    {
        "name": "Bali",
        "value": "Bali"
    },
    {
        "name": "Balia",
        "value": "Balia"
    },
    {
        "name": "Baligaon",
        "value": "Baligaon"
    },
    {
        "name": "Balighattam",
        "value": "Balighattam"
    },
    {
        "name": "Balkonda",
        "value": "Balkonda"
    },
    {
        "name": "Ballia",
        "value": "Ballia"
    },
    {
        "name": "Balrampur",
        "value": "Balrampur"
    },
    {
        "name": "Baltara",
        "value": "Baltara"
    },
    {
        "name": "Balua",
        "value": "Balua"
    },
    {
        "name": "Balua Rampur",
        "value": "Balua Rampur"
    },
    {
        "name": "Balugan",
        "value": "Balugan"
    },
    {
        "name": "Balumath",
        "value": "Balumath"
    },
    {
        "name": "Balupur",
        "value": "Balupur"
    },
    {
        "name": "Balurghat",
        "value": "Balurghat"
    },
    {
        "name": "Balussheri",
        "value": "Balussheri"
    },
    {
        "name": "Balvadi",
        "value": "Balvadi"
    },
    {
        "name": "Balwa",
        "value": "Balwa"
    },
    {
        "name": "Bamaiya Harlal",
        "value": "Bamaiya Harlal"
    },
    {
        "name": "Bamaur",
        "value": "Bamaur"
    },
    {
        "name": "Bamhni",
        "value": "Bamhni"
    },
    {
        "name": "Bamora",
        "value": "Bamora"
    },
    {
        "name": "Banagi",
        "value": "Banagi"
    },
    {
        "name": "Banapur",
        "value": "Banapur"
    },
    {
        "name": "Banaso",
        "value": "Banaso"
    },
    {
        "name": "Banavar",
        "value": "Banavar"
    },
    {
        "name": "Banbhag",
        "value": "Banbhag"
    },
    {
        "name": "Banda",
        "value": "Banda"
    },
    {
        "name": "Bandalli",
        "value": "Bandalli"
    },
    {
        "name": "Bandamurlanka",
        "value": "Bandamurlanka"
    },
    {
        "name": "Bandipura",
        "value": "Bandipura"
    },
    {
        "name": "Bandlaguda",
        "value": "Bandlaguda"
    },
    {
        "name": "Bandora",
        "value": "Bandora"
    },
    {
        "name": "Bandwar",
        "value": "Bandwar"
    },
    {
        "name": "Banga",
        "value": "Banga"
    },
    {
        "name": "Bangalore",
        "value": "Bangalore"
    },
    {
        "name": "Banganapalle",
        "value": "Banganapalle"
    },
    {
        "name": "Bangaon",
        "value": "Bangaon"
    },
    {
        "name": "Bangawan",
        "value": "Bangawan"
    },
    {
        "name": "Bangramanjeshvara",
        "value": "Bangramanjeshvara"
    },
    {
        "name": "Banhatti",
        "value": "Banhatti"
    },
    {
        "name": "Bank",
        "value": "Bank"
    },
    {
        "name": "Bankapur",
        "value": "Bankapur"
    },
    {
        "name": "Bankheri",
        "value": "Bankheri"
    },
    {
        "name": "Banki",
        "value": "Banki"
    },
    {
        "name": "Bankra",
        "value": "Bankra"
    },
    {
        "name": "Bankura",
        "value": "Bankura"
    },
    {
        "name": "Banni",
        "value": "Banni"
    },
    {
        "name": "Bannur",
        "value": "Bannur"
    },
    {
        "name": "Bansbari",
        "value": "Bansbari"
    },
    {
        "name": "Bansbaria",
        "value": "Bansbaria"
    },
    {
        "name": "Banswada",
        "value": "Banswada"
    },
    {
        "name": "Banta",
        "value": "Banta"
    },
    {
        "name": "Bantval",
        "value": "Bantval"
    },
    {
        "name": "Banu Chhapra",
        "value": "Banu Chhapra"
    },
    {
        "name": "Bapatla",
        "value": "Bapatla"
    },
    {
        "name": "Bapaura",
        "value": "Bapaura"
    },
    {
        "name": "Baqiabad",
        "value": "Baqiabad"
    },
    {
        "name": "Bar Bigha",
        "value": "Bar Bigha"
    },
    {
        "name": "Bara",
        "value": "Bara"
    },
    {
        "name": "Bara Belun",
        "value": "Bara Belun"
    },
    {
        "name": "Bara Khurd",
        "value": "Bara Khurd"
    },
    {
        "name": "Bara Malehra",
        "value": "Bara Malehra"
    },
    {
        "name": "Baragaon",
        "value": "Baragaon"
    },
    {
        "name": "Barah",
        "value": "Barah"
    },
    {
        "name": "Barahari",
        "value": "Barahari"
    },
    {
        "name": "Barahbatta",
        "value": "Barahbatta"
    },
    {
        "name": "Barahi",
        "value": "Barahi"
    },
    {
        "name": "Barahkurwa",
        "value": "Barahkurwa"
    },
    {
        "name": "Barahpur",
        "value": "Barahpur"
    },
    {
        "name": "Barahra",
        "value": "Barahra"
    },
    {
        "name": "Barai",
        "value": "Barai"
    },
    {
        "name": "Baraidih",
        "value": "Baraidih"
    },
    {
        "name": "Barajor",
        "value": "Barajor"
    },
    {
        "name": "Barakpur",
        "value": "Barakpur"
    },
    {
        "name": "Baramati",
        "value": "Baramati"
    },
    {
        "name": "Baramula",
        "value": "Baramula"
    },
    {
        "name": "Baraon",
        "value": "Baraon"
    },
    {
        "name": "Bararam",
        "value": "Bararam"
    },
    {
        "name": "Barari",
        "value": "Barari"
    },
    {
        "name": "Barasat",
        "value": "Barasat"
    },
    {
        "name": "Barauli",
        "value": "Barauli"
    },
    {
        "name": "Barbana",
        "value": "Barbana"
    },
    {
        "name": "Barddhaman",
        "value": "Barddhaman"
    },
    {
        "name": "Bardiha Turki",
        "value": "Bardiha Turki"
    },
    {
        "name": "Bardipuram",
        "value": "Bardipuram"
    },
    {
        "name": "Bardoli",
        "value": "Bardoli"
    },
    {
        "name": "Bareh",
        "value": "Bareh"
    },
    {
        "name": "Bareilly",
        "value": "Bareilly"
    },
    {
        "name": "Bareja",
        "value": "Bareja"
    },
    {
        "name": "Barela",
        "value": "Barela"
    },
    {
        "name": "Baretha",
        "value": "Baretha"
    },
    {
        "name": "Bargaon",
        "value": "Bargaon"
    },
    {
        "name": "Bargarh",
        "value": "Bargarh"
    },
    {
        "name": "Barghat",
        "value": "Barghat"
    },
    {
        "name": "Bargur",
        "value": "Bargur"
    },
    {
        "name": "Barhagarh",
        "value": "Barhagarh"
    },
    {
        "name": "Barhampur",
        "value": "Barhampur"
    },
    {
        "name": "Barhan",
        "value": "Barhan"
    },
    {
        "name": "Barharia",
        "value": "Barharia"
    },
    {
        "name": "Barharwa",
        "value": "Barharwa"
    },
    {
        "name": "Barharwa Kalan",
        "value": "Barharwa Kalan"
    },
    {
        "name": "Barhauna",
        "value": "Barhauna"
    },
    {
        "name": "Barhi",
        "value": "Barhi"
    },
    {
        "name": "Barhiya",
        "value": "Barhiya"
    },
    {
        "name": "Barhni",
        "value": "Barhni"
    },
    {
        "name": "Bari",
        "value": "Bari"
    },
    {
        "name": "Bariariya",
        "value": "Bariariya"
    },
    {
        "name": "Bariariya Tola Rajpur",
        "value": "Bariariya Tola Rajpur"
    },
    {
        "name": "Bariarpur",
        "value": "Bariarpur"
    },
    {
        "name": "Bariarpur Kandh",
        "value": "Bariarpur Kandh"
    },
    {
        "name": "Barigarh",
        "value": "Barigarh"
    },
    {
        "name": "Baripada",
        "value": "Baripada"
    },
    {
        "name": "Bariyarpur",
        "value": "Bariyarpur"
    },
    {
        "name": "Barjhar",
        "value": "Barjhar"
    },
    {
        "name": "Barjora",
        "value": "Barjora"
    },
    {
        "name": "Barka Gaon",
        "value": "Barka Gaon"
    },
    {
        "name": "Barka Parbatta",
        "value": "Barka Parbatta"
    },
    {
        "name": "Barkagaon",
        "value": "Barkagaon"
    },
    {
        "name": "Barki Ballia",
        "value": "Barki Ballia"
    },
    {
        "name": "Barki Saria",
        "value": "Barki Saria"
    },
    {
        "name": "Barkot",
        "value": "Barkot"
    },
    {
        "name": "Barkuhi",
        "value": "Barkuhi"
    },
    {
        "name": "Barnagar",
        "value": "Barnagar"
    },
    {
        "name": "Barnala",
        "value": "Barnala"
    },
    {
        "name": "Barnaon",
        "value": "Barnaon"
    },
    {
        "name": "Barni",
        "value": "Barni"
    },
    {
        "name": "Barnia",
        "value": "Barnia"
    },
    {
        "name": "Barnoi",
        "value": "Barnoi"
    },
    {
        "name": "Baro",
        "value": "Baro"
    },
    {
        "name": "Barod",
        "value": "Barod"
    },
    {
        "name": "Baroda",
        "value": "Baroda"
    },
    {
        "name": "Barokhar",
        "value": "Barokhar"
    },
    {
        "name": "Baroni Khurd",
        "value": "Baroni Khurd"
    },
    {
        "name": "Barpathar",
        "value": "Barpathar"
    },
    {
        "name": "Barpeta",
        "value": "Barpeta"
    },
    {
        "name": "Barsaun",
        "value": "Barsaun"
    },
    {
        "name": "Baruari",
        "value": "Baruari"
    },
    {
        "name": "Barud",
        "value": "Barud"
    },
    {
        "name": "Barughutu",
        "value": "Barughutu"
    },
    {
        "name": "Baruipur",
        "value": "Baruipur"
    },
    {
        "name": "Barun",
        "value": "Barun"
    },
    {
        "name": "Baruni",
        "value": "Baruni"
    },
    {
        "name": "Barvala",
        "value": "Barvala"
    },
    {
        "name": "Barwa Sagar",
        "value": "Barwa Sagar"
    },
    {
        "name": "Barwadih",
        "value": "Barwadih"
    },
    {
        "name": "Barwah",
        "value": "Barwah"
    },
    {
        "name": "Barwan",
        "value": "Barwan"
    },
    {
        "name": "Barwani",
        "value": "Barwani"
    },
    {
        "name": "Barwat Pasrain",
        "value": "Barwat Pasrain"
    },
    {
        "name": "Basaha",
        "value": "Basaha"
    },
    {
        "name": "Basaithi",
        "value": "Basaithi"
    },
    {
        "name": "Basantpur",
        "value": "Basantpur"
    },
    {
        "name": "Basaon",
        "value": "Basaon"
    },
    {
        "name": "Basapatna",
        "value": "Basapatna"
    },
    {
        "name": "Basarh",
        "value": "Basarh"
    },
    {
        "name": "Basatpur",
        "value": "Basatpur"
    },
    {
        "name": "Basavakalyan",
        "value": "Basavakalyan"
    },
    {
        "name": "Basavana Bagevadi",
        "value": "Basavana Bagevadi"
    },
    {
        "name": "Basbiti",
        "value": "Basbiti"
    },
    {
        "name": "Basdeopur",
        "value": "Basdeopur"
    },
    {
        "name": "Basi",
        "value": "Basi"
    },
    {
        "name": "Basirhat",
        "value": "Basirhat"
    },
    {
        "name": "Basmanpur",
        "value": "Basmanpur"
    },
    {
        "name": "Basni",
        "value": "Basni"
    },
    {
        "name": "Basoda",
        "value": "Basoda"
    },
    {
        "name": "Basopatti",
        "value": "Basopatti"
    },
    {
        "name": "Basrur",
        "value": "Basrur"
    },
    {
        "name": "Bassian",
        "value": "Bassian"
    },
    {
        "name": "Basudebpur",
        "value": "Basudebpur"
    },
    {
        "name": "Batala",
        "value": "Batala"
    },
    {
        "name": "Bataredh",
        "value": "Bataredh"
    },
    {
        "name": "Bathnaha",
        "value": "Bathnaha"
    },
    {
        "name": "Battulapalle",
        "value": "Battulapalle"
    },
    {
        "name": "Baturbari",
        "value": "Baturbari"
    },
    {
        "name": "Baud",
        "value": "Baud"
    },
    {
        "name": "Baulia",
        "value": "Baulia"
    },
    {
        "name": "Bauria",
        "value": "Bauria"
    },
    {
        "name": "Bawali",
        "value": "Bawali"
    },
    {
        "name": "Bawana",
        "value": "Bawana"
    },
    {
        "name": "Bayaram",
        "value": "Bayaram"
    },
    {
        "name": "Baynala",
        "value": "Baynala"
    },
    {
        "name": "Bayyanagudem",
        "value": "Bayyanagudem"
    },
    {
        "name": "Bayyavaram",
        "value": "Bayyavaram"
    },
    {
        "name": "Bazid Chak Kasturi",
        "value": "Bazid Chak Kasturi"
    },
    {
        "name": "Bazidpur",
        "value": "Bazidpur"
    },
    {
        "name": "Bazidpur Madhaul",
        "value": "Bazidpur Madhaul"
    },
    {
        "name": "Beawar",
        "value": "Beawar"
    },
    {
        "name": "Begamganj",
        "value": "Begamganj"
    },
    {
        "name": "Begampur",
        "value": "Begampur"
    },
    {
        "name": "Begowal",
        "value": "Begowal"
    },
    {
        "name": "Begusarai",
        "value": "Begusarai"
    },
    {
        "name": "Behat",
        "value": "Behat"
    },
    {
        "name": "Behror",
        "value": "Behror"
    },
    {
        "name": "Beko",
        "value": "Beko"
    },
    {
        "name": "Bela",
        "value": "Bela"
    },
    {
        "name": "Bela Simri",
        "value": "Bela Simri"
    },
    {
        "name": "Beladi",
        "value": "Beladi"
    },
    {
        "name": "Belagal",
        "value": "Belagal"
    },
    {
        "name": "Belagola",
        "value": "Belagola"
    },
    {
        "name": "Belakoba",
        "value": "Belakoba"
    },
    {
        "name": "Belakvadi",
        "value": "Belakvadi"
    },
    {
        "name": "Belampalli",
        "value": "Belampalli"
    },
    {
        "name": "Belao",
        "value": "Belao"
    },
    {
        "name": "Belari",
        "value": "Belari"
    },
    {
        "name": "Belauncha",
        "value": "Belauncha"
    },
    {
        "name": "Belaur",
        "value": "Belaur"
    },
    {
        "name": "Beldanga",
        "value": "Beldanga"
    },
    {
        "name": "Beldaur",
        "value": "Beldaur"
    },
    {
        "name": "Belgaum",
        "value": "Belgaum"
    },
    {
        "name": "Belhatti",
        "value": "Belhatti"
    },
    {
        "name": "Belhi",
        "value": "Belhi"
    },
    {
        "name": "Bellamkonda",
        "value": "Bellamkonda"
    },
    {
        "name": "Bellary",
        "value": "Bellary"
    },
    {
        "name": "Bellatti",
        "value": "Bellatti"
    },
    {
        "name": "Belma",
        "value": "Belma"
    },
    {
        "name": "Belo",
        "value": "Belo"
    },
    {
        "name": "Belonia",
        "value": "Belonia"
    },
    {
        "name": "Belpara",
        "value": "Belpara"
    },
    {
        "name": "Belpukur",
        "value": "Belpukur"
    },
    {
        "name": "Belsand",
        "value": "Belsand"
    },
    {
        "name": "Belsandi Tara",
        "value": "Belsandi Tara"
    },
    {
        "name": "Belsara",
        "value": "Belsara"
    },
    {
        "name": "Beltangadi",
        "value": "Beltangadi"
    },
    {
        "name": "Belur",
        "value": "Belur"
    },
    {
        "name": "Belwa",
        "value": "Belwa"
    },
    {
        "name": "Belwara",
        "value": "Belwara"
    },
    {
        "name": "Benaulim",
        "value": "Benaulim"
    },
    {
        "name": "Bendapudi",
        "value": "Bendapudi"
    },
    {
        "name": "Bendarhalli",
        "value": "Bendarhalli"
    },
    {
        "name": "Bendrahalli",
        "value": "Bendrahalli"
    },
    {
        "name": "Benipati",
        "value": "Benipati"
    },
    {
        "name": "Beohari",
        "value": "Beohari"
    },
    {
        "name": "Berchha",
        "value": "Berchha"
    },
    {
        "name": "Berikai",
        "value": "Berikai"
    },
    {
        "name": "Bermo",
        "value": "Bermo"
    },
    {
        "name": "Berubari",
        "value": "Berubari"
    },
    {
        "name": "Besagarahalli",
        "value": "Besagarahalli"
    },
    {
        "name": "Besarh",
        "value": "Besarh"
    },
    {
        "name": "Betamcherla",
        "value": "Betamcherla"
    },
    {
        "name": "Betigeri",
        "value": "Betigeri"
    },
    {
        "name": "Betma",
        "value": "Betma"
    },
    {
        "name": "Betmangala",
        "value": "Betmangala"
    },
    {
        "name": "Betnoti",
        "value": "Betnoti"
    },
    {
        "name": "Bettiah",
        "value": "Bettiah"
    },
    {
        "name": "Betul Bazar",
        "value": "Betul Bazar"
    },
    {
        "name": "Beur",
        "value": "Beur"
    },
    {
        "name": "Beypore",
        "value": "Beypore"
    },
    {
        "name": "Bhabanipur",
        "value": "Bhabanipur"
    },
    {
        "name": "Bhabhua",
        "value": "Bhabhua"
    },
    {
        "name": "Bhachau",
        "value": "Bhachau"
    },
    {
        "name": "Bhachhi",
        "value": "Bhachhi"
    },
    {
        "name": "Bhachhi Asli",
        "value": "Bhachhi Asli"
    },
    {
        "name": "Bhadarwah",
        "value": "Bhadarwah"
    },
    {
        "name": "Bhadas",
        "value": "Bhadas"
    },
    {
        "name": "Bhadaur",
        "value": "Bhadaur"
    },
    {
        "name": "Bhado Khara",
        "value": "Bhado Khara"
    },
    {
        "name": "Bhadohi",
        "value": "Bhadohi"
    },
    {
        "name": "Bhadrachalam",
        "value": "Bhadrachalam"
    },
    {
        "name": "Bhadrakh",
        "value": "Bhadrakh"
    },
    {
        "name": "Bhadravati",
        "value": "Bhadravati"
    },
    {
        "name": "Bhadreswar",
        "value": "Bhadreswar"
    },
    {
        "name": "Bhadsara",
        "value": "Bhadsara"
    },
    {
        "name": "Bhadwar",
        "value": "Bhadwar"
    },
    {
        "name": "Bhagabanpur",
        "value": "Bhagabanpur"
    },
    {
        "name": "Bhagalpur",
        "value": "Bhagalpur"
    },
    {
        "name": "Bhagatpur",
        "value": "Bhagatpur"
    },
    {
        "name": "Bhagirathpur",
        "value": "Bhagirathpur"
    },
    {
        "name": "Bhagsar",
        "value": "Bhagsar"
    },
    {
        "name": "Bhagta",
        "value": "Bhagta"
    },
    {
        "name": "Bhagwangola",
        "value": "Bhagwangola"
    },
    {
        "name": "Bhagwanpur Desua",
        "value": "Bhagwanpur Desua"
    },
    {
        "name": "Bhagwanpur Khurd",
        "value": "Bhagwanpur Khurd"
    },
    {
        "name": "Bhagwatpur",
        "value": "Bhagwatpur"
    },
    {
        "name": "Bhai Rupa",
        "value": "Bhai Rupa"
    },
    {
        "name": "Bhainsa",
        "value": "Bhainsa"
    },
    {
        "name": "Bhainsahi",
        "value": "Bhainsahi"
    },
    {
        "name": "Bhainsdehi",
        "value": "Bhainsdehi"
    },
    {
        "name": "Bhainsoda",
        "value": "Bhainsoda"
    },
    {
        "name": "Bhairapura",
        "value": "Bhairapura"
    },
    {
        "name": "Bhaisalotan",
        "value": "Bhaisalotan"
    },
    {
        "name": "Bhakua",
        "value": "Bhakua"
    },
    {
        "name": "Bhalki",
        "value": "Bhalki"
    },
    {
        "name": "Bhalpatti",
        "value": "Bhalpatti"
    },
    {
        "name": "Bhalswa Jahangirpur",
        "value": "Bhalswa Jahangirpur"
    },
    {
        "name": "Bhaluhar",
        "value": "Bhaluhar"
    },
    {
        "name": "Bhambia Bhai",
        "value": "Bhambia Bhai"
    },
    {
        "name": "Bhanas Hivre",
        "value": "Bhanas Hivre"
    },
    {
        "name": "Bhandarso",
        "value": "Bhandarso"
    },
    {
        "name": "Bhander",
        "value": "Bhander"
    },
    {
        "name": "Bhangar",
        "value": "Bhangar"
    },
    {
        "name": "Bhangha",
        "value": "Bhangha"
    },
    {
        "name": "Bhanghi",
        "value": "Bhanghi"
    },
    {
        "name": "Bhankarpur",
        "value": "Bhankarpur"
    },
    {
        "name": "Bhanpura",
        "value": "Bhanpura"
    },
    {
        "name": "Bhansia",
        "value": "Bhansia"
    },
    {
        "name": "Bhanukumari",
        "value": "Bhanukumari"
    },
    {
        "name": "Bhanumukkala",
        "value": "Bhanumukkala"
    },
    {
        "name": "Bhanuvalli",
        "value": "Bhanuvalli"
    },
    {
        "name": "Bhanvad",
        "value": "Bhanvad"
    },
    {
        "name": "Bharanikavu Tekku",
        "value": "Bharanikavu Tekku"
    },
    {
        "name": "Bharatpur",
        "value": "Bharatpur"
    },
    {
        "name": "Bhargaon",
        "value": "Bhargaon"
    },
    {
        "name": "Bharhopur",
        "value": "Bharhopur"
    },
    {
        "name": "Bharno",
        "value": "Bharno"
    },
    {
        "name": "Bharokhara",
        "value": "Bharokhara"
    },
    {
        "name": "Bharra",
        "value": "Bharra"
    },
    {
        "name": "Bharuch",
        "value": "Bharuch"
    },
    {
        "name": "Bharweli",
        "value": "Bharweli"
    },
    {
        "name": "Bhasaula Danapur",
        "value": "Bhasaula Danapur"
    },
    {
        "name": "Bhasawar",
        "value": "Bhasawar"
    },
    {
        "name": "Bhataulia",
        "value": "Bhataulia"
    },
    {
        "name": "Bhatinda",
        "value": "Bhatinda"
    },
    {
        "name": "Bhatkal",
        "value": "Bhatkal"
    },
    {
        "name": "Bhatkhori",
        "value": "Bhatkhori"
    },
    {
        "name": "Bhatpalli",
        "value": "Bhatpalli"
    },
    {
        "name": "Bhatpara",
        "value": "Bhatpara"
    },
    {
        "name": "Bhatpuri",
        "value": "Bhatpuri"
    },
    {
        "name": "Bhatranha",
        "value": "Bhatranha"
    },
    {
        "name": "Bhattiprolu",
        "value": "Bhattiprolu"
    },
    {
        "name": "Bhattu Kolan",
        "value": "Bhattu Kolan"
    },
    {
        "name": "Bhaur",
        "value": "Bhaur"
    },
    {
        "name": "Bhauradah",
        "value": "Bhauradah"
    },
    {
        "name": "Bhavani",
        "value": "Bhavani"
    },
    {
        "name": "Bhavnagar",
        "value": "Bhavnagar"
    },
    {
        "name": "Bhawanandpur",
        "value": "Bhawanandpur"
    },
    {
        "name": "Bhawanigarh",
        "value": "Bhawanigarh"
    },
    {
        "name": "Bhawanipatna",
        "value": "Bhawanipatna"
    },
    {
        "name": "Bhawanipur",
        "value": "Bhawanipur"
    },
    {
        "name": "Bhawanipur Rajdham",
        "value": "Bhawanipur Rajdham"
    },
    {
        "name": "Bhayala",
        "value": "Bhayala"
    },
    {
        "name": "Bhayandar",
        "value": "Bhayandar"
    },
    {
        "name": "Bheja",
        "value": "Bheja"
    },
    {
        "name": "Bhelsi",
        "value": "Bhelsi"
    },
    {
        "name": "Bhikhi",
        "value": "Bhikhi"
    },
    {
        "name": "Bhikkiwind Uttar",
        "value": "Bhikkiwind Uttar"
    },
    {
        "name": "Bhilai",
        "value": "Bhilai"
    },
    {
        "name": "Bhilai Karanja",
        "value": "Bhilai Karanja"
    },
    {
        "name": "Bhilavadi",
        "value": "Bhilavadi"
    },
    {
        "name": "Bhiloda",
        "value": "Bhiloda"
    },
    {
        "name": "Bhilwara",
        "value": "Bhilwara"
    },
    {
        "name": "Bhimadolu",
        "value": "Bhimadolu"
    },
    {
        "name": "Bhimavaram",
        "value": "Bhimavaram"
    },
    {
        "name": "Bhimunipatnam",
        "value": "Bhimunipatnam"
    },
    {
        "name": "Bhind",
        "value": "Bhind"
    },
    {
        "name": "Bhiraha",
        "value": "Bhiraha"
    },
    {
        "name": "Bhirua",
        "value": "Bhirua"
    },
    {
        "name": "Bhit Bhagwanpur",
        "value": "Bhit Bhagwanpur"
    },
    {
        "name": "Bhitarwar",
        "value": "Bhitarwar"
    },
    {
        "name": "Bhiwandi",
        "value": "Bhiwandi"
    },
    {
        "name": "Bhiwani",
        "value": "Bhiwani"
    },
    {
        "name": "Bhogapuram",
        "value": "Bhogapuram"
    },
    {
        "name": "Bhogpur",
        "value": "Bhogpur"
    },
    {
        "name": "Bhoj",
        "value": "Bhoj"
    },
    {
        "name": "Bhojpur Jadid",
        "value": "Bhojpur Jadid"
    },
    {
        "name": "Bhojpur Kadim",
        "value": "Bhojpur Kadim"
    },
    {
        "name": "Bholsar",
        "value": "Bholsar"
    },
    {
        "name": "Bhongir",
        "value": "Bhongir"
    },
    {
        "name": "Bhopal",
        "value": "Bhopal"
    },
    {
        "name": "Bhopalia",
        "value": "Bhopalia"
    },
    {
        "name": "Bhopatpur",
        "value": "Bhopatpur"
    },
    {
        "name": "Bhuban",
        "value": "Bhuban"
    },
    {
        "name": "Bhubaneshwar",
        "value": "Bhubaneshwar"
    },
    {
        "name": "Bhucho Mandi",
        "value": "Bhucho Mandi"
    },
    {
        "name": "Bhui",
        "value": "Bhui"
    },
    {
        "name": "Bhuj",
        "value": "Bhuj"
    },
    {
        "name": "Bhulwal",
        "value": "Bhulwal"
    },
    {
        "name": "Bhusaval",
        "value": "Bhusaval"
    },
    {
        "name": "Biaora",
        "value": "Biaora"
    },
    {
        "name": "Bibhutpur",
        "value": "Bibhutpur"
    },
    {
        "name": "Bibipet",
        "value": "Bibipet"
    },
    {
        "name": "Bichkunda",
        "value": "Bichkunda"
    },
    {
        "name": "Bidar",
        "value": "Bidar"
    },
    {
        "name": "Biddupur",
        "value": "Biddupur"
    },
    {
        "name": "Bideipur",
        "value": "Bideipur"
    },
    {
        "name": "Bihar",
        "value": "Bihar"
    },
    {
        "name": "Bihariganj",
        "value": "Bihariganj"
    },
    {
        "name": "Bihat",
        "value": "Bihat"
    },
    {
        "name": "Bihpur",
        "value": "Bihpur"
    },
    {
        "name": "Bihpuriagaon",
        "value": "Bihpuriagaon"
    },
    {
        "name": "Bihta",
        "value": "Bihta"
    },
    {
        "name": "Bijai",
        "value": "Bijai"
    },
    {
        "name": "Bijaipur",
        "value": "Bijaipur"
    },
    {
        "name": "Bijapur",
        "value": "Bijapur"
    },
    {
        "name": "Bijawar",
        "value": "Bijawar"
    },
    {
        "name": "Bijaynagar",
        "value": "Bijaynagar"
    },
    {
        "name": "Bijbiara",
        "value": "Bijbiara"
    },
    {
        "name": "Bijeraghogarh",
        "value": "Bijeraghogarh"
    },
    {
        "name": "Bijni",
        "value": "Bijni"
    },
    {
        "name": "Bijnor",
        "value": "Bijnor"
    },
    {
        "name": "Bikaner",
        "value": "Bikaner"
    },
    {
        "name": "Bikkatti",
        "value": "Bikkatti"
    },
    {
        "name": "Bikkavolu",
        "value": "Bikkavolu"
    },
    {
        "name": "Biknur",
        "value": "Biknur"
    },
    {
        "name": "Bikramganj",
        "value": "Bikramganj"
    },
    {
        "name": "Bikrampur Bande",
        "value": "Bikrampur Bande"
    },
    {
        "name": "Bilara",
        "value": "Bilara"
    },
    {
        "name": "Bilasipara",
        "value": "Bilasipara"
    },
    {
        "name": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "name": "Bilaua",
        "value": "Bilaua"
    },
    {
        "name": "Bilauri",
        "value": "Bilauri"
    },
    {
        "name": "Bilehra",
        "value": "Bilehra"
    },
    {
        "name": "Bilga",
        "value": "Bilga"
    },
    {
        "name": "Bilgi",
        "value": "Bilgi"
    },
    {
        "name": "Bilimora",
        "value": "Bilimora"
    },
    {
        "name": "Billapadu",
        "value": "Billapadu"
    },
    {
        "name": "Bilpura",
        "value": "Bilpura"
    },
    {
        "name": "Bimawan",
        "value": "Bimawan"
    },
    {
        "name": "Bimgal",
        "value": "Bimgal"
    },
    {
        "name": "Bimun",
        "value": "Bimun"
    },
    {
        "name": "Bina",
        "value": "Bina"
    },
    {
        "name": "Bind",
        "value": "Bind"
    },
    {
        "name": "Bindki",
        "value": "Bindki"
    },
    {
        "name": "Binka",
        "value": "Binka"
    },
    {
        "name": "Binnaguri",
        "value": "Binnaguri"
    },
    {
        "name": "Bir",
        "value": "Bir"
    },
    {
        "name": "Birdaban",
        "value": "Birdaban"
    },
    {
        "name": "Birhana",
        "value": "Birhana"
    },
    {
        "name": "Birmitrapur",
        "value": "Birmitrapur"
    },
    {
        "name": "Birnagar",
        "value": "Birnagar"
    },
    {
        "name": "Birpur",
        "value": "Birpur"
    },
    {
        "name": "Birpur Barapatti Pindraun",
        "value": "Birpur Barapatti Pindraun"
    },
    {
        "name": "Birsinghpur",
        "value": "Birsinghpur"
    },
    {
        "name": "Birur",
        "value": "Birur"
    },
    {
        "name": "Bisaria",
        "value": "Bisaria"
    },
    {
        "name": "Bisaul",
        "value": "Bisaul"
    },
    {
        "name": "Bishamagiri",
        "value": "Bishamagiri"
    },
    {
        "name": "Bishenpur",
        "value": "Bishenpur"
    },
    {
        "name": "Bishnah",
        "value": "Bishnah"
    },
    {
        "name": "Bishnupur",
        "value": "Bishnupur"
    },
    {
        "name": "Bishunpur",
        "value": "Bishunpur"
    },
    {
        "name": "Bishunpur Hakimabad",
        "value": "Bishunpur Hakimabad"
    },
    {
        "name": "Bishunpur Sundar",
        "value": "Bishunpur Sundar"
    },
    {
        "name": "Bishunpura",
        "value": "Bishunpura"
    },
    {
        "name": "Bistan",
        "value": "Bistan"
    },
    {
        "name": "Bistaria",
        "value": "Bistaria"
    },
    {
        "name": "Biswan",
        "value": "Biswan"
    },
    {
        "name": "Biswanath Chariali",
        "value": "Biswanath Chariali"
    },
    {
        "name": "Bithan",
        "value": "Bithan"
    },
    {
        "name": "Bithar",
        "value": "Bithar"
    },
    {
        "name": "Bithauli",
        "value": "Bithauli"
    },
    {
        "name": "Bobbili",
        "value": "Bobbili"
    },
    {
        "name": "Bobil",
        "value": "Bobil"
    },
    {
        "name": "Bobleshwar",
        "value": "Bobleshwar"
    },
    {
        "name": "Bochaha",
        "value": "Bochaha"
    },
    {
        "name": "Bodagudipadu",
        "value": "Bodagudipadu"
    },
    {
        "name": "Boddikurapadu",
        "value": "Boddikurapadu"
    },
    {
        "name": "Bodhan",
        "value": "Bodhan"
    },
    {
        "name": "Bodinayakkanur",
        "value": "Bodinayakkanur"
    },
    {
        "name": "Bodippatti",
        "value": "Bodippatti"
    },
    {
        "name": "Bodupal",
        "value": "Bodupal"
    },
    {
        "name": "Boha",
        "value": "Boha"
    },
    {
        "name": "Bokakhat",
        "value": "Bokakhat"
    },
    {
        "name": "Bokaro",
        "value": "Bokaro"
    },
    {
        "name": "Bokaro Steel City",
        "value": "Bokaro Steel City"
    },
    {
        "name": "Bolpur",
        "value": "Bolpur"
    },
    {
        "name": "Bomareddipalli",
        "value": "Bomareddipalli"
    },
    {
        "name": "Bommagondanahalli",
        "value": "Bommagondanahalli"
    },
    {
        "name": "Bommarbettu",
        "value": "Bommarbettu"
    },
    {
        "name": "Bommayapalaiyam",
        "value": "Bommayapalaiyam"
    },
    {
        "name": "Bondada",
        "value": "Bondada"
    },
    {
        "name": "Bongaigaon",
        "value": "Bongaigaon"
    },
    {
        "name": "Bora",
        "value": "Bora"
    },
    {
        "name": "Borgampad",
        "value": "Borgampad"
    },
    {
        "name": "Borgaon",
        "value": "Borgaon"
    },
    {
        "name": "Boriguma",
        "value": "Boriguma"
    },
    {
        "name": "Borim",
        "value": "Borim"
    },
    {
        "name": "Borio",
        "value": "Borio"
    },
    {
        "name": "Borna",
        "value": "Borna"
    },
    {
        "name": "Borne",
        "value": "Borne"
    },
    {
        "name": "Botad",
        "value": "Botad"
    },
    {
        "name": "Bowringpet",
        "value": "Bowringpet"
    },
    {
        "name": "Brahmadesam",
        "value": "Brahmadesam"
    },
    {
        "name": "Brahmana Periya Agraharam",
        "value": "Brahmana Periya Agraharam"
    },
    {
        "name": "Brahmanandapuram",
        "value": "Brahmanandapuram"
    },
    {
        "name": "Brahmapur",
        "value": "Brahmapur"
    },
    {
        "name": "Brajarajnagar",
        "value": "Brajarajnagar"
    },
    {
        "name": "Bramhabarada",
        "value": "Bramhabarada"
    },
    {
        "name": "Bramhapuri",
        "value": "Bramhapuri"
    },
    {
        "name": "Bucheya",
        "value": "Bucheya"
    },
    {
        "name": "Buchireddipalem",
        "value": "Buchireddipalem"
    },
    {
        "name": "Budalur",
        "value": "Budalur"
    },
    {
        "name": "Budamangalam",
        "value": "Budamangalam"
    },
    {
        "name": "Budaun",
        "value": "Budaun"
    },
    {
        "name": "Buddayyakota",
        "value": "Buddayyakota"
    },
    {
        "name": "Buddh Gaya",
        "value": "Buddh Gaya"
    },
    {
        "name": "Budha Theh",
        "value": "Budha Theh"
    },
    {
        "name": "Budhlada",
        "value": "Budhlada"
    },
    {
        "name": "Budhma",
        "value": "Budhma"
    },
    {
        "name": "Budhni",
        "value": "Budhni"
    },
    {
        "name": "Budili",
        "value": "Budili"
    },
    {
        "name": "Budipuram",
        "value": "Budipuram"
    },
    {
        "name": "Budwan",
        "value": "Budwan"
    },
    {
        "name": "Bugganipalle",
        "value": "Bugganipalle"
    },
    {
        "name": "Buguda",
        "value": "Buguda"
    },
    {
        "name": "Bukkapatnam",
        "value": "Bukkapatnam"
    },
    {
        "name": "Bukkarayasamudram",
        "value": "Bukkarayasamudram"
    },
    {
        "name": "Bulandshahr",
        "value": "Bulandshahr"
    },
    {
        "name": "Bundala",
        "value": "Bundala"
    },
    {
        "name": "Bundehra",
        "value": "Bundehra"
    },
    {
        "name": "Bundi",
        "value": "Bundi"
    },
    {
        "name": "Bundu",
        "value": "Bundu"
    },
    {
        "name": "Buram",
        "value": "Buram"
    },
    {
        "name": "Burhanpur",
        "value": "Burhanpur"
    },
    {
        "name": "Burhar",
        "value": "Burhar"
    },
    {
        "name": "Burhia Dhanghatta",
        "value": "Burhia Dhanghatta"
    },
    {
        "name": "Burla",
        "value": "Burla"
    },
    {
        "name": "Buttar",
        "value": "Buttar"
    },
    {
        "name": "Buttar Khurd",
        "value": "Buttar Khurd"
    },
    {
        "name": "Buttayagudem",
        "value": "Buttayagudem"
    },
    {
        "name": "Buxar",
        "value": "Buxar"
    },
    {
        "name": "Byadgi",
        "value": "Byadgi"
    },
    {
        "name": "Byahatti",
        "value": "Byahatti"
    },
    {
        "name": "Byatarayanpur",
        "value": "Byatarayanpur"
    },
    {
        "name": "Calicut",
        "value": "Calicut"
    },
    {
        "name": "Campiernagar",
        "value": "Campiernagar"
    },
    {
        "name": "Cannanore",
        "value": "Cannanore"
    },
    {
        "name": "Chabal Kalan",
        "value": "Chabal Kalan"
    },
    {
        "name": "Chada",
        "value": "Chada"
    },
    {
        "name": "Chadchan",
        "value": "Chadchan"
    },
    {
        "name": "Chagalamarri",
        "value": "Chagalamarri"
    },
    {
        "name": "Chagallu",
        "value": "Chagallu"
    },
    {
        "name": "Chahatpur",
        "value": "Chahatpur"
    },
    {
        "name": "Chailaha",
        "value": "Chailaha"
    },
    {
        "name": "Chainpur",
        "value": "Chainpur"
    },
    {
        "name": "Chainpura",
        "value": "Chainpura"
    },
    {
        "name": "Chaita",
        "value": "Chaita"
    },
    {
        "name": "Chaital",
        "value": "Chaital"
    },
    {
        "name": "Chak Habib",
        "value": "Chak Habib"
    },
    {
        "name": "Chak Husaini",
        "value": "Chak Husaini"
    },
    {
        "name": "Chak Pahar",
        "value": "Chak Pahar"
    },
    {
        "name": "Chak That",
        "value": "Chak That"
    },
    {
        "name": "Chakai",
        "value": "Chakai"
    },
    {
        "name": "Chakand",
        "value": "Chakand"
    },
    {
        "name": "Chakapara",
        "value": "Chakapara"
    },
    {
        "name": "Chakdaha",
        "value": "Chakdaha"
    },
    {
        "name": "Chakia",
        "value": "Chakia"
    },
    {
        "name": "Chakicherla",
        "value": "Chakicherla"
    },
    {
        "name": "Chakkuvarakal",
        "value": "Chakkuvarakal"
    },
    {
        "name": "Chakla Waini",
        "value": "Chakla Waini"
    },
    {
        "name": "Chaklasi",
        "value": "Chaklasi"
    },
    {
        "name": "Chakpi Karong",
        "value": "Chakpi Karong"
    },
    {
        "name": "Chakradharpur",
        "value": "Chakradharpur"
    },
    {
        "name": "Chakur",
        "value": "Chakur"
    },
    {
        "name": "Chakwai",
        "value": "Chakwai"
    },
    {
        "name": "Chalala",
        "value": "Chalala"
    },
    {
        "name": "Chalgeri",
        "value": "Chalgeri"
    },
    {
        "name": "Chalil",
        "value": "Chalil"
    },
    {
        "name": "Chalkari",
        "value": "Chalkari"
    },
    {
        "name": "Challakere",
        "value": "Challakere"
    },
    {
        "name": "Challapalle",
        "value": "Challapalle"
    },
    {
        "name": "Chaltabaria",
        "value": "Chaltabaria"
    },
    {
        "name": "Chalungalpadam",
        "value": "Chalungalpadam"
    },
    {
        "name": "Chamarru",
        "value": "Chamarru"
    },
    {
        "name": "Champadanga",
        "value": "Champadanga"
    },
    {
        "name": "Champahati",
        "value": "Champahati"
    },
    {
        "name": "Champapur",
        "value": "Champapur"
    },
    {
        "name": "Champdani",
        "value": "Champdani"
    },
    {
        "name": "Champua",
        "value": "Champua"
    },
    {
        "name": "Chamrajnagar",
        "value": "Chamrajnagar"
    },
    {
        "name": "Chamtha",
        "value": "Chamtha"
    },
    {
        "name": "Chanasma",
        "value": "Chanasma"
    },
    {
        "name": "Chanaur",
        "value": "Chanaur"
    },
    {
        "name": "Chand Chaur",
        "value": "Chand Chaur"
    },
    {
        "name": "Chanda",
        "value": "Chanda"
    },
    {
        "name": "Chandankiari",
        "value": "Chandankiari"
    },
    {
        "name": "Chandannagar",
        "value": "Chandannagar"
    },
    {
        "name": "Chandauli",
        "value": "Chandauli"
    },
    {
        "name": "Chandera",
        "value": "Chandera"
    },
    {
        "name": "Chanderi",
        "value": "Chanderi"
    },
    {
        "name": "Chandhaus",
        "value": "Chandhaus"
    },
    {
        "name": "Chandi",
        "value": "Chandi"
    },
    {
        "name": "Chandia",
        "value": "Chandia"
    },
    {
        "name": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "name": "Chandili",
        "value": "Chandili"
    },
    {
        "name": "Chandla",
        "value": "Chandla"
    },
    {
        "name": "Chandpur",
        "value": "Chandpur"
    },
    {
        "name": "Chandpura",
        "value": "Chandpura"
    },
    {
        "name": "Chandragiri",
        "value": "Chandragiri"
    },
    {
        "name": "Chandrakona",
        "value": "Chandrakona"
    },
    {
        "name": "Chandralapadu",
        "value": "Chandralapadu"
    },
    {
        "name": "Chandrawada",
        "value": "Chandrawada"
    },
    {
        "name": "Chandreru",
        "value": "Chandreru"
    },
    {
        "name": "Chandur",
        "value": "Chandur"
    },
    {
        "name": "Chandwa",
        "value": "Chandwa"
    },
    {
        "name": "Chandwara",
        "value": "Chandwara"
    },
    {
        "name": "Chang",
        "value": "Chang"
    },
    {
        "name": "Changamkari",
        "value": "Changamkari"
    },
    {
        "name": "Changanacheri",
        "value": "Changanacheri"
    },
    {
        "name": "Channagiri",
        "value": "Channagiri"
    },
    {
        "name": "Channapatna",
        "value": "Channapatna"
    },
    {
        "name": "Channarayapatna",
        "value": "Channarayapatna"
    },
    {
        "name": "Channubanda",
        "value": "Channubanda"
    },
    {
        "name": "Chanp",
        "value": "Chanp"
    },
    {
        "name": "Chanwari",
        "value": "Chanwari"
    },
    {
        "name": "Chaoke",
        "value": "Chaoke"
    },
    {
        "name": "Chapalamadugu",
        "value": "Chapalamadugu"
    },
    {
        "name": "Chapra",
        "value": "Chapra"
    },
    {
        "name": "Charabidya",
        "value": "Charabidya"
    },
    {
        "name": "Charakunda",
        "value": "Charakunda"
    },
    {
        "name": "Charaut",
        "value": "Charaut"
    },
    {
        "name": "Charipara",
        "value": "Charipara"
    },
    {
        "name": "Chariyakulam",
        "value": "Chariyakulam"
    },
    {
        "name": "Charkhari",
        "value": "Charkhari"
    },
    {
        "name": "Charkhi Dadri",
        "value": "Charkhi Dadri"
    },
    {
        "name": "Charne",
        "value": "Charne"
    },
    {
        "name": "Charthawal",
        "value": "Charthawal"
    },
    {
        "name": "Charuanwan",
        "value": "Charuanwan"
    },
    {
        "name": "Chas",
        "value": "Chas"
    },
    {
        "name": "Chatayamangalam",
        "value": "Chatayamangalam"
    },
    {
        "name": "Chatia",
        "value": "Chatia"
    },
    {
        "name": "Chatra",
        "value": "Chatra"
    },
    {
        "name": "Chatra Gobraura",
        "value": "Chatra Gobraura"
    },
    {
        "name": "Chattamangalam",
        "value": "Chattamangalam"
    },
    {
        "name": "Chaugain",
        "value": "Chaugain"
    },
    {
        "name": "Chauhanpatti",
        "value": "Chauhanpatti"
    },
    {
        "name": "Chaukhata",
        "value": "Chaukhata"
    },
    {
        "name": "Chauki",
        "value": "Chauki"
    },
    {
        "name": "Chauki Hasan Chauki Makhdum",
        "value": "Chauki Hasan Chauki Makhdum"
    },
    {
        "name": "Chausa",
        "value": "Chausa"
    },
    {
        "name": "Chautapal",
        "value": "Chautapal"
    },
    {
        "name": "Chautham",
        "value": "Chautham"
    },
    {
        "name": "Chavakkad",
        "value": "Chavakkad"
    },
    {
        "name": "Chavara Gramam",
        "value": "Chavara Gramam"
    },
    {
        "name": "Chavassheri",
        "value": "Chavassheri"
    },
    {
        "name": "Chavuttahalli",
        "value": "Chavuttahalli"
    },
    {
        "name": "Chawalhati",
        "value": "Chawalhati"
    },
    {
        "name": "Chebrolu",
        "value": "Chebrolu"
    },
    {
        "name": "Chegur",
        "value": "Chegur"
    },
    {
        "name": "Chegurumomadi",
        "value": "Chegurumomadi"
    },
    {
        "name": "Chekkal",
        "value": "Chekkal"
    },
    {
        "name": "Chelora",
        "value": "Chelora"
    },
    {
        "name": "Chemancheri",
        "value": "Chemancheri"
    },
    {
        "name": "Chembagaramanpudur",
        "value": "Chembagaramanpudur"
    },
    {
        "name": "Chembra",
        "value": "Chembra"
    },
    {
        "name": "Chembrassheri",
        "value": "Chembrassheri"
    },
    {
        "name": "Chemmanam",
        "value": "Chemmanam"
    },
    {
        "name": "Chemmaruthi",
        "value": "Chemmaruthi"
    },
    {
        "name": "Chempalli",
        "value": "Chempalli"
    },
    {
        "name": "Chengam",
        "value": "Chengam"
    },
    {
        "name": "Chengannur",
        "value": "Chengannur"
    },
    {
        "name": "Chengara",
        "value": "Chengara"
    },
    {
        "name": "Chennai",
        "value": "Chennai"
    },
    {
        "name": "Chennamangalam",
        "value": "Chennamangalam"
    },
    {
        "name": "Chennampatti",
        "value": "Chennampatti"
    },
    {
        "name": "Chennimalai",
        "value": "Chennimalai"
    },
    {
        "name": "Chennirkara",
        "value": "Chennirkara"
    },
    {
        "name": "Chennur",
        "value": "Chennur"
    },
    {
        "name": "Cherakara",
        "value": "Cherakara"
    },
    {
        "name": "Cherakhera",
        "value": "Cherakhera"
    },
    {
        "name": "Cheramkod",
        "value": "Cheramkod"
    },
    {
        "name": "Cheran",
        "value": "Cheran"
    },
    {
        "name": "Cheranallur",
        "value": "Cheranallur"
    },
    {
        "name": "Cherial",
        "value": "Cherial"
    },
    {
        "name": "Cherlagandlapalem",
        "value": "Cherlagandlapalem"
    },
    {
        "name": "Chero",
        "value": "Chero"
    },
    {
        "name": "Cherrapunji",
        "value": "Cherrapunji"
    },
    {
        "name": "Cherukara",
        "value": "Cherukara"
    },
    {
        "name": "Cherukolattur",
        "value": "Cherukolattur"
    },
    {
        "name": "Cherukunnu",
        "value": "Cherukunnu"
    },
    {
        "name": "Cherupazhasshi",
        "value": "Cherupazhasshi"
    },
    {
        "name": "Cherupulassheri",
        "value": "Cherupulassheri"
    },
    {
        "name": "Cherutana Tekku",
        "value": "Cherutana Tekku"
    },
    {
        "name": "Cheruvannur",
        "value": "Cheruvannur"
    },
    {
        "name": "Cheruvaranam",
        "value": "Cheruvaranam"
    },
    {
        "name": "Chethakal",
        "value": "Chethakal"
    },
    {
        "name": "Cheticheri",
        "value": "Cheticheri"
    },
    {
        "name": "Chettikulam",
        "value": "Chettikulam"
    },
    {
        "name": "Chettimangurichchi",
        "value": "Chettimangurichchi"
    },
    {
        "name": "Chettinayakkanpatti",
        "value": "Chettinayakkanpatti"
    },
    {
        "name": "Chettipalaiyam",
        "value": "Chettipalaiyam"
    },
    {
        "name": "Chettipulam",
        "value": "Chettipulam"
    },
    {
        "name": "Chettiyapatti",
        "value": "Chettiyapatti"
    },
    {
        "name": "Chevella",
        "value": "Chevella"
    },
    {
        "name": "Chewara",
        "value": "Chewara"
    },
    {
        "name": "Cheyyar",
        "value": "Cheyyar"
    },
    {
        "name": "Chhabila",
        "value": "Chhabila"
    },
    {
        "name": "Chhajli",
        "value": "Chhajli"
    },
    {
        "name": "Chhanera",
        "value": "Chhanera"
    },
    {
        "name": "Chhapera",
        "value": "Chhapera"
    },
    {
        "name": "Chhapia",
        "value": "Chhapia"
    },
    {
        "name": "Chhapra",
        "value": "Chhapra"
    },
    {
        "name": "Chhapra Bahas",
        "value": "Chhapra Bahas"
    },
    {
        "name": "Chhara",
        "value": "Chhara"
    },
    {
        "name": "Chhatapur",
        "value": "Chhatapur"
    },
    {
        "name": "Chhatarpur",
        "value": "Chhatarpur"
    },
    {
        "name": "Chhimluang",
        "value": "Chhimluang"
    },
    {
        "name": "Chhindwara",
        "value": "Chhindwara"
    },
    {
        "name": "Chhota Udepur",
        "value": "Chhota Udepur"
    },
    {
        "name": "Chhoti Sadri",
        "value": "Chhoti Sadri"
    },
    {
        "name": "Chianki",
        "value": "Chianki"
    },
    {
        "name": "Chicacole",
        "value": "Chicacole"
    },
    {
        "name": "Chichli",
        "value": "Chichli"
    },
    {
        "name": "Chicholi",
        "value": "Chicholi"
    },
    {
        "name": "Chidambaram",
        "value": "Chidambaram"
    },
    {
        "name": "Chik Ballapur",
        "value": "Chik Ballapur"
    },
    {
        "name": "Chik Banavar",
        "value": "Chik Banavar"
    },
    {
        "name": "Chikhli Kalan",
        "value": "Chikhli Kalan"
    },
    {
        "name": "Chikitigarh",
        "value": "Chikitigarh"
    },
    {
        "name": "Chikkala",
        "value": "Chikkala"
    },
    {
        "name": "Chikkarampalaiyam",
        "value": "Chikkarampalaiyam"
    },
    {
        "name": "Chikmagalur",
        "value": "Chikmagalur"
    },
    {
        "name": "Chiknayakanhalli",
        "value": "Chiknayakanhalli"
    },
    {
        "name": "Chikni",
        "value": "Chikni"
    },
    {
        "name": "Chikodi",
        "value": "Chikodi"
    },
    {
        "name": "Chilakalurupet",
        "value": "Chilakalurupet"
    },
    {
        "name": "Chilakhana",
        "value": "Chilakhana"
    },
    {
        "name": "Chilamatturu",
        "value": "Chilamatturu"
    },
    {
        "name": "Chilkuru",
        "value": "Chilkuru"
    },
    {
        "name": "Chilmil",
        "value": "Chilmil"
    },
    {
        "name": "Chilpur",
        "value": "Chilpur"
    },
    {
        "name": "Chimakurti",
        "value": "Chimakurti"
    },
    {
        "name": "Chimalapadu",
        "value": "Chimalapadu"
    },
    {
        "name": "Chiman",
        "value": "Chiman"
    },
    {
        "name": "Chimthana",
        "value": "Chimthana"
    },
    {
        "name": "Chinaur",
        "value": "Chinaur"
    },
    {
        "name": "Chinaval",
        "value": "Chinaval"
    },
    {
        "name": "Chinchali",
        "value": "Chinchali"
    },
    {
        "name": "Chincholi",
        "value": "Chincholi"
    },
    {
        "name": "Chingleput",
        "value": "Chingleput"
    },
    {
        "name": "Chinna Annaluru",
        "value": "Chinna Annaluru"
    },
    {
        "name": "Chinna Ganjam",
        "value": "Chinna Ganjam"
    },
    {
        "name": "Chinna Gollapalem",
        "value": "Chinna Gollapalem"
    },
    {
        "name": "Chinna Kalaiyamputtur",
        "value": "Chinna Kalaiyamputtur"
    },
    {
        "name": "Chinna Mupparam",
        "value": "Chinna Mupparam"
    },
    {
        "name": "Chinna Mushidivada",
        "value": "Chinna Mushidivada"
    },
    {
        "name": "Chinna Orampadu",
        "value": "Chinna Orampadu"
    },
    {
        "name": "Chinna Salem",
        "value": "Chinna Salem"
    },
    {
        "name": "Chinnachauku",
        "value": "Chinnachauku"
    },
    {
        "name": "Chinnakkampalaiyam",
        "value": "Chinnakkampalaiyam"
    },
    {
        "name": "Chinnakkavundanur",
        "value": "Chinnakkavundanur"
    },
    {
        "name": "Chinnalapatti",
        "value": "Chinnalapatti"
    },
    {
        "name": "Chinnamandem",
        "value": "Chinnamandem"
    },
    {
        "name": "Chinnamanur",
        "value": "Chinnamanur"
    },
    {
        "name": "Chinnampalaiyam",
        "value": "Chinnampalaiyam"
    },
    {
        "name": "Chinnasekkadu",
        "value": "Chinnasekkadu"
    },
    {
        "name": "Chinnatadagam",
        "value": "Chinnatadagam"
    },
    {
        "name": "Chinnavadampatti",
        "value": "Chinnavadampatti"
    },
    {
        "name": "Chinnayagudem",
        "value": "Chinnayagudem"
    },
    {
        "name": "Chintakommadinne",
        "value": "Chintakommadinne"
    },
    {
        "name": "Chintakunta",
        "value": "Chintakunta"
    },
    {
        "name": "Chintalapalle",
        "value": "Chintalapalle"
    },
    {
        "name": "Chintalapalli",
        "value": "Chintalapalli"
    },
    {
        "name": "Chintalapudi",
        "value": "Chintalapudi"
    },
    {
        "name": "Chintalavadi",
        "value": "Chintalavadi"
    },
    {
        "name": "Chintamani",
        "value": "Chintamani"
    },
    {
        "name": "Chintapalle",
        "value": "Chintapalle"
    },
    {
        "name": "Chiplun",
        "value": "Chiplun"
    },
    {
        "name": "Chipurupalle",
        "value": "Chipurupalle"
    },
    {
        "name": "Chirak",
        "value": "Chirak"
    },
    {
        "name": "Chirala",
        "value": "Chirala"
    },
    {
        "name": "Chiramanangad",
        "value": "Chiramanangad"
    },
    {
        "name": "Chiranellur",
        "value": "Chiranellur"
    },
    {
        "name": "Chitaldrug",
        "value": "Chitaldrug"
    },
    {
        "name": "Chitapur",
        "value": "Chitapur"
    },
    {
        "name": "Chitarpur",
        "value": "Chitarpur"
    },
    {
        "name": "Chitauria",
        "value": "Chitauria"
    },
    {
        "name": "Chithara",
        "value": "Chithara"
    },
    {
        "name": "Chitrada",
        "value": "Chitrada"
    },
    {
        "name": "Chittandikavundanur",
        "value": "Chittandikavundanur"
    },
    {
        "name": "Chittaranjan",
        "value": "Chittaranjan"
    },
    {
        "name": "Chittarkottal",
        "value": "Chittarkottal"
    },
    {
        "name": "Chittattukara",
        "value": "Chittattukara"
    },
    {
        "name": "Chittaurgarh",
        "value": "Chittaurgarh"
    },
    {
        "name": "Chittayankottai",
        "value": "Chittayankottai"
    },
    {
        "name": "Chittoor",
        "value": "Chittoor"
    },
    {
        "name": "Chittur",
        "value": "Chittur"
    },
    {
        "name": "Chitvel",
        "value": "Chitvel"
    },
    {
        "name": "Chityal",
        "value": "Chityal"
    },
    {
        "name": "Chodavaram",
        "value": "Chodavaram"
    },
    {
        "name": "Chokkalingapuram",
        "value": "Chokkalingapuram"
    },
    {
        "name": "Chokkampatti",
        "value": "Chokkampatti"
    },
    {
        "name": "Chokkanathapuram",
        "value": "Chokkanathapuram"
    },
    {
        "name": "Cholai",
        "value": "Cholai"
    },
    {
        "name": "Cholavandan",
        "value": "Cholavandan"
    },
    {
        "name": "Cholavaram",
        "value": "Cholavaram"
    },
    {
        "name": "Chonthrhu",
        "value": "Chonthrhu"
    },
    {
        "name": "Chopadandi",
        "value": "Chopadandi"
    },
    {
        "name": "Chopda",
        "value": "Chopda"
    },
    {
        "name": "Chorhat",
        "value": "Chorhat"
    },
    {
        "name": "Chorwad",
        "value": "Chorwad"
    },
    {
        "name": "Chota Mollakhali",
        "value": "Chota Mollakhali"
    },
    {
        "name": "Chotala",
        "value": "Chotala"
    },
    {
        "name": "Chudamani",
        "value": "Chudamani"
    },
    {
        "name": "Chuhal",
        "value": "Chuhal"
    },
    {
        "name": "Chunakara Vadakku",
        "value": "Chunakara Vadakku"
    },
    {
        "name": "Chunakhali",
        "value": "Chunakhali"
    },
    {
        "name": "Chunar",
        "value": "Chunar"
    },
    {
        "name": "Chunchura",
        "value": "Chunchura"
    },
    {
        "name": "Chundal",
        "value": "Chundal"
    },
    {
        "name": "Chundale",
        "value": "Chundale"
    },
    {
        "name": "Churi",
        "value": "Churi"
    },
    {
        "name": "Closepet",
        "value": "Closepet"
    },
    {
        "name": "Coimbatore",
        "value": "Coimbatore"
    },
    {
        "name": "Colangute",
        "value": "Colangute"
    },
    {
        "name": "Colgong",
        "value": "Colgong"
    },
    {
        "name": "Conjeeveram",
        "value": "Conjeeveram"
    },
    {
        "name": "Contai",
        "value": "Contai"
    },
    {
        "name": "Coondapoor",
        "value": "Coondapoor"
    },
    {
        "name": "Coonoor",
        "value": "Coonoor"
    },
    {
        "name": "Corgao",
        "value": "Corgao"
    },
    {
        "name": "Coringa",
        "value": "Coringa"
    },
    {
        "name": "Cortalim",
        "value": "Cortalim"
    },
    {
        "name": "Cossimbazar",
        "value": "Cossimbazar"
    },
    {
        "name": "Cuddalore",
        "value": "Cuddalore"
    },
    {
        "name": "Cuddapah",
        "value": "Cuddapah"
    },
    {
        "name": "Cumbum",
        "value": "Cumbum"
    },
    {
        "name": "Cuncolim",
        "value": "Cuncolim"
    },
    {
        "name": "Curchorem",
        "value": "Curchorem"
    },
    {
        "name": "Curti",
        "value": "Curti"
    },
    {
        "name": "Curtorim",
        "value": "Curtorim"
    },
    {
        "name": "Cuttack",
        "value": "Cuttack"
    },
    {
        "name": "Dabhaura",
        "value": "Dabhaura"
    },
    {
        "name": "Dabhoi",
        "value": "Dabhoi"
    },
    {
        "name": "Daboh",
        "value": "Daboh"
    },
    {
        "name": "Dabra",
        "value": "Dabra"
    },
    {
        "name": "Dachepalle",
        "value": "Dachepalle"
    },
    {
        "name": "Daddi",
        "value": "Daddi"
    },
    {
        "name": "Dadrewa",
        "value": "Dadrewa"
    },
    {
        "name": "Dagarua",
        "value": "Dagarua"
    },
    {
        "name": "Dagiapara",
        "value": "Dagiapara"
    },
    {
        "name": "Dagmara",
        "value": "Dagmara"
    },
    {
        "name": "Daharia",
        "value": "Daharia"
    },
    {
        "name": "Dahegam",
        "value": "Dahegam"
    },
    {
        "name": "Dahi",
        "value": "Dahi"
    },
    {
        "name": "Dahibhat Madhopur",
        "value": "Dahibhat Madhopur"
    },
    {
        "name": "Dahivel",
        "value": "Dahivel"
    },
    {
        "name": "Dahua",
        "value": "Dahua"
    },
    {
        "name": "Daita",
        "value": "Daita"
    },
    {
        "name": "Dakhram",
        "value": "Dakhram"
    },
    {
        "name": "Dakor",
        "value": "Dakor"
    },
    {
        "name": "Dakpatthar",
        "value": "Dakpatthar"
    },
    {
        "name": "Dala",
        "value": "Dala"
    },
    {
        "name": "Dalan",
        "value": "Dalan"
    },
    {
        "name": "Dalavaypattanam",
        "value": "Dalavaypattanam"
    },
    {
        "name": "Dalawarpur",
        "value": "Dalawarpur"
    },
    {
        "name": "Dalippur",
        "value": "Dalippur"
    },
    {
        "name": "Dalkola",
        "value": "Dalkola"
    },
    {
        "name": "Dalli Rajhara",
        "value": "Dalli Rajhara"
    },
    {
        "name": "Dalsingh Sarai",
        "value": "Dalsingh Sarai"
    },
    {
        "name": "Daltonganj",
        "value": "Daltonganj"
    },
    {
        "name": "Dalupura",
        "value": "Dalupura"
    },
    {
        "name": "Dam Dam",
        "value": "Dam Dam"
    },
    {
        "name": "Damal",
        "value": "Damal"
    },
    {
        "name": "Damalcheruvu",
        "value": "Damalcheruvu"
    },
    {
        "name": "Daman",
        "value": "Daman"
    },
    {
        "name": "Damarcherla",
        "value": "Damarcherla"
    },
    {
        "name": "Damargidda",
        "value": "Damargidda"
    },
    {
        "name": "Dambal",
        "value": "Dambal"
    },
    {
        "name": "Damdama",
        "value": "Damdama"
    },
    {
        "name": "Dammapeta",
        "value": "Dammapeta"
    },
    {
        "name": "Dammennu",
        "value": "Dammennu"
    },
    {
        "name": "Damoh",
        "value": "Damoh"
    },
    {
        "name": "Damonojodi",
        "value": "Damonojodi"
    },
    {
        "name": "Damu",
        "value": "Damu"
    },
    {
        "name": "Damua",
        "value": "Damua"
    },
    {
        "name": "Danau Kandimarg",
        "value": "Danau Kandimarg"
    },
    {
        "name": "Dandeli",
        "value": "Dandeli"
    },
    {
        "name": "Dandkhora",
        "value": "Dandkhora"
    },
    {
        "name": "Dandu Mailaram",
        "value": "Dandu Mailaram"
    },
    {
        "name": "Dantewara",
        "value": "Dantewara"
    },
    {
        "name": "Danwan",
        "value": "Danwan"
    },
    {
        "name": "Daparkha",
        "value": "Daparkha"
    },
    {
        "name": "Dapoli",
        "value": "Dapoli"
    },
    {
        "name": "Dara",
        "value": "Dara"
    },
    {
        "name": "Darasuram",
        "value": "Darasuram"
    },
    {
        "name": "Darauli",
        "value": "Darauli"
    },
    {
        "name": "Darbhanga",
        "value": "Darbhanga"
    },
    {
        "name": "Dargot",
        "value": "Dargot"
    },
    {
        "name": "Darihat",
        "value": "Darihat"
    },
    {
        "name": "Darjeeling",
        "value": "Darjeeling"
    },
    {
        "name": "Darmaha",
        "value": "Darmaha"
    },
    {
        "name": "Darpa",
        "value": "Darpa"
    },
    {
        "name": "Darsi",
        "value": "Darsi"
    },
    {
        "name": "Darsur",
        "value": "Darsur"
    },
    {
        "name": "Darwa",
        "value": "Darwa"
    },
    {
        "name": "Daryabad",
        "value": "Daryabad"
    },
    {
        "name": "Dasai",
        "value": "Dasai"
    },
    {
        "name": "Dasarhalli",
        "value": "Dasarhalli"
    },
    {
        "name": "Dasaut",
        "value": "Dasaut"
    },
    {
        "name": "Dasnapur",
        "value": "Dasnapur"
    },
    {
        "name": "Dasraha Bhograjpur",
        "value": "Dasraha Bhograjpur"
    },
    {
        "name": "Dasuya",
        "value": "Dasuya"
    },
    {
        "name": "Data",
        "value": "Data"
    },
    {
        "name": "Datia",
        "value": "Datia"
    },
    {
        "name": "Datian",
        "value": "Datian"
    },
    {
        "name": "Datiana",
        "value": "Datiana"
    },
    {
        "name": "Datoda",
        "value": "Datoda"
    },
    {
        "name": "Dattapara",
        "value": "Dattapara"
    },
    {
        "name": "Dattapulia",
        "value": "Dattapulia"
    },
    {
        "name": "Daudnagar",
        "value": "Daudnagar"
    },
    {
        "name": "Daulatnagar",
        "value": "Daulatnagar"
    },
    {
        "name": "Daulatpur",
        "value": "Daulatpur"
    },
    {
        "name": "Davangere",
        "value": "Davangere"
    },
    {
        "name": "Davorlim",
        "value": "Davorlim"
    },
    {
        "name": "Davuluru",
        "value": "Davuluru"
    },
    {
        "name": "Dayalpur",
        "value": "Dayalpur"
    },
    {
        "name": "Dayalpur Sapha",
        "value": "Dayalpur Sapha"
    },
    {
        "name": "Debagram",
        "value": "Debagram"
    },
    {
        "name": "Debiapur",
        "value": "Debiapur"
    },
    {
        "name": "Degana",
        "value": "Degana"
    },
    {
        "name": "Deh",
        "value": "Deh"
    },
    {
        "name": "Dehra Dun",
        "value": "Dehra Dun"
    },
    {
        "name": "Dehri",
        "value": "Dehri"
    },
    {
        "name": "Dehti",
        "value": "Dehti"
    },
    {
        "name": "Delhi",
        "value": "Delhi"
    },
    {
        "name": "Delhi Cantonment",
        "value": "Delhi Cantonment"
    },
    {
        "name": "Delvada",
        "value": "Delvada"
    },
    {
        "name": "Denduluru",
        "value": "Denduluru"
    },
    {
        "name": "Deo",
        "value": "Deo"
    },
    {
        "name": "Deodha",
        "value": "Deodha"
    },
    {
        "name": "Deodora",
        "value": "Deodora"
    },
    {
        "name": "Deodrug",
        "value": "Deodrug"
    },
    {
        "name": "Deogarh",
        "value": "Deogarh"
    },
    {
        "name": "Deoghar",
        "value": "Deoghar"
    },
    {
        "name": "Deokali",
        "value": "Deokali"
    },
    {
        "name": "Deolali",
        "value": "Deolali"
    },
    {
        "name": "Deoni Buzurg",
        "value": "Deoni Buzurg"
    },
    {
        "name": "Deori Khas",
        "value": "Deori Khas"
    },
    {
        "name": "Deoria",
        "value": "Deoria"
    },
    {
        "name": "Depalpur",
        "value": "Depalpur"
    },
    {
        "name": "Dera Baba Nanak",
        "value": "Dera Baba Nanak"
    },
    {
        "name": "Dergaon",
        "value": "Dergaon"
    },
    {
        "name": "Desanagi",
        "value": "Desanagi"
    },
    {
        "name": "Desavilakku",
        "value": "Desavilakku"
    },
    {
        "name": "Desri",
        "value": "Desri"
    },
    {
        "name": "Desuri",
        "value": "Desuri"
    },
    {
        "name": "Deulgaon Mahi",
        "value": "Deulgaon Mahi"
    },
    {
        "name": "Devadanappatti",
        "value": "Devadanappatti"
    },
    {
        "name": "Devanakavundanur",
        "value": "Devanakavundanur"
    },
    {
        "name": "Devanakonda",
        "value": "Devanakonda"
    },
    {
        "name": "Devanangurichchi",
        "value": "Devanangurichchi"
    },
    {
        "name": "Devanhalli",
        "value": "Devanhalli"
    },
    {
        "name": "Devapur",
        "value": "Devapur"
    },
    {
        "name": "Devarapalle",
        "value": "Devarapalle"
    },
    {
        "name": "Devarkonda",
        "value": "Devarkonda"
    },
    {
        "name": "Devarshola",
        "value": "Devarshola"
    },
    {
        "name": "Devendranagar",
        "value": "Devendranagar"
    },
    {
        "name": "Devgadh Bariya",
        "value": "Devgadh Bariya"
    },
    {
        "name": "Devgeri",
        "value": "Devgeri"
    },
    {
        "name": "Devikapuram",
        "value": "Devikapuram"
    },
    {
        "name": "Devikolam",
        "value": "Devikolam"
    },
    {
        "name": "Devipattinam",
        "value": "Devipattinam"
    },
    {
        "name": "Devirammanahalli",
        "value": "Devirammanahalli"
    },
    {
        "name": "Devmaudaldal",
        "value": "Devmaudaldal"
    },
    {
        "name": "Devrukh",
        "value": "Devrukh"
    },
    {
        "name": "Devsar",
        "value": "Devsar"
    },
    {
        "name": "Dewal Thal",
        "value": "Dewal Thal"
    },
    {
        "name": "Dewangarh",
        "value": "Dewangarh"
    },
    {
        "name": "Dewas",
        "value": "Dewas"
    },
    {
        "name": "Dhabauli",
        "value": "Dhabauli"
    },
    {
        "name": "Dhaka",
        "value": "Dhaka"
    },
    {
        "name": "Dhakaich",
        "value": "Dhakaich"
    },
    {
        "name": "Dhalai",
        "value": "Dhalai"
    },
    {
        "name": "Dhamaun",
        "value": "Dhamaun"
    },
    {
        "name": "Dhamdaha",
        "value": "Dhamdaha"
    },
    {
        "name": "Dhamnagar",
        "value": "Dhamnagar"
    },
    {
        "name": "Dhamnod",
        "value": "Dhamnod"
    },
    {
        "name": "Dhamsain",
        "value": "Dhamsain"
    },
    {
        "name": "Dhamtari",
        "value": "Dhamtari"
    },
    {
        "name": "Dhamua",
        "value": "Dhamua"
    },
    {
        "name": "Dhana",
        "value": "Dhana"
    },
    {
        "name": "Dhanaula",
        "value": "Dhanaula"
    },
    {
        "name": "Dhanauli",
        "value": "Dhanauli"
    },
    {
        "name": "Dhanaura",
        "value": "Dhanaura"
    },
    {
        "name": "Dhanauri",
        "value": "Dhanauri"
    },
    {
        "name": "Dhanbad",
        "value": "Dhanbad"
    },
    {
        "name": "Dhandhuka",
        "value": "Dhandhuka"
    },
    {
        "name": "Dhanera",
        "value": "Dhanera"
    },
    {
        "name": "Dhanga",
        "value": "Dhanga"
    },
    {
        "name": "Dhangaraha",
        "value": "Dhangaraha"
    },
    {
        "name": "Dhani Sukhan",
        "value": "Dhani Sukhan"
    },
    {
        "name": "Dhansaria",
        "value": "Dhansaria"
    },
    {
        "name": "Dhansura",
        "value": "Dhansura"
    },
    {
        "name": "Dhantola",
        "value": "Dhantola"
    },
    {
        "name": "Dhanupra",
        "value": "Dhanupra"
    },
    {
        "name": "Dhanur Kalyanwadi",
        "value": "Dhanur Kalyanwadi"
    },
    {
        "name": "Dhanwada",
        "value": "Dhanwada"
    },
    {
        "name": "Dhanwar",
        "value": "Dhanwar"
    },
    {
        "name": "Dhar",
        "value": "Dhar"
    },
    {
        "name": "Dharampur",
        "value": "Dharampur"
    },
    {
        "name": "Dharampur Bande",
        "value": "Dharampur Bande"
    },
    {
        "name": "Dharampuri",
        "value": "Dharampuri"
    },
    {
        "name": "Dharapuram",
        "value": "Dharapuram"
    },
    {
        "name": "Dharawat",
        "value": "Dharawat"
    },
    {
        "name": "Dharhara",
        "value": "Dharhara"
    },
    {
        "name": "Dharhwa",
        "value": "Dharhwa"
    },
    {
        "name": "Dhari",
        "value": "Dhari"
    },
    {
        "name": "Dharir",
        "value": "Dharir"
    },
    {
        "name": "Dhariwal",
        "value": "Dhariwal"
    },
    {
        "name": "Dharmaj",
        "value": "Dharmaj"
    },
    {
        "name": "Dharmajigudem",
        "value": "Dharmajigudem"
    },
    {
        "name": "Dharmapuram",
        "value": "Dharmapuram"
    },
    {
        "name": "Dharmapuri",
        "value": "Dharmapuri"
    },
    {
        "name": "Dharmaragar",
        "value": "Dharmaragar"
    },
    {
        "name": "Dharmaram",
        "value": "Dharmaram"
    },
    {
        "name": "Dharmasagaram",
        "value": "Dharmasagaram"
    },
    {
        "name": "Dharmastala",
        "value": "Dharmastala"
    },
    {
        "name": "Dharmavaram",
        "value": "Dharmavaram"
    },
    {
        "name": "Dharmkot",
        "value": "Dharmkot"
    },
    {
        "name": "Dharmsala",
        "value": "Dharmsala"
    },
    {
        "name": "Dharphari",
        "value": "Dharphari"
    },
    {
        "name": "Dhaula",
        "value": "Dhaula"
    },
    {
        "name": "Dhauni",
        "value": "Dhauni"
    },
    {
        "name": "Dhekiajuli",
        "value": "Dhekiajuli"
    },
    {
        "name": "Dhemaji",
        "value": "Dhemaji"
    },
    {
        "name": "Dhenkanal",
        "value": "Dhenkanal"
    },
    {
        "name": "Dhilwan",
        "value": "Dhilwan"
    },
    {
        "name": "Dhilwan Kalan",
        "value": "Dhilwan Kalan"
    },
    {
        "name": "Dhing",
        "value": "Dhing"
    },
    {
        "name": "Dhirwas",
        "value": "Dhirwas"
    },
    {
        "name": "Dhobauli",
        "value": "Dhobauli"
    },
    {
        "name": "Dhobipet",
        "value": "Dhobipet"
    },
    {
        "name": "Dholbaja",
        "value": "Dholbaja"
    },
    {
        "name": "Dholka",
        "value": "Dholka"
    },
    {
        "name": "Dhone",
        "value": "Dhone"
    },
    {
        "name": "Dhoraji",
        "value": "Dhoraji"
    },
    {
        "name": "Dhorgaon",
        "value": "Dhorgaon"
    },
    {
        "name": "Dhorimanna",
        "value": "Dhorimanna"
    },
    {
        "name": "Dhrangadhra",
        "value": "Dhrangadhra"
    },
    {
        "name": "Dhrol",
        "value": "Dhrol"
    },
    {
        "name": "Dhuburi",
        "value": "Dhuburi"
    },
    {
        "name": "Dhulia",
        "value": "Dhulia"
    },
    {
        "name": "Dhulian",
        "value": "Dhulian"
    },
    {
        "name": "Dhulkot",
        "value": "Dhulkot"
    },
    {
        "name": "Dhumnagar",
        "value": "Dhumnagar"
    },
    {
        "name": "Dhupgari",
        "value": "Dhupgari"
    },
    {
        "name": "Dhuri",
        "value": "Dhuri"
    },
    {
        "name": "Dhusar Tikapatti",
        "value": "Dhusar Tikapatti"
    },
    {
        "name": "Dhutauli",
        "value": "Dhutauli"
    },
    {
        "name": "Diamond Harbour",
        "value": "Diamond Harbour"
    },
    {
        "name": "Dibraghani",
        "value": "Dibraghani"
    },
    {
        "name": "Dibrugarh",
        "value": "Dibrugarh"
    },
    {
        "name": "Dicholi",
        "value": "Dicholi"
    },
    {
        "name": "Dig",
        "value": "Dig"
    },
    {
        "name": "Digboi",
        "value": "Digboi"
    },
    {
        "name": "Dighaun",
        "value": "Dighaun"
    },
    {
        "name": "Dighawani",
        "value": "Dighawani"
    },
    {
        "name": "Dighirpar",
        "value": "Dighirpar"
    },
    {
        "name": "Dighwa",
        "value": "Dighwa"
    },
    {
        "name": "Dighwara",
        "value": "Dighwara"
    },
    {
        "name": "Diglipur",
        "value": "Diglipur"
    },
    {
        "name": "Dihri",
        "value": "Dihri"
    },
    {
        "name": "Dilarpur",
        "value": "Dilarpur"
    },
    {
        "name": "Dilawarpur",
        "value": "Dilawarpur"
    },
    {
        "name": "Dildarnagar",
        "value": "Dildarnagar"
    },
    {
        "name": "Dilra",
        "value": "Dilra"
    },
    {
        "name": "Dimapur",
        "value": "Dimapur"
    },
    {
        "name": "Dinanagar",
        "value": "Dinanagar"
    },
    {
        "name": "Dinapore",
        "value": "Dinapore"
    },
    {
        "name": "Dinara",
        "value": "Dinara"
    },
    {
        "name": "Dindigul",
        "value": "Dindigul"
    },
    {
        "name": "Dindori",
        "value": "Dindori"
    },
    {
        "name": "Dinhata",
        "value": "Dinhata"
    },
    {
        "name": "Dinmanpur",
        "value": "Dinmanpur"
    },
    {
        "name": "Dip",
        "value": "Dip"
    },
    {
        "name": "Diphu",
        "value": "Diphu"
    },
    {
        "name": "Dirba",
        "value": "Dirba"
    },
    {
        "name": "Dirusumarru",
        "value": "Dirusumarru"
    },
    {
        "name": "Disa",
        "value": "Disa"
    },
    {
        "name": "Dispur",
        "value": "Dispur"
    },
    {
        "name": "Diu",
        "value": "Diu"
    },
    {
        "name": "Dobhawan",
        "value": "Dobhawan"
    },
    {
        "name": "Dobni Para",
        "value": "Dobni Para"
    },
    {
        "name": "Dod Ballapur",
        "value": "Dod Ballapur"
    },
    {
        "name": "Doda",
        "value": "Doda"
    },
    {
        "name": "Dodarasinakere",
        "value": "Dodarasinakere"
    },
    {
        "name": "Doddanahalli",
        "value": "Doddanahalli"
    },
    {
        "name": "Doddappanayakkanur",
        "value": "Doddappanayakkanur"
    },
    {
        "name": "Doddipatla",
        "value": "Doddipatla"
    },
    {
        "name": "Dodvad",
        "value": "Dodvad"
    },
    {
        "name": "Dogachi",
        "value": "Dogachi"
    },
    {
        "name": "Dohta",
        "value": "Dohta"
    },
    {
        "name": "Doiwala",
        "value": "Doiwala"
    },
    {
        "name": "Domahani",
        "value": "Domahani"
    },
    {
        "name": "Dombachcheri",
        "value": "Dombachcheri"
    },
    {
        "name": "Domchanch",
        "value": "Domchanch"
    },
    {
        "name": "Domkonda",
        "value": "Domkonda"
    },
    {
        "name": "Dommasandra",
        "value": "Dommasandra"
    },
    {
        "name": "Dora",
        "value": "Dora"
    },
    {
        "name": "Doranahalli",
        "value": "Doranahalli"
    },
    {
        "name": "Doranala",
        "value": "Doranala"
    },
    {
        "name": "Doranda",
        "value": "Doranda"
    },
    {
        "name": "Doria Sonapur",
        "value": "Doria Sonapur"
    },
    {
        "name": "Dornakal",
        "value": "Dornakal"
    },
    {
        "name": "Dowlaiswaram",
        "value": "Dowlaiswaram"
    },
    {
        "name": "Drabar",
        "value": "Drabar"
    },
    {
        "name": "Draksharama",
        "value": "Draksharama"
    },
    {
        "name": "Dras",
        "value": "Dras"
    },
    {
        "name": "Drug",
        "value": "Drug"
    },
    {
        "name": "Dubacherla",
        "value": "Dubacherla"
    },
    {
        "name": "Dubak",
        "value": "Dubak"
    },
    {
        "name": "Dubrajpur",
        "value": "Dubrajpur"
    },
    {
        "name": "Dudhgaon",
        "value": "Dudhgaon"
    },
    {
        "name": "Dudhpura",
        "value": "Dudhpura"
    },
    {
        "name": "Dugda",
        "value": "Dugda"
    },
    {
        "name": "Duggirala",
        "value": "Duggirala"
    },
    {
        "name": "Dukli",
        "value": "Dukli"
    },
    {
        "name": "Dulhanganj",
        "value": "Dulhanganj"
    },
    {
        "name": "Dum Duma",
        "value": "Dum Duma"
    },
    {
        "name": "Dumaria",
        "value": "Dumaria"
    },
    {
        "name": "Dumjor",
        "value": "Dumjor"
    },
    {
        "name": "Dumka",
        "value": "Dumka"
    },
    {
        "name": "Dumra",
        "value": "Dumra"
    },
    {
        "name": "Dumraon",
        "value": "Dumraon"
    },
    {
        "name": "Dumri",
        "value": "Dumri"
    },
    {
        "name": "Dundankop",
        "value": "Dundankop"
    },
    {
        "name": "Dundigal",
        "value": "Dundigal"
    },
    {
        "name": "Dungarpur",
        "value": "Dungarpur"
    },
    {
        "name": "Dungra Chhota",
        "value": "Dungra Chhota"
    },
    {
        "name": "Duraiswamipuram",
        "value": "Duraiswamipuram"
    },
    {
        "name": "Durgapur",
        "value": "Durgapur"
    },
    {
        "name": "Durgauti",
        "value": "Durgauti"
    },
    {
        "name": "Durgi",
        "value": "Durgi"
    },
    {
        "name": "Durpalli",
        "value": "Durpalli"
    },
    {
        "name": "Duvva",
        "value": "Duvva"
    },
    {
        "name": "Duvvuru",
        "value": "Duvvuru"
    },
    {
        "name": "Dwarka",
        "value": "Dwarka"
    },
    {
        "name": "Edakkazhiyur",
        "value": "Edakkazhiyur"
    },
    {
        "name": "Edakkunnam",
        "value": "Edakkunnam"
    },
    {
        "name": "Edamon",
        "value": "Edamon"
    },
    {
        "name": "Edappalli",
        "value": "Edappalli"
    },
    {
        "name": "Edasseri",
        "value": "Edasseri"
    },
    {
        "name": "Edassheri",
        "value": "Edassheri"
    },
    {
        "name": "Edattala",
        "value": "Edattala"
    },
    {
        "name": "Edattirutti",
        "value": "Edattirutti"
    },
    {
        "name": "Edavanakad",
        "value": "Edavanakad"
    },
    {
        "name": "Edayikunnam",
        "value": "Edayikunnam"
    },
    {
        "name": "Edlapadu",
        "value": "Edlapadu"
    },
    {
        "name": "Eduttavaynattam",
        "value": "Eduttavaynattam"
    },
    {
        "name": "Egra",
        "value": "Egra"
    },
    {
        "name": "Ekalbehri",
        "value": "Ekalbehri"
    },
    {
        "name": "Ekamba",
        "value": "Ekamba"
    },
    {
        "name": "Ekangar Sarai",
        "value": "Ekangar Sarai"
    },
    {
        "name": "Ekchari",
        "value": "Ekchari"
    },
    {
        "name": "Ekhari",
        "value": "Ekhari"
    },
    {
        "name": "Eklahra",
        "value": "Eklahra"
    },
    {
        "name": "Ekma",
        "value": "Ekma"
    },
    {
        "name": "Eksambe",
        "value": "Eksambe"
    },
    {
        "name": "Ekwari",
        "value": "Ekwari"
    },
    {
        "name": "Elakadu",
        "value": "Elakadu"
    },
    {
        "name": "Elamattur",
        "value": "Elamattur"
    },
    {
        "name": "Elambalur",
        "value": "Elambalur"
    },
    {
        "name": "Elanad",
        "value": "Elanad"
    },
    {
        "name": "Elandakuttai",
        "value": "Elandakuttai"
    },
    {
        "name": "Elangunnapuzha",
        "value": "Elangunnapuzha"
    },
    {
        "name": "Elanjivaliseri",
        "value": "Elanjivaliseri"
    },
    {
        "name": "Elankur",
        "value": "Elankur"
    },
    {
        "name": "Elattur",
        "value": "Elattur"
    },
    {
        "name": "Elavalli",
        "value": "Elavalli"
    },
    {
        "name": "Elavanasur",
        "value": "Elavanasur"
    },
    {
        "name": "Elchuru",
        "value": "Elchuru"
    },
    {
        "name": "Elesvaram",
        "value": "Elesvaram"
    },
    {
        "name": "Ellamanda",
        "value": "Ellamanda"
    },
    {
        "name": "Ellenabad",
        "value": "Ellenabad"
    },
    {
        "name": "Ellore",
        "value": "Ellore"
    },
    {
        "name": "Elukone",
        "value": "Elukone"
    },
    {
        "name": "Elumalai",
        "value": "Elumalai"
    },
    {
        "name": "Elur",
        "value": "Elur"
    },
    {
        "name": "Elurpatti",
        "value": "Elurpatti"
    },
    {
        "name": "Elurupadu",
        "value": "Elurupadu"
    },
    {
        "name": "Emmiganur",
        "value": "Emmiganur"
    },
    {
        "name": "Emmiganuru",
        "value": "Emmiganuru"
    },
    {
        "name": "Enamadala",
        "value": "Enamadala"
    },
    {
        "name": "Engandiyur",
        "value": "Engandiyur"
    },
    {
        "name": "Enkakad",
        "value": "Enkakad"
    },
    {
        "name": "Ennamangalam",
        "value": "Ennamangalam"
    },
    {
        "name": "Enumulapalle",
        "value": "Enumulapalle"
    },
    {
        "name": "Eral",
        "value": "Eral"
    },
    {
        "name": "Eramala",
        "value": "Eramala"
    },
    {
        "name": "Eranapuram",
        "value": "Eranapuram"
    },
    {
        "name": "Eraniel",
        "value": "Eraniel"
    },
    {
        "name": "Erattukulakkada",
        "value": "Erattukulakkada"
    },
    {
        "name": "Eraura",
        "value": "Eraura"
    },
    {
        "name": "Eravattur",
        "value": "Eravattur"
    },
    {
        "name": "Erikolam",
        "value": "Erikolam"
    },
    {
        "name": "Eriyodu",
        "value": "Eriyodu"
    },
    {
        "name": "Ernagudem",
        "value": "Ernagudem"
    },
    {
        "name": "Erode",
        "value": "Erode"
    },
    {
        "name": "Erraballa",
        "value": "Erraballa"
    },
    {
        "name": "Erragondapalem",
        "value": "Erragondapalem"
    },
    {
        "name": "Erraguntla",
        "value": "Erraguntla"
    },
    {
        "name": "Erraguntlakota",
        "value": "Erraguntlakota"
    },
    {
        "name": "Errahalli",
        "value": "Errahalli"
    },
    {
        "name": "Erramvaripalem",
        "value": "Erramvaripalem"
    },
    {
        "name": "Erravaram",
        "value": "Erravaram"
    },
    {
        "name": "Erumad",
        "value": "Erumad"
    },
    {
        "name": "Erumaippatti",
        "value": "Erumaippatti"
    },
    {
        "name": "Erumakkuzhi",
        "value": "Erumakkuzhi"
    },
    {
        "name": "Erumapalaiyam",
        "value": "Erumapalaiyam"
    },
    {
        "name": "Erutukada",
        "value": "Erutukada"
    },
    {
        "name": "Eruvatti",
        "value": "Eruvatti"
    },
    {
        "name": "Ervadi",
        "value": "Ervadi"
    },
    {
        "name": "Esanai",
        "value": "Esanai"
    },
    {
        "name": "Etacheri",
        "value": "Etacheri"
    },
    {
        "name": "Etah",
        "value": "Etah"
    },
    {
        "name": "Etawa",
        "value": "Etawa"
    },
    {
        "name": "Etawah",
        "value": "Etawah"
    },
    {
        "name": "Ettaiyapuram",
        "value": "Ettaiyapuram"
    },
    {
        "name": "Ettapur",
        "value": "Ettapur"
    },
    {
        "name": "Ettimadai",
        "value": "Ettimadai"
    },
    {
        "name": "Ezhamkulam",
        "value": "Ezhamkulam"
    },
    {
        "name": "Ezhipram",
        "value": "Ezhipram"
    },
    {
        "name": "Fakirtaki",
        "value": "Fakirtaki"
    },
    {
        "name": "Falakata",
        "value": "Falakata"
    },
    {
        "name": "Falimari",
        "value": "Falimari"
    },
    {
        "name": "Farakka",
        "value": "Farakka"
    },
    {
        "name": "Faridabad",
        "value": "Faridabad"
    },
    {
        "name": "Faridkot",
        "value": "Faridkot"
    },
    {
        "name": "Faridpur",
        "value": "Faridpur"
    },
    {
        "name": "Farrukhabad",
        "value": "Farrukhabad"
    },
    {
        "name": "Farrukhnagar",
        "value": "Farrukhnagar"
    },
    {
        "name": "Fateh Nangal",
        "value": "Fateh Nangal"
    },
    {
        "name": "Fateha",
        "value": "Fateha"
    },
    {
        "name": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "name": "Fatehgarh",
        "value": "Fatehgarh"
    },
    {
        "name": "Fatehgarh Churian",
        "value": "Fatehgarh Churian"
    },
    {
        "name": "Fatehpur",
        "value": "Fatehpur"
    },
    {
        "name": "Fatehpur Bala",
        "value": "Fatehpur Bala"
    },
    {
        "name": "Fatehpur Shahbaz",
        "value": "Fatehpur Shahbaz"
    },
    {
        "name": "Fatehpur Sikri",
        "value": "Fatehpur Sikri"
    },
    {
        "name": "Fatipura",
        "value": "Fatipura"
    },
    {
        "name": "Fatwa",
        "value": "Fatwa"
    },
    {
        "name": "Fazilka",
        "value": "Fazilka"
    },
    {
        "name": "Ferozepore",
        "value": "Ferozepore"
    },
    {
        "name": "Forbesganj",
        "value": "Forbesganj"
    },
    {
        "name": "Fraserpet",
        "value": "Fraserpet"
    },
    {
        "name": "Fyzabad",
        "value": "Fyzabad"
    },
    {
        "name": "Gadag",
        "value": "Gadag"
    },
    {
        "name": "Gadarpur",
        "value": "Gadarpur"
    },
    {
        "name": "Gadarwara",
        "value": "Gadarwara"
    },
    {
        "name": "Gadaul",
        "value": "Gadaul"
    },
    {
        "name": "Gadda Madiral",
        "value": "Gadda Madiral"
    },
    {
        "name": "Gadhada",
        "value": "Gadhada"
    },
    {
        "name": "Gadwal",
        "value": "Gadwal"
    },
    {
        "name": "Gahmar",
        "value": "Gahmar"
    },
    {
        "name": "Gainrha",
        "value": "Gainrha"
    },
    {
        "name": "Gairtganj",
        "value": "Gairtganj"
    },
    {
        "name": "Gajendragarh",
        "value": "Gajendragarh"
    },
    {
        "name": "Gajhara",
        "value": "Gajhara"
    },
    {
        "name": "Gajiginhalu",
        "value": "Gajiginhalu"
    },
    {
        "name": "Gajwel",
        "value": "Gajwel"
    },
    {
        "name": "Galatge",
        "value": "Galatge"
    },
    {
        "name": "Galivedu",
        "value": "Galivedu"
    },
    {
        "name": "Galsi",
        "value": "Galsi"
    },
    {
        "name": "Gamail",
        "value": "Gamail"
    },
    {
        "name": "Gamharia",
        "value": "Gamharia"
    },
    {
        "name": "Ganapathivattam",
        "value": "Ganapathivattam"
    },
    {
        "name": "Ganapatipalaiyam",
        "value": "Ganapatipalaiyam"
    },
    {
        "name": "Ganapavaram",
        "value": "Ganapavaram"
    },
    {
        "name": "Ganaram",
        "value": "Ganaram"
    },
    {
        "name": "Gandarbal",
        "value": "Gandarbal"
    },
    {
        "name": "Gandevi",
        "value": "Gandevi"
    },
    {
        "name": "Gandhali",
        "value": "Gandhali"
    },
    {
        "name": "Gandhari",
        "value": "Gandhari"
    },
    {
        "name": "Gandhidham",
        "value": "Gandhidham"
    },
    {
        "name": "Gandhwani",
        "value": "Gandhwani"
    },
    {
        "name": "Gandikunta",
        "value": "Gandikunta"
    },
    {
        "name": "Gandlapenta",
        "value": "Gandlapenta"
    },
    {
        "name": "Ganeshpur",
        "value": "Ganeshpur"
    },
    {
        "name": "Ganga",
        "value": "Ganga"
    },
    {
        "name": "Ganga Sagar",
        "value": "Ganga Sagar"
    },
    {
        "name": "Gangadhar",
        "value": "Gangadhar"
    },
    {
        "name": "Gangaikondan",
        "value": "Gangaikondan"
    },
    {
        "name": "Gangajalghati",
        "value": "Gangajalghati"
    },
    {
        "name": "Gangammapeta",
        "value": "Gangammapeta"
    },
    {
        "name": "Ganganagar",
        "value": "Ganganagar"
    },
    {
        "name": "Gangania",
        "value": "Gangania"
    },
    {
        "name": "Gangapatnam",
        "value": "Gangapatnam"
    },
    {
        "name": "Gangapur",
        "value": "Gangapur"
    },
    {
        "name": "Gangapur Athar",
        "value": "Gangapur Athar"
    },
    {
        "name": "Gangarampur",
        "value": "Gangarampur"
    },
    {
        "name": "Gangaur",
        "value": "Gangaur"
    },
    {
        "name": "Gangaura Behra",
        "value": "Gangaura Behra"
    },
    {
        "name": "Gangavalli",
        "value": "Gangavalli"
    },
    {
        "name": "Gangawati",
        "value": "Gangawati"
    },
    {
        "name": "Gangoli",
        "value": "Gangoli"
    },
    {
        "name": "Gangtok",
        "value": "Gangtok"
    },
    {
        "name": "Ganguru",
        "value": "Ganguru"
    },
    {
        "name": "Ganguvarpatti",
        "value": "Ganguvarpatti"
    },
    {
        "name": "Ganjam",
        "value": "Ganjam"
    },
    {
        "name": "Gannavaram",
        "value": "Gannavaram"
    },
    {
        "name": "Garag",
        "value": "Garag"
    },
    {
        "name": "Garaimari",
        "value": "Garaimari"
    },
    {
        "name": "Garh Sisai",
        "value": "Garh Sisai"
    },
    {
        "name": "Garhakota",
        "value": "Garhakota"
    },
    {
        "name": "Garhara",
        "value": "Garhara"
    },
    {
        "name": "Garhi",
        "value": "Garhi"
    },
    {
        "name": "Garhpura",
        "value": "Garhpura"
    },
    {
        "name": "Garhshankar",
        "value": "Garhshankar"
    },
    {
        "name": "Garhwa",
        "value": "Garhwa"
    },
    {
        "name": "Gariadhar",
        "value": "Gariadhar"
    },
    {
        "name": "Garkha",
        "value": "Garkha"
    },
    {
        "name": "Garot",
        "value": "Garot"
    },
    {
        "name": "Garsekurti",
        "value": "Garsekurti"
    },
    {
        "name": "Garulia",
        "value": "Garulia"
    },
    {
        "name": "Gatada",
        "value": "Gatada"
    },
    {
        "name": "Gauli Palasiya",
        "value": "Gauli Palasiya"
    },
    {
        "name": "Gaundra",
        "value": "Gaundra"
    },
    {
        "name": "Gaunivaripalle",
        "value": "Gaunivaripalle"
    },
    {
        "name": "Gaura",
        "value": "Gaura"
    },
    {
        "name": "Gauravaram",
        "value": "Gauravaram"
    },
    {
        "name": "Gaurdah",
        "value": "Gaurdah"
    },
    {
        "name": "Gaurihar Khaliqnagar",
        "value": "Gaurihar Khaliqnagar"
    },
    {
        "name": "Gauripur",
        "value": "Gauripur"
    },
    {
        "name": "Gautampura",
        "value": "Gautampura"
    },
    {
        "name": "Gavinivaripalem",
        "value": "Gavinivaripalem"
    },
    {
        "name": "Gawan",
        "value": "Gawan"
    },
    {
        "name": "Gaya",
        "value": "Gaya"
    },
    {
        "name": "Gayaspur",
        "value": "Gayaspur"
    },
    {
        "name": "Gazulapalle",
        "value": "Gazulapalle"
    },
    {
        "name": "Gethaura",
        "value": "Gethaura"
    },
    {
        "name": "Ghagga",
        "value": "Ghagga"
    },
    {
        "name": "Ghal Kalan",
        "value": "Ghal Kalan"
    },
    {
        "name": "Ghambiraopet",
        "value": "Ghambiraopet"
    },
    {
        "name": "Ghandinagar",
        "value": "Ghandinagar"
    },
    {
        "name": "Ghanipur Bejha",
        "value": "Ghanipur Bejha"
    },
    {
        "name": "Ghanpur",
        "value": "Ghanpur"
    },
    {
        "name": "Gharbara",
        "value": "Gharbara"
    },
    {
        "name": "Ghargaon",
        "value": "Ghargaon"
    },
    {
        "name": "Gharyala",
        "value": "Gharyala"
    },
    {
        "name": "Ghat Borul",
        "value": "Ghat Borul"
    },
    {
        "name": "Ghatal",
        "value": "Ghatal"
    },
    {
        "name": "Ghataro Chaturbhuj",
        "value": "Ghataro Chaturbhuj"
    },
    {
        "name": "Ghatawan",
        "value": "Ghatawan"
    },
    {
        "name": "Ghatkesar",
        "value": "Ghatkesar"
    },
    {
        "name": "Ghattu",
        "value": "Ghattu"
    },
    {
        "name": "Ghattupal",
        "value": "Ghattupal"
    },
    {
        "name": "Ghaura",
        "value": "Ghaura"
    },
    {
        "name": "Ghaziabad",
        "value": "Ghaziabad"
    },
    {
        "name": "Ghazipur",
        "value": "Ghazipur"
    },
    {
        "name": "Ghogaon",
        "value": "Ghogaon"
    },
    {
        "name": "Ghogardiha",
        "value": "Ghogardiha"
    },
    {
        "name": "Ghogha",
        "value": "Ghogha"
    },
    {
        "name": "Gholia Kalan",
        "value": "Gholia Kalan"
    },
    {
        "name": "Ghoradal",
        "value": "Ghoradal"
    },
    {
        "name": "Ghoradongri",
        "value": "Ghoradongri"
    },
    {
        "name": "Ghorbanki",
        "value": "Ghorbanki"
    },
    {
        "name": "Ghordaur",
        "value": "Ghordaur"
    },
    {
        "name": "Ghosai",
        "value": "Ghosai"
    },
    {
        "name": "Ghosrawan",
        "value": "Ghosrawan"
    },
    {
        "name": "Ghoswari",
        "value": "Ghoswari"
    },
    {
        "name": "Ghoti Budrukh",
        "value": "Ghoti Budrukh"
    },
    {
        "name": "Ghusiya",
        "value": "Ghusiya"
    },
    {
        "name": "Giddalur",
        "value": "Giddalur"
    },
    {
        "name": "Giddarbaha",
        "value": "Giddarbaha"
    },
    {
        "name": "Gidha",
        "value": "Gidha"
    },
    {
        "name": "Gidi",
        "value": "Gidi"
    },
    {
        "name": "Gidri",
        "value": "Gidri"
    },
    {
        "name": "Gil",
        "value": "Gil"
    },
    {
        "name": "Gilarchat",
        "value": "Gilarchat"
    },
    {
        "name": "Gingee",
        "value": "Gingee"
    },
    {
        "name": "Giria",
        "value": "Giria"
    },
    {
        "name": "Goalpara",
        "value": "Goalpara"
    },
    {
        "name": "Goasi",
        "value": "Goasi"
    },
    {
        "name": "Gobardanga",
        "value": "Gobardanga"
    },
    {
        "name": "Gobardhanpur Kanap",
        "value": "Gobardhanpur Kanap"
    },
    {
        "name": "Gobindgarh",
        "value": "Gobindgarh"
    },
    {
        "name": "Gobindpur",
        "value": "Gobindpur"
    },
    {
        "name": "Gobindpura",
        "value": "Gobindpura"
    },
    {
        "name": "Godda",
        "value": "Godda"
    },
    {
        "name": "Godhra",
        "value": "Godhra"
    },
    {
        "name": "Goh",
        "value": "Goh"
    },
    {
        "name": "Gohadi",
        "value": "Gohadi"
    },
    {
        "name": "Gohi Bishunpur",
        "value": "Gohi Bishunpur"
    },
    {
        "name": "Gohna",
        "value": "Gohna"
    },
    {
        "name": "Gohpur",
        "value": "Gohpur"
    },
    {
        "name": "Gohuma Bairia",
        "value": "Gohuma Bairia"
    },
    {
        "name": "Gok",
        "value": "Gok"
    },
    {
        "name": "Gokak",
        "value": "Gokak"
    },
    {
        "name": "Gokarn",
        "value": "Gokarn"
    },
    {
        "name": "Gokarna",
        "value": "Gokarna"
    },
    {
        "name": "Gokavaram",
        "value": "Gokavaram"
    },
    {
        "name": "Gokhulapur",
        "value": "Gokhulapur"
    },
    {
        "name": "Gokinepalle",
        "value": "Gokinepalle"
    },
    {
        "name": "Gokulgarh",
        "value": "Gokulgarh"
    },
    {
        "name": "Golaghat",
        "value": "Golaghat"
    },
    {
        "name": "Golakpur",
        "value": "Golakpur"
    },
    {
        "name": "Gold",
        "value": "Gold"
    },
    {
        "name": "Golet",
        "value": "Golet"
    },
    {
        "name": "Gollalagunta",
        "value": "Gollalagunta"
    },
    {
        "name": "Gollapudi",
        "value": "Gollapudi"
    },
    {
        "name": "Goluwali",
        "value": "Goluwali"
    },
    {
        "name": "Gomoh",
        "value": "Gomoh"
    },
    {
        "name": "Gondal",
        "value": "Gondal"
    },
    {
        "name": "Gondalga",
        "value": "Gondalga"
    },
    {
        "name": "Gondar",
        "value": "Gondar"
    },
    {
        "name": "Gondauli",
        "value": "Gondauli"
    },
    {
        "name": "Gondia",
        "value": "Gondia"
    },
    {
        "name": "Gonegandla",
        "value": "Gonegandla"
    },
    {
        "name": "Goner",
        "value": "Goner"
    },
    {
        "name": "Gonghaur",
        "value": "Gonghaur"
    },
    {
        "name": "Gonikoppal",
        "value": "Gonikoppal"
    },
    {
        "name": "Gooty",
        "value": "Gooty"
    },
    {
        "name": "Gopalapuram",
        "value": "Gopalapuram"
    },
    {
        "name": "Gopalasamudram",
        "value": "Gopalasamudram"
    },
    {
        "name": "Gopalganj",
        "value": "Gopalganj"
    },
    {
        "name": "Gopalnagar",
        "value": "Gopalnagar"
    },
    {
        "name": "Gopalpur",
        "value": "Gopalpur"
    },
    {
        "name": "Gopavaram",
        "value": "Gopavaram"
    },
    {
        "name": "Gopichettipalaiyam",
        "value": "Gopichettipalaiyam"
    },
    {
        "name": "Gorakhpur",
        "value": "Gorakhpur"
    },
    {
        "name": "Gorantla",
        "value": "Gorantla"
    },
    {
        "name": "Goraya",
        "value": "Goraya"
    },
    {
        "name": "Gorha",
        "value": "Gorha"
    },
    {
        "name": "Goriar",
        "value": "Goriar"
    },
    {
        "name": "Goribidnur",
        "value": "Goribidnur"
    },
    {
        "name": "Gormi",
        "value": "Gormi"
    },
    {
        "name": "Gosaingaon",
        "value": "Gosaingaon"
    },
    {
        "name": "Goshaingaon",
        "value": "Goshaingaon"
    },
    {
        "name": "Gothini",
        "value": "Gothini"
    },
    {
        "name": "Gothurutha",
        "value": "Gothurutha"
    },
    {
        "name": "Gothva",
        "value": "Gothva"
    },
    {
        "name": "Govindapalle",
        "value": "Govindapalle"
    },
    {
        "name": "Govindapuram",
        "value": "Govindapuram"
    },
    {
        "name": "Govindgarh",
        "value": "Govindgarh"
    },
    {
        "name": "Gua",
        "value": "Gua"
    },
    {
        "name": "Guabari",
        "value": "Guabari"
    },
    {
        "name": "Gubbi",
        "value": "Gubbi"
    },
    {
        "name": "Gudalur",
        "value": "Gudalur"
    },
    {
        "name": "Gudgeri",
        "value": "Gudgeri"
    },
    {
        "name": "Gudibanda",
        "value": "Gudibanda"
    },
    {
        "name": "Gudikallu",
        "value": "Gudikallu"
    },
    {
        "name": "Gudimulakhandrika",
        "value": "Gudimulakhandrika"
    },
    {
        "name": "Gudipallipadu",
        "value": "Gudipallipadu"
    },
    {
        "name": "Gudivada",
        "value": "Gudivada"
    },
    {
        "name": "Gudiyattam",
        "value": "Gudiyattam"
    },
    {
        "name": "Gudlavalleru",
        "value": "Gudlavalleru"
    },
    {
        "name": "Gudluru",
        "value": "Gudluru"
    },
    {
        "name": "Gudur",
        "value": "Gudur"
    },
    {
        "name": "Guduru",
        "value": "Guduru"
    },
    {
        "name": "Gulam",
        "value": "Gulam"
    },
    {
        "name": "Gulbarga",
        "value": "Gulbarga"
    },
    {
        "name": "Guledagudda",
        "value": "Guledagudda"
    },
    {
        "name": "Gulgam",
        "value": "Gulgam"
    },
    {
        "name": "Gullapuram",
        "value": "Gullapuram"
    },
    {
        "name": "Gulni",
        "value": "Gulni"
    },
    {
        "name": "Gulyam",
        "value": "Gulyam"
    },
    {
        "name": "Gumia",
        "value": "Gumia"
    },
    {
        "name": "Gumla",
        "value": "Gumla"
    },
    {
        "name": "Gummudipundi",
        "value": "Gummudipundi"
    },
    {
        "name": "Guna",
        "value": "Guna"
    },
    {
        "name": "Gundi",
        "value": "Gundi"
    },
    {
        "name": "Gundlapelle",
        "value": "Gundlapelle"
    },
    {
        "name": "Gundlupet",
        "value": "Gundlupet"
    },
    {
        "name": "Gundrajukuppam",
        "value": "Gundrajukuppam"
    },
    {
        "name": "Gundugolanu",
        "value": "Gundugolanu"
    },
    {
        "name": "Gundumal",
        "value": "Gundumal"
    },
    {
        "name": "Gundur",
        "value": "Gundur"
    },
    {
        "name": "Gunjapalle",
        "value": "Gunjapalle"
    },
    {
        "name": "Gunri",
        "value": "Gunri"
    },
    {
        "name": "Guntakal",
        "value": "Guntakal"
    },
    {
        "name": "Guntakal Junction",
        "value": "Guntakal Junction"
    },
    {
        "name": "Guntapalli",
        "value": "Guntapalli"
    },
    {
        "name": "Guntupalle",
        "value": "Guntupalle"
    },
    {
        "name": "Guntur",
        "value": "Guntur"
    },
    {
        "name": "Gunupur",
        "value": "Gunupur"
    },
    {
        "name": "Guraahai",
        "value": "Guraahai"
    },
    {
        "name": "Gurais",
        "value": "Gurais"
    },
    {
        "name": "Gurdaspur",
        "value": "Gurdaspur"
    },
    {
        "name": "Gurgaon",
        "value": "Gurgaon"
    },
    {
        "name": "Gurgunta",
        "value": "Gurgunta"
    },
    {
        "name": "Gurh",
        "value": "Gurh"
    },
    {
        "name": "Guria",
        "value": "Guria"
    },
    {
        "name": "Gurlapeta",
        "value": "Gurlapeta"
    },
    {
        "name": "Gurmaila",
        "value": "Gurmaila"
    },
    {
        "name": "Gurmatkal",
        "value": "Gurmatkal"
    },
    {
        "name": "Gurmia",
        "value": "Gurmia"
    },
    {
        "name": "Gurramkonda",
        "value": "Gurramkonda"
    },
    {
        "name": "Gursarai",
        "value": "Gursarai"
    },
    {
        "name": "Guru Har Sahai",
        "value": "Guru Har Sahai"
    },
    {
        "name": "Guruvarajukuppam",
        "value": "Guruvarajukuppam"
    },
    {
        "name": "Guruvayur",
        "value": "Guruvayur"
    },
    {
        "name": "Guruzala",
        "value": "Guruzala"
    },
    {
        "name": "Gurwalia Biswas",
        "value": "Gurwalia Biswas"
    },
    {
        "name": "Guskhara",
        "value": "Guskhara"
    },
    {
        "name": "Guttal",
        "value": "Guttal"
    },
    {
        "name": "Guttikonda",
        "value": "Guttikonda"
    },
    {
        "name": "Guwahati",
        "value": "Guwahati"
    },
    {
        "name": "Gwalior",
        "value": "Gwalior"
    },
    {
        "name": "Habra",
        "value": "Habra"
    },
    {
        "name": "Hadiaya",
        "value": "Hadiaya"
    },
    {
        "name": "Hagaranahalli",
        "value": "Hagaranahalli"
    },
    {
        "name": "Hagaribommanahalli",
        "value": "Hagaribommanahalli"
    },
    {
        "name": "Haidarnagar",
        "value": "Haidarnagar"
    },
    {
        "name": "Hailakandi",
        "value": "Hailakandi"
    },
    {
        "name": "Hajan",
        "value": "Hajan"
    },
    {
        "name": "Hajipur",
        "value": "Hajipur"
    },
    {
        "name": "Hakka",
        "value": "Hakka"
    },
    {
        "name": "Halavagalu",
        "value": "Halavagalu"
    },
    {
        "name": "Haldia",
        "value": "Haldia"
    },
    {
        "name": "Haldibari",
        "value": "Haldibari"
    },
    {
        "name": "Haldipur",
        "value": "Haldipur"
    },
    {
        "name": "Haldwani",
        "value": "Haldwani"
    },
    {
        "name": "Hale Dyamavvanahalli",
        "value": "Hale Dyamavvanahalli"
    },
    {
        "name": "Halgar",
        "value": "Halgar"
    },
    {
        "name": "Halgeri",
        "value": "Halgeri"
    },
    {
        "name": "Halgur",
        "value": "Halgur"
    },
    {
        "name": "Halikner",
        "value": "Halikner"
    },
    {
        "name": "Halisahar",
        "value": "Halisahar"
    },
    {
        "name": "Haliyal",
        "value": "Haliyal"
    },
    {
        "name": "Halol",
        "value": "Halol"
    },
    {
        "name": "Halsur",
        "value": "Halsur"
    },
    {
        "name": "Halvad",
        "value": "Halvad"
    },
    {
        "name": "Halwara",
        "value": "Halwara"
    },
    {
        "name": "Hamira",
        "value": "Hamira"
    },
    {
        "name": "Hamirpur",
        "value": "Hamirpur"
    },
    {
        "name": "Hamsavaram",
        "value": "Hamsavaram"
    },
    {
        "name": "Hanamsagar",
        "value": "Hanamsagar"
    },
    {
        "name": "Handwara",
        "value": "Handwara"
    },
    {
        "name": "Hangal",
        "value": "Hangal"
    },
    {
        "name": "Hansa",
        "value": "Hansa"
    },
    {
        "name": "Hansi",
        "value": "Hansi"
    },
    {
        "name": "Hansot",
        "value": "Hansot"
    },
    {
        "name": "Hanumana",
        "value": "Hanumana"
    },
    {
        "name": "Hanumangarh",
        "value": "Hanumangarh"
    },
    {
        "name": "Hanumantanpatti",
        "value": "Hanumantanpatti"
    },
    {
        "name": "Hanur",
        "value": "Hanur"
    },
    {
        "name": "Hanzviur",
        "value": "Hanzviur"
    },
    {
        "name": "Haora",
        "value": "Haora"
    },
    {
        "name": "Hapur",
        "value": "Hapur"
    },
    {
        "name": "Haraiya",
        "value": "Haraiya"
    },
    {
        "name": "Harbatpur",
        "value": "Harbatpur"
    },
    {
        "name": "Harchandpur",
        "value": "Harchandpur"
    },
    {
        "name": "Harda Khas",
        "value": "Harda Khas"
    },
    {
        "name": "Hardas Bigha",
        "value": "Hardas Bigha"
    },
    {
        "name": "Hardi",
        "value": "Hardi"
    },
    {
        "name": "Hardia",
        "value": "Hardia"
    },
    {
        "name": "Hardiya",
        "value": "Hardiya"
    },
    {
        "name": "Hardoi",
        "value": "Hardoi"
    },
    {
        "name": "Harduli",
        "value": "Harduli"
    },
    {
        "name": "Harewa",
        "value": "Harewa"
    },
    {
        "name": "Hargawan",
        "value": "Hargawan"
    },
    {
        "name": "Hariana",
        "value": "Hariana"
    },
    {
        "name": "Haridwar",
        "value": "Haridwar"
    },
    {
        "name": "Harihans",
        "value": "Harihans"
    },
    {
        "name": "Harihar",
        "value": "Harihar"
    },
    {
        "name": "Hariharpara",
        "value": "Hariharpara"
    },
    {
        "name": "Harij",
        "value": "Harij"
    },
    {
        "name": "Harike",
        "value": "Harike"
    },
    {
        "name": "Haripur",
        "value": "Haripur"
    },
    {
        "name": "Haripura",
        "value": "Haripura"
    },
    {
        "name": "Harji",
        "value": "Harji"
    },
    {
        "name": "Harlakhi",
        "value": "Harlakhi"
    },
    {
        "name": "Harnaut",
        "value": "Harnaut"
    },
    {
        "name": "Harohalli",
        "value": "Harohalli"
    },
    {
        "name": "Harpalpur",
        "value": "Harpalpur"
    },
    {
        "name": "Harpanahalli",
        "value": "Harpanahalli"
    },
    {
        "name": "Harpur",
        "value": "Harpur"
    },
    {
        "name": "Harpur Bhindi",
        "value": "Harpur Bhindi"
    },
    {
        "name": "Harpur Bochaha",
        "value": "Harpur Bochaha"
    },
    {
        "name": "Harra",
        "value": "Harra"
    },
    {
        "name": "Harrai",
        "value": "Harrai"
    },
    {
        "name": "Harri",
        "value": "Harri"
    },
    {
        "name": "Harsinghpur",
        "value": "Harsinghpur"
    },
    {
        "name": "Harsola",
        "value": "Harsola"
    },
    {
        "name": "Harua",
        "value": "Harua"
    },
    {
        "name": "Harur",
        "value": "Harur"
    },
    {
        "name": "Hasanganj",
        "value": "Hasanganj"
    },
    {
        "name": "Hasanparti",
        "value": "Hasanparti"
    },
    {
        "name": "Hasanpur",
        "value": "Hasanpur"
    },
    {
        "name": "Hasanpur Juned",
        "value": "Hasanpur Juned"
    },
    {
        "name": "Hasanpura",
        "value": "Hasanpura"
    },
    {
        "name": "Hassan",
        "value": "Hassan"
    },
    {
        "name": "Hastinapur",
        "value": "Hastinapur"
    },
    {
        "name": "Hata",
        "value": "Hata"
    },
    {
        "name": "Hathapur",
        "value": "Hathapur"
    },
    {
        "name": "Hathaura",
        "value": "Hathaura"
    },
    {
        "name": "Hathauri",
        "value": "Hathauri"
    },
    {
        "name": "Hathiakan",
        "value": "Hathiakan"
    },
    {
        "name": "Hathiaundha",
        "value": "Hathiaundha"
    },
    {
        "name": "Hathidah Buzurg",
        "value": "Hathidah Buzurg"
    },
    {
        "name": "Hathwan",
        "value": "Hathwan"
    },
    {
        "name": "Hatod",
        "value": "Hatod"
    },
    {
        "name": "Hatta",
        "value": "Hatta"
    },
    {
        "name": "Hatti",
        "value": "Hatti"
    },
    {
        "name": "Hatti Mattur",
        "value": "Hatti Mattur"
    },
    {
        "name": "Hatwans",
        "value": "Hatwans"
    },
    {
        "name": "Havanur",
        "value": "Havanur"
    },
    {
        "name": "Haveri",
        "value": "Haveri"
    },
    {
        "name": "Havi Bhauar",
        "value": "Havi Bhauar"
    },
    {
        "name": "Hazaribagh",
        "value": "Hazaribagh"
    },
    {
        "name": "Hazrat Shiura",
        "value": "Hazrat Shiura"
    },
    {
        "name": "Hebli",
        "value": "Hebli"
    },
    {
        "name": "Hebri",
        "value": "Hebri"
    },
    {
        "name": "Heggadadevankote",
        "value": "Heggadadevankote"
    },
    {
        "name": "Hejamadi",
        "value": "Hejamadi"
    },
    {
        "name": "Hemavati",
        "value": "Hemavati"
    },
    {
        "name": "Hengken",
        "value": "Hengken"
    },
    {
        "name": "Herohalli",
        "value": "Herohalli"
    },
    {
        "name": "Herseh Chhina",
        "value": "Herseh Chhina"
    },
    {
        "name": "Hesarghatta",
        "value": "Hesarghatta"
    },
    {
        "name": "Hetanpur",
        "value": "Hetanpur"
    },
    {
        "name": "Hilsa",
        "value": "Hilsa"
    },
    {
        "name": "Himatnagar",
        "value": "Himatnagar"
    },
    {
        "name": "Himmatpura",
        "value": "Himmatpura"
    },
    {
        "name": "Hindalgi",
        "value": "Hindalgi"
    },
    {
        "name": "Hindaun",
        "value": "Hindaun"
    },
    {
        "name": "Hindoli",
        "value": "Hindoli"
    },
    {
        "name": "Hindoria",
        "value": "Hindoria"
    },
    {
        "name": "Hindupur",
        "value": "Hindupur"
    },
    {
        "name": "Hipparga",
        "value": "Hipparga"
    },
    {
        "name": "Hirakud",
        "value": "Hirakud"
    },
    {
        "name": "Hiramandalam",
        "value": "Hiramandalam"
    },
    {
        "name": "Hire Megalageri",
        "value": "Hire Megalageri"
    },
    {
        "name": "Hire Vadvatti",
        "value": "Hire Vadvatti"
    },
    {
        "name": "Hirehadagalli",
        "value": "Hirehadagalli"
    },
    {
        "name": "Hirehalli",
        "value": "Hirehalli"
    },
    {
        "name": "Hirehaluhosahalli",
        "value": "Hirehaluhosahalli"
    },
    {
        "name": "Hirekerur",
        "value": "Hirekerur"
    },
    {
        "name": "Hiriyur",
        "value": "Hiriyur"
    },
    {
        "name": "Hirni",
        "value": "Hirni"
    },
    {
        "name": "Hirpardangal",
        "value": "Hirpardangal"
    },
    {
        "name": "Hisar",
        "value": "Hisar"
    },
    {
        "name": "Hissaramuruvani",
        "value": "Hissaramuruvani"
    },
    {
        "name": "Hisua",
        "value": "Hisua"
    },
    {
        "name": "Hodal",
        "value": "Hodal"
    },
    {
        "name": "Hojai",
        "value": "Hojai"
    },
    {
        "name": "Hokur Badasgom",
        "value": "Hokur Badasgom"
    },
    {
        "name": "Holalagondi",
        "value": "Holalagondi"
    },
    {
        "name": "Holalkere",
        "value": "Holalkere"
    },
    {
        "name": "Holalu",
        "value": "Holalu"
    },
    {
        "name": "Hole Narsipur",
        "value": "Hole Narsipur"
    },
    {
        "name": "Hombal",
        "value": "Hombal"
    },
    {
        "name": "Homnabad",
        "value": "Homnabad"
    },
    {
        "name": "Honavar",
        "value": "Honavar"
    },
    {
        "name": "Honganur",
        "value": "Honganur"
    },
    {
        "name": "Honnali",
        "value": "Honnali"
    },
    {
        "name": "Honwada",
        "value": "Honwada"
    },
    {
        "name": "Horti",
        "value": "Horti"
    },
    {
        "name": "Hosahalli",
        "value": "Hosahalli"
    },
    {
        "name": "Hosakote",
        "value": "Hosakote"
    },
    {
        "name": "Hosdurga",
        "value": "Hosdurga"
    },
    {
        "name": "Hoshangabad",
        "value": "Hoshangabad"
    },
    {
        "name": "Hosir",
        "value": "Hosir"
    },
    {
        "name": "Hoskote",
        "value": "Hoskote"
    },
    {
        "name": "Hospet",
        "value": "Hospet"
    },
    {
        "name": "Hosur",
        "value": "Hosur"
    },
    {
        "name": "Hosuru",
        "value": "Hosuru"
    },
    {
        "name": "Howli",
        "value": "Howli"
    },
    {
        "name": "Hubli",
        "value": "Hubli"
    },
    {
        "name": "Hudli",
        "value": "Hudli"
    },
    {
        "name": "Hugli",
        "value": "Hugli"
    },
    {
        "name": "Hukeri",
        "value": "Hukeri"
    },
    {
        "name": "Hulikal",
        "value": "Hulikal"
    },
    {
        "name": "Huliyar",
        "value": "Huliyar"
    },
    {
        "name": "Hulkoti",
        "value": "Hulkoti"
    },
    {
        "name": "Hullahalli",
        "value": "Hullahalli"
    },
    {
        "name": "Hullatti",
        "value": "Hullatti"
    },
    {
        "name": "Hunasagi",
        "value": "Hunasagi"
    },
    {
        "name": "Hunasamaranhalli",
        "value": "Hunasamaranhalli"
    },
    {
        "name": "Hungund",
        "value": "Hungund"
    },
    {
        "name": "Hunsur",
        "value": "Hunsur"
    },
    {
        "name": "Husainabad",
        "value": "Husainabad"
    },
    {
        "name": "Husainpur",
        "value": "Husainpur"
    },
    {
        "name": "Huseni",
        "value": "Huseni"
    },
    {
        "name": "Husepur",
        "value": "Husepur"
    },
    {
        "name": "Husnabad",
        "value": "Husnabad"
    },
    {
        "name": "Hussepur",
        "value": "Hussepur"
    },
    {
        "name": "Huvin Hippargi",
        "value": "Huvin Hippargi"
    },
    {
        "name": "Huzurabad",
        "value": "Huzurabad"
    },
    {
        "name": "Huzurnagar",
        "value": "Huzurnagar"
    },
    {
        "name": "Hyderabad",
        "value": "Hyderabad"
    },
    {
        "name": "Iarpur",
        "value": "Iarpur"
    },
    {
        "name": "Iawar",
        "value": "Iawar"
    },
    {
        "name": "Ibrahimpatan",
        "value": "Ibrahimpatan"
    },
    {
        "name": "Ibrahimpatnam",
        "value": "Ibrahimpatnam"
    },
    {
        "name": "Ichalkaranji",
        "value": "Ichalkaranji"
    },
    {
        "name": "Ichchapuram",
        "value": "Ichchapuram"
    },
    {
        "name": "Ichhapur",
        "value": "Ichhapur"
    },
    {
        "name": "Ichora",
        "value": "Ichora"
    },
    {
        "name": "Idangansalai",
        "value": "Idangansalai"
    },
    {
        "name": "Idappadi",
        "value": "Idappadi"
    },
    {
        "name": "Idar",
        "value": "Idar"
    },
    {
        "name": "Idukki",
        "value": "Idukki"
    },
    {
        "name": "Idumbavanam",
        "value": "Idumbavanam"
    },
    {
        "name": "Idupugallu",
        "value": "Idupugallu"
    },
    {
        "name": "Ijra",
        "value": "Ijra"
    },
    {
        "name": "Ikhlaspur",
        "value": "Ikhlaspur"
    },
    {
        "name": "Ikkadu",
        "value": "Ikkadu"
    },
    {
        "name": "Ikkarai Boluvampatti",
        "value": "Ikkarai Boluvampatti"
    },
    {
        "name": "Iklod",
        "value": "Iklod"
    },
    {
        "name": "Ilaiyankudi",
        "value": "Ilaiyankudi"
    },
    {
        "name": "Ilami",
        "value": "Ilami"
    },
    {
        "name": "Ilampillai",
        "value": "Ilampillai"
    },
    {
        "name": "Ilindu Kothi",
        "value": "Ilindu Kothi"
    },
    {
        "name": "Ilkal",
        "value": "Ilkal"
    },
    {
        "name": "Iluppur",
        "value": "Iluppur"
    },
    {
        "name": "Imani",
        "value": "Imani"
    },
    {
        "name": "Imphal",
        "value": "Imphal"
    },
    {
        "name": "Indalvai",
        "value": "Indalvai"
    },
    {
        "name": "Indargarh",
        "value": "Indargarh"
    },
    {
        "name": "Indi",
        "value": "Indi"
    },
    {
        "name": "Indore",
        "value": "Indore"
    },
    {
        "name": "Indurti",
        "value": "Indurti"
    },
    {
        "name": "Ingraj Bazar",
        "value": "Ingraj Bazar"
    },
    {
        "name": "Ingurti",
        "value": "Ingurti"
    },
    {
        "name": "Inkollu",
        "value": "Inkollu"
    },
    {
        "name": "Inole",
        "value": "Inole"
    },
    {
        "name": "Inungur",
        "value": "Inungur"
    },
    {
        "name": "Ippagudem",
        "value": "Ippagudem"
    },
    {
        "name": "Iraiyur",
        "value": "Iraiyur"
    },
    {
        "name": "Iramala",
        "value": "Iramala"
    },
    {
        "name": "Irikkur",
        "value": "Irikkur"
    },
    {
        "name": "Irimbiliyam",
        "value": "Irimbiliyam"
    },
    {
        "name": "Iringal",
        "value": "Iringal"
    },
    {
        "name": "Irinjalakuda",
        "value": "Irinjalakuda"
    },
    {
        "name": "Irugur",
        "value": "Irugur"
    },
    {
        "name": "Irukanni",
        "value": "Irukanni"
    },
    {
        "name": "Irungalur",
        "value": "Irungalur"
    },
    {
        "name": "Iruttarakonam",
        "value": "Iruttarakonam"
    },
    {
        "name": "Isagarh",
        "value": "Isagarh"
    },
    {
        "name": "Isapur",
        "value": "Isapur"
    },
    {
        "name": "Iskapalem",
        "value": "Iskapalem"
    },
    {
        "name": "Iskapalli",
        "value": "Iskapalli"
    },
    {
        "name": "Islamabad",
        "value": "Islamabad"
    },
    {
        "name": "Islampur",
        "value": "Islampur"
    },
    {
        "name": "Ismailpur",
        "value": "Ismailpur"
    },
    {
        "name": "Isnapuram",
        "value": "Isnapuram"
    },
    {
        "name": "Israin Kalan",
        "value": "Israin Kalan"
    },
    {
        "name": "Israna",
        "value": "Israna"
    },
    {
        "name": "Isua",
        "value": "Isua"
    },
    {
        "name": "Itahri",
        "value": "Itahri"
    },
    {
        "name": "Itamati",
        "value": "Itamati"
    },
    {
        "name": "Itamukkala",
        "value": "Itamukkala"
    },
    {
        "name": "Itanagar",
        "value": "Itanagar"
    },
    {
        "name": "Itarhi",
        "value": "Itarhi"
    },
    {
        "name": "Itarsi",
        "value": "Itarsi"
    },
    {
        "name": "Itikalapalle",
        "value": "Itikalapalle"
    },
    {
        "name": "Itiki",
        "value": "Itiki"
    },
    {
        "name": "Itki Thakurgaon",
        "value": "Itki Thakurgaon"
    },
    {
        "name": "Ittikara",
        "value": "Ittikara"
    },
    {
        "name": "Ittikelakunta",
        "value": "Ittikelakunta"
    },
    {
        "name": "Ittiva",
        "value": "Ittiva"
    },
    {
        "name": "Jabalpur",
        "value": "Jabalpur"
    },
    {
        "name": "Jabera",
        "value": "Jabera"
    },
    {
        "name": "Jadayampalaiyam",
        "value": "Jadayampalaiyam"
    },
    {
        "name": "Jadcherla",
        "value": "Jadcherla"
    },
    {
        "name": "Jadia",
        "value": "Jadia"
    },
    {
        "name": "Jadopur Shukul",
        "value": "Jadopur Shukul"
    },
    {
        "name": "Jadupatti",
        "value": "Jadupatti"
    },
    {
        "name": "Jafarabad",
        "value": "Jafarabad"
    },
    {
        "name": "Jaffar Khanpet",
        "value": "Jaffar Khanpet"
    },
    {
        "name": "Jagadhri",
        "value": "Jagadhri"
    },
    {
        "name": "Jagalur",
        "value": "Jagalur"
    },
    {
        "name": "Jagannadapuram",
        "value": "Jagannadapuram"
    },
    {
        "name": "Jagannathpur",
        "value": "Jagannathpur"
    },
    {
        "name": "Jagatpur",
        "value": "Jagatpur"
    },
    {
        "name": "Jagdalpur",
        "value": "Jagdalpur"
    },
    {
        "name": "Jagdishpur",
        "value": "Jagdishpur"
    },
    {
        "name": "Jagdispur",
        "value": "Jagdispur"
    },
    {
        "name": "Jaggampeta",
        "value": "Jaggampeta"
    },
    {
        "name": "Jaggayyapeta",
        "value": "Jaggayyapeta"
    },
    {
        "name": "Jaggisettigudem",
        "value": "Jaggisettigudem"
    },
    {
        "name": "Jagoniguda",
        "value": "Jagoniguda"
    },
    {
        "name": "Jagraon",
        "value": "Jagraon"
    },
    {
        "name": "Jagta",
        "value": "Jagta"
    },
    {
        "name": "Jahangirpur Salkhani",
        "value": "Jahangirpur Salkhani"
    },
    {
        "name": "Jai",
        "value": "Jai"
    },
    {
        "name": "Jaimalpura",
        "value": "Jaimalpura"
    },
    {
        "name": "Jainagar",
        "value": "Jainagar"
    },
    {
        "name": "Jaipur",
        "value": "Jaipur"
    },
    {
        "name": "Jaipur Chuhar",
        "value": "Jaipur Chuhar"
    },
    {
        "name": "Jaisalmer",
        "value": "Jaisalmer"
    },
    {
        "name": "Jaisinghnagar",
        "value": "Jaisinghnagar"
    },
    {
        "name": "Jaisinghpur",
        "value": "Jaisinghpur"
    },
    {
        "name": "Jaito",
        "value": "Jaito"
    },
    {
        "name": "Jaitpur",
        "value": "Jaitpur"
    },
    {
        "name": "Jaitpura",
        "value": "Jaitpura"
    },
    {
        "name": "Jaitwar",
        "value": "Jaitwar"
    },
    {
        "name": "Jajireddigudem",
        "value": "Jajireddigudem"
    },
    {
        "name": "Jajpur",
        "value": "Jajpur"
    },
    {
        "name": "Jakkampalaiyam",
        "value": "Jakkampalaiyam"
    },
    {
        "name": "Jaladurgam",
        "value": "Jaladurgam"
    },
    {
        "name": "Jalakandapuram",
        "value": "Jalakandapuram"
    },
    {
        "name": "Jalalabad",
        "value": "Jalalabad"
    },
    {
        "name": "Jalalkhera",
        "value": "Jalalkhera"
    },
    {
        "name": "Jalalpur",
        "value": "Jalalpur"
    },
    {
        "name": "Jalam",
        "value": "Jalam"
    },
    {
        "name": "Jalandhar",
        "value": "Jalandhar"
    },
    {
        "name": "Jalandhar Cantonment",
        "value": "Jalandhar Cantonment"
    },
    {
        "name": "Jalarpet",
        "value": "Jalarpet"
    },
    {
        "name": "Jale",
        "value": "Jale"
    },
    {
        "name": "Jalgaon",
        "value": "Jalgaon"
    },
    {
        "name": "Jalhalli",
        "value": "Jalhalli"
    },
    {
        "name": "Jalihalli",
        "value": "Jalihalli"
    },
    {
        "name": "Jalkaura",
        "value": "Jalkaura"
    },
    {
        "name": "Jalna",
        "value": "Jalna"
    },
    {
        "name": "Jalor",
        "value": "Jalor"
    },
    {
        "name": "Jalpaiguri",
        "value": "Jalpaiguri"
    },
    {
        "name": "Jalpura",
        "value": "Jalpura"
    },
    {
        "name": "Jamai",
        "value": "Jamai"
    },
    {
        "name": "Jamalpur",
        "value": "Jamalpur"
    },
    {
        "name": "Jambai",
        "value": "Jambai"
    },
    {
        "name": "Jambughoda",
        "value": "Jambughoda"
    },
    {
        "name": "Jambukuttaippatti",
        "value": "Jambukuttaippatti"
    },
    {
        "name": "Jambusar",
        "value": "Jambusar"
    },
    {
        "name": "Jamhor",
        "value": "Jamhor"
    },
    {
        "name": "Jamhra",
        "value": "Jamhra"
    },
    {
        "name": "Jami",
        "value": "Jami"
    },
    {
        "name": "Jamikunta",
        "value": "Jamikunta"
    },
    {
        "name": "Jamira",
        "value": "Jamira"
    },
    {
        "name": "Jamkhed",
        "value": "Jamkhed"
    },
    {
        "name": "Jammalamadugu",
        "value": "Jammalamadugu"
    },
    {
        "name": "Jammu",
        "value": "Jammu"
    },
    {
        "name": "Jamnagar",
        "value": "Jamnagar"
    },
    {
        "name": "Jamsaut",
        "value": "Jamsaut"
    },
    {
        "name": "Jamshedpur",
        "value": "Jamshedpur"
    },
    {
        "name": "Jamtara",
        "value": "Jamtara"
    },
    {
        "name": "Jamui",
        "value": "Jamui"
    },
    {
        "name": "Jamunamukh",
        "value": "Jamunamukh"
    },
    {
        "name": "Jamunia",
        "value": "Jamunia"
    },
    {
        "name": "Janai",
        "value": "Janai"
    },
    {
        "name": "Janakammapeta",
        "value": "Janakammapeta"
    },
    {
        "name": "Janapadu",
        "value": "Janapadu"
    },
    {
        "name": "Janapul",
        "value": "Janapul"
    },
    {
        "name": "Jandiala",
        "value": "Jandiala"
    },
    {
        "name": "Jandrapeta",
        "value": "Jandrapeta"
    },
    {
        "name": "Jangalapalle",
        "value": "Jangalapalle"
    },
    {
        "name": "Jangalapalli",
        "value": "Jangalapalli"
    },
    {
        "name": "Jangamguda",
        "value": "Jangamguda"
    },
    {
        "name": "Jangaon",
        "value": "Jangaon"
    },
    {
        "name": "Jangid",
        "value": "Jangid"
    },
    {
        "name": "Jangipur",
        "value": "Jangipur"
    },
    {
        "name": "Janglot",
        "value": "Janglot"
    },
    {
        "name": "Janhapara",
        "value": "Janhapara"
    },
    {
        "name": "Janjgir",
        "value": "Janjgir"
    },
    {
        "name": "Jankampet",
        "value": "Jankampet"
    },
    {
        "name": "Jankinagar",
        "value": "Jankinagar"
    },
    {
        "name": "Janpur",
        "value": "Janpur"
    },
    {
        "name": "Jaora",
        "value": "Jaora"
    },
    {
        "name": "Jasauli",
        "value": "Jasauli"
    },
    {
        "name": "Jasauli Patti",
        "value": "Jasauli Patti"
    },
    {
        "name": "Jasdan",
        "value": "Jasdan"
    },
    {
        "name": "Jasidih",
        "value": "Jasidih"
    },
    {
        "name": "Jasol",
        "value": "Jasol"
    },
    {
        "name": "Jaspur",
        "value": "Jaspur"
    },
    {
        "name": "Jatani",
        "value": "Jatani"
    },
    {
        "name": "Jatara",
        "value": "Jatara"
    },
    {
        "name": "Jauli",
        "value": "Jauli"
    },
    {
        "name": "Jaunpur",
        "value": "Jaunpur"
    },
    {
        "name": "Jawad",
        "value": "Jawad"
    },
    {
        "name": "Jawalgeri",
        "value": "Jawalgeri"
    },
    {
        "name": "Jawasa",
        "value": "Jawasa"
    },
    {
        "name": "Jawkatia",
        "value": "Jawkatia"
    },
    {
        "name": "Jayal",
        "value": "Jayal"
    },
    {
        "name": "Jayamkondacholapuram",
        "value": "Jayamkondacholapuram"
    },
    {
        "name": "Jaynagar",
        "value": "Jaynagar"
    },
    {
        "name": "Jaynagar-Majilpur",
        "value": "Jaynagar-Majilpur"
    },
    {
        "name": "Jaypul",
        "value": "Jaypul"
    },
    {
        "name": "Jethuli",
        "value": "Jethuli"
    },
    {
        "name": "Jetpur",
        "value": "Jetpur"
    },
    {
        "name": "Jettihalli",
        "value": "Jettihalli"
    },
    {
        "name": "Jevargi",
        "value": "Jevargi"
    },
    {
        "name": "Jeypore",
        "value": "Jeypore"
    },
    {
        "name": "Jhabrera",
        "value": "Jhabrera"
    },
    {
        "name": "Jhabua",
        "value": "Jhabua"
    },
    {
        "name": "Jhagarua",
        "value": "Jhagarua"
    },
    {
        "name": "Jhakhra",
        "value": "Jhakhra"
    },
    {
        "name": "Jhalida",
        "value": "Jhalida"
    },
    {
        "name": "Jhandapur",
        "value": "Jhandapur"
    },
    {
        "name": "Jhanjharpur",
        "value": "Jhanjharpur"
    },
    {
        "name": "Jhansi",
        "value": "Jhansi"
    },
    {
        "name": "Jhargram",
        "value": "Jhargram"
    },
    {
        "name": "Jharka",
        "value": "Jharka"
    },
    {
        "name": "Jharsugra",
        "value": "Jharsugra"
    },
    {
        "name": "Jhaua",
        "value": "Jhaua"
    },
    {
        "name": "Jhitkahiya",
        "value": "Jhitkahiya"
    },
    {
        "name": "Jhonkar",
        "value": "Jhonkar"
    },
    {
        "name": "Jhundo",
        "value": "Jhundo"
    },
    {
        "name": "Jhundpura",
        "value": "Jhundpura"
    },
    {
        "name": "Jiaganj",
        "value": "Jiaganj"
    },
    {
        "name": "Jianganj",
        "value": "Jianganj"
    },
    {
        "name": "Jidigunta",
        "value": "Jidigunta"
    },
    {
        "name": "Jigani",
        "value": "Jigani"
    },
    {
        "name": "Jind",
        "value": "Jind"
    },
    {
        "name": "Jiran",
        "value": "Jiran"
    },
    {
        "name": "Jirwa",
        "value": "Jirwa"
    },
    {
        "name": "Jitpur",
        "value": "Jitpur"
    },
    {
        "name": "Jitwarpur Chauth",
        "value": "Jitwarpur Chauth"
    },
    {
        "name": "Jitwarpur Kumhra",
        "value": "Jitwarpur Kumhra"
    },
    {
        "name": "Jitwarpur Nizamat",
        "value": "Jitwarpur Nizamat"
    },
    {
        "name": "Jiwachhpur",
        "value": "Jiwachhpur"
    },
    {
        "name": "Jiyyammavalasa",
        "value": "Jiyyammavalasa"
    },
    {
        "name": "Jobat",
        "value": "Jobat"
    },
    {
        "name": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "name": "Jogaili",
        "value": "Jogaili"
    },
    {
        "name": "Jogbani",
        "value": "Jogbani"
    },
    {
        "name": "Jogiara",
        "value": "Jogiara"
    },
    {
        "name": "Jogipet",
        "value": "Jogipet"
    },
    {
        "name": "Jonnagiri",
        "value": "Jonnagiri"
    },
    {
        "name": "Jora",
        "value": "Jora"
    },
    {
        "name": "Jora Khurd",
        "value": "Jora Khurd"
    },
    {
        "name": "Jorhat",
        "value": "Jorhat"
    },
    {
        "name": "Jori Kalan",
        "value": "Jori Kalan"
    },
    {
        "name": "Joshimath",
        "value": "Joshimath"
    },
    {
        "name": "Jugial",
        "value": "Jugial"
    },
    {
        "name": "Jujharpur",
        "value": "Jujharpur"
    },
    {
        "name": "Jula Buzarg",
        "value": "Jula Buzarg"
    },
    {
        "name": "Jumri Tilaiya",
        "value": "Jumri Tilaiya"
    },
    {
        "name": "Junagadh",
        "value": "Junagadh"
    },
    {
        "name": "Junagarh",
        "value": "Junagarh"
    },
    {
        "name": "Junnar",
        "value": "Junnar"
    },
    {
        "name": "Junnardev",
        "value": "Junnardev"
    },
    {
        "name": "Jurawanpur Karari",
        "value": "Jurawanpur Karari"
    },
    {
        "name": "Kabbur",
        "value": "Kabbur"
    },
    {
        "name": "Kabira",
        "value": "Kabira"
    },
    {
        "name": "Kabirpur",
        "value": "Kabirpur"
    },
    {
        "name": "Kachavaram",
        "value": "Kachavaram"
    },
    {
        "name": "Kachchippalli",
        "value": "Kachchippalli"
    },
    {
        "name": "Kachhari",
        "value": "Kachhari"
    },
    {
        "name": "Kachnar",
        "value": "Kachnar"
    },
    {
        "name": "Kadachchinallur",
        "value": "Kadachchinallur"
    },
    {
        "name": "Kadaiyam",
        "value": "Kadaiyam"
    },
    {
        "name": "Kadaiyanallur",
        "value": "Kadaiyanallur"
    },
    {
        "name": "Kadakola",
        "value": "Kadakola"
    },
    {
        "name": "Kadaladi",
        "value": "Kadaladi"
    },
    {
        "name": "Kadalur",
        "value": "Kadalur"
    },
    {
        "name": "Kadamakudi",
        "value": "Kadamakudi"
    },
    {
        "name": "Kadamalaikkundu",
        "value": "Kadamalaikkundu"
    },
    {
        "name": "Kadambanad",
        "value": "Kadambanad"
    },
    {
        "name": "Kadanadu",
        "value": "Kadanadu"
    },
    {
        "name": "Kadanganeri",
        "value": "Kadanganeri"
    },
    {
        "name": "Kadappuram",
        "value": "Kadappuram"
    },
    {
        "name": "Kadattur",
        "value": "Kadattur"
    },
    {
        "name": "Kadavur",
        "value": "Kadavur"
    },
    {
        "name": "Kadi",
        "value": "Kadi"
    },
    {
        "name": "Kadiapattanam",
        "value": "Kadiapattanam"
    },
    {
        "name": "Kadikkad",
        "value": "Kadikkad"
    },
    {
        "name": "Kadimetla",
        "value": "Kadimetla"
    },
    {
        "name": "Kadinamkulam",
        "value": "Kadinamkulam"
    },
    {
        "name": "Kadiri",
        "value": "Kadiri"
    },
    {
        "name": "Kadiyadda",
        "value": "Kadiyadda"
    },
    {
        "name": "Kadiyam",
        "value": "Kadiyam"
    },
    {
        "name": "Kadiyampatti",
        "value": "Kadiyampatti"
    },
    {
        "name": "Kadod",
        "value": "Kadod"
    },
    {
        "name": "Kadoli",
        "value": "Kadoli"
    },
    {
        "name": "Kadrabad",
        "value": "Kadrabad"
    },
    {
        "name": "Kadungapuram",
        "value": "Kadungapuram"
    },
    {
        "name": "Kadur",
        "value": "Kadur"
    },
    {
        "name": "Kadur Sahib",
        "value": "Kadur Sahib"
    },
    {
        "name": "Kaduturutti",
        "value": "Kaduturutti"
    },
    {
        "name": "Kadwa",
        "value": "Kadwa"
    },
    {
        "name": "Kaglipur",
        "value": "Kaglipur"
    },
    {
        "name": "Kaguchi",
        "value": "Kaguchi"
    },
    {
        "name": "Kagvad",
        "value": "Kagvad"
    },
    {
        "name": "Kahla",
        "value": "Kahla"
    },
    {
        "name": "Kaikalur",
        "value": "Kaikalur"
    },
    {
        "name": "Kaikaram",
        "value": "Kaikaram"
    },
    {
        "name": "Kaikkudi",
        "value": "Kaikkudi"
    },
    {
        "name": "Kailaras",
        "value": "Kailaras"
    },
    {
        "name": "Kailashahar",
        "value": "Kailashahar"
    },
    {
        "name": "Kaimati",
        "value": "Kaimati"
    },
    {
        "name": "Kaimuh",
        "value": "Kaimuh"
    },
    {
        "name": "Kaintragarh",
        "value": "Kaintragarh"
    },
    {
        "name": "Kaippakancheri",
        "value": "Kaippakancheri"
    },
    {
        "name": "Kaipram",
        "value": "Kaipram"
    },
    {
        "name": "Kairana",
        "value": "Kairana"
    },
    {
        "name": "Kaith",
        "value": "Kaith"
    },
    {
        "name": "Kaithahi",
        "value": "Kaithahi"
    },
    {
        "name": "Kaithal",
        "value": "Kaithal"
    },
    {
        "name": "Kaithinia",
        "value": "Kaithinia"
    },
    {
        "name": "Kaithwar",
        "value": "Kaithwar"
    },
    {
        "name": "Kajha",
        "value": "Kajha"
    },
    {
        "name": "Kajhi Hridenagar",
        "value": "Kajhi Hridenagar"
    },
    {
        "name": "Kajur",
        "value": "Kajur"
    },
    {
        "name": "Kakalur",
        "value": "Kakalur"
    },
    {
        "name": "Kakan",
        "value": "Kakan"
    },
    {
        "name": "Kakarati",
        "value": "Kakarati"
    },
    {
        "name": "Kakching",
        "value": "Kakching"
    },
    {
        "name": "Kakching Khunou",
        "value": "Kakching Khunou"
    },
    {
        "name": "Kakdwip",
        "value": "Kakdwip"
    },
    {
        "name": "Kakhandiki",
        "value": "Kakhandiki"
    },
    {
        "name": "Kakinada",
        "value": "Kakinada"
    },
    {
        "name": "Kakkalapalle",
        "value": "Kakkalapalle"
    },
    {
        "name": "Kakkat",
        "value": "Kakkat"
    },
    {
        "name": "Kako",
        "value": "Kako"
    },
    {
        "name": "Kakrala",
        "value": "Kakrala"
    },
    {
        "name": "Kakraul",
        "value": "Kakraul"
    },
    {
        "name": "Kala Diara",
        "value": "Kala Diara"
    },
    {
        "name": "Kaladgi",
        "value": "Kaladgi"
    },
    {
        "name": "Kalaikunda",
        "value": "Kalaikunda"
    },
    {
        "name": "Kalaiyamputtur",
        "value": "Kalaiyamputtur"
    },
    {
        "name": "Kalakada",
        "value": "Kalakada"
    },
    {
        "name": "Kalakeri",
        "value": "Kalakeri"
    },
    {
        "name": "Kalakkadu",
        "value": "Kalakkadu"
    },
    {
        "name": "Kalamasseri",
        "value": "Kalamasseri"
    },
    {
        "name": "Kalamboli",
        "value": "Kalamboli"
    },
    {
        "name": "Kalamner",
        "value": "Kalamner"
    },
    {
        "name": "Kalamula",
        "value": "Kalamula"
    },
    {
        "name": "Kalanadu",
        "value": "Kalanadu"
    },
    {
        "name": "Kalanaur",
        "value": "Kalanaur"
    },
    {
        "name": "Kalanjur",
        "value": "Kalanjur"
    },
    {
        "name": "Kalappalangulam",
        "value": "Kalappalangulam"
    },
    {
        "name": "Kalappatti",
        "value": "Kalappatti"
    },
    {
        "name": "Kalaruch",
        "value": "Kalaruch"
    },
    {
        "name": "Kalas",
        "value": "Kalas"
    },
    {
        "name": "Kalasa",
        "value": "Kalasa"
    },
    {
        "name": "Kalavad",
        "value": "Kalavad"
    },
    {
        "name": "Kalavai",
        "value": "Kalavai"
    },
    {
        "name": "Kalavapudi",
        "value": "Kalavapudi"
    },
    {
        "name": "Kalayapuram",
        "value": "Kalayapuram"
    },
    {
        "name": "Kalchini",
        "value": "Kalchini"
    },
    {
        "name": "Kaler",
        "value": "Kaler"
    },
    {
        "name": "Kaleyanpur",
        "value": "Kaleyanpur"
    },
    {
        "name": "Kalghatgi",
        "value": "Kalghatgi"
    },
    {
        "name": "Kalgi",
        "value": "Kalgi"
    },
    {
        "name": "Kali",
        "value": "Kali"
    },
    {
        "name": "Kalianpur",
        "value": "Kalianpur"
    },
    {
        "name": "Kalicherla",
        "value": "Kalicherla"
    },
    {
        "name": "Kaliganj",
        "value": "Kaliganj"
    },
    {
        "name": "Kalikapur",
        "value": "Kalikapur"
    },
    {
        "name": "Kalikavu",
        "value": "Kalikavu"
    },
    {
        "name": "Kalikiri",
        "value": "Kalikiri"
    },
    {
        "name": "Kalimala",
        "value": "Kalimala"
    },
    {
        "name": "Kalimpong",
        "value": "Kalimpong"
    },
    {
        "name": "Kalinagar",
        "value": "Kalinagar"
    },
    {
        "name": "Kalingiyam",
        "value": "Kalingiyam"
    },
    {
        "name": "Kalipatnam",
        "value": "Kalipatnam"
    },
    {
        "name": "Kaliyaganj",
        "value": "Kaliyaganj"
    },
    {
        "name": "Kalkuni",
        "value": "Kalkuni"
    },
    {
        "name": "Kallad",
        "value": "Kallad"
    },
    {
        "name": "Kalladai",
        "value": "Kalladai"
    },
    {
        "name": "Kallakkudi",
        "value": "Kallakkudi"
    },
    {
        "name": "Kallakkurichchi",
        "value": "Kallakkurichchi"
    },
    {
        "name": "Kallamalai",
        "value": "Kallamalai"
    },
    {
        "name": "Kallanai",
        "value": "Kallanai"
    },
    {
        "name": "Kallar",
        "value": "Kallar"
    },
    {
        "name": "Kallayi",
        "value": "Kallayi"
    },
    {
        "name": "Kalleribhagam",
        "value": "Kalleribhagam"
    },
    {
        "name": "Kallidaikurichi",
        "value": "Kallidaikurichi"
    },
    {
        "name": "Kalliyassheri",
        "value": "Kalliyassheri"
    },
    {
        "name": "Kallupatti",
        "value": "Kallupatti"
    },
    {
        "name": "Kallur",
        "value": "Kallur"
    },
    {
        "name": "Kalluru",
        "value": "Kalluru"
    },
    {
        "name": "Kalna",
        "value": "Kalna"
    },
    {
        "name": "Kalol",
        "value": "Kalol"
    },
    {
        "name": "Kalpakathukonam",
        "value": "Kalpakathukonam"
    },
    {
        "name": "Kalpatta",
        "value": "Kalpatta"
    },
    {
        "name": "Kalpi",
        "value": "Kalpi"
    },
    {
        "name": "Kalugumalai",
        "value": "Kalugumalai"
    },
    {
        "name": "Kaluvaya",
        "value": "Kaluvaya"
    },
    {
        "name": "Kalvarpatti",
        "value": "Kalvarpatti"
    },
    {
        "name": "Kalwakurti",
        "value": "Kalwakurti"
    },
    {
        "name": "Kalyan",
        "value": "Kalyan"
    },
    {
        "name": "Kalyandrug",
        "value": "Kalyandrug"
    },
    {
        "name": "Kalyani",
        "value": "Kalyani"
    },
    {
        "name": "Kalyanpur",
        "value": "Kalyanpur"
    },
    {
        "name": "Kalyanpur Bamaiya",
        "value": "Kalyanpur Bamaiya"
    },
    {
        "name": "Kamakhyanagar",
        "value": "Kamakhyanagar"
    },
    {
        "name": "Kamalapuram",
        "value": "Kamalapuram"
    },
    {
        "name": "Kamalganj",
        "value": "Kamalganj"
    },
    {
        "name": "Kamalnagar",
        "value": "Kamalnagar"
    },
    {
        "name": "Kamareddipet",
        "value": "Kamareddipet"
    },
    {
        "name": "Kamargani",
        "value": "Kamargani"
    },
    {
        "name": "Kamarhati",
        "value": "Kamarhati"
    },
    {
        "name": "Kamatgi",
        "value": "Kamatgi"
    },
    {
        "name": "Kamavarapukota",
        "value": "Kamavarapukota"
    },
    {
        "name": "Kamayakkavundanpatti",
        "value": "Kamayakkavundanpatti"
    },
    {
        "name": "Kambaduru",
        "value": "Kambaduru"
    },
    {
        "name": "Kambainellur",
        "value": "Kambainellur"
    },
    {
        "name": "Kambaliyampatti",
        "value": "Kambaliyampatti"
    },
    {
        "name": "Kambam",
        "value": "Kambam"
    },
    {
        "name": "Kambaneri Pudukkudi",
        "value": "Kambaneri Pudukkudi"
    },
    {
        "name": "Kambhaladinne",
        "value": "Kambhaladinne"
    },
    {
        "name": "Kambhampadu",
        "value": "Kambhampadu"
    },
    {
        "name": "Kambla",
        "value": "Kambla"
    },
    {
        "name": "Kambur",
        "value": "Kambur"
    },
    {
        "name": "Kamdoli",
        "value": "Kamdoli"
    },
    {
        "name": "Kamepalle",
        "value": "Kamepalle"
    },
    {
        "name": "Kamlapur",
        "value": "Kamlapur"
    },
    {
        "name": "Kampel",
        "value": "Kampel"
    },
    {
        "name": "Kampli",
        "value": "Kampli"
    },
    {
        "name": "Kamrawan",
        "value": "Kamrawan"
    },
    {
        "name": "Kamrej",
        "value": "Kamrej"
    },
    {
        "name": "Kamtaul",
        "value": "Kamtaul"
    },
    {
        "name": "Kamthi",
        "value": "Kamthi"
    },
    {
        "name": "Kamudi",
        "value": "Kamudi"
    },
    {
        "name": "Kanabur",
        "value": "Kanabur"
    },
    {
        "name": "Kanajanahalli",
        "value": "Kanajanahalli"
    },
    {
        "name": "Kanakpur",
        "value": "Kanakpur"
    },
    {
        "name": "Kanamadi",
        "value": "Kanamadi"
    },
    {
        "name": "Kanasanapalle",
        "value": "Kanasanapalle"
    },
    {
        "name": "Kanavaikuli",
        "value": "Kanavaikuli"
    },
    {
        "name": "Kanavaypatti",
        "value": "Kanavaypatti"
    },
    {
        "name": "Kanavaypudur",
        "value": "Kanavaypudur"
    },
    {
        "name": "Kanchanpalli",
        "value": "Kanchanpalli"
    },
    {
        "name": "Kanchanpur",
        "value": "Kanchanpur"
    },
    {
        "name": "Kanchika",
        "value": "Kanchika"
    },
    {
        "name": "Kanchrapara",
        "value": "Kanchrapara"
    },
    {
        "name": "Kandakkadava",
        "value": "Kandakkadava"
    },
    {
        "name": "Kandanati",
        "value": "Kandanati"
    },
    {
        "name": "Kandanur",
        "value": "Kandanur"
    },
    {
        "name": "Kandi",
        "value": "Kandi"
    },
    {
        "name": "Kandiyankovil",
        "value": "Kandiyankovil"
    },
    {
        "name": "Kandla Port",
        "value": "Kandla Port"
    },
    {
        "name": "Kandra",
        "value": "Kandra"
    },
    {
        "name": "Kandrawan",
        "value": "Kandrawan"
    },
    {
        "name": "Kandukur",
        "value": "Kandukur"
    },
    {
        "name": "Kandulapuram",
        "value": "Kandulapuram"
    },
    {
        "name": "Kanekallu",
        "value": "Kanekallu"
    },
    {
        "name": "Kangan",
        "value": "Kangan"
    },
    {
        "name": "Kangayam",
        "value": "Kangayam"
    },
    {
        "name": "Kangazha",
        "value": "Kangazha"
    },
    {
        "name": "Kanhai",
        "value": "Kanhai"
    },
    {
        "name": "Kanhaipur",
        "value": "Kanhaipur"
    },
    {
        "name": "Kanhangad",
        "value": "Kanhangad"
    },
    {
        "name": "Kanhauli",
        "value": "Kanhauli"
    },
    {
        "name": "Kanhauli Manohar",
        "value": "Kanhauli Manohar"
    },
    {
        "name": "Kanigiri",
        "value": "Kanigiri"
    },
    {
        "name": "Kaniwara",
        "value": "Kaniwara"
    },
    {
        "name": "Kaniyambadi",
        "value": "Kaniyambadi"
    },
    {
        "name": "Kaniyur",
        "value": "Kaniyur"
    },
    {
        "name": "Kanjikkovil",
        "value": "Kanjikkovil"
    },
    {
        "name": "Kankanalapalle",
        "value": "Kankanalapalle"
    },
    {
        "name": "Kankandighi",
        "value": "Kankandighi"
    },
    {
        "name": "Kanke",
        "value": "Kanke"
    },
    {
        "name": "Kankipadu",
        "value": "Kankipadu"
    },
    {
        "name": "Kankol",
        "value": "Kankol"
    },
    {
        "name": "Kankon",
        "value": "Kankon"
    },
    {
        "name": "Kankuria",
        "value": "Kankuria"
    },
    {
        "name": "Kannadiparamba",
        "value": "Kannadiparamba"
    },
    {
        "name": "Kannal",
        "value": "Kannal"
    },
    {
        "name": "Kannamanayakkanur",
        "value": "Kannamanayakkanur"
    },
    {
        "name": "Kannamangalam",
        "value": "Kannamangalam"
    },
    {
        "name": "Kannamangalam Tekku",
        "value": "Kannamangalam Tekku"
    },
    {
        "name": "Kannampalaiyam",
        "value": "Kannampalaiyam"
    },
    {
        "name": "Kannandahalli",
        "value": "Kannandahalli"
    },
    {
        "name": "Kannanendal",
        "value": "Kannanendal"
    },
    {
        "name": "Kannankurichchi",
        "value": "Kannankurichchi"
    },
    {
        "name": "Kannapuram",
        "value": "Kannapuram"
    },
    {
        "name": "Kannavam",
        "value": "Kannavam"
    },
    {
        "name": "Kanniparamba",
        "value": "Kanniparamba"
    },
    {
        "name": "Kannivadi",
        "value": "Kannivadi"
    },
    {
        "name": "Kanniyakumari",
        "value": "Kanniyakumari"
    },
    {
        "name": "Kanniyambram",
        "value": "Kanniyambram"
    },
    {
        "name": "Kannod",
        "value": "Kannod"
    },
    {
        "name": "Kannudaiyampatti",
        "value": "Kannudaiyampatti"
    },
    {
        "name": "Kannulu",
        "value": "Kannulu"
    },
    {
        "name": "Kanp",
        "value": "Kanp"
    },
    {
        "name": "Kanpur",
        "value": "Kanpur"
    },
    {
        "name": "Kantabanji",
        "value": "Kantabanji"
    },
    {
        "name": "Kantai",
        "value": "Kantai"
    },
    {
        "name": "Kanteru",
        "value": "Kanteru"
    },
    {
        "name": "Kantilo",
        "value": "Kantilo"
    },
    {
        "name": "Kanur",
        "value": "Kanur"
    },
    {
        "name": "Kanyakulam",
        "value": "Kanyakulam"
    },
    {
        "name": "Kanyana",
        "value": "Kanyana"
    },
    {
        "name": "Kaoni",
        "value": "Kaoni"
    },
    {
        "name": "Kaonke",
        "value": "Kaonke"
    },
    {
        "name": "Kapadvanj",
        "value": "Kapadvanj"
    },
    {
        "name": "Kapasiawan",
        "value": "Kapasiawan"
    },
    {
        "name": "Kappiyara",
        "value": "Kappiyara"
    },
    {
        "name": "Kapra",
        "value": "Kapra"
    },
    {
        "name": "Kapurthala",
        "value": "Kapurthala"
    },
    {
        "name": "Karad",
        "value": "Karad"
    },
    {
        "name": "Karadge",
        "value": "Karadge"
    },
    {
        "name": "Karadichittur",
        "value": "Karadichittur"
    },
    {
        "name": "Karadipara",
        "value": "Karadipara"
    },
    {
        "name": "Karahal",
        "value": "Karahal"
    },
    {
        "name": "Karahia",
        "value": "Karahia"
    },
    {
        "name": "Karai",
        "value": "Karai"
    },
    {
        "name": "Karaikandi",
        "value": "Karaikandi"
    },
    {
        "name": "Karaikkudi",
        "value": "Karaikkudi"
    },
    {
        "name": "Karaiyampudur",
        "value": "Karaiyampudur"
    },
    {
        "name": "Karajgi",
        "value": "Karajgi"
    },
    {
        "name": "Karakthal",
        "value": "Karakthal"
    },
    {
        "name": "Karakurisshi",
        "value": "Karakurisshi"
    },
    {
        "name": "Karamadai",
        "value": "Karamadai"
    },
    {
        "name": "Karambakkudi",
        "value": "Karambakkudi"
    },
    {
        "name": "Karanchedu",
        "value": "Karanchedu"
    },
    {
        "name": "Karanja",
        "value": "Karanja"
    },
    {
        "name": "Karanjia",
        "value": "Karanjia"
    },
    {
        "name": "Karankot",
        "value": "Karankot"
    },
    {
        "name": "Karapa",
        "value": "Karapa"
    },
    {
        "name": "Kararan",
        "value": "Kararan"
    },
    {
        "name": "Karariya",
        "value": "Karariya"
    },
    {
        "name": "Karath",
        "value": "Karath"
    },
    {
        "name": "Karattuppalaiyam",
        "value": "Karattuppalaiyam"
    },
    {
        "name": "Karaund",
        "value": "Karaund"
    },
    {
        "name": "Karavalur",
        "value": "Karavalur"
    },
    {
        "name": "Karavaram",
        "value": "Karavaram"
    },
    {
        "name": "Karebilachi",
        "value": "Karebilachi"
    },
    {
        "name": "Karedu",
        "value": "Karedu"
    },
    {
        "name": "Kareli",
        "value": "Kareli"
    },
    {
        "name": "Karempudi",
        "value": "Karempudi"
    },
    {
        "name": "Karera",
        "value": "Karera"
    },
    {
        "name": "Kargahia Purab",
        "value": "Kargahia Purab"
    },
    {
        "name": "Karghar",
        "value": "Karghar"
    },
    {
        "name": "Kargil",
        "value": "Kargil"
    },
    {
        "name": "Kari",
        "value": "Kari"
    },
    {
        "name": "Karian",
        "value": "Karian"
    },
    {
        "name": "Karikad",
        "value": "Karikad"
    },
    {
        "name": "Karimganj",
        "value": "Karimganj"
    },
    {
        "name": "Karimkunnum",
        "value": "Karimkunnum"
    },
    {
        "name": "Karimnagar",
        "value": "Karimnagar"
    },
    {
        "name": "Karimpur",
        "value": "Karimpur"
    },
    {
        "name": "Karippira",
        "value": "Karippira"
    },
    {
        "name": "Karivalamvandanallur",
        "value": "Karivalamvandanallur"
    },
    {
        "name": "Kariyapatti",
        "value": "Kariyapatti"
    },
    {
        "name": "Karjan",
        "value": "Karjan"
    },
    {
        "name": "Karjat",
        "value": "Karjat"
    },
    {
        "name": "Karkala",
        "value": "Karkala"
    },
    {
        "name": "Karkamb",
        "value": "Karkamb"
    },
    {
        "name": "Karkudalpatti",
        "value": "Karkudalpatti"
    },
    {
        "name": "Karlapalem",
        "value": "Karlapalem"
    },
    {
        "name": "Karma",
        "value": "Karma"
    },
    {
        "name": "Karmauli",
        "value": "Karmauli"
    },
    {
        "name": "Karmegh",
        "value": "Karmegh"
    },
    {
        "name": "Karnal",
        "value": "Karnal"
    },
    {
        "name": "Karnawad",
        "value": "Karnawad"
    },
    {
        "name": "Karor",
        "value": "Karor"
    },
    {
        "name": "Karpi",
        "value": "Karpi"
    },
    {
        "name": "Karrapur",
        "value": "Karrapur"
    },
    {
        "name": "Karsaut",
        "value": "Karsaut"
    },
    {
        "name": "Karsiyang",
        "value": "Karsiyang"
    },
    {
        "name": "Kartal",
        "value": "Kartal"
    },
    {
        "name": "Kartarpur",
        "value": "Kartarpur"
    },
    {
        "name": "Kartikapalli",
        "value": "Kartikapalli"
    },
    {
        "name": "Karttigappalli",
        "value": "Karttigappalli"
    },
    {
        "name": "Karugamad",
        "value": "Karugamad"
    },
    {
        "name": "Karukachal",
        "value": "Karukachal"
    },
    {
        "name": "Karukkalvadi",
        "value": "Karukkalvadi"
    },
    {
        "name": "Karukurti",
        "value": "Karukurti"
    },
    {
        "name": "Karumandi Chellipalaiyam",
        "value": "Karumandi Chellipalaiyam"
    },
    {
        "name": "Karumattampatti",
        "value": "Karumattampatti"
    },
    {
        "name": "Karumulaikkal",
        "value": "Karumulaikkal"
    },
    {
        "name": "Karunagapalli",
        "value": "Karunagapalli"
    },
    {
        "name": "Karuppur",
        "value": "Karuppur"
    },
    {
        "name": "Karur",
        "value": "Karur"
    },
    {
        "name": "Karuvakulam",
        "value": "Karuvakulam"
    },
    {
        "name": "Karuvambram",
        "value": "Karuvambram"
    },
    {
        "name": "Karuvelampatti",
        "value": "Karuvelampatti"
    },
    {
        "name": "Karuvellur",
        "value": "Karuvellur"
    },
    {
        "name": "Karuveppampatti",
        "value": "Karuveppampatti"
    },
    {
        "name": "Karvarakundu",
        "value": "Karvarakundu"
    },
    {
        "name": "Karvetnagar",
        "value": "Karvetnagar"
    },
    {
        "name": "Karwar",
        "value": "Karwar"
    },
    {
        "name": "Kasap",
        "value": "Kasap"
    },
    {
        "name": "Kasaragod",
        "value": "Kasaragod"
    },
    {
        "name": "Kasavanampatti",
        "value": "Kasavanampatti"
    },
    {
        "name": "Kasba",
        "value": "Kasba"
    },
    {
        "name": "Kasba Maker",
        "value": "Kasba Maker"
    },
    {
        "name": "Kashipur",
        "value": "Kashipur"
    },
    {
        "name": "Kashti",
        "value": "Kashti"
    },
    {
        "name": "Kasimkota",
        "value": "Kasimkota"
    },
    {
        "name": "Kasipalaiyam",
        "value": "Kasipalaiyam"
    },
    {
        "name": "Kasrawad",
        "value": "Kasrawad"
    },
    {
        "name": "Kasumpti",
        "value": "Kasumpti"
    },
    {
        "name": "Kataha",
        "value": "Kataha"
    },
    {
        "name": "Katahra",
        "value": "Katahra"
    },
    {
        "name": "Katakos",
        "value": "Katakos"
    },
    {
        "name": "Katalpur",
        "value": "Katalpur"
    },
    {
        "name": "Katangi",
        "value": "Katangi"
    },
    {
        "name": "Katarmala",
        "value": "Katarmala"
    },
    {
        "name": "Kathanian",
        "value": "Kathanian"
    },
    {
        "name": "Kathevaram",
        "value": "Kathevaram"
    },
    {
        "name": "Kathua",
        "value": "Kathua"
    },
    {
        "name": "Kathurah",
        "value": "Kathurah"
    },
    {
        "name": "Katigang",
        "value": "Katigang"
    },
    {
        "name": "Katihar",
        "value": "Katihar"
    },
    {
        "name": "Katkol",
        "value": "Katkol"
    },
    {
        "name": "Katoya",
        "value": "Katoya"
    },
    {
        "name": "Katpadi",
        "value": "Katpadi"
    },
    {
        "name": "Katra",
        "value": "Katra"
    },
    {
        "name": "Katravulapalle",
        "value": "Katravulapalle"
    },
    {
        "name": "Katri",
        "value": "Katri"
    },
    {
        "name": "Katridih",
        "value": "Katridih"
    },
    {
        "name": "Kattagaram",
        "value": "Kattagaram"
    },
    {
        "name": "Kattakampala",
        "value": "Kattakampala"
    },
    {
        "name": "Kattamuru",
        "value": "Kattamuru"
    },
    {
        "name": "Kattanam",
        "value": "Kattanam"
    },
    {
        "name": "Kattari",
        "value": "Kattari"
    },
    {
        "name": "Katteragandla",
        "value": "Katteragandla"
    },
    {
        "name": "Kattipparutti",
        "value": "Kattipparutti"
    },
    {
        "name": "Kattipudi",
        "value": "Kattipudi"
    },
    {
        "name": "Kattirippulam",
        "value": "Kattirippulam"
    },
    {
        "name": "Kattivakkam",
        "value": "Kattivakkam"
    },
    {
        "name": "Kattiyeri",
        "value": "Kattiyeri"
    },
    {
        "name": "Kattukkottai",
        "value": "Kattukkottai"
    },
    {
        "name": "Kattupputtur",
        "value": "Kattupputtur"
    },
    {
        "name": "Katuria",
        "value": "Katuria"
    },
    {
        "name": "Kaua Kol",
        "value": "Kaua Kol"
    },
    {
        "name": "Kaujalgi",
        "value": "Kaujalgi"
    },
    {
        "name": "Kaul",
        "value": "Kaul"
    },
    {
        "name": "Kaunra",
        "value": "Kaunra"
    },
    {
        "name": "Kaurihar",
        "value": "Kaurihar"
    },
    {
        "name": "Kauriya",
        "value": "Kauriya"
    },
    {
        "name": "Kautalam",
        "value": "Kautalam"
    },
    {
        "name": "Kavali",
        "value": "Kavali"
    },
    {
        "name": "Kavallemmavu",
        "value": "Kavallemmavu"
    },
    {
        "name": "Kavaratti",
        "value": "Kavaratti"
    },
    {
        "name": "Kaveripatnam",
        "value": "Kaveripatnam"
    },
    {
        "name": "Kavital",
        "value": "Kavital"
    },
    {
        "name": "Kaviti",
        "value": "Kaviti"
    },
    {
        "name": "Kavundappadi",
        "value": "Kavundappadi"
    },
    {
        "name": "Kawadgaon",
        "value": "Kawadgaon"
    },
    {
        "name": "Kayalpattanam",
        "value": "Kayalpattanam"
    },
    {
        "name": "Kayankulam",
        "value": "Kayankulam"
    },
    {
        "name": "Kayanna",
        "value": "Kayanna"
    },
    {
        "name": "Kayaralam",
        "value": "Kayaralam"
    },
    {
        "name": "Kayattar",
        "value": "Kayattar"
    },
    {
        "name": "Kaza",
        "value": "Kaza"
    },
    {
        "name": "Kazhukambalam",
        "value": "Kazhukambalam"
    },
    {
        "name": "Keal",
        "value": "Keal"
    },
    {
        "name": "Kedavur",
        "value": "Kedavur"
    },
    {
        "name": "Kedgaon",
        "value": "Kedgaon"
    },
    {
        "name": "Kedia",
        "value": "Kedia"
    },
    {
        "name": "Keevallur",
        "value": "Keevallur"
    },
    {
        "name": "Keitumkawn",
        "value": "Keitumkawn"
    },
    {
        "name": "Kela Khera",
        "value": "Kela Khera"
    },
    {
        "name": "Kelamangalam",
        "value": "Kelamangalam"
    },
    {
        "name": "Kelangah",
        "value": "Kelangah"
    },
    {
        "name": "Kembhavi",
        "value": "Kembhavi"
    },
    {
        "name": "Kenar",
        "value": "Kenar"
    },
    {
        "name": "Kendraparha",
        "value": "Kendraparha"
    },
    {
        "name": "Kenduadih",
        "value": "Kenduadih"
    },
    {
        "name": "Kengarai",
        "value": "Kengarai"
    },
    {
        "name": "Kengri",
        "value": "Kengri"
    },
    {
        "name": "Keolari",
        "value": "Keolari"
    },
    {
        "name": "Keonjhargarh",
        "value": "Keonjhargarh"
    },
    {
        "name": "Keora",
        "value": "Keora"
    },
    {
        "name": "Keota",
        "value": "Keota"
    },
    {
        "name": "Kerai",
        "value": "Kerai"
    },
    {
        "name": "Kerap",
        "value": "Kerap"
    },
    {
        "name": "Keregodu",
        "value": "Keregodu"
    },
    {
        "name": "Kerur",
        "value": "Kerur"
    },
    {
        "name": "Kerwada",
        "value": "Kerwada"
    },
    {
        "name": "Kesabpur",
        "value": "Kesabpur"
    },
    {
        "name": "Kesamudram",
        "value": "Kesamudram"
    },
    {
        "name": "Kesarimangalam",
        "value": "Kesarimangalam"
    },
    {
        "name": "Kesariya",
        "value": "Kesariya"
    },
    {
        "name": "Kesath",
        "value": "Kesath"
    },
    {
        "name": "Kesavapuram",
        "value": "Kesavapuram"
    },
    {
        "name": "Keshod",
        "value": "Keshod"
    },
    {
        "name": "Keshwari",
        "value": "Keshwari"
    },
    {
        "name": "Kesli",
        "value": "Kesli"
    },
    {
        "name": "Ketti",
        "value": "Ketti"
    },
    {
        "name": "Ketugram",
        "value": "Ketugram"
    },
    {
        "name": "Kewatgawan",
        "value": "Kewatgawan"
    },
    {
        "name": "Khachrod",
        "value": "Khachrod"
    },
    {
        "name": "Khagam",
        "value": "Khagam"
    },
    {
        "name": "Khagaria",
        "value": "Khagaria"
    },
    {
        "name": "Khagaul",
        "value": "Khagaul"
    },
    {
        "name": "Khagaur",
        "value": "Khagaur"
    },
    {
        "name": "Khagra",
        "value": "Khagra"
    },
    {
        "name": "Khair Khan",
        "value": "Khair Khan"
    },
    {
        "name": "Khaira",
        "value": "Khaira"
    },
    {
        "name": "Khaira Tola",
        "value": "Khaira Tola"
    },
    {
        "name": "Khairabad",
        "value": "Khairabad"
    },
    {
        "name": "Khairtal",
        "value": "Khairtal"
    },
    {
        "name": "Khajamahalpur",
        "value": "Khajamahalpur"
    },
    {
        "name": "Khajawa",
        "value": "Khajawa"
    },
    {
        "name": "Khajuraho",
        "value": "Khajuraho"
    },
    {
        "name": "Khajuri",
        "value": "Khajuri"
    },
    {
        "name": "Khalari",
        "value": "Khalari"
    },
    {
        "name": "Khallikot",
        "value": "Khallikot"
    },
    {
        "name": "Khamanon Kalan",
        "value": "Khamanon Kalan"
    },
    {
        "name": "Khamaria",
        "value": "Khamaria"
    },
    {
        "name": "Khambhaliya",
        "value": "Khambhaliya"
    },
    {
        "name": "Khambhat",
        "value": "Khambhat"
    },
    {
        "name": "Khammam",
        "value": "Khammam"
    },
    {
        "name": "Khanapur",
        "value": "Khanapur"
    },
    {
        "name": "Khanda",
        "value": "Khanda"
    },
    {
        "name": "Khandaich",
        "value": "Khandaich"
    },
    {
        "name": "Khandala",
        "value": "Khandala"
    },
    {
        "name": "Khandauli",
        "value": "Khandauli"
    },
    {
        "name": "Khandela",
        "value": "Khandela"
    },
    {
        "name": "Khandhar",
        "value": "Khandhar"
    },
    {
        "name": "Khandpara",
        "value": "Khandpara"
    },
    {
        "name": "Khandrauli",
        "value": "Khandrauli"
    },
    {
        "name": "Khandsa",
        "value": "Khandsa"
    },
    {
        "name": "Khandwa",
        "value": "Khandwa"
    },
    {
        "name": "Khangaon",
        "value": "Khangaon"
    },
    {
        "name": "Khaniadhana",
        "value": "Khaniadhana"
    },
    {
        "name": "Khanjahanpur",
        "value": "Khanjahanpur"
    },
    {
        "name": "Khanna",
        "value": "Khanna"
    },
    {
        "name": "Khanpur",
        "value": "Khanpur"
    },
    {
        "name": "Khanpur Khairanti",
        "value": "Khanpur Khairanti"
    },
    {
        "name": "Khansahibpuram",
        "value": "Khansahibpuram"
    },
    {
        "name": "Khapdeh",
        "value": "Khapdeh"
    },
    {
        "name": "Kharagbani",
        "value": "Kharagbani"
    },
    {
        "name": "Kharagpur",
        "value": "Kharagpur"
    },
    {
        "name": "Kharahara",
        "value": "Kharahara"
    },
    {
        "name": "Kharak Kalan",
        "value": "Kharak Kalan"
    },
    {
        "name": "Kharar",
        "value": "Kharar"
    },
    {
        "name": "Khardah",
        "value": "Khardah"
    },
    {
        "name": "Khargapur",
        "value": "Khargapur"
    },
    {
        "name": "Khargone",
        "value": "Khargone"
    },
    {
        "name": "Khargram",
        "value": "Khargram"
    },
    {
        "name": "Kharhial",
        "value": "Kharhial"
    },
    {
        "name": "Khari",
        "value": "Khari"
    },
    {
        "name": "Kharial",
        "value": "Kharial"
    },
    {
        "name": "Kharik",
        "value": "Kharik"
    },
    {
        "name": "Kharika",
        "value": "Kharika"
    },
    {
        "name": "Kharki",
        "value": "Kharki"
    },
    {
        "name": "Kharod",
        "value": "Kharod"
    },
    {
        "name": "Kharsawan",
        "value": "Kharsawan"
    },
    {
        "name": "Kharsod B",
        "value": "Kharsod B"
    },
    {
        "name": "Kharupatia",
        "value": "Kharupatia"
    },
    {
        "name": "Khasbalanda",
        "value": "Khasbalanda"
    },
    {
        "name": "Khaspur",
        "value": "Khaspur"
    },
    {
        "name": "Khategaon",
        "value": "Khategaon"
    },
    {
        "name": "Khathjari",
        "value": "Khathjari"
    },
    {
        "name": "Khatima",
        "value": "Khatima"
    },
    {
        "name": "Khattan",
        "value": "Khattan"
    },
    {
        "name": "Khaur",
        "value": "Khaur"
    },
    {
        "name": "Khawad",
        "value": "Khawad"
    },
    {
        "name": "Khawaspur",
        "value": "Khawaspur"
    },
    {
        "name": "Khed",
        "value": "Khed"
    },
    {
        "name": "Khed Brahma",
        "value": "Khed Brahma"
    },
    {
        "name": "Kheda",
        "value": "Kheda"
    },
    {
        "name": "Khejroli",
        "value": "Khejroli"
    },
    {
        "name": "Khem Karan",
        "value": "Khem Karan"
    },
    {
        "name": "Khem Karan Saray",
        "value": "Khem Karan Saray"
    },
    {
        "name": "Kheralu",
        "value": "Kheralu"
    },
    {
        "name": "Khergam",
        "value": "Khergam"
    },
    {
        "name": "Kheri Naru",
        "value": "Kheri Naru"
    },
    {
        "name": "Khetia",
        "value": "Khetia"
    },
    {
        "name": "Khetko",
        "value": "Khetko"
    },
    {
        "name": "Khijri",
        "value": "Khijri"
    },
    {
        "name": "Khilchipur",
        "value": "Khilchipur"
    },
    {
        "name": "Khimlasa",
        "value": "Khimlasa"
    },
    {
        "name": "Khiram",
        "value": "Khiram"
    },
    {
        "name": "Khirhar",
        "value": "Khirhar"
    },
    {
        "name": "Khiria",
        "value": "Khiria"
    },
    {
        "name": "Khiria Jhansi",
        "value": "Khiria Jhansi"
    },
    {
        "name": "Khiriawan",
        "value": "Khiriawan"
    },
    {
        "name": "Khirkiyan",
        "value": "Khirkiyan"
    },
    {
        "name": "Khirpai",
        "value": "Khirpai"
    },
    {
        "name": "Khizrpur",
        "value": "Khizrpur"
    },
    {
        "name": "Khokha",
        "value": "Khokha"
    },
    {
        "name": "Khokri Kalan",
        "value": "Khokri Kalan"
    },
    {
        "name": "Khopoli",
        "value": "Khopoli"
    },
    {
        "name": "Khorabar",
        "value": "Khorabar"
    },
    {
        "name": "Khoragachhi",
        "value": "Khoragachhi"
    },
    {
        "name": "Khowai",
        "value": "Khowai"
    },
    {
        "name": "Khujner",
        "value": "Khujner"
    },
    {
        "name": "Khundawandpur",
        "value": "Khundawandpur"
    },
    {
        "name": "Khunti",
        "value": "Khunti"
    },
    {
        "name": "Khunti Dhanaili",
        "value": "Khunti Dhanaili"
    },
    {
        "name": "Khurai",
        "value": "Khurai"
    },
    {
        "name": "Khuran Milik",
        "value": "Khuran Milik"
    },
    {
        "name": "Khurda",
        "value": "Khurda"
    },
    {
        "name": "Khusropur",
        "value": "Khusropur"
    },
    {
        "name": "Khutaha",
        "value": "Khutaha"
    },
    {
        "name": "Khutauna",
        "value": "Khutauna"
    },
    {
        "name": "Khutha Baijnath",
        "value": "Khutha Baijnath"
    },
    {
        "name": "Kichha",
        "value": "Kichha"
    },
    {
        "name": "Kidamangalam",
        "value": "Kidamangalam"
    },
    {
        "name": "Kidangazhi",
        "value": "Kidangazhi"
    },
    {
        "name": "Kil Perambalur",
        "value": "Kil Perambalur"
    },
    {
        "name": "Kil Valur",
        "value": "Kil Valur"
    },
    {
        "name": "Kilakkarai",
        "value": "Kilakkarai"
    },
    {
        "name": "Kilakkurichchi",
        "value": "Kilakkurichchi"
    },
    {
        "name": "Kilankundal",
        "value": "Kilankundal"
    },
    {
        "name": "Kilattingal",
        "value": "Kilattingal"
    },
    {
        "name": "Kilkottai",
        "value": "Kilkottai"
    },
    {
        "name": "Kilkunda",
        "value": "Kilkunda"
    },
    {
        "name": "Killai",
        "value": "Killai"
    },
    {
        "name": "Killimangalam",
        "value": "Killimangalam"
    },
    {
        "name": "Kilmangalam",
        "value": "Kilmangalam"
    },
    {
        "name": "Kilminnal",
        "value": "Kilminnal"
    },
    {
        "name": "Kilrajakularaman",
        "value": "Kilrajakularaman"
    },
    {
        "name": "Kilvisharam",
        "value": "Kilvisharam"
    },
    {
        "name": "Kinalur",
        "value": "Kinalur"
    },
    {
        "name": "Kinattukkadavu",
        "value": "Kinattukkadavu"
    },
    {
        "name": "Kingri",
        "value": "Kingri"
    },
    {
        "name": "Kinhalu",
        "value": "Kinhalu"
    },
    {
        "name": "Kiramangalam",
        "value": "Kiramangalam"
    },
    {
        "name": "Kirangur",
        "value": "Kirangur"
    },
    {
        "name": "Kiranur",
        "value": "Kiranur"
    },
    {
        "name": "Kiratot",
        "value": "Kiratot"
    },
    {
        "name": "Kiratpur Rajaram",
        "value": "Kiratpur Rajaram"
    },
    {
        "name": "Kirikera",
        "value": "Kirikera"
    },
    {
        "name": "Kirippatti",
        "value": "Kirippatti"
    },
    {
        "name": "Kirlampudi",
        "value": "Kirlampudi"
    },
    {
        "name": "Kirrayach",
        "value": "Kirrayach"
    },
    {
        "name": "Kisara",
        "value": "Kisara"
    },
    {
        "name": "Kishanganj",
        "value": "Kishanganj"
    },
    {
        "name": "Kishangarh",
        "value": "Kishangarh"
    },
    {
        "name": "Kishanpur Ratwara",
        "value": "Kishanpur Ratwara"
    },
    {
        "name": "Kishanpura Kalan",
        "value": "Kishanpura Kalan"
    },
    {
        "name": "Kishtwar",
        "value": "Kishtwar"
    },
    {
        "name": "Kishundaspur",
        "value": "Kishundaspur"
    },
    {
        "name": "Kishunpur",
        "value": "Kishunpur"
    },
    {
        "name": "Kita Chauhattar",
        "value": "Kita Chauhattar"
    },
    {
        "name": "Kittanapalli",
        "value": "Kittanapalli"
    },
    {
        "name": "Kittur",
        "value": "Kittur"
    },
    {
        "name": "Kizhake Chalakudi",
        "value": "Kizhake Chalakudi"
    },
    {
        "name": "Kizhakkanela",
        "value": "Kizhakkanela"
    },
    {
        "name": "Kizhakkemanad",
        "value": "Kizhakkemanad"
    },
    {
        "name": "Kizhakkott",
        "value": "Kizhakkott"
    },
    {
        "name": "Kizhariyur",
        "value": "Kizhariyur"
    },
    {
        "name": "Kizhattur",
        "value": "Kizhattur"
    },
    {
        "name": "Kizhur",
        "value": "Kizhur"
    },
    {
        "name": "Koath",
        "value": "Koath"
    },
    {
        "name": "Koba",
        "value": "Koba"
    },
    {
        "name": "Koch",
        "value": "Koch"
    },
    {
        "name": "Koch Bihar",
        "value": "Koch Bihar"
    },
    {
        "name": "Kochas",
        "value": "Kochas"
    },
    {
        "name": "Kochgawan",
        "value": "Kochgawan"
    },
    {
        "name": "Kochhor",
        "value": "Kochhor"
    },
    {
        "name": "Kochi",
        "value": "Kochi"
    },
    {
        "name": "Kochugaon",
        "value": "Kochugaon"
    },
    {
        "name": "Kod",
        "value": "Kod"
    },
    {
        "name": "Kodad",
        "value": "Kodad"
    },
    {
        "name": "Kodaikanal",
        "value": "Kodaikanal"
    },
    {
        "name": "Kodaimangalam",
        "value": "Kodaimangalam"
    },
    {
        "name": "Kodakkal",
        "value": "Kodakkal"
    },
    {
        "name": "Kodala",
        "value": "Kodala"
    },
    {
        "name": "Kodanad",
        "value": "Kodanad"
    },
    {
        "name": "Kodangipatti",
        "value": "Kodangipatti"
    },
    {
        "name": "Kodarma",
        "value": "Kodarma"
    },
    {
        "name": "Kodavatipudi",
        "value": "Kodavatipudi"
    },
    {
        "name": "Kodayattur",
        "value": "Kodayattur"
    },
    {
        "name": "Kodigenahalli",
        "value": "Kodigenahalli"
    },
    {
        "name": "Kodikkulam",
        "value": "Kodikkulam"
    },
    {
        "name": "Kodikulam",
        "value": "Kodikulam"
    },
    {
        "name": "Kodikuthi",
        "value": "Kodikuthi"
    },
    {
        "name": "Kodinar",
        "value": "Kodinar"
    },
    {
        "name": "Kodivalasa",
        "value": "Kodivalasa"
    },
    {
        "name": "Kodiyeri",
        "value": "Kodiyeri"
    },
    {
        "name": "Kodmial",
        "value": "Kodmial"
    },
    {
        "name": "Kodoli",
        "value": "Kodoli"
    },
    {
        "name": "Kodriva",
        "value": "Kodriva"
    },
    {
        "name": "Koduman",
        "value": "Koduman"
    },
    {
        "name": "Kodumba",
        "value": "Kodumba"
    },
    {
        "name": "Kodumudi",
        "value": "Kodumudi"
    },
    {
        "name": "Kodumur",
        "value": "Kodumur"
    },
    {
        "name": "Kodungallur",
        "value": "Kodungallur"
    },
    {
        "name": "Koduru",
        "value": "Koduru"
    },
    {
        "name": "Kodusseri",
        "value": "Kodusseri"
    },
    {
        "name": "Koduvalli",
        "value": "Koduvalli"
    },
    {
        "name": "Koduvilarpatti",
        "value": "Koduvilarpatti"
    },
    {
        "name": "Koelwar",
        "value": "Koelwar"
    },
    {
        "name": "Koheda",
        "value": "Koheda"
    },
    {
        "name": "Kohima",
        "value": "Kohima"
    },
    {
        "name": "Kohir",
        "value": "Kohir"
    },
    {
        "name": "Koila",
        "value": "Koila"
    },
    {
        "name": "Koila Belwa",
        "value": "Koila Belwa"
    },
    {
        "name": "Koila Dewa",
        "value": "Koila Dewa"
    },
    {
        "name": "Koilakh",
        "value": "Koilakh"
    },
    {
        "name": "Koili Simra",
        "value": "Koili Simra"
    },
    {
        "name": "Koilkuntla",
        "value": "Koilkuntla"
    },
    {
        "name": "Koini",
        "value": "Koini"
    },
    {
        "name": "Koipadi",
        "value": "Koipadi"
    },
    {
        "name": "Koiri Bigha",
        "value": "Koiri Bigha"
    },
    {
        "name": "Koiridih",
        "value": "Koiridih"
    },
    {
        "name": "Kokiladanga",
        "value": "Kokiladanga"
    },
    {
        "name": "Kokrajhar",
        "value": "Kokrajhar"
    },
    {
        "name": "Kola",
        "value": "Kola"
    },
    {
        "name": "Kolaccheri",
        "value": "Kolaccheri"
    },
    {
        "name": "Kolachel",
        "value": "Kolachel"
    },
    {
        "name": "Kolagallu",
        "value": "Kolagallu"
    },
    {
        "name": "Kolakaluru",
        "value": "Kolakaluru"
    },
    {
        "name": "Kolanpak",
        "value": "Kolanpak"
    },
    {
        "name": "Kolappalur",
        "value": "Kolappalur"
    },
    {
        "name": "Kolar",
        "value": "Kolar"
    },
    {
        "name": "Kolaras",
        "value": "Kolaras"
    },
    {
        "name": "Kolattupuzha",
        "value": "Kolattupuzha"
    },
    {
        "name": "Kolattur",
        "value": "Kolattur"
    },
    {
        "name": "Kolavallur",
        "value": "Kolavallur"
    },
    {
        "name": "Kolga",
        "value": "Kolga"
    },
    {
        "name": "Kolhapur",
        "value": "Kolhapur"
    },
    {
        "name": "Koliakkod",
        "value": "Koliakkod"
    },
    {
        "name": "Kolkata",
        "value": "Kolkata"
    },
    {
        "name": "Kollankulam",
        "value": "Kollankulam"
    },
    {
        "name": "Kollegal",
        "value": "Kollegal"
    },
    {
        "name": "Kollengode",
        "value": "Kollengode"
    },
    {
        "name": "Kollipara",
        "value": "Kollipara"
    },
    {
        "name": "Kolluru",
        "value": "Kolluru"
    },
    {
        "name": "Kolnad",
        "value": "Kolnad"
    },
    {
        "name": "Kolnur",
        "value": "Kolnur"
    },
    {
        "name": "Koloriang",
        "value": "Koloriang"
    },
    {
        "name": "Kolumalapalle",
        "value": "Kolumalapalle"
    },
    {
        "name": "Kolwara",
        "value": "Kolwara"
    },
    {
        "name": "Komarolu",
        "value": "Komarolu"
    },
    {
        "name": "Kombai",
        "value": "Kombai"
    },
    {
        "name": "Kommuru",
        "value": "Kommuru"
    },
    {
        "name": "Kompalle",
        "value": "Kompalle"
    },
    {
        "name": "Konaje",
        "value": "Konaje"
    },
    {
        "name": "Konakondla",
        "value": "Konakondla"
    },
    {
        "name": "Konand",
        "value": "Konand"
    },
    {
        "name": "Konarka",
        "value": "Konarka"
    },
    {
        "name": "Kondakindi Agraharam",
        "value": "Kondakindi Agraharam"
    },
    {
        "name": "Kondakomarla",
        "value": "Kondakomarla"
    },
    {
        "name": "Kondalahalli",
        "value": "Kondalahalli"
    },
    {
        "name": "Kondalampatti",
        "value": "Kondalampatti"
    },
    {
        "name": "Kondapalle",
        "value": "Kondapalle"
    },
    {
        "name": "Kondaparti",
        "value": "Kondaparti"
    },
    {
        "name": "Kondarangi Kiranur",
        "value": "Kondarangi Kiranur"
    },
    {
        "name": "Kondayampalaiyam",
        "value": "Kondayampalaiyam"
    },
    {
        "name": "Kondazhi",
        "value": "Kondazhi"
    },
    {
        "name": "Kondhali",
        "value": "Kondhali"
    },
    {
        "name": "Kondrukota",
        "value": "Kondrukota"
    },
    {
        "name": "Kondur",
        "value": "Kondur"
    },
    {
        "name": "Konduru",
        "value": "Konduru"
    },
    {
        "name": "Konen Agrahar",
        "value": "Konen Agrahar"
    },
    {
        "name": "Konganapuram",
        "value": "Konganapuram"
    },
    {
        "name": "Kongarapalli",
        "value": "Kongarapalli"
    },
    {
        "name": "Kongnolli",
        "value": "Kongnolli"
    },
    {
        "name": "Kongupatti",
        "value": "Kongupatti"
    },
    {
        "name": "Koni",
        "value": "Koni"
    },
    {
        "name": "Konidena",
        "value": "Konidena"
    },
    {
        "name": "Konkavaripalle",
        "value": "Konkavaripalle"
    },
    {
        "name": "Konnur",
        "value": "Konnur"
    },
    {
        "name": "Kopa",
        "value": "Kopa"
    },
    {
        "name": "Koppaka",
        "value": "Koppaka"
    },
    {
        "name": "Koppal",
        "value": "Koppal"
    },
    {
        "name": "Kopparam",
        "value": "Kopparam"
    },
    {
        "name": "Koppunur",
        "value": "Koppunur"
    },
    {
        "name": "Korahia",
        "value": "Korahia"
    },
    {
        "name": "Koranampatti",
        "value": "Koranampatti"
    },
    {
        "name": "Korangal",
        "value": "Korangal"
    },
    {
        "name": "Koraput",
        "value": "Koraput"
    },
    {
        "name": "Koratagere",
        "value": "Koratagere"
    },
    {
        "name": "Koratgi",
        "value": "Koratgi"
    },
    {
        "name": "Koratla",
        "value": "Koratla"
    },
    {
        "name": "Korba",
        "value": "Korba"
    },
    {
        "name": "Koregaon",
        "value": "Koregaon"
    },
    {
        "name": "Koriapatti",
        "value": "Koriapatti"
    },
    {
        "name": "Kornepadu",
        "value": "Kornepadu"
    },
    {
        "name": "Korosavada",
        "value": "Korosavada"
    },
    {
        "name": "Koroth",
        "value": "Koroth"
    },
    {
        "name": "Kosamba",
        "value": "Kosamba"
    },
    {
        "name": "Kosgi",
        "value": "Kosgi"
    },
    {
        "name": "Koshanam",
        "value": "Koshanam"
    },
    {
        "name": "Kosigi",
        "value": "Kosigi"
    },
    {
        "name": "Koskapur",
        "value": "Koskapur"
    },
    {
        "name": "Kot Bhai",
        "value": "Kot Bhai"
    },
    {
        "name": "Kot Kapura",
        "value": "Kot Kapura"
    },
    {
        "name": "Kot Shamir",
        "value": "Kot Shamir"
    },
    {
        "name": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kotabommali",
        "value": "Kotabommali"
    },
    {
        "name": "Kotagiri",
        "value": "Kotagiri"
    },
    {
        "name": "Kotal",
        "value": "Kotal"
    },
    {
        "name": "Kotancheri",
        "value": "Kotancheri"
    },
    {
        "name": "Kotdwara",
        "value": "Kotdwara"
    },
    {
        "name": "Kotekara",
        "value": "Kotekara"
    },
    {
        "name": "Koteshwar",
        "value": "Koteshwar"
    },
    {
        "name": "Kotgir",
        "value": "Kotgir"
    },
    {
        "name": "Koth",
        "value": "Koth"
    },
    {
        "name": "Kotha Guru",
        "value": "Kotha Guru"
    },
    {
        "name": "Kothanuru",
        "value": "Kothanuru"
    },
    {
        "name": "Kothapet",
        "value": "Kothapet"
    },
    {
        "name": "Kotharpettai",
        "value": "Kotharpettai"
    },
    {
        "name": "Kothi",
        "value": "Kothi"
    },
    {
        "name": "Kothia",
        "value": "Kothia"
    },
    {
        "name": "Kothri Kalan",
        "value": "Kothri Kalan"
    },
    {
        "name": "Kotia",
        "value": "Kotia"
    },
    {
        "name": "Kotla",
        "value": "Kotla"
    },
    {
        "name": "Kotli Ablu",
        "value": "Kotli Ablu"
    },
    {
        "name": "Kotma",
        "value": "Kotma"
    },
    {
        "name": "Kotra",
        "value": "Kotra"
    },
    {
        "name": "Kotta Kalidindi",
        "value": "Kotta Kalidindi"
    },
    {
        "name": "Kottacheruvu",
        "value": "Kottacheruvu"
    },
    {
        "name": "Kottadindulu",
        "value": "Kottadindulu"
    },
    {
        "name": "Kottagudem",
        "value": "Kottagudem"
    },
    {
        "name": "Kottagudi Muttanad",
        "value": "Kottagudi Muttanad"
    },
    {
        "name": "Kottaikuppam",
        "value": "Kottaikuppam"
    },
    {
        "name": "Kottaipatti",
        "value": "Kottaipatti"
    },
    {
        "name": "Kottaiyur",
        "value": "Kottaiyur"
    },
    {
        "name": "Kottakota",
        "value": "Kottakota"
    },
    {
        "name": "Kottampatti",
        "value": "Kottampatti"
    },
    {
        "name": "Kottangara",
        "value": "Kottangara"
    },
    {
        "name": "Kottapadi",
        "value": "Kottapadi"
    },
    {
        "name": "Kottapalem",
        "value": "Kottapalem"
    },
    {
        "name": "Kottapalle",
        "value": "Kottapalle"
    },
    {
        "name": "Kottapeta",
        "value": "Kottapeta"
    },
    {
        "name": "Kottapuram",
        "value": "Kottapuram"
    },
    {
        "name": "Kottaram",
        "value": "Kottaram"
    },
    {
        "name": "Kottavalasa",
        "value": "Kottavalasa"
    },
    {
        "name": "Kottaya",
        "value": "Kottaya"
    },
    {
        "name": "Kottayam",
        "value": "Kottayam"
    },
    {
        "name": "Kottoppadam",
        "value": "Kottoppadam"
    },
    {
        "name": "Kottukal",
        "value": "Kottukal"
    },
    {
        "name": "Kottur",
        "value": "Kottur"
    },
    {
        "name": "Kotturu",
        "value": "Kotturu"
    },
    {
        "name": "Koturkolkara",
        "value": "Koturkolkara"
    },
    {
        "name": "Kotwapatti Rampur",
        "value": "Kotwapatti Rampur"
    },
    {
        "name": "Kovilpatti",
        "value": "Kovilpatti"
    },
    {
        "name": "Kovilur",
        "value": "Kovilur"
    },
    {
        "name": "Kovur",
        "value": "Kovur"
    },
    {
        "name": "Kovurupalli",
        "value": "Kovurupalli"
    },
    {
        "name": "Kovvali",
        "value": "Kovvali"
    },
    {
        "name": "Kovvur",
        "value": "Kovvur"
    },
    {
        "name": "Kowdalli",
        "value": "Kowdalli"
    },
    {
        "name": "Kozhinjampara",
        "value": "Kozhinjampara"
    },
    {
        "name": "Krishnagiri",
        "value": "Krishnagiri"
    },
    {
        "name": "Krishnamsettipalle",
        "value": "Krishnamsettipalle"
    },
    {
        "name": "Krishnanagar",
        "value": "Krishnanagar"
    },
    {
        "name": "Krishnapur",
        "value": "Krishnapur"
    },
    {
        "name": "Krishnapuram",
        "value": "Krishnapuram"
    },
    {
        "name": "Krishnarajasagara",
        "value": "Krishnarajasagara"
    },
    {
        "name": "Krishnarajpet",
        "value": "Krishnarajpet"
    },
    {
        "name": "Krishnarajpur",
        "value": "Krishnarajpur"
    },
    {
        "name": "Krishnarayapuram",
        "value": "Krishnarayapuram"
    },
    {
        "name": "Krosuru",
        "value": "Krosuru"
    },
    {
        "name": "Kubadupuram",
        "value": "Kubadupuram"
    },
    {
        "name": "Kuchai Kot",
        "value": "Kuchai Kot"
    },
    {
        "name": "Kuchaiburi",
        "value": "Kuchaiburi"
    },
    {
        "name": "Kuchinda",
        "value": "Kuchinda"
    },
    {
        "name": "Kudachi",
        "value": "Kudachi"
    },
    {
        "name": "Kudal",
        "value": "Kudal"
    },
    {
        "name": "Kudali",
        "value": "Kudali"
    },
    {
        "name": "Kudangulam",
        "value": "Kudangulam"
    },
    {
        "name": "Kudatini",
        "value": "Kudatini"
    },
    {
        "name": "Kudavasal",
        "value": "Kudavasal"
    },
    {
        "name": "Kudayattur",
        "value": "Kudayattur"
    },
    {
        "name": "Kudligi",
        "value": "Kudligi"
    },
    {
        "name": "Kudlu",
        "value": "Kudlu"
    },
    {
        "name": "Kudra",
        "value": "Kudra"
    },
    {
        "name": "Kuduru",
        "value": "Kuduru"
    },
    {
        "name": "Kujri",
        "value": "Kujri"
    },
    {
        "name": "Kuju",
        "value": "Kuju"
    },
    {
        "name": "Kukarmunda",
        "value": "Kukarmunda"
    },
    {
        "name": "Kukatpalli",
        "value": "Kukatpalli"
    },
    {
        "name": "Kukdeshwar",
        "value": "Kukdeshwar"
    },
    {
        "name": "Kukkundur",
        "value": "Kukkundur"
    },
    {
        "name": "Kuknur",
        "value": "Kuknur"
    },
    {
        "name": "Kukraun",
        "value": "Kukraun"
    },
    {
        "name": "Kukshi",
        "value": "Kukshi"
    },
    {
        "name": "Kulasegaram",
        "value": "Kulasegaram"
    },
    {
        "name": "Kulasekharapuram",
        "value": "Kulasekharapuram"
    },
    {
        "name": "Kulattuppalaiyam",
        "value": "Kulattuppalaiyam"
    },
    {
        "name": "Kulattur",
        "value": "Kulattur"
    },
    {
        "name": "Kulgam",
        "value": "Kulgam"
    },
    {
        "name": "Kulgo",
        "value": "Kulgo"
    },
    {
        "name": "Kulharia",
        "value": "Kulharia"
    },
    {
        "name": "Kuli",
        "value": "Kuli"
    },
    {
        "name": "Kulittalai",
        "value": "Kulittalai"
    },
    {
        "name": "Kulpahar",
        "value": "Kulpahar"
    },
    {
        "name": "Kulrian",
        "value": "Kulrian"
    },
    {
        "name": "Kulu",
        "value": "Kulu"
    },
    {
        "name": "Kumar Khad",
        "value": "Kumar Khad"
    },
    {
        "name": "Kumaralingam",
        "value": "Kumaralingam"
    },
    {
        "name": "Kumaramangalam",
        "value": "Kumaramangalam"
    },
    {
        "name": "Kumaramputtur",
        "value": "Kumaramputtur"
    },
    {
        "name": "Kumaranallur",
        "value": "Kumaranallur"
    },
    {
        "name": "Kumarankari",
        "value": "Kumarankari"
    },
    {
        "name": "Kumarapalaiyam",
        "value": "Kumarapalaiyam"
    },
    {
        "name": "Kumarapuram",
        "value": "Kumarapuram"
    },
    {
        "name": "Kumaravadi",
        "value": "Kumaravadi"
    },
    {
        "name": "Kumari",
        "value": "Kumari"
    },
    {
        "name": "Kumaripur",
        "value": "Kumaripur"
    },
    {
        "name": "Kumarkhali",
        "value": "Kumarkhali"
    },
    {
        "name": "Kumbadaje",
        "value": "Kumbadaje"
    },
    {
        "name": "Kumbakonam",
        "value": "Kumbakonam"
    },
    {
        "name": "Kumbhari",
        "value": "Kumbhari"
    },
    {
        "name": "Kumbhraj",
        "value": "Kumbhraj"
    },
    {
        "name": "Kumharsan",
        "value": "Kumharsan"
    },
    {
        "name": "Kumil",
        "value": "Kumil"
    },
    {
        "name": "Kumirimora",
        "value": "Kumirimora"
    },
    {
        "name": "Kummarapurugupalem",
        "value": "Kummarapurugupalem"
    },
    {
        "name": "Kumta",
        "value": "Kumta"
    },
    {
        "name": "Kun Puhal",
        "value": "Kun Puhal"
    },
    {
        "name": "Kundal",
        "value": "Kundal"
    },
    {
        "name": "Kundgol",
        "value": "Kundgol"
    },
    {
        "name": "Kundli",
        "value": "Kundli"
    },
    {
        "name": "Kundurpi",
        "value": "Kundurpi"
    },
    {
        "name": "Kunigal",
        "value": "Kunigal"
    },
    {
        "name": "Kunimedu",
        "value": "Kunimedu"
    },
    {
        "name": "Kunithala",
        "value": "Kunithala"
    },
    {
        "name": "Kuniyamuttur",
        "value": "Kuniyamuttur"
    },
    {
        "name": "Kunjatturu",
        "value": "Kunjatturu"
    },
    {
        "name": "Kunkalagunta",
        "value": "Kunkalagunta"
    },
    {
        "name": "Kunnamkulam",
        "value": "Kunnamkulam"
    },
    {
        "name": "Kunnatnad",
        "value": "Kunnatnad"
    },
    {
        "name": "Kunnattur",
        "value": "Kunnattur"
    },
    {
        "name": "Kunnumel",
        "value": "Kunnumel"
    },
    {
        "name": "Kunnur",
        "value": "Kunnur"
    },
    {
        "name": "Kuppachchipalaiyam",
        "value": "Kuppachchipalaiyam"
    },
    {
        "name": "Kuppadi",
        "value": "Kuppadi"
    },
    {
        "name": "Kuppam",
        "value": "Kuppam"
    },
    {
        "name": "Kurabalakota",
        "value": "Kurabalakota"
    },
    {
        "name": "Kurabur",
        "value": "Kurabur"
    },
    {
        "name": "Kurawar",
        "value": "Kurawar"
    },
    {
        "name": "Kurdi",
        "value": "Kurdi"
    },
    {
        "name": "Kurgunta",
        "value": "Kurgunta"
    },
    {
        "name": "Kurhani",
        "value": "Kurhani"
    },
    {
        "name": "Kurichchi",
        "value": "Kurichchi"
    },
    {
        "name": "Kurichedu",
        "value": "Kurichedu"
    },
    {
        "name": "Kurikuppi",
        "value": "Kurikuppi"
    },
    {
        "name": "Kurivikod",
        "value": "Kurivikod"
    },
    {
        "name": "Kurnool",
        "value": "Kurnool"
    },
    {
        "name": "Kursaha",
        "value": "Kursaha"
    },
    {
        "name": "Kurtkoti",
        "value": "Kurtkoti"
    },
    {
        "name": "Kurugodu",
        "value": "Kurugodu"
    },
    {
        "name": "Kurumbalur",
        "value": "Kurumbalur"
    },
    {
        "name": "Kurumbapalaiyam",
        "value": "Kurumbapalaiyam"
    },
    {
        "name": "Kuruvambalam",
        "value": "Kuruvambalam"
    },
    {
        "name": "Kurwa Mathia",
        "value": "Kurwa Mathia"
    },
    {
        "name": "Kurwar",
        "value": "Kurwar"
    },
    {
        "name": "Kusaha",
        "value": "Kusaha"
    },
    {
        "name": "Kushmanchi",
        "value": "Kushmanchi"
    },
    {
        "name": "Kushtagi",
        "value": "Kushtagi"
    },
    {
        "name": "Kusmaul",
        "value": "Kusmaul"
    },
    {
        "name": "Kusugal",
        "value": "Kusugal"
    },
    {
        "name": "Kusumbe",
        "value": "Kusumbe"
    },
    {
        "name": "Kusumha",
        "value": "Kusumha"
    },
    {
        "name": "Kutavettur",
        "value": "Kutavettur"
    },
    {
        "name": "Kutavur",
        "value": "Kutavur"
    },
    {
        "name": "Kutiyana",
        "value": "Kutiyana"
    },
    {
        "name": "Kuttalam",
        "value": "Kuttalam"
    },
    {
        "name": "Kuttampala",
        "value": "Kuttampala"
    },
    {
        "name": "Kuttampuzha",
        "value": "Kuttampuzha"
    },
    {
        "name": "Kuttanallur",
        "value": "Kuttanallur"
    },
    {
        "name": "Kuttappatti",
        "value": "Kuttappatti"
    },
    {
        "name": "Kuttattuppatti",
        "value": "Kuttattuppatti"
    },
    {
        "name": "Kuttiyeri",
        "value": "Kuttiyeri"
    },
    {
        "name": "Kuttuparamba",
        "value": "Kuttuparamba"
    },
    {
        "name": "Kuttur",
        "value": "Kuttur"
    },
    {
        "name": "Kuttyadi",
        "value": "Kuttyadi"
    },
    {
        "name": "Kuttyattur",
        "value": "Kuttyattur"
    },
    {
        "name": "Kuzhippilli",
        "value": "Kuzhippilli"
    },
    {
        "name": "Kuzhittura",
        "value": "Kuzhittura"
    },
    {
        "name": "Labbaikkudikkadu",
        "value": "Labbaikkudikkadu"
    },
    {
        "name": "Labhgaon",
        "value": "Labhgaon"
    },
    {
        "name": "Lachhmangarh Sikar",
        "value": "Lachhmangarh Sikar"
    },
    {
        "name": "Lachhmipur",
        "value": "Lachhmipur"
    },
    {
        "name": "Ladhuka",
        "value": "Ladhuka"
    },
    {
        "name": "Ladkhed",
        "value": "Ladkhed"
    },
    {
        "name": "Ladol",
        "value": "Ladol"
    },
    {
        "name": "Ladwa",
        "value": "Ladwa"
    },
    {
        "name": "Lagunia Raghukanth",
        "value": "Lagunia Raghukanth"
    },
    {
        "name": "Lagunia Surajkanth",
        "value": "Lagunia Surajkanth"
    },
    {
        "name": "Lahar",
        "value": "Lahar"
    },
    {
        "name": "Laharpur",
        "value": "Laharpur"
    },
    {
        "name": "Laheji",
        "value": "Laheji"
    },
    {
        "name": "Lahra Muhabbat",
        "value": "Lahra Muhabbat"
    },
    {
        "name": "Lakhanapuram",
        "value": "Lakhanapuram"
    },
    {
        "name": "Lakhaura",
        "value": "Lakhaura"
    },
    {
        "name": "Lakheri",
        "value": "Lakheri"
    },
    {
        "name": "Lakhipur",
        "value": "Lakhipur"
    },
    {
        "name": "Lakhminia",
        "value": "Lakhminia"
    },
    {
        "name": "Lakhna",
        "value": "Lakhna"
    },
    {
        "name": "Lakhnadon",
        "value": "Lakhnadon"
    },
    {
        "name": "Lakhnaur",
        "value": "Lakhnaur"
    },
    {
        "name": "Lakho",
        "value": "Lakho"
    },
    {
        "name": "Lakhsetipet",
        "value": "Lakhsetipet"
    },
    {
        "name": "Lakkampatti",
        "value": "Lakkampatti"
    },
    {
        "name": "Lakkavaram",
        "value": "Lakkavaram"
    },
    {
        "name": "Lakkireddipalle",
        "value": "Lakkireddipalle"
    },
    {
        "name": "Lakkundi",
        "value": "Lakkundi"
    },
    {
        "name": "Lakri",
        "value": "Lakri"
    },
    {
        "name": "Laksar",
        "value": "Laksar"
    },
    {
        "name": "Lakshmaneswaram",
        "value": "Lakshmaneswaram"
    },
    {
        "name": "Lakshmeshwar",
        "value": "Lakshmeshwar"
    },
    {
        "name": "Lakshminarayanapuram",
        "value": "Lakshminarayanapuram"
    },
    {
        "name": "Lakshmipuram",
        "value": "Lakshmipuram"
    },
    {
        "name": "Lal Khatanga",
        "value": "Lal Khatanga"
    },
    {
        "name": "Lala",
        "value": "Lala"
    },
    {
        "name": "Lalam",
        "value": "Lalam"
    },
    {
        "name": "Lalganj",
        "value": "Lalganj"
    },
    {
        "name": "Lalgola",
        "value": "Lalgola"
    },
    {
        "name": "Lalgudi",
        "value": "Lalgudi"
    },
    {
        "name": "Laligam",
        "value": "Laligam"
    },
    {
        "name": "Lalitpur",
        "value": "Lalitpur"
    },
    {
        "name": "Lalmunia Munhara",
        "value": "Lalmunia Munhara"
    },
    {
        "name": "Lalru",
        "value": "Lalru"
    },
    {
        "name": "Lalsaraia",
        "value": "Lalsaraia"
    },
    {
        "name": "Landhaura",
        "value": "Landhaura"
    },
    {
        "name": "Landupdih",
        "value": "Landupdih"
    },
    {
        "name": "Langhnaj",
        "value": "Langhnaj"
    },
    {
        "name": "Lanji",
        "value": "Lanji"
    },
    {
        "name": "Lapanga",
        "value": "Lapanga"
    },
    {
        "name": "Latauna",
        "value": "Latauna"
    },
    {
        "name": "Latehar",
        "value": "Latehar"
    },
    {
        "name": "Lathasepura",
        "value": "Lathasepura"
    },
    {
        "name": "Lathi",
        "value": "Lathi"
    },
    {
        "name": "Latteri",
        "value": "Latteri"
    },
    {
        "name": "Laualagaon",
        "value": "Laualagaon"
    },
    {
        "name": "Laukaha",
        "value": "Laukaha"
    },
    {
        "name": "Laukaria",
        "value": "Laukaria"
    },
    {
        "name": "Laungowal",
        "value": "Laungowal"
    },
    {
        "name": "Lauri",
        "value": "Lauri"
    },
    {
        "name": "Lauriya Nandangarh",
        "value": "Lauriya Nandangarh"
    },
    {
        "name": "Leama",
        "value": "Leama"
    },
    {
        "name": "Leh",
        "value": "Leh"
    },
    {
        "name": "Lehara",
        "value": "Lehara"
    },
    {
        "name": "Lepakshi",
        "value": "Lepakshi"
    },
    {
        "name": "Leteri",
        "value": "Leteri"
    },
    {
        "name": "Limbdi",
        "value": "Limbdi"
    },
    {
        "name": "Lingal",
        "value": "Lingal"
    },
    {
        "name": "Lingampalli",
        "value": "Lingampalli"
    },
    {
        "name": "Lingamparti",
        "value": "Lingamparti"
    },
    {
        "name": "Lingampet",
        "value": "Lingampet"
    },
    {
        "name": "Linganaboyinacherla",
        "value": "Linganaboyinacherla"
    },
    {
        "name": "Lingsugur",
        "value": "Lingsugur"
    },
    {
        "name": "Livinjipuram",
        "value": "Livinjipuram"
    },
    {
        "name": "Lodhikheda",
        "value": "Lodhikheda"
    },
    {
        "name": "Lodhwe",
        "value": "Lodhwe"
    },
    {
        "name": "Loha",
        "value": "Loha"
    },
    {
        "name": "Lohagaon",
        "value": "Lohagaon"
    },
    {
        "name": "Lohara",
        "value": "Lohara"
    },
    {
        "name": "Loharda",
        "value": "Loharda"
    },
    {
        "name": "Lohardaga",
        "value": "Lohardaga"
    },
    {
        "name": "Lohiyar Ujain",
        "value": "Lohiyar Ujain"
    },
    {
        "name": "Lohna",
        "value": "Lohna"
    },
    {
        "name": "Loiyo",
        "value": "Loiyo"
    },
    {
        "name": "Lokapur",
        "value": "Lokapur"
    },
    {
        "name": "Lolokhur",
        "value": "Lolokhur"
    },
    {
        "name": "Lonar",
        "value": "Lonar"
    },
    {
        "name": "Lonavale",
        "value": "Lonavale"
    },
    {
        "name": "Loni",
        "value": "Loni"
    },
    {
        "name": "Lopon",
        "value": "Lopon"
    },
    {
        "name": "Loran",
        "value": "Loran"
    },
    {
        "name": "Losal",
        "value": "Losal"
    },
    {
        "name": "Luathaha",
        "value": "Luathaha"
    },
    {
        "name": "Luckeesarai",
        "value": "Luckeesarai"
    },
    {
        "name": "Lucknow",
        "value": "Lucknow"
    },
    {
        "name": "Ludhiana",
        "value": "Ludhiana"
    },
    {
        "name": "Lulhaul",
        "value": "Lulhaul"
    },
    {
        "name": "Lumding",
        "value": "Lumding"
    },
    {
        "name": "Lunavada",
        "value": "Lunavada"
    },
    {
        "name": "Lunglei",
        "value": "Lunglei"
    },
    {
        "name": "Machalpur",
        "value": "Machalpur"
    },
    {
        "name": "Machchand",
        "value": "Machchand"
    },
    {
        "name": "Macherla",
        "value": "Macherla"
    },
    {
        "name": "Machilipatnam",
        "value": "Machilipatnam"
    },
    {
        "name": "Madakalavaripalli",
        "value": "Madakalavaripalli"
    },
    {
        "name": "Madakasira",
        "value": "Madakasira"
    },
    {
        "name": "Madakkathara",
        "value": "Madakkathara"
    },
    {
        "name": "Madanancheri",
        "value": "Madanancheri"
    },
    {
        "name": "Madanapalle",
        "value": "Madanapalle"
    },
    {
        "name": "Madangir",
        "value": "Madangir"
    },
    {
        "name": "Madanpur",
        "value": "Madanpur"
    },
    {
        "name": "Madappalli",
        "value": "Madappalli"
    },
    {
        "name": "Madari Hat",
        "value": "Madari Hat"
    },
    {
        "name": "Madathapatti",
        "value": "Madathapatti"
    },
    {
        "name": "Madattukkulam",
        "value": "Madattukkulam"
    },
    {
        "name": "Madavur",
        "value": "Madavur"
    },
    {
        "name": "Maddagiri",
        "value": "Maddagiri"
    },
    {
        "name": "Maddikera",
        "value": "Maddikera"
    },
    {
        "name": "Maddur",
        "value": "Maddur"
    },
    {
        "name": "Madepalli",
        "value": "Madepalli"
    },
    {
        "name": "Madgaon",
        "value": "Madgaon"
    },
    {
        "name": "Madha",
        "value": "Madha"
    },
    {
        "name": "Madhavaram",
        "value": "Madhavaram"
    },
    {
        "name": "Madhipura",
        "value": "Madhipura"
    },
    {
        "name": "Madhopur",
        "value": "Madhopur"
    },
    {
        "name": "Madhopur Hazari",
        "value": "Madhopur Hazari"
    },
    {
        "name": "Madhuban",
        "value": "Madhuban"
    },
    {
        "name": "Madhuban Bediban",
        "value": "Madhuban Bediban"
    },
    {
        "name": "Madhubani",
        "value": "Madhubani"
    },
    {
        "name": "Madhupur",
        "value": "Madhupur"
    },
    {
        "name": "Madhura",
        "value": "Madhura"
    },
    {
        "name": "Madhurapur",
        "value": "Madhurapur"
    },
    {
        "name": "Madhyamgram",
        "value": "Madhyamgram"
    },
    {
        "name": "Madikeri",
        "value": "Madikeri"
    },
    {
        "name": "Madipakkam",
        "value": "Madipakkam"
    },
    {
        "name": "Madira",
        "value": "Madira"
    },
    {
        "name": "Madna",
        "value": "Madna"
    },
    {
        "name": "Madnur",
        "value": "Madnur"
    },
    {
        "name": "Madugula",
        "value": "Madugula"
    },
    {
        "name": "Madukkarai",
        "value": "Madukkarai"
    },
    {
        "name": "Madukkur",
        "value": "Madukkur"
    },
    {
        "name": "Madurai",
        "value": "Madurai"
    },
    {
        "name": "Maduraivayal",
        "value": "Maduraivayal"
    },
    {
        "name": "Maduru",
        "value": "Maduru"
    },
    {
        "name": "Magadi",
        "value": "Magadi"
    },
    {
        "name": "Magam",
        "value": "Magam"
    },
    {
        "name": "Maghar",
        "value": "Maghar"
    },
    {
        "name": "Maghra",
        "value": "Maghra"
    },
    {
        "name": "Magilampupuram",
        "value": "Magilampupuram"
    },
    {
        "name": "Mahabaleshwar",
        "value": "Mahabaleshwar"
    },
    {
        "name": "Mahad",
        "value": "Mahad"
    },
    {
        "name": "Mahadebnagar",
        "value": "Mahadebnagar"
    },
    {
        "name": "Mahadeopur",
        "value": "Mahadeopur"
    },
    {
        "name": "Mahadipur",
        "value": "Mahadipur"
    },
    {
        "name": "Mahagama",
        "value": "Mahagama"
    },
    {
        "name": "Mahagaon",
        "value": "Mahagaon"
    },
    {
        "name": "Mahalandi",
        "value": "Mahalandi"
    },
    {
        "name": "Mahalgaon",
        "value": "Mahalgaon"
    },
    {
        "name": "Mahalingpur",
        "value": "Mahalingpur"
    },
    {
        "name": "Mahalpur",
        "value": "Mahalpur"
    },
    {
        "name": "Mahamda",
        "value": "Mahamda"
    },
    {
        "name": "Maharajapuram",
        "value": "Maharajapuram"
    },
    {
        "name": "Maharajgani",
        "value": "Maharajgani"
    },
    {
        "name": "Maharajpur",
        "value": "Maharajpur"
    },
    {
        "name": "Mahasamund",
        "value": "Mahasamund"
    },
    {
        "name": "Mahbubabad",
        "value": "Mahbubabad"
    },
    {
        "name": "Mahbubnagar",
        "value": "Mahbubnagar"
    },
    {
        "name": "Mahe",
        "value": "Mahe"
    },
    {
        "name": "Mahem",
        "value": "Mahem"
    },
    {
        "name": "Mahemdavad",
        "value": "Mahemdavad"
    },
    {
        "name": "Mahendragarh",
        "value": "Mahendragarh"
    },
    {
        "name": "Mahesana",
        "value": "Mahesana"
    },
    {
        "name": "Mahesh Khunt",
        "value": "Mahesh Khunt"
    },
    {
        "name": "Maheshram",
        "value": "Maheshram"
    },
    {
        "name": "Maheshwar",
        "value": "Maheshwar"
    },
    {
        "name": "Mahespur",
        "value": "Mahespur"
    },
    {
        "name": "Maheswa",
        "value": "Maheswa"
    },
    {
        "name": "Mahiari",
        "value": "Mahiari"
    },
    {
        "name": "Mahinathpur",
        "value": "Mahinathpur"
    },
    {
        "name": "Mahinawan",
        "value": "Mahinawan"
    },
    {
        "name": "Mahisanrh",
        "value": "Mahisanrh"
    },
    {
        "name": "Mahisi",
        "value": "Mahisi"
    },
    {
        "name": "Mahmuda",
        "value": "Mahmuda"
    },
    {
        "name": "Mahmudabad",
        "value": "Mahmudabad"
    },
    {
        "name": "Mahna",
        "value": "Mahna"
    },
    {
        "name": "Mahrail",
        "value": "Mahrail"
    },
    {
        "name": "Mahraurh",
        "value": "Mahraurh"
    },
    {
        "name": "Mahta",
        "value": "Mahta"
    },
    {
        "name": "Mahtha",
        "value": "Mahtha"
    },
    {
        "name": "Mahthi",
        "value": "Mahthi"
    },
    {
        "name": "Mahuakheraganj",
        "value": "Mahuakheraganj"
    },
    {
        "name": "Mahudha",
        "value": "Mahudha"
    },
    {
        "name": "Mahugaon",
        "value": "Mahugaon"
    },
    {
        "name": "Mahuva",
        "value": "Mahuva"
    },
    {
        "name": "Mahuver",
        "value": "Mahuver"
    },
    {
        "name": "Mahuwa Singhrai",
        "value": "Mahuwa Singhrai"
    },
    {
        "name": "Maida Babhangawan",
        "value": "Maida Babhangawan"
    },
    {
        "name": "Maihar",
        "value": "Maihar"
    },
    {
        "name": "Maihma Sarja",
        "value": "Maihma Sarja"
    },
    {
        "name": "Mailapur",
        "value": "Mailapur"
    },
    {
        "name": "Mailavaram",
        "value": "Mailavaram"
    },
    {
        "name": "Maina",
        "value": "Maina"
    },
    {
        "name": "Mainaguri",
        "value": "Mainaguri"
    },
    {
        "name": "Mainpuri",
        "value": "Mainpuri"
    },
    {
        "name": "Mairwa",
        "value": "Mairwa"
    },
    {
        "name": "Maisaram",
        "value": "Maisaram"
    },
    {
        "name": "Majalgaon",
        "value": "Majalgaon"
    },
    {
        "name": "Majali",
        "value": "Majali"
    },
    {
        "name": "Majarhat",
        "value": "Majarhat"
    },
    {
        "name": "Majhariya",
        "value": "Majhariya"
    },
    {
        "name": "Majhariya Sheikh",
        "value": "Majhariya Sheikh"
    },
    {
        "name": "Majhaul",
        "value": "Majhaul"
    },
    {
        "name": "Majhaulia",
        "value": "Majhaulia"
    },
    {
        "name": "Majhgawan",
        "value": "Majhgawan"
    },
    {
        "name": "Majholi",
        "value": "Majholi"
    },
    {
        "name": "Majhua",
        "value": "Majhua"
    },
    {
        "name": "Majitha",
        "value": "Majitha"
    },
    {
        "name": "Majra",
        "value": "Majra"
    },
    {
        "name": "Majurwa",
        "value": "Majurwa"
    },
    {
        "name": "Makaya",
        "value": "Makaya"
    },
    {
        "name": "Makhar",
        "value": "Makhar"
    },
    {
        "name": "Makhdumpur",
        "value": "Makhdumpur"
    },
    {
        "name": "Makhmalpur",
        "value": "Makhmalpur"
    },
    {
        "name": "Makhtal",
        "value": "Makhtal"
    },
    {
        "name": "Makhu",
        "value": "Makhu"
    },
    {
        "name": "Makrana",
        "value": "Makrana"
    },
    {
        "name": "Makri",
        "value": "Makri"
    },
    {
        "name": "Makronia",
        "value": "Makronia"
    },
    {
        "name": "Maksi",
        "value": "Maksi"
    },
    {
        "name": "Malaikkal",
        "value": "Malaikkal"
    },
    {
        "name": "Malaimachchampatti",
        "value": "Malaimachchampatti"
    },
    {
        "name": "Malaimarpuram",
        "value": "Malaimarpuram"
    },
    {
        "name": "Malakanagiri",
        "value": "Malakanagiri"
    },
    {
        "name": "Malancha",
        "value": "Malancha"
    },
    {
        "name": "Malangam",
        "value": "Malangam"
    },
    {
        "name": "Malanvadi",
        "value": "Malanvadi"
    },
    {
        "name": "Malapannanagudi",
        "value": "Malapannanagudi"
    },
    {
        "name": "Malappuram",
        "value": "Malappuram"
    },
    {
        "name": "Malar",
        "value": "Malar"
    },
    {
        "name": "Malaudh",
        "value": "Malaudh"
    },
    {
        "name": "Malaut",
        "value": "Malaut"
    },
    {
        "name": "Malavalli",
        "value": "Malavalli"
    },
    {
        "name": "Maldah",
        "value": "Maldah"
    },
    {
        "name": "Malebennur",
        "value": "Malebennur"
    },
    {
        "name": "Malegaon",
        "value": "Malegaon"
    },
    {
        "name": "Malepur",
        "value": "Malepur"
    },
    {
        "name": "Maler Kotla",
        "value": "Maler Kotla"
    },
    {
        "name": "Malhargarh",
        "value": "Malhargarh"
    },
    {
        "name": "Malhipur",
        "value": "Malhipur"
    },
    {
        "name": "Mali",
        "value": "Mali"
    },
    {
        "name": "Malial",
        "value": "Malial"
    },
    {
        "name": "Malinagar",
        "value": "Malinagar"
    },
    {
        "name": "Malingaon",
        "value": "Malingaon"
    },
    {
        "name": "Malior",
        "value": "Malior"
    },
    {
        "name": "Malipakar",
        "value": "Malipakar"
    },
    {
        "name": "Malkajgiri",
        "value": "Malkajgiri"
    },
    {
        "name": "Malkanur",
        "value": "Malkanur"
    },
    {
        "name": "Malkapur",
        "value": "Malkapur"
    },
    {
        "name": "Malkhaid",
        "value": "Malkhaid"
    },
    {
        "name": "Mallagunta",
        "value": "Mallagunta"
    },
    {
        "name": "Mallampalli",
        "value": "Mallampalli"
    },
    {
        "name": "Mallan",
        "value": "Mallan"
    },
    {
        "name": "Mallapalli",
        "value": "Mallapalli"
    },
    {
        "name": "Mallappadi",
        "value": "Mallappadi"
    },
    {
        "name": "Mallappulasseri",
        "value": "Mallappulasseri"
    },
    {
        "name": "Mallapur",
        "value": "Mallapur"
    },
    {
        "name": "Mallapuram",
        "value": "Mallapuram"
    },
    {
        "name": "Mallaram",
        "value": "Mallaram"
    },
    {
        "name": "Mallasamudram",
        "value": "Mallasamudram"
    },
    {
        "name": "Mallikkundam",
        "value": "Mallikkundam"
    },
    {
        "name": "Malmal",
        "value": "Malmal"
    },
    {
        "name": "Malpura",
        "value": "Malpura"
    },
    {
        "name": "Malthone",
        "value": "Malthone"
    },
    {
        "name": "Mamarappatti",
        "value": "Mamarappatti"
    },
    {
        "name": "Mamidalapadu",
        "value": "Mamidalapadu"
    },
    {
        "name": "Mamidipalli",
        "value": "Mamidipalli"
    },
    {
        "name": "Mamnur",
        "value": "Mamnur"
    },
    {
        "name": "Mamobihat",
        "value": "Mamobihat"
    },
    {
        "name": "Mamun",
        "value": "Mamun"
    },
    {
        "name": "Manakayi",
        "value": "Manakayi"
    },
    {
        "name": "Manakondur",
        "value": "Manakondur"
    },
    {
        "name": "Manali",
        "value": "Manali"
    },
    {
        "name": "Manalurpettai",
        "value": "Manalurpettai"
    },
    {
        "name": "Manamadurai",
        "value": "Manamadurai"
    },
    {
        "name": "Manambur",
        "value": "Manambur"
    },
    {
        "name": "Manamelkudi",
        "value": "Manamelkudi"
    },
    {
        "name": "Manamodu",
        "value": "Manamodu"
    },
    {
        "name": "Manampizha",
        "value": "Manampizha"
    },
    {
        "name": "Manantheri",
        "value": "Manantheri"
    },
    {
        "name": "Manappakkam",
        "value": "Manappakkam"
    },
    {
        "name": "Manapparai",
        "value": "Manapparai"
    },
    {
        "name": "Manasa",
        "value": "Manasa"
    },
    {
        "name": "Manatanna",
        "value": "Manatanna"
    },
    {
        "name": "Manavadar",
        "value": "Manavadar"
    },
    {
        "name": "Manavalakurichi",
        "value": "Manavalakurichi"
    },
    {
        "name": "Manawar",
        "value": "Manawar"
    },
    {
        "name": "Manbazar",
        "value": "Manbazar"
    },
    {
        "name": "Manchenahalli",
        "value": "Manchenahalli"
    },
    {
        "name": "Mancheral",
        "value": "Mancheral"
    },
    {
        "name": "Mandalapalle",
        "value": "Mandalapalle"
    },
    {
        "name": "Mandalavadi",
        "value": "Mandalavadi"
    },
    {
        "name": "Mandalgarh",
        "value": "Mandalgarh"
    },
    {
        "name": "Mandamari",
        "value": "Mandamari"
    },
    {
        "name": "Mandapam",
        "value": "Mandapam"
    },
    {
        "name": "Mandapeta",
        "value": "Mandapeta"
    },
    {
        "name": "Mandasa",
        "value": "Mandasa"
    },
    {
        "name": "Mandawa",
        "value": "Mandawa"
    },
    {
        "name": "Mandi",
        "value": "Mandi"
    },
    {
        "name": "Mandi Bamora",
        "value": "Mandi Bamora"
    },
    {
        "name": "Mandi Dabwali",
        "value": "Mandi Dabwali"
    },
    {
        "name": "Mandla",
        "value": "Mandla"
    },
    {
        "name": "Mandleshwar",
        "value": "Mandleshwar"
    },
    {
        "name": "Mandramo",
        "value": "Mandramo"
    },
    {
        "name": "Mandsaur",
        "value": "Mandsaur"
    },
    {
        "name": "Mandu",
        "value": "Mandu"
    },
    {
        "name": "Mandvi",
        "value": "Mandvi"
    },
    {
        "name": "Mandya",
        "value": "Mandya"
    },
    {
        "name": "Manegaon",
        "value": "Manegaon"
    },
    {
        "name": "Maner",
        "value": "Maner"
    },
    {
        "name": "Mangala",
        "value": "Mangala"
    },
    {
        "name": "Mangalagiri",
        "value": "Mangalagiri"
    },
    {
        "name": "Mangalam",
        "value": "Mangalam"
    },
    {
        "name": "Mangalapadi",
        "value": "Mangalapadi"
    },
    {
        "name": "Mangalapur",
        "value": "Mangalapur"
    },
    {
        "name": "Mangalapuram",
        "value": "Mangalapuram"
    },
    {
        "name": "Mangaldai",
        "value": "Mangaldai"
    },
    {
        "name": "Mangalkot",
        "value": "Mangalkot"
    },
    {
        "name": "Mangalore",
        "value": "Mangalore"
    },
    {
        "name": "Mangalpur Gudaria",
        "value": "Mangalpur Gudaria"
    },
    {
        "name": "Mangalvedha",
        "value": "Mangalvedha"
    },
    {
        "name": "Manganam",
        "value": "Manganam"
    },
    {
        "name": "Manganj",
        "value": "Manganj"
    },
    {
        "name": "Mangapet",
        "value": "Mangapet"
    },
    {
        "name": "Mangarwara",
        "value": "Mangarwara"
    },
    {
        "name": "Mangasamudram",
        "value": "Mangasamudram"
    },
    {
        "name": "Mangawan",
        "value": "Mangawan"
    },
    {
        "name": "Manglaur",
        "value": "Manglaur"
    },
    {
        "name": "Manglur",
        "value": "Manglur"
    },
    {
        "name": "Mangobandar",
        "value": "Mangobandar"
    },
    {
        "name": "Mangoli",
        "value": "Mangoli"
    },
    {
        "name": "Mangrauni",
        "value": "Mangrauni"
    },
    {
        "name": "Mangrawan",
        "value": "Mangrawan"
    },
    {
        "name": "Mangrol",
        "value": "Mangrol"
    },
    {
        "name": "Mangur",
        "value": "Mangur"
    },
    {
        "name": "Maniamkulam",
        "value": "Maniamkulam"
    },
    {
        "name": "Manihari",
        "value": "Manihari"
    },
    {
        "name": "Manikkal",
        "value": "Manikkal"
    },
    {
        "name": "Manikpur",
        "value": "Manikpur"
    },
    {
        "name": "Manimala",
        "value": "Manimala"
    },
    {
        "name": "Manivilundan",
        "value": "Manivilundan"
    },
    {
        "name": "Maniyamturuttu",
        "value": "Maniyamturuttu"
    },
    {
        "name": "Maniyur",
        "value": "Maniyur"
    },
    {
        "name": "Manjathala",
        "value": "Manjathala"
    },
    {
        "name": "Manjeri",
        "value": "Manjeri"
    },
    {
        "name": "Manjha",
        "value": "Manjha"
    },
    {
        "name": "Manjhi",
        "value": "Manjhi"
    },
    {
        "name": "Mankachar",
        "value": "Mankachar"
    },
    {
        "name": "Mankada",
        "value": "Mankada"
    },
    {
        "name": "Mankal",
        "value": "Mankal"
    },
    {
        "name": "Mankara",
        "value": "Mankara"
    },
    {
        "name": "Manki",
        "value": "Manki"
    },
    {
        "name": "Mankur",
        "value": "Mankur"
    },
    {
        "name": "Manmad",
        "value": "Manmad"
    },
    {
        "name": "Mannachchanellur",
        "value": "Mannachchanellur"
    },
    {
        "name": "Mannadipattu",
        "value": "Mannadipattu"
    },
    {
        "name": "Mannamangalam",
        "value": "Mannamangalam"
    },
    {
        "name": "Mannamturuttu",
        "value": "Mannamturuttu"
    },
    {
        "name": "Mannanchori",
        "value": "Mannanchori"
    },
    {
        "name": "Mannara",
        "value": "Mannara"
    },
    {
        "name": "Mannarai",
        "value": "Mannarai"
    },
    {
        "name": "Mannarakkat",
        "value": "Mannarakkat"
    },
    {
        "name": "Mannargudi",
        "value": "Mannargudi"
    },
    {
        "name": "Manne Ekeli",
        "value": "Manne Ekeli"
    },
    {
        "name": "Mannegudam",
        "value": "Mannegudam"
    },
    {
        "name": "Mannukara",
        "value": "Mannukara"
    },
    {
        "name": "Mannur",
        "value": "Mannur"
    },
    {
        "name": "Manoharpur",
        "value": "Manoharpur"
    },
    {
        "name": "Manoke",
        "value": "Manoke"
    },
    {
        "name": "Manoli",
        "value": "Manoli"
    },
    {
        "name": "Manpaur",
        "value": "Manpaur"
    },
    {
        "name": "Manpur",
        "value": "Manpur"
    },
    {
        "name": "Manrar",
        "value": "Manrar"
    },
    {
        "name": "Mansa",
        "value": "Mansa"
    },
    {
        "name": "Mansala",
        "value": "Mansala"
    },
    {
        "name": "Mansapur",
        "value": "Mansapur"
    },
    {
        "name": "Mansingha",
        "value": "Mansingha"
    },
    {
        "name": "Mansinghpur Bijrauli",
        "value": "Mansinghpur Bijrauli"
    },
    {
        "name": "Mansong",
        "value": "Mansong"
    },
    {
        "name": "Manteswar",
        "value": "Manteswar"
    },
    {
        "name": "Manthani",
        "value": "Manthani"
    },
    {
        "name": "Manubolu",
        "value": "Manubolu"
    },
    {
        "name": "Manullahpatti",
        "value": "Manullahpatti"
    },
    {
        "name": "Manushmuria",
        "value": "Manushmuria"
    },
    {
        "name": "Manvi",
        "value": "Manvi"
    },
    {
        "name": "Manwat",
        "value": "Manwat"
    },
    {
        "name": "Maqsuda",
        "value": "Maqsuda"
    },
    {
        "name": "Marahom",
        "value": "Marahom"
    },
    {
        "name": "Marahra",
        "value": "Marahra"
    },
    {
        "name": "Marai Kalan",
        "value": "Marai Kalan"
    },
    {
        "name": "Maraiyur",
        "value": "Maraiyur"
    },
    {
        "name": "Marakkara",
        "value": "Marakkara"
    },
    {
        "name": "Marancheri",
        "value": "Marancheri"
    },
    {
        "name": "Maranchi",
        "value": "Maranchi"
    },
    {
        "name": "Maranga",
        "value": "Maranga"
    },
    {
        "name": "Marar",
        "value": "Marar"
    },
    {
        "name": "Marathalli",
        "value": "Marathalli"
    },
    {
        "name": "Maratturai",
        "value": "Maratturai"
    },
    {
        "name": "Marauatpur",
        "value": "Marauatpur"
    },
    {
        "name": "Margherita",
        "value": "Margherita"
    },
    {
        "name": "Margram",
        "value": "Margram"
    },
    {
        "name": "Marhaura",
        "value": "Marhaura"
    },
    {
        "name": "Mariani",
        "value": "Mariani"
    },
    {
        "name": "Marikal",
        "value": "Marikal"
    },
    {
        "name": "Maripad",
        "value": "Maripad"
    },
    {
        "name": "Mariyadau",
        "value": "Mariyadau"
    },
    {
        "name": "Mariyammanahalli",
        "value": "Mariyammanahalli"
    },
    {
        "name": "Marjampad",
        "value": "Marjampad"
    },
    {
        "name": "Markacho",
        "value": "Markacho"
    },
    {
        "name": "Markapur",
        "value": "Markapur"
    },
    {
        "name": "Marmagao",
        "value": "Marmagao"
    },
    {
        "name": "Marne",
        "value": "Marne"
    },
    {
        "name": "Maroli",
        "value": "Maroli"
    },
    {
        "name": "Marpalli",
        "value": "Marpalli"
    },
    {
        "name": "Marreddipalli",
        "value": "Marreddipalli"
    },
    {
        "name": "Martahalli",
        "value": "Martahalli"
    },
    {
        "name": "Marturu",
        "value": "Marturu"
    },
    {
        "name": "Marudur",
        "value": "Marudur"
    },
    {
        "name": "Marui",
        "value": "Marui"
    },
    {
        "name": "Marungur",
        "value": "Marungur"
    },
    {
        "name": "Maruteru",
        "value": "Maruteru"
    },
    {
        "name": "Marutharod",
        "value": "Marutharod"
    },
    {
        "name": "Maruttuvakkudi",
        "value": "Maruttuvakkudi"
    },
    {
        "name": "Maruturu",
        "value": "Maruturu"
    },
    {
        "name": "Marwa",
        "value": "Marwa"
    },
    {
        "name": "Masabdisa",
        "value": "Masabdisa"
    },
    {
        "name": "Masakkavundanchettipalaiyam",
        "value": "Masakkavundanchettipalaiyam"
    },
    {
        "name": "Masar",
        "value": "Masar"
    },
    {
        "name": "Masaurhi Buzurg",
        "value": "Masaurhi Buzurg"
    },
    {
        "name": "Masdi",
        "value": "Masdi"
    },
    {
        "name": "Mashyal",
        "value": "Mashyal"
    },
    {
        "name": "Masinigudi",
        "value": "Masinigudi"
    },
    {
        "name": "Maski",
        "value": "Maski"
    },
    {
        "name": "Masrakh",
        "value": "Masrakh"
    },
    {
        "name": "Masur",
        "value": "Masur"
    },
    {
        "name": "Matabhanga",
        "value": "Matabhanga"
    },
    {
        "name": "Mataili Khemchand",
        "value": "Mataili Khemchand"
    },
    {
        "name": "Matala",
        "value": "Matala"
    },
    {
        "name": "Matar",
        "value": "Matar"
    },
    {
        "name": "Matauna",
        "value": "Matauna"
    },
    {
        "name": "Math Lohiyar",
        "value": "Math Lohiyar"
    },
    {
        "name": "Mathigeri",
        "value": "Mathigeri"
    },
    {
        "name": "Mathila",
        "value": "Mathila"
    },
    {
        "name": "Mathukumnel",
        "value": "Mathukumnel"
    },
    {
        "name": "Mathura",
        "value": "Mathura"
    },
    {
        "name": "Mathurapur",
        "value": "Mathurapur"
    },
    {
        "name": "Matsavaram",
        "value": "Matsavaram"
    },
    {
        "name": "Mattanur",
        "value": "Mattanur"
    },
    {
        "name": "Mattathur",
        "value": "Mattathur"
    },
    {
        "name": "Mattigiri",
        "value": "Mattigiri"
    },
    {
        "name": "Mattul",
        "value": "Mattul"
    },
    {
        "name": "Mau",
        "value": "Mau"
    },
    {
        "name": "Mau Dhaneshpur",
        "value": "Mau Dhaneshpur"
    },
    {
        "name": "Mauganj",
        "value": "Mauganj"
    },
    {
        "name": "Mauji",
        "value": "Mauji"
    },
    {
        "name": "Mauli",
        "value": "Mauli"
    },
    {
        "name": "Maur",
        "value": "Maur"
    },
    {
        "name": "Maur Kalan",
        "value": "Maur Kalan"
    },
    {
        "name": "Mauranwan",
        "value": "Mauranwan"
    },
    {
        "name": "Mavalli",
        "value": "Mavalli"
    },
    {
        "name": "Mavelikara",
        "value": "Mavelikara"
    },
    {
        "name": "Mavinpalli",
        "value": "Mavinpalli"
    },
    {
        "name": "Mawai",
        "value": "Mawai"
    },
    {
        "name": "Mayahaura",
        "value": "Mayahaura"
    },
    {
        "name": "Mayamankurichchi",
        "value": "Mayamankurichchi"
    },
    {
        "name": "Mayang Imphal",
        "value": "Mayang Imphal"
    },
    {
        "name": "Mayiladi",
        "value": "Mayiladi"
    },
    {
        "name": "Mayilur",
        "value": "Mayilur"
    },
    {
        "name": "Mayureswar",
        "value": "Mayureswar"
    },
    {
        "name": "Mayyanad",
        "value": "Mayyanad"
    },
    {
        "name": "Medak",
        "value": "Medak"
    },
    {
        "name": "Medapadu",
        "value": "Medapadu"
    },
    {
        "name": "Medarametla",
        "value": "Medarametla"
    },
    {
        "name": "Medchal",
        "value": "Medchal"
    },
    {
        "name": "Meddappakkam",
        "value": "Meddappakkam"
    },
    {
        "name": "Medikunda",
        "value": "Medikunda"
    },
    {
        "name": "Medinipur",
        "value": "Medinipur"
    },
    {
        "name": "Medleri",
        "value": "Medleri"
    },
    {
        "name": "Meerut",
        "value": "Meerut"
    },
    {
        "name": "Meghaul",
        "value": "Meghaul"
    },
    {
        "name": "Meghauna",
        "value": "Meghauna"
    },
    {
        "name": "Meghraj",
        "value": "Meghraj"
    },
    {
        "name": "Mehdauli",
        "value": "Mehdauli"
    },
    {
        "name": "Mehdipur",
        "value": "Mehdipur"
    },
    {
        "name": "Mehidpur",
        "value": "Mehidpur"
    },
    {
        "name": "Mehnajpur",
        "value": "Mehnajpur"
    },
    {
        "name": "Mehsari",
        "value": "Mehsari"
    },
    {
        "name": "Meiti",
        "value": "Meiti"
    },
    {
        "name": "Mekra",
        "value": "Mekra"
    },
    {
        "name": "Mel Bhuvanagiri",
        "value": "Mel Bhuvanagiri"
    },
    {
        "name": "Mel Nariyappanur",
        "value": "Mel Nariyappanur"
    },
    {
        "name": "Mel Palaiyam",
        "value": "Mel Palaiyam"
    },
    {
        "name": "Mel Seval",
        "value": "Mel Seval"
    },
    {
        "name": "Mela Gudalur",
        "value": "Mela Gudalur"
    },
    {
        "name": "Melavayi",
        "value": "Melavayi"
    },
    {
        "name": "Melila",
        "value": "Melila"
    },
    {
        "name": "Mellacheruvu",
        "value": "Mellacheruvu"
    },
    {
        "name": "Melmadai",
        "value": "Melmadai"
    },
    {
        "name": "Melmuri",
        "value": "Melmuri"
    },
    {
        "name": "Melpanaikkadu",
        "value": "Melpanaikkadu"
    },
    {
        "name": "Meltonakkal",
        "value": "Meltonakkal"
    },
    {
        "name": "Melur",
        "value": "Melur"
    },
    {
        "name": "Memari",
        "value": "Memari"
    },
    {
        "name": "Memunda",
        "value": "Memunda"
    },
    {
        "name": "Meppayyur",
        "value": "Meppayyur"
    },
    {
        "name": "Meral",
        "value": "Meral"
    },
    {
        "name": "Merkanam",
        "value": "Merkanam"
    },
    {
        "name": "Merta",
        "value": "Merta"
    },
    {
        "name": "Meru",
        "value": "Meru"
    },
    {
        "name": "Mesra",
        "value": "Mesra"
    },
    {
        "name": "Metekora",
        "value": "Metekora"
    },
    {
        "name": "Metpalli",
        "value": "Metpalli"
    },
    {
        "name": "Mettupalaiyam",
        "value": "Mettupalaiyam"
    },
    {
        "name": "Mettuppalaiyam",
        "value": "Mettuppalaiyam"
    },
    {
        "name": "Mevani",
        "value": "Mevani"
    },
    {
        "name": "Mhow",
        "value": "Mhow"
    },
    {
        "name": "Miani",
        "value": "Miani"
    },
    {
        "name": "Mianpur Dubauli",
        "value": "Mianpur Dubauli"
    },
    {
        "name": "Midalam",
        "value": "Midalam"
    },
    {
        "name": "Miduturu",
        "value": "Miduturu"
    },
    {
        "name": "Mihona",
        "value": "Mihona"
    },
    {
        "name": "Milattur",
        "value": "Milattur"
    },
    {
        "name": "Minad",
        "value": "Minad"
    },
    {
        "name": "Minakshipuram",
        "value": "Minakshipuram"
    },
    {
        "name": "Minja",
        "value": "Minja"
    },
    {
        "name": "Minnal",
        "value": "Minnal"
    },
    {
        "name": "Minnampalli",
        "value": "Minnampalli"
    },
    {
        "name": "Mirchpur",
        "value": "Mirchpur"
    },
    {
        "name": "Mirdaul",
        "value": "Mirdaul"
    },
    {
        "name": "Mirdoddi",
        "value": "Mirdoddi"
    },
    {
        "name": "Mirganj",
        "value": "Mirganj"
    },
    {
        "name": "Mirialguda",
        "value": "Mirialguda"
    },
    {
        "name": "Mirik",
        "value": "Mirik"
    },
    {
        "name": "Mirpeta",
        "value": "Mirpeta"
    },
    {
        "name": "Miryal",
        "value": "Miryal"
    },
    {
        "name": "Mirzanagar",
        "value": "Mirzanagar"
    },
    {
        "name": "Mirzapur",
        "value": "Mirzapur"
    },
    {
        "name": "Mishrikot",
        "value": "Mishrikot"
    },
    {
        "name": "Mitrapur",
        "value": "Mitrapur"
    },
    {
        "name": "Mittahalli",
        "value": "Mittahalli"
    },
    {
        "name": "Miyar",
        "value": "Miyar"
    },
    {
        "name": "Mocharim",
        "value": "Mocharim"
    },
    {
        "name": "Moda",
        "value": "Moda"
    },
    {
        "name": "Modachchur",
        "value": "Modachchur"
    },
    {
        "name": "Modakkurichchi",
        "value": "Modakkurichchi"
    },
    {
        "name": "Modasa",
        "value": "Modasa"
    },
    {
        "name": "Modavandisatyamangalam",
        "value": "Modavandisatyamangalam"
    },
    {
        "name": "Moga",
        "value": "Moga"
    },
    {
        "name": "Mogalturru",
        "value": "Mogalturru"
    },
    {
        "name": "Mohan Eghu",
        "value": "Mohan Eghu"
    },
    {
        "name": "Mohana",
        "value": "Mohana"
    },
    {
        "name": "Mohania",
        "value": "Mohania"
    },
    {
        "name": "Mohanpur",
        "value": "Mohanpur"
    },
    {
        "name": "Mohanpur Gaughata",
        "value": "Mohanpur Gaughata"
    },
    {
        "name": "Mohanur",
        "value": "Mohanur"
    },
    {
        "name": "Mohda",
        "value": "Mohda"
    },
    {
        "name": "Mohdra",
        "value": "Mohdra"
    },
    {
        "name": "Mohgaon",
        "value": "Mohgaon"
    },
    {
        "name": "Mohiuddinnagar",
        "value": "Mohiuddinnagar"
    },
    {
        "name": "Moirang",
        "value": "Moirang"
    },
    {
        "name": "Moka",
        "value": "Moka"
    },
    {
        "name": "Mokameh",
        "value": "Mokameh"
    },
    {
        "name": "Mokarrampur",
        "value": "Mokarrampur"
    },
    {
        "name": "Mokeri",
        "value": "Mokeri"
    },
    {
        "name": "Mokokchung",
        "value": "Mokokchung"
    },
    {
        "name": "Mokri",
        "value": "Mokri"
    },
    {
        "name": "Molagavalli",
        "value": "Molagavalli"
    },
    {
        "name": "Molakalumuru",
        "value": "Molakalumuru"
    },
    {
        "name": "Moman Barodiya",
        "value": "Moman Barodiya"
    },
    {
        "name": "Momanpet",
        "value": "Momanpet"
    },
    {
        "name": "Monghyr",
        "value": "Monghyr"
    },
    {
        "name": "Moparipalaiyam",
        "value": "Moparipalaiyam"
    },
    {
        "name": "Morab",
        "value": "Morab"
    },
    {
        "name": "Moradabad",
        "value": "Moradabad"
    },
    {
        "name": "Moranha",
        "value": "Moranha"
    },
    {
        "name": "Morasar",
        "value": "Morasar"
    },
    {
        "name": "Morauna",
        "value": "Morauna"
    },
    {
        "name": "Morbi",
        "value": "Morbi"
    },
    {
        "name": "Morena",
        "value": "Morena"
    },
    {
        "name": "Morinda",
        "value": "Morinda"
    },
    {
        "name": "Morsand",
        "value": "Morsand"
    },
    {
        "name": "Morsi",
        "value": "Morsi"
    },
    {
        "name": "Mortad",
        "value": "Mortad"
    },
    {
        "name": "Morubagalu",
        "value": "Morubagalu"
    },
    {
        "name": "Morur",
        "value": "Morur"
    },
    {
        "name": "Morwa",
        "value": "Morwa"
    },
    {
        "name": "Mosrah",
        "value": "Mosrah"
    },
    {
        "name": "Mothihari",
        "value": "Mothihari"
    },
    {
        "name": "Motibennur",
        "value": "Motibennur"
    },
    {
        "name": "Motipur",
        "value": "Motipur"
    },
    {
        "name": "Motkur",
        "value": "Motkur"
    },
    {
        "name": "Moviya",
        "value": "Moviya"
    },
    {
        "name": "Mudakkal",
        "value": "Mudakkal"
    },
    {
        "name": "Mudakkiraye",
        "value": "Mudakkiraye"
    },
    {
        "name": "Mudbidri",
        "value": "Mudbidri"
    },
    {
        "name": "Muddada",
        "value": "Muddada"
    },
    {
        "name": "Muddanuru",
        "value": "Muddanuru"
    },
    {
        "name": "Muddebihal",
        "value": "Muddebihal"
    },
    {
        "name": "Mudgal",
        "value": "Mudgal"
    },
    {
        "name": "Mudgere",
        "value": "Mudgere"
    },
    {
        "name": "Mudhol",
        "value": "Mudhol"
    },
    {
        "name": "Mudichchur",
        "value": "Mudichchur"
    },
    {
        "name": "Mudki",
        "value": "Mudki"
    },
    {
        "name": "Mudukulattur",
        "value": "Mudukulattur"
    },
    {
        "name": "Mugalivakkam",
        "value": "Mugalivakkam"
    },
    {
        "name": "Mugdampalli",
        "value": "Mugdampalli"
    },
    {
        "name": "Mugutkhan Hubli",
        "value": "Mugutkhan Hubli"
    },
    {
        "name": "Muhammadabad",
        "value": "Muhammadabad"
    },
    {
        "name": "Muhammadganj",
        "value": "Muhammadganj"
    },
    {
        "name": "Mukasi Pidariyur",
        "value": "Mukasi Pidariyur"
    },
    {
        "name": "Mukerian",
        "value": "Mukerian"
    },
    {
        "name": "Mukher",
        "value": "Mukher"
    },
    {
        "name": "Mukhtarpur Salkani",
        "value": "Mukhtarpur Salkani"
    },
    {
        "name": "Mukkanur",
        "value": "Mukkanur"
    },
    {
        "name": "Mukkudal",
        "value": "Mukkudal"
    },
    {
        "name": "Mukondapalli",
        "value": "Mukondapalli"
    },
    {
        "name": "Muktsar",
        "value": "Muktsar"
    },
    {
        "name": "Mulaikkaraippatti",
        "value": "Mulaikkaraippatti"
    },
    {
        "name": "Mulakad",
        "value": "Mulakad"
    },
    {
        "name": "Mulakaledu",
        "value": "Mulakaledu"
    },
    {
        "name": "Mulakaluru",
        "value": "Mulakaluru"
    },
    {
        "name": "Mulakumud",
        "value": "Mulakumud"
    },
    {
        "name": "Mulampilli",
        "value": "Mulampilli"
    },
    {
        "name": "Mulangodi",
        "value": "Mulangodi"
    },
    {
        "name": "Mulanur",
        "value": "Mulanur"
    },
    {
        "name": "Mulavana",
        "value": "Mulavana"
    },
    {
        "name": "Mulavur",
        "value": "Mulavur"
    },
    {
        "name": "Mulbagal",
        "value": "Mulbagal"
    },
    {
        "name": "Mulgund",
        "value": "Mulgund"
    },
    {
        "name": "Muli",
        "value": "Muli"
    },
    {
        "name": "Mulki",
        "value": "Mulki"
    },
    {
        "name": "Mullanwala",
        "value": "Mullanwala"
    },
    {
        "name": "Mullassheri",
        "value": "Mullassheri"
    },
    {
        "name": "Mullurkara",
        "value": "Mullurkara"
    },
    {
        "name": "Multai",
        "value": "Multai"
    },
    {
        "name": "Multi",
        "value": "Multi"
    },
    {
        "name": "Mulug",
        "value": "Mulug"
    },
    {
        "name": "Mumaradikop",
        "value": "Mumaradikop"
    },
    {
        "name": "Mumbai",
        "value": "Mumbai"
    },
    {
        "name": "Mummidivaram",
        "value": "Mummidivaram"
    },
    {
        "name": "Munagala",
        "value": "Munagala"
    },
    {
        "name": "Munagapaka",
        "value": "Munagapaka"
    },
    {
        "name": "Munak",
        "value": "Munak"
    },
    {
        "name": "Mundahal Khurd",
        "value": "Mundahal Khurd"
    },
    {
        "name": "Mundakkal",
        "value": "Mundakkal"
    },
    {
        "name": "Mundargi",
        "value": "Mundargi"
    },
    {
        "name": "Mundgod",
        "value": "Mundgod"
    },
    {
        "name": "Mundi",
        "value": "Mundi"
    },
    {
        "name": "Mundka",
        "value": "Mundka"
    },
    {
        "name": "Mundra",
        "value": "Mundra"
    },
    {
        "name": "Mungaoli",
        "value": "Mungaoli"
    },
    {
        "name": "Mungeli",
        "value": "Mungeli"
    },
    {
        "name": "Mungod",
        "value": "Mungod"
    },
    {
        "name": "Munnalam",
        "value": "Munnalam"
    },
    {
        "name": "Munnar",
        "value": "Munnar"
    },
    {
        "name": "Munnarkod",
        "value": "Munnarkod"
    },
    {
        "name": "Munnelli",
        "value": "Munnelli"
    },
    {
        "name": "Munnuru",
        "value": "Munnuru"
    },
    {
        "name": "Munro Turuttu",
        "value": "Munro Turuttu"
    },
    {
        "name": "Muppalla",
        "value": "Muppalla"
    },
    {
        "name": "Muradpur",
        "value": "Muradpur"
    },
    {
        "name": "Muragacha",
        "value": "Muragacha"
    },
    {
        "name": "Murajpur",
        "value": "Murajpur"
    },
    {
        "name": "Murapaka",
        "value": "Murapaka"
    },
    {
        "name": "Murgod",
        "value": "Murgod"
    },
    {
        "name": "Muriyad",
        "value": "Muriyad"
    },
    {
        "name": "Murliganj",
        "value": "Murliganj"
    },
    {
        "name": "Murshidabad",
        "value": "Murshidabad"
    },
    {
        "name": "Murtajapur",
        "value": "Murtajapur"
    },
    {
        "name": "Murtazabad",
        "value": "Murtazabad"
    },
    {
        "name": "Murud",
        "value": "Murud"
    },
    {
        "name": "Murugampalaiyam",
        "value": "Murugampalaiyam"
    },
    {
        "name": "Murukondapadu",
        "value": "Murukondapadu"
    },
    {
        "name": "Murungattoluvu",
        "value": "Murungattoluvu"
    },
    {
        "name": "Musapur",
        "value": "Musapur"
    },
    {
        "name": "Mushabani",
        "value": "Mushabani"
    },
    {
        "name": "Musiri",
        "value": "Musiri"
    },
    {
        "name": "Mussoorie",
        "value": "Mussoorie"
    },
    {
        "name": "Mustafabad",
        "value": "Mustafabad"
    },
    {
        "name": "Muthabana",
        "value": "Muthabana"
    },
    {
        "name": "Muthuswamipuram",
        "value": "Muthuswamipuram"
    },
    {
        "name": "Muthutala",
        "value": "Muthutala"
    },
    {
        "name": "Mutluru",
        "value": "Mutluru"
    },
    {
        "name": "Muttalakanpatti",
        "value": "Muttalakanpatti"
    },
    {
        "name": "Muttam",
        "value": "Muttam"
    },
    {
        "name": "Muttamtura",
        "value": "Muttamtura"
    },
    {
        "name": "Muttanampalaiyam",
        "value": "Muttanampalaiyam"
    },
    {
        "name": "Muttatodi",
        "value": "Muttatodi"
    },
    {
        "name": "Muttattuteruvu",
        "value": "Muttattuteruvu"
    },
    {
        "name": "Muttayyapuram",
        "value": "Muttayyapuram"
    },
    {
        "name": "Muttukuru",
        "value": "Muttukuru"
    },
    {
        "name": "Muttunayakkanpatti",
        "value": "Muttunayakkanpatti"
    },
    {
        "name": "Muttupet",
        "value": "Muttupet"
    },
    {
        "name": "Mutukuru",
        "value": "Mutukuru"
    },
    {
        "name": "Muturkha",
        "value": "Muturkha"
    },
    {
        "name": "Mutyalapalle",
        "value": "Mutyalapalle"
    },
    {
        "name": "Muvattupula",
        "value": "Muvattupula"
    },
    {
        "name": "Muzaffarnagar",
        "value": "Muzaffarnagar"
    },
    {
        "name": "Muzaffarpur",
        "value": "Muzaffarpur"
    },
    {
        "name": "Muzhakkunnu",
        "value": "Muzhakkunnu"
    },
    {
        "name": "Nabha",
        "value": "Nabha"
    },
    {
        "name": "Nabinagar",
        "value": "Nabinagar"
    },
    {
        "name": "Nachchandupatti",
        "value": "Nachchandupatti"
    },
    {
        "name": "Nadbai",
        "value": "Nadbai"
    },
    {
        "name": "Nadendla",
        "value": "Nadendla"
    },
    {
        "name": "Nadiad",
        "value": "Nadiad"
    },
    {
        "name": "Nadikude",
        "value": "Nadikude"
    },
    {
        "name": "Nadimpalem",
        "value": "Nadimpalem"
    },
    {
        "name": "Nadisal",
        "value": "Nadisal"
    },
    {
        "name": "Nadol",
        "value": "Nadol"
    },
    {
        "name": "Nadugadda",
        "value": "Nadugadda"
    },
    {
        "name": "Naduhatti",
        "value": "Naduhatti"
    },
    {
        "name": "Naduvannur",
        "value": "Naduvannur"
    },
    {
        "name": "Naduvattam",
        "value": "Naduvattam"
    },
    {
        "name": "Nagaiyampatti",
        "value": "Nagaiyampatti"
    },
    {
        "name": "Nagalapuram",
        "value": "Nagalapuram"
    },
    {
        "name": "Nagamangala",
        "value": "Nagamangala"
    },
    {
        "name": "Nagambhotlapalem",
        "value": "Nagambhotlapalem"
    },
    {
        "name": "Nagar",
        "value": "Nagar"
    },
    {
        "name": "Nagar Nahusa",
        "value": "Nagar Nahusa"
    },
    {
        "name": "Nagardevla Budrukh",
        "value": "Nagardevla Budrukh"
    },
    {
        "name": "Nagari",
        "value": "Nagari"
    },
    {
        "name": "Nagarpara",
        "value": "Nagarpara"
    },
    {
        "name": "Nagarur",
        "value": "Nagarur"
    },
    {
        "name": "Nagasamudram",
        "value": "Nagasamudram"
    },
    {
        "name": "Nagathan",
        "value": "Nagathan"
    },
    {
        "name": "Nagawaram",
        "value": "Nagawaram"
    },
    {
        "name": "Nagayalanka",
        "value": "Nagayalanka"
    },
    {
        "name": "Nagda",
        "value": "Nagda"
    },
    {
        "name": "Nagdah",
        "value": "Nagdah"
    },
    {
        "name": "Nagercoil",
        "value": "Nagercoil"
    },
    {
        "name": "Nagina",
        "value": "Nagina"
    },
    {
        "name": "Nagireddipalli",
        "value": "Nagireddipalli"
    },
    {
        "name": "Nagla",
        "value": "Nagla"
    },
    {
        "name": "Nagnur",
        "value": "Nagnur"
    },
    {
        "name": "Nagod",
        "value": "Nagod"
    },
    {
        "name": "Nagojanahalli",
        "value": "Nagojanahalli"
    },
    {
        "name": "Nagpur",
        "value": "Nagpur"
    },
    {
        "name": "Nagra",
        "value": "Nagra"
    },
    {
        "name": "Nagtala",
        "value": "Nagtala"
    },
    {
        "name": "Nagulapadu",
        "value": "Nagulapadu"
    },
    {
        "name": "Nahan",
        "value": "Nahan"
    },
    {
        "name": "Nahargarh",
        "value": "Nahargarh"
    },
    {
        "name": "Nahazari",
        "value": "Nahazari"
    },
    {
        "name": "Nahorkatiya",
        "value": "Nahorkatiya"
    },
    {
        "name": "Naigarhi",
        "value": "Naigarhi"
    },
    {
        "name": "Naihati",
        "value": "Naihati"
    },
    {
        "name": "Naikankudi",
        "value": "Naikankudi"
    },
    {
        "name": "Naini",
        "value": "Naini"
    },
    {
        "name": "Naini Tal",
        "value": "Naini Tal"
    },
    {
        "name": "Nainijor",
        "value": "Nainijor"
    },
    {
        "name": "Nainpur",
        "value": "Nainpur"
    },
    {
        "name": "Najafgarh",
        "value": "Najafgarh"
    },
    {
        "name": "Najibabad",
        "value": "Najibabad"
    },
    {
        "name": "Nakhtarana",
        "value": "Nakhtarana"
    },
    {
        "name": "Nakodar",
        "value": "Nakodar"
    },
    {
        "name": "Nakrekal",
        "value": "Nakrekal"
    },
    {
        "name": "Nakur",
        "value": "Nakur"
    },
    {
        "name": "Nalakadoddi",
        "value": "Nalakadoddi"
    },
    {
        "name": "Nalambur",
        "value": "Nalambur"
    },
    {
        "name": "Nalatvad",
        "value": "Nalatvad"
    },
    {
        "name": "Nalbari",
        "value": "Nalbari"
    },
    {
        "name": "Nalegaon",
        "value": "Nalegaon"
    },
    {
        "name": "Nalgonda",
        "value": "Nalgonda"
    },
    {
        "name": "Nalgora",
        "value": "Nalgora"
    },
    {
        "name": "Nalhati",
        "value": "Nalhati"
    },
    {
        "name": "Nalikkalpatti",
        "value": "Nalikkalpatti"
    },
    {
        "name": "Naliya",
        "value": "Naliya"
    },
    {
        "name": "Nallagunta",
        "value": "Nallagunta"
    },
    {
        "name": "Nallajerla",
        "value": "Nallajerla"
    },
    {
        "name": "Nallamada",
        "value": "Nallamada"
    },
    {
        "name": "Nallamadu",
        "value": "Nallamadu"
    },
    {
        "name": "Nallikodur",
        "value": "Nallikodur"
    },
    {
        "name": "Nallippalaiyam",
        "value": "Nallippalaiyam"
    },
    {
        "name": "Nallur",
        "value": "Nallur"
    },
    {
        "name": "Nalua",
        "value": "Nalua"
    },
    {
        "name": "Nalwar",
        "value": "Nalwar"
    },
    {
        "name": "Namagiripettai",
        "value": "Namagiripettai"
    },
    {
        "name": "Namakadu",
        "value": "Namakadu"
    },
    {
        "name": "Namakkal",
        "value": "Namakkal"
    },
    {
        "name": "Nambiyur",
        "value": "Nambiyur"
    },
    {
        "name": "Namburu",
        "value": "Namburu"
    },
    {
        "name": "Nambutalai",
        "value": "Nambutalai"
    },
    {
        "name": "Namchi",
        "value": "Namchi"
    },
    {
        "name": "Namli",
        "value": "Namli"
    },
    {
        "name": "Namminikara",
        "value": "Namminikara"
    },
    {
        "name": "Namrup",
        "value": "Namrup"
    },
    {
        "name": "Nana",
        "value": "Nana"
    },
    {
        "name": "Nanan",
        "value": "Nanan"
    },
    {
        "name": "Nanattuparai",
        "value": "Nanattuparai"
    },
    {
        "name": "Nandamuru",
        "value": "Nandamuru"
    },
    {
        "name": "Nandavaram",
        "value": "Nandavaram"
    },
    {
        "name": "Nanded",
        "value": "Nanded"
    },
    {
        "name": "Nandgaon",
        "value": "Nandgaon"
    },
    {
        "name": "Nandigama",
        "value": "Nandigama"
    },
    {
        "name": "Nandigaon",
        "value": "Nandigaon"
    },
    {
        "name": "Nandikotkur",
        "value": "Nandikotkur"
    },
    {
        "name": "Nandimandalam",
        "value": "Nandimandalam"
    },
    {
        "name": "Nandipeta",
        "value": "Nandipeta"
    },
    {
        "name": "Nandiyalam",
        "value": "Nandiyalam"
    },
    {
        "name": "Nandnawan",
        "value": "Nandnawan"
    },
    {
        "name": "Nandod",
        "value": "Nandod"
    },
    {
        "name": "Nandura Buzurg",
        "value": "Nandura Buzurg"
    },
    {
        "name": "Nandurbar",
        "value": "Nandurbar"
    },
    {
        "name": "Nandyal",
        "value": "Nandyal"
    },
    {
        "name": "Nandyalampeta",
        "value": "Nandyalampeta"
    },
    {
        "name": "Nangal Chaudhri",
        "value": "Nangal Chaudhri"
    },
    {
        "name": "Nangal Township",
        "value": "Nangal Township"
    },
    {
        "name": "Nangavalli",
        "value": "Nangavalli"
    },
    {
        "name": "Nangavaram",
        "value": "Nangavaram"
    },
    {
        "name": "Nangloi Jat",
        "value": "Nangloi Jat"
    },
    {
        "name": "Nanguneri",
        "value": "Nanguneri"
    },
    {
        "name": "Nanjai Kilabadi",
        "value": "Nanjai Kilabadi"
    },
    {
        "name": "Nanjakkad",
        "value": "Nanjakkad"
    },
    {
        "name": "Nanjanad",
        "value": "Nanjanad"
    },
    {
        "name": "Nanjangud",
        "value": "Nanjangud"
    },
    {
        "name": "Nanjikkottai",
        "value": "Nanjikkottai"
    },
    {
        "name": "Nanjundapuram",
        "value": "Nanjundapuram"
    },
    {
        "name": "Nanminda",
        "value": "Nanminda"
    },
    {
        "name": "Nannamukku",
        "value": "Nannamukku"
    },
    {
        "name": "Nanpara",
        "value": "Nanpara"
    },
    {
        "name": "Nanthankulam",
        "value": "Nanthankulam"
    },
    {
        "name": "Nao Kothi",
        "value": "Nao Kothi"
    },
    {
        "name": "Narahia",
        "value": "Narahia"
    },
    {
        "name": "Narala",
        "value": "Narala"
    },
    {
        "name": "Naranammalpuram",
        "value": "Naranammalpuram"
    },
    {
        "name": "Naranapuram",
        "value": "Naranapuram"
    },
    {
        "name": "Naranattenvanpatti",
        "value": "Naranattenvanpatti"
    },
    {
        "name": "Naranda",
        "value": "Naranda"
    },
    {
        "name": "Narapala",
        "value": "Narapala"
    },
    {
        "name": "Narapalli",
        "value": "Narapalli"
    },
    {
        "name": "Narasannapeta",
        "value": "Narasannapeta"
    },
    {
        "name": "Narasapur",
        "value": "Narasapur"
    },
    {
        "name": "Narasapuram",
        "value": "Narasapuram"
    },
    {
        "name": "Narasaraopet",
        "value": "Narasaraopet"
    },
    {
        "name": "Narasimharajapura",
        "value": "Narasimharajapura"
    },
    {
        "name": "Narasingam",
        "value": "Narasingam"
    },
    {
        "name": "Narasingapuram",
        "value": "Narasingapuram"
    },
    {
        "name": "Naraura",
        "value": "Naraura"
    },
    {
        "name": "Naravarikuppam",
        "value": "Naravarikuppam"
    },
    {
        "name": "Narayanavanam",
        "value": "Narayanavanam"
    },
    {
        "name": "Narayangarh",
        "value": "Narayangarh"
    },
    {
        "name": "Narayankher",
        "value": "Narayankher"
    },
    {
        "name": "Narayanpet",
        "value": "Narayanpet"
    },
    {
        "name": "Narayanpur",
        "value": "Narayanpur"
    },
    {
        "name": "Narayanraopet",
        "value": "Narayanraopet"
    },
    {
        "name": "Naregal",
        "value": "Naregal"
    },
    {
        "name": "Narela",
        "value": "Narela"
    },
    {
        "name": "Narendrapatnam",
        "value": "Narendrapatnam"
    },
    {
        "name": "Nargund",
        "value": "Nargund"
    },
    {
        "name": "Narhan",
        "value": "Narhan"
    },
    {
        "name": "Narhat",
        "value": "Narhat"
    },
    {
        "name": "Nari Bhadaun",
        "value": "Nari Bhadaun"
    },
    {
        "name": "Nariar",
        "value": "Nariar"
    },
    {
        "name": "Narippatta",
        "value": "Narippatta"
    },
    {
        "name": "Narkatpalli",
        "value": "Narkatpalli"
    },
    {
        "name": "Narkher",
        "value": "Narkher"
    },
    {
        "name": "Narnaul",
        "value": "Narnaul"
    },
    {
        "name": "Narona",
        "value": "Narona"
    },
    {
        "name": "Narot Mehra",
        "value": "Narot Mehra"
    },
    {
        "name": "Narsampet",
        "value": "Narsampet"
    },
    {
        "name": "Narsapur",
        "value": "Narsapur"
    },
    {
        "name": "Narsimlapet",
        "value": "Narsimlapet"
    },
    {
        "name": "Narsinghgarh",
        "value": "Narsinghgarh"
    },
    {
        "name": "Narsingi",
        "value": "Narsingi"
    },
    {
        "name": "Narsipatnam",
        "value": "Narsipatnam"
    },
    {
        "name": "Naruar",
        "value": "Naruar"
    },
    {
        "name": "Narwana",
        "value": "Narwana"
    },
    {
        "name": "Narwar",
        "value": "Narwar"
    },
    {
        "name": "Naryai ka Puri",
        "value": "Naryai ka Puri"
    },
    {
        "name": "Nasik",
        "value": "Nasik"
    },
    {
        "name": "Nasirabad",
        "value": "Nasirabad"
    },
    {
        "name": "Nasiyanur",
        "value": "Nasiyanur"
    },
    {
        "name": "Naspur",
        "value": "Naspur"
    },
    {
        "name": "Nasriganj",
        "value": "Nasriganj"
    },
    {
        "name": "Nasrullahganj",
        "value": "Nasrullahganj"
    },
    {
        "name": "Nathana",
        "value": "Nathana"
    },
    {
        "name": "Nathdwara",
        "value": "Nathdwara"
    },
    {
        "name": "Nathpur",
        "value": "Nathpur"
    },
    {
        "name": "Natshal",
        "value": "Natshal"
    },
    {
        "name": "Nattakkadaiyur",
        "value": "Nattakkadaiyur"
    },
    {
        "name": "Nattam",
        "value": "Nattam"
    },
    {
        "name": "Nattappettai",
        "value": "Nattappettai"
    },
    {
        "name": "Nattarampalli",
        "value": "Nattarampalli"
    },
    {
        "name": "Nattarasankottai",
        "value": "Nattarasankottai"
    },
    {
        "name": "Nauagarhi",
        "value": "Nauagarhi"
    },
    {
        "name": "Naubatpur",
        "value": "Naubatpur"
    },
    {
        "name": "Naugachhia",
        "value": "Naugachhia"
    },
    {
        "name": "Naugaon Sadat",
        "value": "Naugaon Sadat"
    },
    {
        "name": "Nauhata",
        "value": "Nauhata"
    },
    {
        "name": "Naula",
        "value": "Naula"
    },
    {
        "name": "Naurhiya",
        "value": "Naurhiya"
    },
    {
        "name": "Nautan Dube",
        "value": "Nautan Dube"
    },
    {
        "name": "Nautanwa",
        "value": "Nautanwa"
    },
    {
        "name": "Nava Raipur",
        "value": "Nava Raipur"
    },
    {
        "name": "Navadwip",
        "value": "Navadwip"
    },
    {
        "name": "Navalgund",
        "value": "Navalgund"
    },
    {
        "name": "Navalyal",
        "value": "Navalyal"
    },
    {
        "name": "Navani",
        "value": "Navani"
    },
    {
        "name": "Navappatti",
        "value": "Navappatti"
    },
    {
        "name": "Navapur",
        "value": "Navapur"
    },
    {
        "name": "Navelim",
        "value": "Navelim"
    },
    {
        "name": "Navinipatti",
        "value": "Navinipatti"
    },
    {
        "name": "Navipet",
        "value": "Navipet"
    },
    {
        "name": "Navsari",
        "value": "Navsari"
    },
    {
        "name": "Nawa Nagar Nizamat",
        "value": "Nawa Nagar Nizamat"
    },
    {
        "name": "Nawabganj",
        "value": "Nawabganj"
    },
    {
        "name": "Nawada",
        "value": "Nawada"
    },
    {
        "name": "Nawada Gobindganj",
        "value": "Nawada Gobindganj"
    },
    {
        "name": "Nawagarh",
        "value": "Nawagarh"
    },
    {
        "name": "Nawai",
        "value": "Nawai"
    },
    {
        "name": "Nawalgarh",
        "value": "Nawalgarh"
    },
    {
        "name": "Nawalpur",
        "value": "Nawalpur"
    },
    {
        "name": "Nawanagar",
        "value": "Nawanagar"
    },
    {
        "name": "Nawashahr",
        "value": "Nawashahr"
    },
    {
        "name": "Nawsari",
        "value": "Nawsari"
    },
    {
        "name": "Nayagaon",
        "value": "Nayagaon"
    },
    {
        "name": "Nayagarh",
        "value": "Nayagarh"
    },
    {
        "name": "Nayakanhatti",
        "value": "Nayakanhatti"
    },
    {
        "name": "Nayanagar",
        "value": "Nayanagar"
    },
    {
        "name": "Nayanakulam",
        "value": "Nayanakulam"
    },
    {
        "name": "Nayudupet",
        "value": "Nayudupet"
    },
    {
        "name": "Nazareth",
        "value": "Nazareth"
    },
    {
        "name": "Nazira",
        "value": "Nazira"
    },
    {
        "name": "Nediyanad",
        "value": "Nediyanad"
    },
    {
        "name": "Nedugula",
        "value": "Nedugula"
    },
    {
        "name": "Nedumana",
        "value": "Nedumana"
    },
    {
        "name": "Nedumangad",
        "value": "Nedumangad"
    },
    {
        "name": "Nedumpura",
        "value": "Nedumpura"
    },
    {
        "name": "Nedumudi",
        "value": "Nedumudi"
    },
    {
        "name": "Neduvannur",
        "value": "Neduvannur"
    },
    {
        "name": "Neduvattur",
        "value": "Neduvattur"
    },
    {
        "name": "Negapatam",
        "value": "Negapatam"
    },
    {
        "name": "Nekarikallu",
        "value": "Nekarikallu"
    },
    {
        "name": "Nelali",
        "value": "Nelali"
    },
    {
        "name": "Nelamangala",
        "value": "Nelamangala"
    },
    {
        "name": "Nelkattumseval",
        "value": "Nelkattumseval"
    },
    {
        "name": "Nellaya",
        "value": "Nellaya"
    },
    {
        "name": "Nellikkuppam",
        "value": "Nellikkuppam"
    },
    {
        "name": "Nellimarla",
        "value": "Nellimarla"
    },
    {
        "name": "Nellipaka",
        "value": "Nellipaka"
    },
    {
        "name": "Nellipoyil",
        "value": "Nellipoyil"
    },
    {
        "name": "Nelliyalam",
        "value": "Nelliyalam"
    },
    {
        "name": "Nellore",
        "value": "Nellore"
    },
    {
        "name": "Nellutla",
        "value": "Nellutla"
    },
    {
        "name": "Nelmadur",
        "value": "Nelmadur"
    },
    {
        "name": "Nemili",
        "value": "Nemili"
    },
    {
        "name": "Nemmara",
        "value": "Nemmara"
    },
    {
        "name": "Nenmem",
        "value": "Nenmem"
    },
    {
        "name": "Nenmini",
        "value": "Nenmini"
    },
    {
        "name": "Nepanagar",
        "value": "Nepanagar"
    },
    {
        "name": "Neralakaje",
        "value": "Neralakaje"
    },
    {
        "name": "Nerinjippettai",
        "value": "Nerinjippettai"
    },
    {
        "name": "Neriyamangalam",
        "value": "Neriyamangalam"
    },
    {
        "name": "Nerkunram",
        "value": "Nerkunram"
    },
    {
        "name": "Nerupperichchal",
        "value": "Nerupperichchal"
    },
    {
        "name": "Nesarg",
        "value": "Nesarg"
    },
    {
        "name": "Netapur Tanda",
        "value": "Netapur Tanda"
    },
    {
        "name": "Nethirimangalam",
        "value": "Nethirimangalam"
    },
    {
        "name": "Nettadahalli",
        "value": "Nettadahalli"
    },
    {
        "name": "Nettappakkam",
        "value": "Nettappakkam"
    },
    {
        "name": "Neuri",
        "value": "Neuri"
    },
    {
        "name": "New Delhi",
        "value": "New Delhi"
    },
    {
        "name": "Neykkarappatti",
        "value": "Neykkarappatti"
    },
    {
        "name": "Neyveli",
        "value": "Neyveli"
    },
    {
        "name": "Neyyattinkara",
        "value": "Neyyattinkara"
    },
    {
        "name": "Nibria",
        "value": "Nibria"
    },
    {
        "name": "Nidadavole",
        "value": "Nidadavole"
    },
    {
        "name": "Nidamaluru",
        "value": "Nidamaluru"
    },
    {
        "name": "Nidamangalam",
        "value": "Nidamangalam"
    },
    {
        "name": "Nidamanuru",
        "value": "Nidamanuru"
    },
    {
        "name": "Nidgundi",
        "value": "Nidgundi"
    },
    {
        "name": "Nidiyanga",
        "value": "Nidiyanga"
    },
    {
        "name": "Nighoj",
        "value": "Nighoj"
    },
    {
        "name": "Nihal Singhwala",
        "value": "Nihal Singhwala"
    },
    {
        "name": "Nihtaur",
        "value": "Nihtaur"
    },
    {
        "name": "Nij Khari",
        "value": "Nij Khari"
    },
    {
        "name": "Nijgaon Paranpur",
        "value": "Nijgaon Paranpur"
    },
    {
        "name": "Nilaiyur",
        "value": "Nilaiyur"
    },
    {
        "name": "Nilakkottai",
        "value": "Nilakkottai"
    },
    {
        "name": "Nilambur",
        "value": "Nilambur"
    },
    {
        "name": "Nilanga",
        "value": "Nilanga"
    },
    {
        "name": "Nilavarappatti",
        "value": "Nilavarappatti"
    },
    {
        "name": "Nilgiri",
        "value": "Nilgiri"
    },
    {
        "name": "Nilothi",
        "value": "Nilothi"
    },
    {
        "name": "Nim ka Thana",
        "value": "Nim ka Thana"
    },
    {
        "name": "Nimbahera",
        "value": "Nimbahera"
    },
    {
        "name": "Nimmekal",
        "value": "Nimmekal"
    },
    {
        "name": "Ninga",
        "value": "Ninga"
    },
    {
        "name": "Nipani",
        "value": "Nipani"
    },
    {
        "name": "Nirakpurpali",
        "value": "Nirakpurpali"
    },
    {
        "name": "Nirala",
        "value": "Nirala"
    },
    {
        "name": "Niramaruthur",
        "value": "Niramaruthur"
    },
    {
        "name": "Nirkunnam",
        "value": "Nirkunnam"
    },
    {
        "name": "Nirmal",
        "value": "Nirmal"
    },
    {
        "name": "Nirmali",
        "value": "Nirmali"
    },
    {
        "name": "Nirna",
        "value": "Nirna"
    },
    {
        "name": "Nirpur",
        "value": "Nirpur"
    },
    {
        "name": "Nisang",
        "value": "Nisang"
    },
    {
        "name": "Nisarpur",
        "value": "Nisarpur"
    },
    {
        "name": "Nitte",
        "value": "Nitte"
    },
    {
        "name": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "name": "Nizampatam",
        "value": "Nizampatam"
    },
    {
        "name": "Nizampur",
        "value": "Nizampur"
    },
    {
        "name": "Noamundi",
        "value": "Noamundi"
    },
    {
        "name": "Nohar",
        "value": "Nohar"
    },
    {
        "name": "Nohsa",
        "value": "Nohsa"
    },
    {
        "name": "Noida",
        "value": "Noida"
    },
    {
        "name": "Nokha",
        "value": "Nokha"
    },
    {
        "name": "Nonahi",
        "value": "Nonahi"
    },
    {
        "name": "Nonea",
        "value": "Nonea"
    },
    {
        "name": "Nongstoin",
        "value": "Nongstoin"
    },
    {
        "name": "Noniya",
        "value": "Noniya"
    },
    {
        "name": "Noria",
        "value": "Noria"
    },
    {
        "name": "North Guwahati",
        "value": "North Guwahati"
    },
    {
        "name": "North Lakhimpur",
        "value": "North Lakhimpur"
    },
    {
        "name": "Nottampatti",
        "value": "Nottampatti"
    },
    {
        "name": "Nowgong",
        "value": "Nowgong"
    },
    {
        "name": "Nowrangapur",
        "value": "Nowrangapur"
    },
    {
        "name": "Nulivedu",
        "value": "Nulivedu"
    },
    {
        "name": "Nulvi",
        "value": "Nulvi"
    },
    {
        "name": "Nunihat",
        "value": "Nunihat"
    },
    {
        "name": "Nunna",
        "value": "Nunna"
    },
    {
        "name": "Nurpur",
        "value": "Nurpur"
    },
    {
        "name": "Nutakki",
        "value": "Nutakki"
    },
    {
        "name": "Nuvem",
        "value": "Nuvem"
    },
    {
        "name": "Nuzvid",
        "value": "Nuzvid"
    },
    {
        "name": "Nyamti",
        "value": "Nyamti"
    },
    {
        "name": "Obalapuram",
        "value": "Obalapuram"
    },
    {
        "name": "Obra",
        "value": "Obra"
    },
    {
        "name": "Ochanthururtha",
        "value": "Ochanthururtha"
    },
    {
        "name": "Od",
        "value": "Od"
    },
    {
        "name": "Odaipatti",
        "value": "Odaipatti"
    },
    {
        "name": "Odaiyakulam",
        "value": "Odaiyakulam"
    },
    {
        "name": "Odanavattam",
        "value": "Odanavattam"
    },
    {
        "name": "Odatturai",
        "value": "Odatturai"
    },
    {
        "name": "Odayarpatti",
        "value": "Odayarpatti"
    },
    {
        "name": "Odlabari",
        "value": "Odlabari"
    },
    {
        "name": "Odugattur",
        "value": "Odugattur"
    },
    {
        "name": "Okha",
        "value": "Okha"
    },
    {
        "name": "Okhargara",
        "value": "Okhargara"
    },
    {
        "name": "Olagadam",
        "value": "Olagadam"
    },
    {
        "name": "Olaippatti",
        "value": "Olaippatti"
    },
    {
        "name": "Olalapadi",
        "value": "Olalapadi"
    },
    {
        "name": "Olhanpur",
        "value": "Olhanpur"
    },
    {
        "name": "Omallur",
        "value": "Omallur"
    },
    {
        "name": "Omalur",
        "value": "Omalur"
    },
    {
        "name": "Ommangi",
        "value": "Ommangi"
    },
    {
        "name": "Onda",
        "value": "Onda"
    },
    {
        "name": "Ongole",
        "value": "Ongole"
    },
    {
        "name": "Ootacamund",
        "value": "Ootacamund"
    },
    {
        "name": "Op",
        "value": "Op"
    },
    {
        "name": "Oppatavadi",
        "value": "Oppatavadi"
    },
    {
        "name": "Oppicherla",
        "value": "Oppicherla"
    },
    {
        "name": "Orai",
        "value": "Orai"
    },
    {
        "name": "Orchha",
        "value": "Orchha"
    },
    {
        "name": "Orumanayur",
        "value": "Orumanayur"
    },
    {
        "name": "Oruvadalkotta",
        "value": "Oruvadalkotta"
    },
    {
        "name": "Osian",
        "value": "Osian"
    },
    {
        "name": "Osmanabad",
        "value": "Osmanabad"
    },
    {
        "name": "Ottaikkalmantapam",
        "value": "Ottaikkalmantapam"
    },
    {
        "name": "Ottakkadai",
        "value": "Ottakkadai"
    },
    {
        "name": "Ottappalam",
        "value": "Ottappalam"
    },
    {
        "name": "Ottapparai",
        "value": "Ottapparai"
    },
    {
        "name": "Ottappidaram",
        "value": "Ottappidaram"
    },
    {
        "name": "Ottur",
        "value": "Ottur"
    },
    {
        "name": "Oulgaret",
        "value": "Oulgaret"
    },
    {
        "name": "Owk",
        "value": "Owk"
    },
    {
        "name": "Ozhur",
        "value": "Ozhur"
    },
    {
        "name": "Pachahi",
        "value": "Pachahi"
    },
    {
        "name": "Pachauth",
        "value": "Pachauth"
    },
    {
        "name": "Pachchaimalaiyankottai",
        "value": "Pachchaimalaiyankottai"
    },
    {
        "name": "Pachchampalaiyam",
        "value": "Pachchampalaiyam"
    },
    {
        "name": "Pachhapur",
        "value": "Pachhapur"
    },
    {
        "name": "Pachmir",
        "value": "Pachmir"
    },
    {
        "name": "Pachor",
        "value": "Pachor"
    },
    {
        "name": "Pachora",
        "value": "Pachora"
    },
    {
        "name": "Pachrukha",
        "value": "Pachrukha"
    },
    {
        "name": "Pachrukhi",
        "value": "Pachrukhi"
    },
    {
        "name": "Padaivedu",
        "value": "Padaivedu"
    },
    {
        "name": "Padakanti",
        "value": "Padakanti"
    },
    {
        "name": "Padappakara",
        "value": "Padappakara"
    },
    {
        "name": "Padarti",
        "value": "Padarti"
    },
    {
        "name": "Padavedu",
        "value": "Padavedu"
    },
    {
        "name": "Paddhari",
        "value": "Paddhari"
    },
    {
        "name": "Padiala",
        "value": "Padiala"
    },
    {
        "name": "Padinjarebagam",
        "value": "Padinjarebagam"
    },
    {
        "name": "Padinjaremuri",
        "value": "Padinjaremuri"
    },
    {
        "name": "Padiyanallur",
        "value": "Padiyanallur"
    },
    {
        "name": "Padiyur",
        "value": "Padiyur"
    },
    {
        "name": "Padmanabhapuram",
        "value": "Padmanabhapuram"
    },
    {
        "name": "Padra",
        "value": "Padra"
    },
    {
        "name": "Padrauna",
        "value": "Padrauna"
    },
    {
        "name": "Padugaipattu",
        "value": "Padugaipattu"
    },
    {
        "name": "Padugupadu",
        "value": "Padugupadu"
    },
    {
        "name": "Paduma",
        "value": "Paduma"
    },
    {
        "name": "Paduvari",
        "value": "Paduvari"
    },
    {
        "name": "Pagidyala",
        "value": "Pagidyala"
    },
    {
        "name": "Paharpur",
        "value": "Paharpur"
    },
    {
        "name": "Pahsara",
        "value": "Pahsara"
    },
    {
        "name": "Pai Bigha",
        "value": "Pai Bigha"
    },
    {
        "name": "Paidiipalli",
        "value": "Paidiipalli"
    },
    {
        "name": "Paiganapalli",
        "value": "Paiganapalli"
    },
    {
        "name": "Paiker",
        "value": "Paiker"
    },
    {
        "name": "Paikpar",
        "value": "Paikpar"
    },
    {
        "name": "Paikpara",
        "value": "Paikpara"
    },
    {
        "name": "Paina",
        "value": "Paina"
    },
    {
        "name": "Painal",
        "value": "Painal"
    },
    {
        "name": "Painkulam",
        "value": "Painkulam"
    },
    {
        "name": "Paithan",
        "value": "Paithan"
    },
    {
        "name": "Paithan Kawai",
        "value": "Paithan Kawai"
    },
    {
        "name": "Paittur",
        "value": "Paittur"
    },
    {
        "name": "Paivalike",
        "value": "Paivalike"
    },
    {
        "name": "Pakala",
        "value": "Pakala"
    },
    {
        "name": "Pakarya Harsidhi",
        "value": "Pakarya Harsidhi"
    },
    {
        "name": "Pakaur",
        "value": "Pakaur"
    },
    {
        "name": "Pakka Kalan",
        "value": "Pakka Kalan"
    },
    {
        "name": "Pakkam",
        "value": "Pakkam"
    },
    {
        "name": "Pakra",
        "value": "Pakra"
    },
    {
        "name": "Pakri",
        "value": "Pakri"
    },
    {
        "name": "Pakribarawan",
        "value": "Pakribarawan"
    },
    {
        "name": "Palaiya Ayakkudi",
        "value": "Palaiya Ayakkudi"
    },
    {
        "name": "Palaiyam",
        "value": "Palaiyam"
    },
    {
        "name": "Palaiyampatti",
        "value": "Palaiyampatti"
    },
    {
        "name": "Palakkuzhi",
        "value": "Palakkuzhi"
    },
    {
        "name": "Palakodu",
        "value": "Palakodu"
    },
    {
        "name": "Palakollu",
        "value": "Palakollu"
    },
    {
        "name": "Palamedu",
        "value": "Palamedu"
    },
    {
        "name": "Palamel",
        "value": "Palamel"
    },
    {
        "name": "Palampur",
        "value": "Palampur"
    },
    {
        "name": "Palangarai",
        "value": "Palangarai"
    },
    {
        "name": "Palangavangudi",
        "value": "Palangavangudi"
    },
    {
        "name": "Palangotu Adwar",
        "value": "Palangotu Adwar"
    },
    {
        "name": "Palanisettipatti",
        "value": "Palanisettipatti"
    },
    {
        "name": "Palankottai",
        "value": "Palankottai"
    },
    {
        "name": "Palanpur",
        "value": "Palanpur"
    },
    {
        "name": "Palasa",
        "value": "Palasa"
    },
    {
        "name": "Palashi",
        "value": "Palashi"
    },
    {
        "name": "Palavur",
        "value": "Palavur"
    },
    {
        "name": "Palayad",
        "value": "Palayad"
    },
    {
        "name": "Palda",
        "value": "Palda"
    },
    {
        "name": "Palepalli",
        "value": "Palepalli"
    },
    {
        "name": "Palera",
        "value": "Palera"
    },
    {
        "name": "Palghar",
        "value": "Palghar"
    },
    {
        "name": "Palghat",
        "value": "Palghat"
    },
    {
        "name": "Palhalan",
        "value": "Palhalan"
    },
    {
        "name": "Pali",
        "value": "Pali"
    },
    {
        "name": "Palia Kalan",
        "value": "Palia Kalan"
    },
    {
        "name": "Paliaturutu",
        "value": "Paliaturutu"
    },
    {
        "name": "Palitana",
        "value": "Palitana"
    },
    {
        "name": "Palkonda",
        "value": "Palkonda"
    },
    {
        "name": "Palkot",
        "value": "Palkot"
    },
    {
        "name": "Palkur",
        "value": "Palkur"
    },
    {
        "name": "Palladam",
        "value": "Palladam"
    },
    {
        "name": "Pallapatti",
        "value": "Pallapatti"
    },
    {
        "name": "Pallappalaiyam",
        "value": "Pallappalaiyam"
    },
    {
        "name": "Pallappatti",
        "value": "Pallappatti"
    },
    {
        "name": "Pallarimangalam",
        "value": "Pallarimangalam"
    },
    {
        "name": "Pallattur",
        "value": "Pallattur"
    },
    {
        "name": "Pallavaram",
        "value": "Pallavaram"
    },
    {
        "name": "Pallijkarani",
        "value": "Pallijkarani"
    },
    {
        "name": "Pallikapuzha",
        "value": "Pallikapuzha"
    },
    {
        "name": "Pallikondai",
        "value": "Pallikondai"
    },
    {
        "name": "Palliman",
        "value": "Palliman"
    },
    {
        "name": "Pallipalaiyam",
        "value": "Pallipalaiyam"
    },
    {
        "name": "Pallipattu",
        "value": "Pallipattu"
    },
    {
        "name": "Pallippatti",
        "value": "Pallippatti"
    },
    {
        "name": "Pallippuram",
        "value": "Pallippuram"
    },
    {
        "name": "Pallippurattuseri",
        "value": "Pallippurattuseri"
    },
    {
        "name": "Pallipram",
        "value": "Pallipram"
    },
    {
        "name": "Palmaner",
        "value": "Palmaner"
    },
    {
        "name": "Palni",
        "value": "Palni"
    },
    {
        "name": "Palod",
        "value": "Palod"
    },
    {
        "name": "Paloncha",
        "value": "Paloncha"
    },
    {
        "name": "Palsud",
        "value": "Palsud"
    },
    {
        "name": "Palukudoddi",
        "value": "Palukudoddi"
    },
    {
        "name": "Palwal",
        "value": "Palwal"
    },
    {
        "name": "Pama",
        "value": "Pama"
    },
    {
        "name": "Pamarru",
        "value": "Pamarru"
    },
    {
        "name": "Pambadi",
        "value": "Pambadi"
    },
    {
        "name": "Pamban",
        "value": "Pamban"
    },
    {
        "name": "Pamidi",
        "value": "Pamidi"
    },
    {
        "name": "Pampur",
        "value": "Pampur"
    },
    {
        "name": "Pamuru",
        "value": "Pamuru"
    },
    {
        "name": "Panagar",
        "value": "Panagar"
    },
    {
        "name": "Panaji",
        "value": "Panaji"
    },
    {
        "name": "Panakkudi",
        "value": "Panakkudi"
    },
    {
        "name": "Panamaram",
        "value": "Panamaram"
    },
    {
        "name": "Panamarattuppatti",
        "value": "Panamarattuppatti"
    },
    {
        "name": "Panapakkam",
        "value": "Panapakkam"
    },
    {
        "name": "Panapur",
        "value": "Panapur"
    },
    {
        "name": "Panapur Langa",
        "value": "Panapur Langa"
    },
    {
        "name": "Panasapadu",
        "value": "Panasapadu"
    },
    {
        "name": "Panchanandapur",
        "value": "Panchanandapur"
    },
    {
        "name": "Panchgani",
        "value": "Panchgani"
    },
    {
        "name": "Panchgram",
        "value": "Panchgram"
    },
    {
        "name": "Panchi",
        "value": "Panchi"
    },
    {
        "name": "Panchkula",
        "value": "Panchkula"
    },
    {
        "name": "Panchla",
        "value": "Panchla"
    },
    {
        "name": "Pandalkudi",
        "value": "Pandalkudi"
    },
    {
        "name": "Pandaul",
        "value": "Pandaul"
    },
    {
        "name": "Pandhana",
        "value": "Pandhana"
    },
    {
        "name": "Pandharpur",
        "value": "Pandharpur"
    },
    {
        "name": "Pandhurna",
        "value": "Pandhurna"
    },
    {
        "name": "Pandireddigudem",
        "value": "Pandireddigudem"
    },
    {
        "name": "Pandua",
        "value": "Pandua"
    },
    {
        "name": "Panetha",
        "value": "Panetha"
    },
    {
        "name": "Pangunattam",
        "value": "Pangunattam"
    },
    {
        "name": "Pangzawl",
        "value": "Pangzawl"
    },
    {
        "name": "Panhar",
        "value": "Panhar"
    },
    {
        "name": "Paniem",
        "value": "Paniem"
    },
    {
        "name": "Panihati",
        "value": "Panihati"
    },
    {
        "name": "Panipat",
        "value": "Panipat"
    },
    {
        "name": "Panjampatti",
        "value": "Panjampatti"
    },
    {
        "name": "Panjgirain Kalan",
        "value": "Panjgirain Kalan"
    },
    {
        "name": "Panjipara",
        "value": "Panjipara"
    },
    {
        "name": "Panmana",
        "value": "Panmana"
    },
    {
        "name": "Panna",
        "value": "Panna"
    },
    {
        "name": "Pannaikkadu",
        "value": "Pannaikkadu"
    },
    {
        "name": "Pannaipuram",
        "value": "Pannaipuram"
    },
    {
        "name": "Pannimadai",
        "value": "Pannimadai"
    },
    {
        "name": "Panniperumthalai",
        "value": "Panniperumthalai"
    },
    {
        "name": "Panniyannur",
        "value": "Panniyannur"
    },
    {
        "name": "Panpuli",
        "value": "Panpuli"
    },
    {
        "name": "Panr",
        "value": "Panr"
    },
    {
        "name": "Panrepatti",
        "value": "Panrepatti"
    },
    {
        "name": "Panruti",
        "value": "Panruti"
    },
    {
        "name": "Pansemal",
        "value": "Pansemal"
    },
    {
        "name": "Panskura",
        "value": "Panskura"
    },
    {
        "name": "Pantnagar",
        "value": "Pantnagar"
    },
    {
        "name": "Panvel",
        "value": "Panvel"
    },
    {
        "name": "Panzgam",
        "value": "Panzgam"
    },
    {
        "name": "Paonta Sahib",
        "value": "Paonta Sahib"
    },
    {
        "name": "Papampeta",
        "value": "Papampeta"
    },
    {
        "name": "Papanasam",
        "value": "Papanasam"
    },
    {
        "name": "Papireddippatti",
        "value": "Papireddippatti"
    },
    {
        "name": "Pappakudi",
        "value": "Pappakudi"
    },
    {
        "name": "Pappakurichchi",
        "value": "Pappakurichchi"
    },
    {
        "name": "Pappampatti",
        "value": "Pappampatti"
    },
    {
        "name": "Pappinissheri",
        "value": "Pappinissheri"
    },
    {
        "name": "Papraur",
        "value": "Papraur"
    },
    {
        "name": "Para",
        "value": "Para"
    },
    {
        "name": "Paradarami",
        "value": "Paradarami"
    },
    {
        "name": "Paradip Garh",
        "value": "Paradip Garh"
    },
    {
        "name": "Paragaticherla",
        "value": "Paragaticherla"
    },
    {
        "name": "Paraippatti",
        "value": "Paraippatti"
    },
    {
        "name": "Parakadavu",
        "value": "Parakadavu"
    },
    {
        "name": "Parali",
        "value": "Parali"
    },
    {
        "name": "Paramagudi",
        "value": "Paramagudi"
    },
    {
        "name": "Paramanandal",
        "value": "Paramanandal"
    },
    {
        "name": "Paramankurichi",
        "value": "Paramankurichi"
    },
    {
        "name": "Paramati",
        "value": "Paramati"
    },
    {
        "name": "Parambatt Kavu",
        "value": "Parambatt Kavu"
    },
    {
        "name": "Parambil",
        "value": "Parambil"
    },
    {
        "name": "Parapatti",
        "value": "Parapatti"
    },
    {
        "name": "Parappanangadi",
        "value": "Parappanangadi"
    },
    {
        "name": "Parappukara",
        "value": "Parappukara"
    },
    {
        "name": "Parappur",
        "value": "Parappur"
    },
    {
        "name": "Pararia",
        "value": "Pararia"
    },
    {
        "name": "Parasbani",
        "value": "Parasbani"
    },
    {
        "name": "Parasi",
        "value": "Parasi"
    },
    {
        "name": "Parasia",
        "value": "Parasia"
    },
    {
        "name": "Parasurampur",
        "value": "Parasurampur"
    },
    {
        "name": "Paratdiha",
        "value": "Paratdiha"
    },
    {
        "name": "Parauli",
        "value": "Parauli"
    },
    {
        "name": "Paravada",
        "value": "Paravada"
    },
    {
        "name": "Paravai",
        "value": "Paravai"
    },
    {
        "name": "Paravakkottai",
        "value": "Paravakkottai"
    },
    {
        "name": "Paravur Tekkumbhagam",
        "value": "Paravur Tekkumbhagam"
    },
    {
        "name": "Paravurkambolam",
        "value": "Paravurkambolam"
    },
    {
        "name": "Parbata",
        "value": "Parbata"
    },
    {
        "name": "Parbhani",
        "value": "Parbhani"
    },
    {
        "name": "Pardi",
        "value": "Pardi"
    },
    {
        "name": "Pardiguda",
        "value": "Pardiguda"
    },
    {
        "name": "Pardwal",
        "value": "Pardwal"
    },
    {
        "name": "Pareo",
        "value": "Pareo"
    },
    {
        "name": "Pargi",
        "value": "Pargi"
    },
    {
        "name": "Parigi",
        "value": "Parigi"
    },
    {
        "name": "Parihara",
        "value": "Parihara"
    },
    {
        "name": "Pariharpur",
        "value": "Pariharpur"
    },
    {
        "name": "Parimpudi",
        "value": "Parimpudi"
    },
    {
        "name": "Pariyapuram",
        "value": "Pariyapuram"
    },
    {
        "name": "Pariyaram",
        "value": "Pariyaram"
    },
    {
        "name": "Pariyari",
        "value": "Pariyari"
    },
    {
        "name": "Parkal",
        "value": "Parkal"
    },
    {
        "name": "Parmanpur",
        "value": "Parmanpur"
    },
    {
        "name": "Parner",
        "value": "Parner"
    },
    {
        "name": "Parnera",
        "value": "Parnera"
    },
    {
        "name": "Parol",
        "value": "Parol"
    },
    {
        "name": "Parora",
        "value": "Parora"
    },
    {
        "name": "Parsa",
        "value": "Parsa"
    },
    {
        "name": "Parsad",
        "value": "Parsad"
    },
    {
        "name": "Parsagarhi",
        "value": "Parsagarhi"
    },
    {
        "name": "Parsahi Sirsia",
        "value": "Parsahi Sirsia"
    },
    {
        "name": "Partap Tanr",
        "value": "Partap Tanr"
    },
    {
        "name": "Partapnagar",
        "value": "Partapnagar"
    },
    {
        "name": "Partibanur",
        "value": "Partibanur"
    },
    {
        "name": "Partur",
        "value": "Partur"
    },
    {
        "name": "Paruchuru",
        "value": "Paruchuru"
    },
    {
        "name": "Parur",
        "value": "Parur"
    },
    {
        "name": "Paruthur",
        "value": "Paruthur"
    },
    {
        "name": "Parvatgiri",
        "value": "Parvatgiri"
    },
    {
        "name": "Parvatipuram",
        "value": "Parvatipuram"
    },
    {
        "name": "Parwaha",
        "value": "Parwaha"
    },
    {
        "name": "Pasaul",
        "value": "Pasaul"
    },
    {
        "name": "Pasighat",
        "value": "Pasighat"
    },
    {
        "name": "Pasivedalajimma",
        "value": "Pasivedalajimma"
    },
    {
        "name": "Pasraha",
        "value": "Pasraha"
    },
    {
        "name": "Pastpar",
        "value": "Pastpar"
    },
    {
        "name": "Pasupatikovil",
        "value": "Pasupatikovil"
    },
    {
        "name": "Pata Ellamilli",
        "value": "Pata Ellamilli"
    },
    {
        "name": "Pata Kalidindi",
        "value": "Pata Kalidindi"
    },
    {
        "name": "Pata Putrela",
        "value": "Pata Putrela"
    },
    {
        "name": "Pata Uppal",
        "value": "Pata Uppal"
    },
    {
        "name": "Pataili",
        "value": "Pataili"
    },
    {
        "name": "Patakakani",
        "value": "Patakakani"
    },
    {
        "name": "Patalia",
        "value": "Patalia"
    },
    {
        "name": "Patamundai",
        "value": "Patamundai"
    },
    {
        "name": "Patan",
        "value": "Patan"
    },
    {
        "name": "Patancheruvu",
        "value": "Patancheruvu"
    },
    {
        "name": "Patapatnam",
        "value": "Patapatnam"
    },
    {
        "name": "Patchur",
        "value": "Patchur"
    },
    {
        "name": "Patera",
        "value": "Patera"
    },
    {
        "name": "Pathanamthitta",
        "value": "Pathanamthitta"
    },
    {
        "name": "Pathankot",
        "value": "Pathankot"
    },
    {
        "name": "Pathardi",
        "value": "Pathardi"
    },
    {
        "name": "Pathari",
        "value": "Pathari"
    },
    {
        "name": "Patharia",
        "value": "Patharia"
    },
    {
        "name": "Pathiyanikunnu",
        "value": "Pathiyanikunnu"
    },
    {
        "name": "Pathra",
        "value": "Pathra"
    },
    {
        "name": "Pathraha",
        "value": "Pathraha"
    },
    {
        "name": "Pathrajolhania",
        "value": "Pathrajolhania"
    },
    {
        "name": "Pathri",
        "value": "Pathri"
    },
    {
        "name": "Patiala",
        "value": "Patiala"
    },
    {
        "name": "Patiali",
        "value": "Patiali"
    },
    {
        "name": "Patilar",
        "value": "Patilar"
    },
    {
        "name": "Patiram",
        "value": "Patiram"
    },
    {
        "name": "Patjirwa",
        "value": "Patjirwa"
    },
    {
        "name": "Patna",
        "value": "Patna"
    },
    {
        "name": "Patnagarh",
        "value": "Patnagarh"
    },
    {
        "name": "Patori",
        "value": "Patori"
    },
    {
        "name": "Patpara",
        "value": "Patpara"
    },
    {
        "name": "Patrasaer",
        "value": "Patrasaer"
    },
    {
        "name": "Patratu",
        "value": "Patratu"
    },
    {
        "name": "Patri",
        "value": "Patri"
    },
    {
        "name": "Patsanda",
        "value": "Patsanda"
    },
    {
        "name": "Pattamadai",
        "value": "Pattamadai"
    },
    {
        "name": "Pattambi",
        "value": "Pattambi"
    },
    {
        "name": "Pattanakkad",
        "value": "Pattanakkad"
    },
    {
        "name": "Pattanam",
        "value": "Pattanam"
    },
    {
        "name": "Pattanapuram",
        "value": "Pattanapuram"
    },
    {
        "name": "Patti",
        "value": "Patti"
    },
    {
        "name": "Pattikonda",
        "value": "Pattikonda"
    },
    {
        "name": "Pattiswaram",
        "value": "Pattiswaram"
    },
    {
        "name": "Pattittara",
        "value": "Pattittara"
    },
    {
        "name": "Pattiyurgramam",
        "value": "Pattiyurgramam"
    },
    {
        "name": "Pattukkottai",
        "value": "Pattukkottai"
    },
    {
        "name": "Patut",
        "value": "Patut"
    },
    {
        "name": "Patuvilayi",
        "value": "Patuvilayi"
    },
    {
        "name": "Paura Madan Singh",
        "value": "Paura Madan Singh"
    },
    {
        "name": "Pauri",
        "value": "Pauri"
    },
    {
        "name": "Pavannur",
        "value": "Pavannur"
    },
    {
        "name": "Pavittiram",
        "value": "Pavittiram"
    },
    {
        "name": "Pavugada",
        "value": "Pavugada"
    },
    {
        "name": "Pavumba",
        "value": "Pavumba"
    },
    {
        "name": "Pawai",
        "value": "Pawai"
    },
    {
        "name": "Pawayan",
        "value": "Pawayan"
    },
    {
        "name": "Pawni",
        "value": "Pawni"
    },
    {
        "name": "Payakaraopeta",
        "value": "Payakaraopeta"
    },
    {
        "name": "Payimattam",
        "value": "Payimattam"
    },
    {
        "name": "Payipira",
        "value": "Payipira"
    },
    {
        "name": "Payyanadam",
        "value": "Payyanadam"
    },
    {
        "name": "Payyannur",
        "value": "Payyannur"
    },
    {
        "name": "Payyanpalli",
        "value": "Payyanpalli"
    },
    {
        "name": "Payyoli",
        "value": "Payyoli"
    },
    {
        "name": "Pazhanji",
        "value": "Pazhanji"
    },
    {
        "name": "Pazhayannur",
        "value": "Pazhayannur"
    },
    {
        "name": "Pebberu",
        "value": "Pebberu"
    },
    {
        "name": "Pedagadi",
        "value": "Pedagadi"
    },
    {
        "name": "Pedana",
        "value": "Pedana"
    },
    {
        "name": "Pedappai",
        "value": "Pedappai"
    },
    {
        "name": "Pedda Adsarlapalli",
        "value": "Pedda Adsarlapalli"
    },
    {
        "name": "Pedda Kotayalanka",
        "value": "Pedda Kotayalanka"
    },
    {
        "name": "Pedda Mupparam",
        "value": "Pedda Mupparam"
    },
    {
        "name": "Pedda Nindrakolanu",
        "value": "Pedda Nindrakolanu"
    },
    {
        "name": "Pedda Pendyala",
        "value": "Pedda Pendyala"
    },
    {
        "name": "Pedda Penki",
        "value": "Pedda Penki"
    },
    {
        "name": "Pedda Tumbalam",
        "value": "Pedda Tumbalam"
    },
    {
        "name": "Pedda Vegi",
        "value": "Pedda Vegi"
    },
    {
        "name": "Peddaboddepalle",
        "value": "Peddaboddepalle"
    },
    {
        "name": "Peddakurapadu",
        "value": "Peddakurapadu"
    },
    {
        "name": "Peddannavaripalle",
        "value": "Peddannavaripalle"
    },
    {
        "name": "Peddapalle",
        "value": "Peddapalle"
    },
    {
        "name": "Peddapalli",
        "value": "Peddapalli"
    },
    {
        "name": "Peddapuram",
        "value": "Peddapuram"
    },
    {
        "name": "Peddavadlapudi",
        "value": "Peddavadlapudi"
    },
    {
        "name": "Pehowa",
        "value": "Pehowa"
    },
    {
        "name": "Pelagor",
        "value": "Pelagor"
    },
    {
        "name": "Pen",
        "value": "Pen"
    },
    {
        "name": "Penaballi",
        "value": "Penaballi"
    },
    {
        "name": "Penagam",
        "value": "Penagam"
    },
    {
        "name": "Penamaluru",
        "value": "Penamaluru"
    },
    {
        "name": "Pendekallu",
        "value": "Pendekallu"
    },
    {
        "name": "Pendurti",
        "value": "Pendurti"
    },
    {
        "name": "Pennadam",
        "value": "Pennadam"
    },
    {
        "name": "Pennagaram",
        "value": "Pennagaram"
    },
    {
        "name": "Pentapadu Kasba",
        "value": "Pentapadu Kasba"
    },
    {
        "name": "Penuganchiprolu",
        "value": "Penuganchiprolu"
    },
    {
        "name": "Penugolanu",
        "value": "Penugolanu"
    },
    {
        "name": "Penugonda",
        "value": "Penugonda"
    },
    {
        "name": "Penukonda",
        "value": "Penukonda"
    },
    {
        "name": "Penumur",
        "value": "Penumur"
    },
    {
        "name": "Peragamanna",
        "value": "Peragamanna"
    },
    {
        "name": "Peraiyur",
        "value": "Peraiyur"
    },
    {
        "name": "Perali",
        "value": "Perali"
    },
    {
        "name": "Perambalur",
        "value": "Perambalur"
    },
    {
        "name": "Perampuzha",
        "value": "Perampuzha"
    },
    {
        "name": "Peranampattu",
        "value": "Peranampattu"
    },
    {
        "name": "Peravali",
        "value": "Peravali"
    },
    {
        "name": "Peravur",
        "value": "Peravur"
    },
    {
        "name": "Peravurani",
        "value": "Peravurani"
    },
    {
        "name": "Perdur",
        "value": "Perdur"
    },
    {
        "name": "Peringalam",
        "value": "Peringalam"
    },
    {
        "name": "Peringanad",
        "value": "Peringanad"
    },
    {
        "name": "Peringom",
        "value": "Peringom"
    },
    {
        "name": "Peringottukurusshi",
        "value": "Peringottukurusshi"
    },
    {
        "name": "Peringuzha",
        "value": "Peringuzha"
    },
    {
        "name": "Perintalmanna",
        "value": "Perintalmanna"
    },
    {
        "name": "Periya Pattanam",
        "value": "Periya Pattanam"
    },
    {
        "name": "Periya Semur",
        "value": "Periya Semur"
    },
    {
        "name": "Periya Soragai",
        "value": "Periya Soragai"
    },
    {
        "name": "Periyakoduveri",
        "value": "Periyakoduveri"
    },
    {
        "name": "Periyakulam",
        "value": "Periyakulam"
    },
    {
        "name": "Periyamuttur",
        "value": "Periyamuttur"
    },
    {
        "name": "Periyanayakkanpalaiyam",
        "value": "Periyanayakkanpalaiyam"
    },
    {
        "name": "Periyanegamam",
        "value": "Periyanegamam"
    },
    {
        "name": "Periyapodu",
        "value": "Periyapodu"
    },
    {
        "name": "Periyapuliyur",
        "value": "Periyapuliyur"
    },
    {
        "name": "Perket",
        "value": "Perket"
    },
    {
        "name": "Perladka",
        "value": "Perladka"
    },
    {
        "name": "Pernamitta",
        "value": "Pernamitta"
    },
    {
        "name": "Perumbakkam",
        "value": "Perumbakkam"
    },
    {
        "name": "Perumbalai",
        "value": "Perumbalai"
    },
    {
        "name": "Perumbalam",
        "value": "Perumbalam"
    },
    {
        "name": "Perumkulam",
        "value": "Perumkulam"
    },
    {
        "name": "Perumuchchi",
        "value": "Perumuchchi"
    },
    {
        "name": "Perunad",
        "value": "Perunad"
    },
    {
        "name": "Perundurai",
        "value": "Perundurai"
    },
    {
        "name": "Perungala",
        "value": "Perungala"
    },
    {
        "name": "Perungudi",
        "value": "Perungudi"
    },
    {
        "name": "Perungulam",
        "value": "Perungulam"
    },
    {
        "name": "Perunkalattu",
        "value": "Perunkalattu"
    },
    {
        "name": "Perunkolattur",
        "value": "Perunkolattur"
    },
    {
        "name": "Perunturuttu",
        "value": "Perunturuttu"
    },
    {
        "name": "Perupalem",
        "value": "Perupalem"
    },
    {
        "name": "Perur",
        "value": "Perur"
    },
    {
        "name": "Peruru",
        "value": "Peruru"
    },
    {
        "name": "Peruvancha",
        "value": "Peruvancha"
    },
    {
        "name": "Peruvanthanam",
        "value": "Peruvanthanam"
    },
    {
        "name": "Petlad",
        "value": "Petlad"
    },
    {
        "name": "Petlawad",
        "value": "Petlawad"
    },
    {
        "name": "Petmanhalli",
        "value": "Petmanhalli"
    },
    {
        "name": "Pettaivayttalai",
        "value": "Pettaivayttalai"
    },
    {
        "name": "Pettampalaiyam",
        "value": "Pettampalaiyam"
    },
    {
        "name": "Petua",
        "value": "Petua"
    },
    {
        "name": "Phagu",
        "value": "Phagu"
    },
    {
        "name": "Phagwara",
        "value": "Phagwara"
    },
    {
        "name": "Phalodi",
        "value": "Phalodi"
    },
    {
        "name": "Phaltan",
        "value": "Phaltan"
    },
    {
        "name": "Phaphot",
        "value": "Phaphot"
    },
    {
        "name": "Pharaha",
        "value": "Pharaha"
    },
    {
        "name": "Pharkia",
        "value": "Pharkia"
    },
    {
        "name": "Phek",
        "value": "Phek"
    },
    {
        "name": "Phillaur",
        "value": "Phillaur"
    },
    {
        "name": "Phirangipuram",
        "value": "Phirangipuram"
    },
    {
        "name": "Phopnar Kalan",
        "value": "Phopnar Kalan"
    },
    {
        "name": "Phulaut",
        "value": "Phulaut"
    },
    {
        "name": "Phulbani",
        "value": "Phulbani"
    },
    {
        "name": "Phulera",
        "value": "Phulera"
    },
    {
        "name": "Phulgachhi",
        "value": "Phulgachhi"
    },
    {
        "name": "Phulhara",
        "value": "Phulhara"
    },
    {
        "name": "Phulmalik",
        "value": "Phulmalik"
    },
    {
        "name": "Phulparas",
        "value": "Phulparas"
    },
    {
        "name": "Phulpur",
        "value": "Phulpur"
    },
    {
        "name": "Phulwar",
        "value": "Phulwar"
    },
    {
        "name": "Phulwaria",
        "value": "Phulwaria"
    },
    {
        "name": "Phusro",
        "value": "Phusro"
    },
    {
        "name": "Pichhor",
        "value": "Pichhor"
    },
    {
        "name": "Pichor",
        "value": "Pichor"
    },
    {
        "name": "Piduguralla",
        "value": "Piduguralla"
    },
    {
        "name": "Pihani",
        "value": "Pihani"
    },
    {
        "name": "Pihra",
        "value": "Pihra"
    },
    {
        "name": "Pilachikare",
        "value": "Pilachikare"
    },
    {
        "name": "Pilani",
        "value": "Pilani"
    },
    {
        "name": "Pilappulli",
        "value": "Pilappulli"
    },
    {
        "name": "Pileru",
        "value": "Pileru"
    },
    {
        "name": "Pilibangan",
        "value": "Pilibangan"
    },
    {
        "name": "Pilibhit",
        "value": "Pilibhit"
    },
    {
        "name": "Pilich",
        "value": "Pilich"
    },
    {
        "name": "Pilikodu",
        "value": "Pilikodu"
    },
    {
        "name": "Piliv",
        "value": "Piliv"
    },
    {
        "name": "Pilkha",
        "value": "Pilkha"
    },
    {
        "name": "Pilkhua",
        "value": "Pilkhua"
    },
    {
        "name": "Pillaiyarkuppam",
        "value": "Pillaiyarkuppam"
    },
    {
        "name": "Pillanallur",
        "value": "Pillanallur"
    },
    {
        "name": "Pillutla",
        "value": "Pillutla"
    },
    {
        "name": "Pimpalgaon Raja",
        "value": "Pimpalgaon Raja"
    },
    {
        "name": "Pimpri-Chinchwad",
        "value": "Pimpri-Chinchwad"
    },
    {
        "name": "Pindra",
        "value": "Pindra"
    },
    {
        "name": "Pindwara",
        "value": "Pindwara"
    },
    {
        "name": "Pinjranwan",
        "value": "Pinjranwan"
    },
    {
        "name": "Pinneli",
        "value": "Pinneli"
    },
    {
        "name": "Pipalrawan",
        "value": "Pipalrawan"
    },
    {
        "name": "Piparia",
        "value": "Piparia"
    },
    {
        "name": "Pipariya",
        "value": "Pipariya"
    },
    {
        "name": "Piploda",
        "value": "Piploda"
    },
    {
        "name": "Pipra",
        "value": "Pipra"
    },
    {
        "name": "Pipra Dewas",
        "value": "Pipra Dewas"
    },
    {
        "name": "Pipra Latif",
        "value": "Pipra Latif"
    },
    {
        "name": "Pipra Naurangia",
        "value": "Pipra Naurangia"
    },
    {
        "name": "Piprahi",
        "value": "Piprahi"
    },
    {
        "name": "Piprai",
        "value": "Piprai"
    },
    {
        "name": "Pipraun",
        "value": "Pipraun"
    },
    {
        "name": "Pir Maker",
        "value": "Pir Maker"
    },
    {
        "name": "Pirakkad",
        "value": "Pirakkad"
    },
    {
        "name": "Pirangut",
        "value": "Pirangut"
    },
    {
        "name": "Piravanthur",
        "value": "Piravanthur"
    },
    {
        "name": "Pirbahora",
        "value": "Pirbahora"
    },
    {
        "name": "Piriyapatna",
        "value": "Piriyapatna"
    },
    {
        "name": "Pirmed",
        "value": "Pirmed"
    },
    {
        "name": "Pirnagar",
        "value": "Pirnagar"
    },
    {
        "name": "Piro",
        "value": "Piro"
    },
    {
        "name": "Pirojgarh",
        "value": "Pirojgarh"
    },
    {
        "name": "Pirthipur",
        "value": "Pirthipur"
    },
    {
        "name": "Piru",
        "value": "Piru"
    },
    {
        "name": "Pithampur",
        "value": "Pithampur"
    },
    {
        "name": "Pithapuram",
        "value": "Pithapuram"
    },
    {
        "name": "Pithaura",
        "value": "Pithaura"
    },
    {
        "name": "Pithoragarh",
        "value": "Pithoragarh"
    },
    {
        "name": "Pitlam",
        "value": "Pitlam"
    },
    {
        "name": "Pittalavanipalem",
        "value": "Pittalavanipalem"
    },
    {
        "name": "Placheri",
        "value": "Placheri"
    },
    {
        "name": "Pochampalli",
        "value": "Pochampalli"
    },
    {
        "name": "Pocharam",
        "value": "Pocharam"
    },
    {
        "name": "Podalakur",
        "value": "Podalakur"
    },
    {
        "name": "Podaturpeta",
        "value": "Podaturpeta"
    },
    {
        "name": "Podili",
        "value": "Podili"
    },
    {
        "name": "Pohadi",
        "value": "Pohadi"
    },
    {
        "name": "Pokaran",
        "value": "Pokaran"
    },
    {
        "name": "Pokhraira",
        "value": "Pokhraira"
    },
    {
        "name": "Pokhram",
        "value": "Pokhram"
    },
    {
        "name": "Pokhuria",
        "value": "Pokhuria"
    },
    {
        "name": "Polaia Kalan",
        "value": "Polaia Kalan"
    },
    {
        "name": "Polakala",
        "value": "Polakala"
    },
    {
        "name": "Polas",
        "value": "Polas"
    },
    {
        "name": "Polasara",
        "value": "Polasara"
    },
    {
        "name": "Polavaram",
        "value": "Polavaram"
    },
    {
        "name": "Pollachi",
        "value": "Pollachi"
    },
    {
        "name": "Polukallu",
        "value": "Polukallu"
    },
    {
        "name": "Polur",
        "value": "Polur"
    },
    {
        "name": "Ponda",
        "value": "Ponda"
    },
    {
        "name": "Pondaluru",
        "value": "Pondaluru"
    },
    {
        "name": "Ponduru",
        "value": "Ponduru"
    },
    {
        "name": "Pongalur",
        "value": "Pongalur"
    },
    {
        "name": "Pongode",
        "value": "Pongode"
    },
    {
        "name": "Poninguinim",
        "value": "Poninguinim"
    },
    {
        "name": "Ponmala",
        "value": "Ponmala"
    },
    {
        "name": "Ponmana",
        "value": "Ponmana"
    },
    {
        "name": "Ponmundam",
        "value": "Ponmundam"
    },
    {
        "name": "Ponnada",
        "value": "Ponnada"
    },
    {
        "name": "Ponnai",
        "value": "Ponnai"
    },
    {
        "name": "Ponnamaravati",
        "value": "Ponnamaravati"
    },
    {
        "name": "Ponnampatti",
        "value": "Ponnampatti"
    },
    {
        "name": "Ponnani",
        "value": "Ponnani"
    },
    {
        "name": "Ponneri",
        "value": "Ponneri"
    },
    {
        "name": "Ponnuru",
        "value": "Ponnuru"
    },
    {
        "name": "Poonamallee",
        "value": "Poonamallee"
    },
    {
        "name": "Poranki",
        "value": "Poranki"
    },
    {
        "name": "Porbandar",
        "value": "Porbandar"
    },
    {
        "name": "Porsa",
        "value": "Porsa"
    },
    {
        "name": "Port Blair",
        "value": "Port Blair"
    },
    {
        "name": "Porumamilla",
        "value": "Porumamilla"
    },
    {
        "name": "Porur",
        "value": "Porur"
    },
    {
        "name": "Poruvakara",
        "value": "Poruvakara"
    },
    {
        "name": "Potangal",
        "value": "Potangal"
    },
    {
        "name": "Potavaram",
        "value": "Potavaram"
    },
    {
        "name": "Pothia",
        "value": "Pothia"
    },
    {
        "name": "Pottanikad",
        "value": "Pottanikad"
    },
    {
        "name": "Pottanur",
        "value": "Pottanur"
    },
    {
        "name": "Pottassheri",
        "value": "Pottassheri"
    },
    {
        "name": "Pottipuram",
        "value": "Pottipuram"
    },
    {
        "name": "Pottireddippatti",
        "value": "Pottireddippatti"
    },
    {
        "name": "Potukonda",
        "value": "Potukonda"
    },
    {
        "name": "Potunuru",
        "value": "Potunuru"
    },
    {
        "name": "Prabhat Pattan",
        "value": "Prabhat Pattan"
    },
    {
        "name": "Pragadavaram",
        "value": "Pragadavaram"
    },
    {
        "name": "Pramatam",
        "value": "Pramatam"
    },
    {
        "name": "Prantij",
        "value": "Prantij"
    },
    {
        "name": "Prataparampuram",
        "value": "Prataparampuram"
    },
    {
        "name": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "name": "Prattipadu",
        "value": "Prattipadu"
    },
    {
        "name": "Prayagraj",
        "value": "Prayagraj"
    },
    {
        "name": "Premnagar",
        "value": "Premnagar"
    },
    {
        "name": "Proddatur",
        "value": "Proddatur"
    },
    {
        "name": "Pudimadaka",
        "value": "Pudimadaka"
    },
    {
        "name": "Pudu",
        "value": "Pudu"
    },
    {
        "name": "Puducherry",
        "value": "Puducherry"
    },
    {
        "name": "Pudukkottai",
        "value": "Pudukkottai"
    },
    {
        "name": "Pudunagaram",
        "value": "Pudunagaram"
    },
    {
        "name": "Puduparambubhagam",
        "value": "Puduparambubhagam"
    },
    {
        "name": "Pudupattanam",
        "value": "Pudupattanam"
    },
    {
        "name": "Puduppalli Kunnam",
        "value": "Puduppalli Kunnam"
    },
    {
        "name": "Puduppattanam",
        "value": "Puduppattanam"
    },
    {
        "name": "Puduppatti",
        "value": "Puduppatti"
    },
    {
        "name": "Pudur",
        "value": "Pudur"
    },
    {
        "name": "Puduru",
        "value": "Puduru"
    },
    {
        "name": "Puduva",
        "value": "Puduva"
    },
    {
        "name": "Puduvayal",
        "value": "Puduvayal"
    },
    {
        "name": "Pujali",
        "value": "Pujali"
    },
    {
        "name": "Pukhrayan",
        "value": "Pukhrayan"
    },
    {
        "name": "Pukkulam",
        "value": "Pukkulam"
    },
    {
        "name": "Pulakurti",
        "value": "Pulakurti"
    },
    {
        "name": "Pulgaon",
        "value": "Pulgaon"
    },
    {
        "name": "Pulicat",
        "value": "Pulicat"
    },
    {
        "name": "Puligunta",
        "value": "Puligunta"
    },
    {
        "name": "Pulimaddi",
        "value": "Pulimaddi"
    },
    {
        "name": "Pulimakkal",
        "value": "Pulimakkal"
    },
    {
        "name": "Pulimathu",
        "value": "Pulimathu"
    },
    {
        "name": "Pulimel",
        "value": "Pulimel"
    },
    {
        "name": "Pulivalam",
        "value": "Pulivalam"
    },
    {
        "name": "Pulivendla",
        "value": "Pulivendla"
    },
    {
        "name": "Puliyampatti",
        "value": "Puliyampatti"
    },
    {
        "name": "Puliyankudi",
        "value": "Puliyankudi"
    },
    {
        "name": "Puliyankunnu",
        "value": "Puliyankunnu"
    },
    {
        "name": "Puliyara",
        "value": "Puliyara"
    },
    {
        "name": "Puliyur",
        "value": "Puliyur"
    },
    {
        "name": "Pulla",
        "value": "Pulla"
    },
    {
        "name": "Pullalacheruvu",
        "value": "Pullalacheruvu"
    },
    {
        "name": "Pullambadi",
        "value": "Pullambadi"
    },
    {
        "name": "Pullanpallikonam",
        "value": "Pullanpallikonam"
    },
    {
        "name": "Pullanvidudi",
        "value": "Pullanvidudi"
    },
    {
        "name": "Pullappalli",
        "value": "Pullappalli"
    },
    {
        "name": "Pulluru",
        "value": "Pulluru"
    },
    {
        "name": "Pulppatta",
        "value": "Pulppatta"
    },
    {
        "name": "Pulur",
        "value": "Pulur"
    },
    {
        "name": "Puluvappatti",
        "value": "Puluvappatti"
    },
    {
        "name": "Pumalakkundu",
        "value": "Pumalakkundu"
    },
    {
        "name": "Punalur",
        "value": "Punalur"
    },
    {
        "name": "Punarakh",
        "value": "Punarakh"
    },
    {
        "name": "Punch",
        "value": "Punch"
    },
    {
        "name": "Pune",
        "value": "Pune"
    },
    {
        "name": "Punganuru",
        "value": "Punganuru"
    },
    {
        "name": "Pungulam",
        "value": "Pungulam"
    },
    {
        "name": "Punjai Kalamangalam",
        "value": "Punjai Kalamangalam"
    },
    {
        "name": "Punjai Lakkapuram",
        "value": "Punjai Lakkapuram"
    },
    {
        "name": "Punjai Puliyampatti",
        "value": "Punjai Puliyampatti"
    },
    {
        "name": "Punjai Turaiyampalaiyam",
        "value": "Punjai Turaiyampalaiyam"
    },
    {
        "name": "Punnaikkayal",
        "value": "Punnaikkayal"
    },
    {
        "name": "Punnappatti",
        "value": "Punnappatti"
    },
    {
        "name": "Punnavalli",
        "value": "Punnavalli"
    },
    {
        "name": "Punnayur",
        "value": "Punnayur"
    },
    {
        "name": "Punnila",
        "value": "Punnila"
    },
    {
        "name": "Pupri",
        "value": "Pupri"
    },
    {
        "name": "Puraini",
        "value": "Puraini"
    },
    {
        "name": "Puran Bigha",
        "value": "Puran Bigha"
    },
    {
        "name": "Puranattukara",
        "value": "Puranattukara"
    },
    {
        "name": "Puranpur",
        "value": "Puranpur"
    },
    {
        "name": "Purattur",
        "value": "Purattur"
    },
    {
        "name": "Puri",
        "value": "Puri"
    },
    {
        "name": "Purna",
        "value": "Purna"
    },
    {
        "name": "Purnea",
        "value": "Purnea"
    },
    {
        "name": "Pursa",
        "value": "Pursa"
    },
    {
        "name": "Puruliya",
        "value": "Puruliya"
    },
    {
        "name": "Purushottampur",
        "value": "Purushottampur"
    },
    {
        "name": "Purwa",
        "value": "Purwa"
    },
    {
        "name": "Purwa Utar",
        "value": "Purwa Utar"
    },
    {
        "name": "Pushkar",
        "value": "Pushkar"
    },
    {
        "name": "Pushpattur",
        "value": "Pushpattur"
    },
    {
        "name": "Putaparti",
        "value": "Putaparti"
    },
    {
        "name": "Puthiyangadi",
        "value": "Puthiyangadi"
    },
    {
        "name": "Puthupalli",
        "value": "Puthupalli"
    },
    {
        "name": "Puthuppariyaram",
        "value": "Puthuppariyaram"
    },
    {
        "name": "Puttai",
        "value": "Puttai"
    },
    {
        "name": "Puttalam",
        "value": "Puttalam"
    },
    {
        "name": "Puttanattam",
        "value": "Puttanattam"
    },
    {
        "name": "Puttankulam",
        "value": "Puttankulam"
    },
    {
        "name": "Puttige",
        "value": "Puttige"
    },
    {
        "name": "Puttur",
        "value": "Puttur"
    },
    {
        "name": "Puvali",
        "value": "Puvali"
    },
    {
        "name": "Puvalur",
        "value": "Puvalur"
    },
    {
        "name": "Puyappalli",
        "value": "Puyappalli"
    },
    {
        "name": "Pyalakurti",
        "value": "Pyalakurti"
    },
    {
        "name": "Pyapali",
        "value": "Pyapali"
    },
    {
        "name": "Qadian",
        "value": "Qadian"
    },
    {
        "name": "Qadirganj",
        "value": "Qadirganj"
    },
    {
        "name": "Qatlupur",
        "value": "Qatlupur"
    },
    {
        "name": "Qazigund",
        "value": "Qazigund"
    },
    {
        "name": "Quilandi",
        "value": "Quilandi"
    },
    {
        "name": "Quilon",
        "value": "Quilon"
    },
    {
        "name": "Qutubpur",
        "value": "Qutubpur"
    },
    {
        "name": "Radhanpur",
        "value": "Radhanpur"
    },
    {
        "name": "Rae Bareli",
        "value": "Rae Bareli"
    },
    {
        "name": "Rafiganj",
        "value": "Rafiganj"
    },
    {
        "name": "Ragampet",
        "value": "Ragampet"
    },
    {
        "name": "Raghogarh",
        "value": "Raghogarh"
    },
    {
        "name": "Raghopur",
        "value": "Raghopur"
    },
    {
        "name": "Raghudebbati",
        "value": "Raghudebbati"
    },
    {
        "name": "Raghunathpur",
        "value": "Raghunathpur"
    },
    {
        "name": "Rahata",
        "value": "Rahata"
    },
    {
        "name": "Rahatgarh",
        "value": "Rahatgarh"
    },
    {
        "name": "Rahiar Kunchi",
        "value": "Rahiar Kunchi"
    },
    {
        "name": "Rahika",
        "value": "Rahika"
    },
    {
        "name": "Rahimpur",
        "value": "Rahimpur"
    },
    {
        "name": "Rahon",
        "value": "Rahon"
    },
    {
        "name": "Rahta",
        "value": "Rahta"
    },
    {
        "name": "Rahui",
        "value": "Rahui"
    },
    {
        "name": "Rahuri",
        "value": "Rahuri"
    },
    {
        "name": "Raia",
        "value": "Raia"
    },
    {
        "name": "Raibari Mahuawa",
        "value": "Raibari Mahuawa"
    },
    {
        "name": "Raibhir",
        "value": "Raibhir"
    },
    {
        "name": "Raichur",
        "value": "Raichur"
    },
    {
        "name": "Raiganj",
        "value": "Raiganj"
    },
    {
        "name": "Raiganj Bazar",
        "value": "Raiganj Bazar"
    },
    {
        "name": "Raigarh",
        "value": "Raigarh"
    },
    {
        "name": "Raikal",
        "value": "Raikal"
    },
    {
        "name": "Raikot",
        "value": "Raikot"
    },
    {
        "name": "Raiparthi",
        "value": "Raiparthi"
    },
    {
        "name": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raipur Buzurg",
        "value": "Raipur Buzurg"
    },
    {
        "name": "Raipura",
        "value": "Raipura"
    },
    {
        "name": "Raisari",
        "value": "Raisari"
    },
    {
        "name": "Raisen",
        "value": "Raisen"
    },
    {
        "name": "Raisinghnagar",
        "value": "Raisinghnagar"
    },
    {
        "name": "Raita",
        "value": "Raita"
    },
    {
        "name": "Raitar",
        "value": "Raitar"
    },
    {
        "name": "Raiyam",
        "value": "Raiyam"
    },
    {
        "name": "Raja Pakar",
        "value": "Raja Pakar"
    },
    {
        "name": "Rajahmundry",
        "value": "Rajahmundry"
    },
    {
        "name": "Rajakheri",
        "value": "Rajakheri"
    },
    {
        "name": "Rajaldesar",
        "value": "Rajaldesar"
    },
    {
        "name": "Rajanagaram",
        "value": "Rajanagaram"
    },
    {
        "name": "Rajaori",
        "value": "Rajaori"
    },
    {
        "name": "Rajapalaiyam",
        "value": "Rajapalaiyam"
    },
    {
        "name": "Rajapudi",
        "value": "Rajapudi"
    },
    {
        "name": "Rajapur",
        "value": "Rajapur"
    },
    {
        "name": "Rajaram",
        "value": "Rajaram"
    },
    {
        "name": "Rajasur",
        "value": "Rajasur"
    },
    {
        "name": "Rajaudha",
        "value": "Rajaudha"
    },
    {
        "name": "Rajauli",
        "value": "Rajauli"
    },
    {
        "name": "Rajbalhai",
        "value": "Rajbalhai"
    },
    {
        "name": "Raje",
        "value": "Raje"
    },
    {
        "name": "Rajendranagar",
        "value": "Rajendranagar"
    },
    {
        "name": "Rajepur",
        "value": "Rajepur"
    },
    {
        "name": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rajghat Garail",
        "value": "Rajghat Garail"
    },
    {
        "name": "Rajgir",
        "value": "Rajgir"
    },
    {
        "name": "Rajhanpur",
        "value": "Rajhanpur"
    },
    {
        "name": "Rajim",
        "value": "Rajim"
    },
    {
        "name": "Rajkot",
        "value": "Rajkot"
    },
    {
        "name": "Rajmahal",
        "value": "Rajmahal"
    },
    {
        "name": "Rajnagar",
        "value": "Rajnagar"
    },
    {
        "name": "Rajni",
        "value": "Rajni"
    },
    {
        "name": "Rajod",
        "value": "Rajod"
    },
    {
        "name": "Rajpur",
        "value": "Rajpur"
    },
    {
        "name": "Rajpur Kalan",
        "value": "Rajpur Kalan"
    },
    {
        "name": "Rajpura",
        "value": "Rajpura"
    },
    {
        "name": "Rajsamand",
        "value": "Rajsamand"
    },
    {
        "name": "Rajula",
        "value": "Rajula"
    },
    {
        "name": "Rajupalem",
        "value": "Rajupalem"
    },
    {
        "name": "Rakhwari",
        "value": "Rakhwari"
    },
    {
        "name": "Raksaha",
        "value": "Raksaha"
    },
    {
        "name": "Ralla",
        "value": "Ralla"
    },
    {
        "name": "Ramabhadrapuram",
        "value": "Ramabhadrapuram"
    },
    {
        "name": "Ramachandrapuram",
        "value": "Ramachandrapuram"
    },
    {
        "name": "Ramachandrapuran",
        "value": "Ramachandrapuran"
    },
    {
        "name": "Ramagiri Udayagiri",
        "value": "Ramagiri Udayagiri"
    },
    {
        "name": "Ramamangalam",
        "value": "Ramamangalam"
    },
    {
        "name": "Raman",
        "value": "Raman"
    },
    {
        "name": "Raman Mandi",
        "value": "Raman Mandi"
    },
    {
        "name": "Ramanathapuram",
        "value": "Ramanathapuram"
    },
    {
        "name": "Ramanayakkanpalaiyam",
        "value": "Ramanayakkanpalaiyam"
    },
    {
        "name": "Ramanayyapeta",
        "value": "Ramanayyapeta"
    },
    {
        "name": "Ramannapeta",
        "value": "Ramannapeta"
    },
    {
        "name": "Ramantali",
        "value": "Ramantali"
    },
    {
        "name": "Ramapattanam",
        "value": "Ramapattanam"
    },
    {
        "name": "Ramareddi",
        "value": "Ramareddi"
    },
    {
        "name": "Ramasingavaram",
        "value": "Ramasingavaram"
    },
    {
        "name": "Ramayampet",
        "value": "Ramayampet"
    },
    {
        "name": "Ramayipatti",
        "value": "Ramayipatti"
    },
    {
        "name": "Rambha",
        "value": "Rambha"
    },
    {
        "name": "Rambilli",
        "value": "Rambilli"
    },
    {
        "name": "Ramchandarpur",
        "value": "Ramchandarpur"
    },
    {
        "name": "Ramchandrapur",
        "value": "Ramchandrapur"
    },
    {
        "name": "Ramdeora",
        "value": "Ramdeora"
    },
    {
        "name": "Ramdiri",
        "value": "Ramdiri"
    },
    {
        "name": "Ramdurg",
        "value": "Ramdurg"
    },
    {
        "name": "Rameswaram",
        "value": "Rameswaram"
    },
    {
        "name": "Ramewadi",
        "value": "Ramewadi"
    },
    {
        "name": "Ramganj Mandi",
        "value": "Ramganj Mandi"
    },
    {
        "name": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Ramgarha",
        "value": "Ramgarha"
    },
    {
        "name": "Ramgundam",
        "value": "Ramgundam"
    },
    {
        "name": "Ramjibanpur",
        "value": "Ramjibanpur"
    },
    {
        "name": "Ramkali",
        "value": "Ramkali"
    },
    {
        "name": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Ramnagar Bankat",
        "value": "Ramnagar Bankat"
    },
    {
        "name": "Ramnagar Farsahi",
        "value": "Ramnagar Farsahi"
    },
    {
        "name": "Rampatti",
        "value": "Rampatti"
    },
    {
        "name": "Rampur",
        "value": "Rampur"
    },
    {
        "name": "Rampur Hat",
        "value": "Rampur Hat"
    },
    {
        "name": "Rampur Jalalpur",
        "value": "Rampur Jalalpur"
    },
    {
        "name": "Rampur Kalan",
        "value": "Rampur Kalan"
    },
    {
        "name": "Rampur Khajuriya",
        "value": "Rampur Khajuriya"
    },
    {
        "name": "Rampur Kudarkatti",
        "value": "Rampur Kudarkatti"
    },
    {
        "name": "Rampur Parhat",
        "value": "Rampur Parhat"
    },
    {
        "name": "Rampur Rajwa",
        "value": "Rampur Rajwa"
    },
    {
        "name": "Rampur Shamchand",
        "value": "Rampur Shamchand"
    },
    {
        "name": "Rampur Tilak",
        "value": "Rampur Tilak"
    },
    {
        "name": "Rampura",
        "value": "Rampura"
    },
    {
        "name": "Rampurwa",
        "value": "Rampurwa"
    },
    {
        "name": "Ranaghat",
        "value": "Ranaghat"
    },
    {
        "name": "Ranapur",
        "value": "Ranapur"
    },
    {
        "name": "Ranavav",
        "value": "Ranavav"
    },
    {
        "name": "Ranbirsinghpura",
        "value": "Ranbirsinghpura"
    },
    {
        "name": "Ranchi",
        "value": "Ranchi"
    },
    {
        "name": "Raneswar",
        "value": "Raneswar"
    },
    {
        "name": "Rangamati",
        "value": "Rangamati"
    },
    {
        "name": "Rangapara",
        "value": "Rangapara"
    },
    {
        "name": "Rangapuram",
        "value": "Rangapuram"
    },
    {
        "name": "Rangasamudram",
        "value": "Rangasamudram"
    },
    {
        "name": "Rangia",
        "value": "Rangia"
    },
    {
        "name": "Rangra",
        "value": "Rangra"
    },
    {
        "name": "Rangvasa",
        "value": "Rangvasa"
    },
    {
        "name": "Rangwasa",
        "value": "Rangwasa"
    },
    {
        "name": "Rani",
        "value": "Rani"
    },
    {
        "name": "Rani Sagar",
        "value": "Rani Sagar"
    },
    {
        "name": "Rani Sawargaon",
        "value": "Rani Sawargaon"
    },
    {
        "name": "Rani Shakarpura",
        "value": "Rani Shakarpura"
    },
    {
        "name": "Rania",
        "value": "Rania"
    },
    {
        "name": "Ranibennur",
        "value": "Ranibennur"
    },
    {
        "name": "Ranigaon",
        "value": "Ranigaon"
    },
    {
        "name": "Ranipet",
        "value": "Ranipet"
    },
    {
        "name": "Raniwara Kalan",
        "value": "Raniwara Kalan"
    },
    {
        "name": "Ranjal",
        "value": "Ranjal"
    },
    {
        "name": "Rankhandi",
        "value": "Rankhandi"
    },
    {
        "name": "Ranko",
        "value": "Ranko"
    },
    {
        "name": "Ranod",
        "value": "Ranod"
    },
    {
        "name": "Ranpur",
        "value": "Ranpur"
    },
    {
        "name": "Ranranagudipeta",
        "value": "Ranranagudipeta"
    },
    {
        "name": "Ranti",
        "value": "Ranti"
    },
    {
        "name": "Raonta",
        "value": "Raonta"
    },
    {
        "name": "Rapar",
        "value": "Rapar"
    },
    {
        "name": "Raparla",
        "value": "Raparla"
    },
    {
        "name": "Rapur",
        "value": "Rapur"
    },
    {
        "name": "Rardhu",
        "value": "Rardhu"
    },
    {
        "name": "Rarott",
        "value": "Rarott"
    },
    {
        "name": "Rasauli",
        "value": "Rasauli"
    },
    {
        "name": "Rasaunk",
        "value": "Rasaunk"
    },
    {
        "name": "Rasiari",
        "value": "Rasiari"
    },
    {
        "name": "Rasingapuram",
        "value": "Rasingapuram"
    },
    {
        "name": "Rasipuram",
        "value": "Rasipuram"
    },
    {
        "name": "Rasivarai Tottam",
        "value": "Rasivarai Tottam"
    },
    {
        "name": "Rasol",
        "value": "Rasol"
    },
    {
        "name": "Raspur Patasia",
        "value": "Raspur Patasia"
    },
    {
        "name": "Rasra",
        "value": "Rasra"
    },
    {
        "name": "Rasulpur",
        "value": "Rasulpur"
    },
    {
        "name": "Rasulpur Dhuria",
        "value": "Rasulpur Dhuria"
    },
    {
        "name": "Ratan",
        "value": "Ratan"
    },
    {
        "name": "Ratangarh",
        "value": "Ratangarh"
    },
    {
        "name": "Ratanpur",
        "value": "Ratanpur"
    },
    {
        "name": "Ratauli",
        "value": "Ratauli"
    },
    {
        "name": "Rath",
        "value": "Rath"
    },
    {
        "name": "Ratia",
        "value": "Ratia"
    },
    {
        "name": "Ratlam",
        "value": "Ratlam"
    },
    {
        "name": "Ratnagiri",
        "value": "Ratnagiri"
    },
    {
        "name": "Ratnahalli",
        "value": "Ratnahalli"
    },
    {
        "name": "Ratnapuram",
        "value": "Ratnapuram"
    },
    {
        "name": "Rattihalli",
        "value": "Rattihalli"
    },
    {
        "name": "Ratu",
        "value": "Ratu"
    },
    {
        "name": "Raun",
        "value": "Raun"
    },
    {
        "name": "Raurkela",
        "value": "Raurkela"
    },
    {
        "name": "Rautara",
        "value": "Rautara"
    },
    {
        "name": "Raver",
        "value": "Raver"
    },
    {
        "name": "Ravulapalem",
        "value": "Ravulapalem"
    },
    {
        "name": "Ravutulapudi",
        "value": "Ravutulapudi"
    },
    {
        "name": "Rawatbhata",
        "value": "Rawatbhata"
    },
    {
        "name": "Rawatsar",
        "value": "Rawatsar"
    },
    {
        "name": "Raxaul",
        "value": "Raxaul"
    },
    {
        "name": "Rayachoti",
        "value": "Rayachoti"
    },
    {
        "name": "Rayadrug",
        "value": "Rayadrug"
    },
    {
        "name": "Rayagada",
        "value": "Rayagada"
    },
    {
        "name": "Rayamangalam",
        "value": "Rayamangalam"
    },
    {
        "name": "Rayapalle",
        "value": "Rayapalle"
    },
    {
        "name": "Rayappanpatti",
        "value": "Rayappanpatti"
    },
    {
        "name": "Rayavaram",
        "value": "Rayavaram"
    },
    {
        "name": "Raybag",
        "value": "Raybag"
    },
    {
        "name": "Razampeta",
        "value": "Razampeta"
    },
    {
        "name": "Razole",
        "value": "Razole"
    },
    {
        "name": "Reddigudem",
        "value": "Reddigudem"
    },
    {
        "name": "Reddipalle",
        "value": "Reddipalle"
    },
    {
        "name": "Reddippatti",
        "value": "Reddippatti"
    },
    {
        "name": "Reddiyapatti",
        "value": "Reddiyapatti"
    },
    {
        "name": "Reha Mota",
        "value": "Reha Mota"
    },
    {
        "name": "Rehli",
        "value": "Rehli"
    },
    {
        "name": "Rehti",
        "value": "Rehti"
    },
    {
        "name": "Relangi",
        "value": "Relangi"
    },
    {
        "name": "Rellivalasa",
        "value": "Rellivalasa"
    },
    {
        "name": "Renapur",
        "value": "Renapur"
    },
    {
        "name": "Rengali",
        "value": "Rengali"
    },
    {
        "name": "Reni",
        "value": "Reni"
    },
    {
        "name": "Renigunta",
        "value": "Renigunta"
    },
    {
        "name": "Rentachintala",
        "value": "Rentachintala"
    },
    {
        "name": "Renukut",
        "value": "Renukut"
    },
    {
        "name": "Reota",
        "value": "Reota"
    },
    {
        "name": "Reoti",
        "value": "Reoti"
    },
    {
        "name": "Repala",
        "value": "Repala"
    },
    {
        "name": "Repalle",
        "value": "Repalle"
    },
    {
        "name": "Rettanai",
        "value": "Rettanai"
    },
    {
        "name": "Revelganj",
        "value": "Revelganj"
    },
    {
        "name": "Revur",
        "value": "Revur"
    },
    {
        "name": "Rewa",
        "value": "Rewa"
    },
    {
        "name": "Rewahi",
        "value": "Rewahi"
    },
    {
        "name": "Rewari",
        "value": "Rewari"
    },
    {
        "name": "Rewtith",
        "value": "Rewtith"
    },
    {
        "name": "Rifadpur",
        "value": "Rifadpur"
    },
    {
        "name": "Riga",
        "value": "Riga"
    },
    {
        "name": "Ringas",
        "value": "Ringas"
    },
    {
        "name": "Ringnod",
        "value": "Ringnod"
    },
    {
        "name": "Rishikesh",
        "value": "Rishikesh"
    },
    {
        "name": "Rishivandiyam",
        "value": "Rishivandiyam"
    },
    {
        "name": "Rishra",
        "value": "Rishra"
    },
    {
        "name": "Risod",
        "value": "Risod"
    },
    {
        "name": "Robertsganj",
        "value": "Robertsganj"
    },
    {
        "name": "Robertsonpet",
        "value": "Robertsonpet"
    },
    {
        "name": "Roda",
        "value": "Roda"
    },
    {
        "name": "Roddam",
        "value": "Roddam"
    },
    {
        "name": "Roh",
        "value": "Roh"
    },
    {
        "name": "Rohar",
        "value": "Rohar"
    },
    {
        "name": "Rohera",
        "value": "Rohera"
    },
    {
        "name": "Rohtak",
        "value": "Rohtak"
    },
    {
        "name": "Rolla",
        "value": "Rolla"
    },
    {
        "name": "Rompicherla",
        "value": "Rompicherla"
    },
    {
        "name": "Ron",
        "value": "Ron"
    },
    {
        "name": "Rongat",
        "value": "Rongat"
    },
    {
        "name": "Ropar",
        "value": "Ropar"
    },
    {
        "name": "Rounia",
        "value": "Rounia"
    },
    {
        "name": "Rudarpur",
        "value": "Rudarpur"
    },
    {
        "name": "Rudauli",
        "value": "Rudauli"
    },
    {
        "name": "Rudra Nagar",
        "value": "Rudra Nagar"
    },
    {
        "name": "Rudrangi",
        "value": "Rudrangi"
    },
    {
        "name": "Rudraprayag",
        "value": "Rudraprayag"
    },
    {
        "name": "Rudravaram",
        "value": "Rudravaram"
    },
    {
        "name": "Rudrur",
        "value": "Rudrur"
    },
    {
        "name": "Rukhae",
        "value": "Rukhae"
    },
    {
        "name": "Rupahi",
        "value": "Rupahi"
    },
    {
        "name": "Rupana",
        "value": "Rupana"
    },
    {
        "name": "Rupauli",
        "value": "Rupauli"
    },
    {
        "name": "Rupbas",
        "value": "Rupbas"
    },
    {
        "name": "Rupenaguntla",
        "value": "Rupenaguntla"
    },
    {
        "name": "Rusera",
        "value": "Rusera"
    },
    {
        "name": "Rustampur",
        "value": "Rustampur"
    },
    {
        "name": "Saadatpur Aguani",
        "value": "Saadatpur Aguani"
    },
    {
        "name": "Sabalgarh",
        "value": "Sabalgarh"
    },
    {
        "name": "Sabalpur",
        "value": "Sabalpur"
    },
    {
        "name": "Sabang",
        "value": "Sabang"
    },
    {
        "name": "Sabaur",
        "value": "Sabaur"
    },
    {
        "name": "Sabbavaram",
        "value": "Sabbavaram"
    },
    {
        "name": "Sabnima",
        "value": "Sabnima"
    },
    {
        "name": "Sachin",
        "value": "Sachin"
    },
    {
        "name": "Sadabad",
        "value": "Sadabad"
    },
    {
        "name": "Sadalgi",
        "value": "Sadalgi"
    },
    {
        "name": "Sadaseopet",
        "value": "Sadaseopet"
    },
    {
        "name": "Sadhoa",
        "value": "Sadhoa"
    },
    {
        "name": "Sadiqpur Maraul",
        "value": "Sadiqpur Maraul"
    },
    {
        "name": "Sadpur",
        "value": "Sadpur"
    },
    {
        "name": "Sadri",
        "value": "Sadri"
    },
    {
        "name": "Safidon",
        "value": "Safidon"
    },
    {
        "name": "Safipur",
        "value": "Safipur"
    },
    {
        "name": "Sagar",
        "value": "Sagar"
    },
    {
        "name": "Sagarpur",
        "value": "Sagarpur"
    },
    {
        "name": "Sagauli",
        "value": "Sagauli"
    },
    {
        "name": "Saghar Sultanpur",
        "value": "Saghar Sultanpur"
    },
    {
        "name": "Sagwara",
        "value": "Sagwara"
    },
    {
        "name": "Saha",
        "value": "Saha"
    },
    {
        "name": "Sahapur",
        "value": "Sahapur"
    },
    {
        "name": "Sahar",
        "value": "Sahar"
    },
    {
        "name": "Saharanpur",
        "value": "Saharanpur"
    },
    {
        "name": "Saharbani",
        "value": "Saharbani"
    },
    {
        "name": "Saharsa",
        "value": "Saharsa"
    },
    {
        "name": "Sahasmal",
        "value": "Sahasmal"
    },
    {
        "name": "Sahaspur",
        "value": "Sahaspur"
    },
    {
        "name": "Sahaswan",
        "value": "Sahaswan"
    },
    {
        "name": "Sahawar",
        "value": "Sahawar"
    },
    {
        "name": "Sahibganj",
        "value": "Sahibganj"
    },
    {
        "name": "Sahibpur Kamal",
        "value": "Sahibpur Kamal"
    },
    {
        "name": "Sahidganj",
        "value": "Sahidganj"
    },
    {
        "name": "Sahit",
        "value": "Sahit"
    },
    {
        "name": "Sahjanwa",
        "value": "Sahjanwa"
    },
    {
        "name": "Sahna",
        "value": "Sahna"
    },
    {
        "name": "Saho",
        "value": "Saho"
    },
    {
        "name": "Sahoria Subhai",
        "value": "Sahoria Subhai"
    },
    {
        "name": "Sahpur",
        "value": "Sahpur"
    },
    {
        "name": "Sahri",
        "value": "Sahri"
    },
    {
        "name": "Sahsaul",
        "value": "Sahsaul"
    },
    {
        "name": "Sahtah",
        "value": "Sahtah"
    },
    {
        "name": "Sahuli",
        "value": "Sahuli"
    },
    {
        "name": "Sahuria",
        "value": "Sahuria"
    },
    {
        "name": "Saidabad",
        "value": "Saidabad"
    },
    {
        "name": "Saidapet",
        "value": "Saidapet"
    },
    {
        "name": "Saidoke",
        "value": "Saidoke"
    },
    {
        "name": "Saidpur",
        "value": "Saidpur"
    },
    {
        "name": "Saidpur Dabra",
        "value": "Saidpur Dabra"
    },
    {
        "name": "Saiha",
        "value": "Saiha"
    },
    {
        "name": "Saila",
        "value": "Saila"
    },
    {
        "name": "Sailana",
        "value": "Sailana"
    },
    {
        "name": "Sainkhera",
        "value": "Sainkhera"
    },
    {
        "name": "Sainthia",
        "value": "Sainthia"
    },
    {
        "name": "Sakaddi",
        "value": "Sakaddi"
    },
    {
        "name": "Sakardih",
        "value": "Sakardih"
    },
    {
        "name": "Sakhmohan",
        "value": "Sakhmohan"
    },
    {
        "name": "Sakhua",
        "value": "Sakhua"
    },
    {
        "name": "Sakkamapatti",
        "value": "Sakkamapatti"
    },
    {
        "name": "Sakleshpur",
        "value": "Sakleshpur"
    },
    {
        "name": "Sakri",
        "value": "Sakri"
    },
    {
        "name": "Saksohara",
        "value": "Saksohara"
    },
    {
        "name": "Sakti",
        "value": "Sakti"
    },
    {
        "name": "Saktipur",
        "value": "Saktipur"
    },
    {
        "name": "Salaiya",
        "value": "Salaiya"
    },
    {
        "name": "Salamedu",
        "value": "Salamedu"
    },
    {
        "name": "Salangaippalaiyam",
        "value": "Salangaippalaiyam"
    },
    {
        "name": "Salar",
        "value": "Salar"
    },
    {
        "name": "Salaya",
        "value": "Salaya"
    },
    {
        "name": "Salehpur",
        "value": "Salehpur"
    },
    {
        "name": "Salem",
        "value": "Salem"
    },
    {
        "name": "Salempur",
        "value": "Salempur"
    },
    {
        "name": "Saligrama",
        "value": "Saligrama"
    },
    {
        "name": "Salimpur",
        "value": "Salimpur"
    },
    {
        "name": "Salkhua",
        "value": "Salkhua"
    },
    {
        "name": "Sallimedu",
        "value": "Sallimedu"
    },
    {
        "name": "Salotgi",
        "value": "Salotgi"
    },
    {
        "name": "Salt Lake City",
        "value": "Salt Lake City"
    },
    {
        "name": "Salua",
        "value": "Salua"
    },
    {
        "name": "Salumbar",
        "value": "Salumbar"
    },
    {
        "name": "Samadh Bhai",
        "value": "Samadh Bhai"
    },
    {
        "name": "Samadiala",
        "value": "Samadiala"
    },
    {
        "name": "Samahuta",
        "value": "Samahuta"
    },
    {
        "name": "Samai",
        "value": "Samai"
    },
    {
        "name": "Samakhiali",
        "value": "Samakhiali"
    },
    {
        "name": "Samalapuram",
        "value": "Samalapuram"
    },
    {
        "name": "Samalkha",
        "value": "Samalkha"
    },
    {
        "name": "Samalkot",
        "value": "Samalkot"
    },
    {
        "name": "Samalpur",
        "value": "Samalpur"
    },
    {
        "name": "Samalsar",
        "value": "Samalsar"
    },
    {
        "name": "Samana",
        "value": "Samana"
    },
    {
        "name": "Samastipur",
        "value": "Samastipur"
    },
    {
        "name": "Samayanallur",
        "value": "Samayanallur"
    },
    {
        "name": "Samba",
        "value": "Samba"
    },
    {
        "name": "Sambalhera",
        "value": "Sambalhera"
    },
    {
        "name": "Sambalpur",
        "value": "Sambalpur"
    },
    {
        "name": "Sambhal",
        "value": "Sambhal"
    },
    {
        "name": "Sambhar",
        "value": "Sambhar"
    },
    {
        "name": "Sambhu Chak",
        "value": "Sambhu Chak"
    },
    {
        "name": "Sambre",
        "value": "Sambre"
    },
    {
        "name": "Samdhin",
        "value": "Samdhin"
    },
    {
        "name": "Samesi",
        "value": "Samesi"
    },
    {
        "name": "Sampgaon",
        "value": "Sampgaon"
    },
    {
        "name": "Samrala",
        "value": "Samrala"
    },
    {
        "name": "Samsikapuram",
        "value": "Samsikapuram"
    },
    {
        "name": "Samthar",
        "value": "Samthar"
    },
    {
        "name": "Sanampudi",
        "value": "Sanampudi"
    },
    {
        "name": "Sanand",
        "value": "Sanand"
    },
    {
        "name": "Sanatikri",
        "value": "Sanatikri"
    },
    {
        "name": "Sanaur",
        "value": "Sanaur"
    },
    {
        "name": "Sanchi",
        "value": "Sanchi"
    },
    {
        "name": "Sanchor",
        "value": "Sanchor"
    },
    {
        "name": "Sancoale",
        "value": "Sancoale"
    },
    {
        "name": "Sandalpur",
        "value": "Sandalpur"
    },
    {
        "name": "Sandi",
        "value": "Sandi"
    },
    {
        "name": "Sandila",
        "value": "Sandila"
    },
    {
        "name": "Sandur",
        "value": "Sandur"
    },
    {
        "name": "Sandwa",
        "value": "Sandwa"
    },
    {
        "name": "Sangalbahita",
        "value": "Sangalbahita"
    },
    {
        "name": "Sangam",
        "value": "Sangam"
    },
    {
        "name": "Sangamner",
        "value": "Sangamner"
    },
    {
        "name": "Sanganakallu",
        "value": "Sanganakallu"
    },
    {
        "name": "Sangareddi",
        "value": "Sangareddi"
    },
    {
        "name": "Sanghera",
        "value": "Sanghera"
    },
    {
        "name": "Sangi",
        "value": "Sangi"
    },
    {
        "name": "Sangli",
        "value": "Sangli"
    },
    {
        "name": "Sangola",
        "value": "Sangola"
    },
    {
        "name": "Sangram",
        "value": "Sangram"
    },
    {
        "name": "Sangrampur",
        "value": "Sangrampur"
    },
    {
        "name": "Sangrur",
        "value": "Sangrur"
    },
    {
        "name": "Sanha",
        "value": "Sanha"
    },
    {
        "name": "Sanjat",
        "value": "Sanjat"
    },
    {
        "name": "Sankaramangalam",
        "value": "Sankaramangalam"
    },
    {
        "name": "Sankaranayinar Kovil",
        "value": "Sankaranayinar Kovil"
    },
    {
        "name": "Sankaridrug",
        "value": "Sankaridrug"
    },
    {
        "name": "Sankeshwar",
        "value": "Sankeshwar"
    },
    {
        "name": "Sankhavaram",
        "value": "Sankhavaram"
    },
    {
        "name": "Sankrail",
        "value": "Sankrail"
    },
    {
        "name": "Sannai",
        "value": "Sannai"
    },
    {
        "name": "Sanquelim",
        "value": "Sanquelim"
    },
    {
        "name": "Sanrh Majhgawan",
        "value": "Sanrh Majhgawan"
    },
    {
        "name": "Sanrha",
        "value": "Sanrha"
    },
    {
        "name": "Sansa",
        "value": "Sansa"
    },
    {
        "name": "Santamaguluru",
        "value": "Santamaguluru"
    },
    {
        "name": "Sante Bennur",
        "value": "Sante Bennur"
    },
    {
        "name": "Sanwas",
        "value": "Sanwas"
    },
    {
        "name": "Sanwer",
        "value": "Sanwer"
    },
    {
        "name": "Saoner",
        "value": "Saoner"
    },
    {
        "name": "Sapahi",
        "value": "Sapahi"
    },
    {
        "name": "Sapatgram",
        "value": "Sapatgram"
    },
    {
        "name": "Sarai Jattan",
        "value": "Sarai Jattan"
    },
    {
        "name": "Sarai Ranjan",
        "value": "Sarai Ranjan"
    },
    {
        "name": "Saraikela",
        "value": "Saraikela"
    },
    {
        "name": "Saraiya",
        "value": "Saraiya"
    },
    {
        "name": "Sarakkayhalli",
        "value": "Sarakkayhalli"
    },
    {
        "name": "Saram",
        "value": "Saram"
    },
    {
        "name": "Saranga",
        "value": "Saranga"
    },
    {
        "name": "Sarangapuram",
        "value": "Sarangapuram"
    },
    {
        "name": "Sarangpur",
        "value": "Sarangpur"
    },
    {
        "name": "Sarapaka",
        "value": "Sarapaka"
    },
    {
        "name": "Sarari",
        "value": "Sarari"
    },
    {
        "name": "Sarauli",
        "value": "Sarauli"
    },
    {
        "name": "Sarauni",
        "value": "Sarauni"
    },
    {
        "name": "Sarauni Kalan",
        "value": "Sarauni Kalan"
    },
    {
        "name": "Saraunja",
        "value": "Saraunja"
    },
    {
        "name": "Sardarshahr",
        "value": "Sardarshahr"
    },
    {
        "name": "Sardhana",
        "value": "Sardhana"
    },
    {
        "name": "Sardulgarh",
        "value": "Sardulgarh"
    },
    {
        "name": "Sarea Khas",
        "value": "Sarea Khas"
    },
    {
        "name": "Saren",
        "value": "Saren"
    },
    {
        "name": "Sarenja",
        "value": "Sarenja"
    },
    {
        "name": "Sargur",
        "value": "Sargur"
    },
    {
        "name": "Sarjapur",
        "value": "Sarjapur"
    },
    {
        "name": "Sarmastpur",
        "value": "Sarmastpur"
    },
    {
        "name": "Sarmera",
        "value": "Sarmera"
    },
    {
        "name": "Sarni",
        "value": "Sarni"
    },
    {
        "name": "Sarotar",
        "value": "Sarotar"
    },
    {
        "name": "Sarpamari",
        "value": "Sarpamari"
    },
    {
        "name": "Sarpavaram",
        "value": "Sarpavaram"
    },
    {
        "name": "Sarsai Nawar",
        "value": "Sarsai Nawar"
    },
    {
        "name": "Sarsawa",
        "value": "Sarsawa"
    },
    {
        "name": "Sarso",
        "value": "Sarso"
    },
    {
        "name": "Sasan",
        "value": "Sasan"
    },
    {
        "name": "Sasaram",
        "value": "Sasaram"
    },
    {
        "name": "Sasthankotta",
        "value": "Sasthankotta"
    },
    {
        "name": "Satai",
        "value": "Satai"
    },
    {
        "name": "Satana",
        "value": "Satana"
    },
    {
        "name": "Satara",
        "value": "Satara"
    },
    {
        "name": "Satgachia",
        "value": "Satgachia"
    },
    {
        "name": "Satghara",
        "value": "Satghara"
    },
    {
        "name": "Sathamba",
        "value": "Sathamba"
    },
    {
        "name": "Sathiala",
        "value": "Sathiala"
    },
    {
        "name": "Sathmalpur",
        "value": "Sathmalpur"
    },
    {
        "name": "Satna",
        "value": "Satna"
    },
    {
        "name": "Satravada",
        "value": "Satravada"
    },
    {
        "name": "Sattar",
        "value": "Sattar"
    },
    {
        "name": "Sattegalam",
        "value": "Sattegalam"
    },
    {
        "name": "Sattenapalle",
        "value": "Sattenapalle"
    },
    {
        "name": "Satuluru",
        "value": "Satuluru"
    },
    {
        "name": "Satwar",
        "value": "Satwar"
    },
    {
        "name": "Satwas",
        "value": "Satwas"
    },
    {
        "name": "Satyamangala",
        "value": "Satyamangala"
    },
    {
        "name": "Satyamangalam",
        "value": "Satyamangalam"
    },
    {
        "name": "Satyavedu",
        "value": "Satyavedu"
    },
    {
        "name": "Satyun",
        "value": "Satyun"
    },
    {
        "name": "Saugor",
        "value": "Saugor"
    },
    {
        "name": "Saunda",
        "value": "Saunda"
    },
    {
        "name": "Saundatti",
        "value": "Saundatti"
    },
    {
        "name": "Saundhonwali",
        "value": "Saundhonwali"
    },
    {
        "name": "Saunshi",
        "value": "Saunshi"
    },
    {
        "name": "Saurh",
        "value": "Saurh"
    },
    {
        "name": "Sausar",
        "value": "Sausar"
    },
    {
        "name": "Savalgi",
        "value": "Savalgi"
    },
    {
        "name": "Savanur",
        "value": "Savanur"
    },
    {
        "name": "Savda",
        "value": "Savda"
    },
    {
        "name": "Sawai Madhopur",
        "value": "Sawai Madhopur"
    },
    {
        "name": "Sayalkudi",
        "value": "Sayalkudi"
    },
    {
        "name": "Sayarpuram",
        "value": "Sayarpuram"
    },
    {
        "name": "Secunderabad",
        "value": "Secunderabad"
    },
    {
        "name": "Segaon",
        "value": "Segaon"
    },
    {
        "name": "Segarai",
        "value": "Segarai"
    },
    {
        "name": "Sehore",
        "value": "Sehore"
    },
    {
        "name": "Selu",
        "value": "Selu"
    },
    {
        "name": "Semari",
        "value": "Semari"
    },
    {
        "name": "Sembedu",
        "value": "Sembedu"
    },
    {
        "name": "Semmarikulan",
        "value": "Semmarikulan"
    },
    {
        "name": "Semra",
        "value": "Semra"
    },
    {
        "name": "Semri",
        "value": "Semri"
    },
    {
        "name": "Senapparetti",
        "value": "Senapparetti"
    },
    {
        "name": "Senda",
        "value": "Senda"
    },
    {
        "name": "Sendamangalam",
        "value": "Sendamangalam"
    },
    {
        "name": "Sendamaram",
        "value": "Sendamaram"
    },
    {
        "name": "Sendarappatti",
        "value": "Sendarappatti"
    },
    {
        "name": "Sendhwa",
        "value": "Sendhwa"
    },
    {
        "name": "Sendurai",
        "value": "Sendurai"
    },
    {
        "name": "Senduria",
        "value": "Senduria"
    },
    {
        "name": "Sengurichchi",
        "value": "Sengurichchi"
    },
    {
        "name": "Seohara",
        "value": "Seohara"
    },
    {
        "name": "Seonar",
        "value": "Seonar"
    },
    {
        "name": "Seondha",
        "value": "Seondha"
    },
    {
        "name": "Seoni",
        "value": "Seoni"
    },
    {
        "name": "Seoni Chhapara",
        "value": "Seoni Chhapara"
    },
    {
        "name": "Seoni Malwa",
        "value": "Seoni Malwa"
    },
    {
        "name": "Seram",
        "value": "Seram"
    },
    {
        "name": "Sermadevi",
        "value": "Sermadevi"
    },
    {
        "name": "Settivaripalle",
        "value": "Settivaripalle"
    },
    {
        "name": "Settiyarpatti",
        "value": "Settiyarpatti"
    },
    {
        "name": "Seven Pagodas",
        "value": "Seven Pagodas"
    },
    {
        "name": "Sevilimedu",
        "value": "Sevilimedu"
    },
    {
        "name": "Sevur",
        "value": "Sevur"
    },
    {
        "name": "Sewa",
        "value": "Sewa"
    },
    {
        "name": "Sewai",
        "value": "Sewai"
    },
    {
        "name": "Sewari",
        "value": "Sewari"
    },
    {
        "name": "Seydunganallur",
        "value": "Seydunganallur"
    },
    {
        "name": "Shafinagar",
        "value": "Shafinagar"
    },
    {
        "name": "Shahar Telpa",
        "value": "Shahar Telpa"
    },
    {
        "name": "Shahdol",
        "value": "Shahdol"
    },
    {
        "name": "Shahganj",
        "value": "Shahganj"
    },
    {
        "name": "Shahgarh",
        "value": "Shahgarh"
    },
    {
        "name": "Shahjanpur",
        "value": "Shahjanpur"
    },
    {
        "name": "Shahkot",
        "value": "Shahkot"
    },
    {
        "name": "Shahmirpet",
        "value": "Shahmirpet"
    },
    {
        "name": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Shahpur Baghauni",
        "value": "Shahpur Baghauni"
    },
    {
        "name": "Shahpur Chaumukhi",
        "value": "Shahpur Chaumukhi"
    },
    {
        "name": "Shahpur Undi",
        "value": "Shahpur Undi"
    },
    {
        "name": "Shahpura",
        "value": "Shahpura"
    },
    {
        "name": "Shahzadpur",
        "value": "Shahzadpur"
    },
    {
        "name": "Shajapur",
        "value": "Shajapur"
    },
    {
        "name": "Shamgarh",
        "value": "Shamgarh"
    },
    {
        "name": "Shamli",
        "value": "Shamli"
    },
    {
        "name": "Shampur",
        "value": "Shampur"
    },
    {
        "name": "Shamsa",
        "value": "Shamsa"
    },
    {
        "name": "Shamsabad",
        "value": "Shamsabad"
    },
    {
        "name": "Shamshernagar",
        "value": "Shamshernagar"
    },
    {
        "name": "Shamunpet",
        "value": "Shamunpet"
    },
    {
        "name": "Shankar Saraiya",
        "value": "Shankar Saraiya"
    },
    {
        "name": "Shankarampet",
        "value": "Shankarampet"
    },
    {
        "name": "Shankarpalli",
        "value": "Shankarpalli"
    },
    {
        "name": "Shankarpur",
        "value": "Shankarpur"
    },
    {
        "name": "Shankarpur Khawas",
        "value": "Shankarpur Khawas"
    },
    {
        "name": "Shanmukhasundarapuram",
        "value": "Shanmukhasundarapuram"
    },
    {
        "name": "Shantipur",
        "value": "Shantipur"
    },
    {
        "name": "Shatrana",
        "value": "Shatrana"
    },
    {
        "name": "Shedbal",
        "value": "Shedbal"
    },
    {
        "name": "Shegaon",
        "value": "Shegaon"
    },
    {
        "name": "Sheikhpura",
        "value": "Sheikhpura"
    },
    {
        "name": "Shekhupur",
        "value": "Shekhupur"
    },
    {
        "name": "Shencottah",
        "value": "Shencottah"
    },
    {
        "name": "Shendurjana",
        "value": "Shendurjana"
    },
    {
        "name": "Sheohar",
        "value": "Sheohar"
    },
    {
        "name": "Sheopur",
        "value": "Sheopur"
    },
    {
        "name": "Sheopuria",
        "value": "Sheopuria"
    },
    {
        "name": "Sher",
        "value": "Sher"
    },
    {
        "name": "Sher Chakla",
        "value": "Sher Chakla"
    },
    {
        "name": "Sher Muhammadpuram",
        "value": "Sher Muhammadpuram"
    },
    {
        "name": "Sherghati",
        "value": "Sherghati"
    },
    {
        "name": "Sherkot",
        "value": "Sherkot"
    },
    {
        "name": "Sheron",
        "value": "Sheron"
    },
    {
        "name": "Sherpur",
        "value": "Sherpur"
    },
    {
        "name": "Sherpur Khurd",
        "value": "Sherpur Khurd"
    },
    {
        "name": "Shertallai",
        "value": "Shertallai"
    },
    {
        "name": "Shiddapur",
        "value": "Shiddapur"
    },
    {
        "name": "Shiggaon",
        "value": "Shiggaon"
    },
    {
        "name": "Shikarpur",
        "value": "Shikarpur"
    },
    {
        "name": "Shikohabad",
        "value": "Shikohabad"
    },
    {
        "name": "Shikrapur",
        "value": "Shikrapur"
    },
    {
        "name": "Shiliguri",
        "value": "Shiliguri"
    },
    {
        "name": "Shillong",
        "value": "Shillong"
    },
    {
        "name": "Shimla",
        "value": "Shimla"
    },
    {
        "name": "Shimoga",
        "value": "Shimoga"
    },
    {
        "name": "Shirali",
        "value": "Shirali"
    },
    {
        "name": "Shirbadgi",
        "value": "Shirbadgi"
    },
    {
        "name": "Shirdi",
        "value": "Shirdi"
    },
    {
        "name": "Shirguppi",
        "value": "Shirguppi"
    },
    {
        "name": "Shirhatti",
        "value": "Shirhatti"
    },
    {
        "name": "Shirud",
        "value": "Shirud"
    },
    {
        "name": "Shiruru",
        "value": "Shiruru"
    },
    {
        "name": "Shirva",
        "value": "Shirva"
    },
    {
        "name": "Shishgarh",
        "value": "Shishgarh"
    },
    {
        "name": "Shitab Diara",
        "value": "Shitab Diara"
    },
    {
        "name": "Shiv",
        "value": "Shiv"
    },
    {
        "name": "Shivganj",
        "value": "Shivganj"
    },
    {
        "name": "Shivpuri",
        "value": "Shivpuri"
    },
    {
        "name": "Shiyali",
        "value": "Shiyali"
    },
    {
        "name": "Sholinghur",
        "value": "Sholinghur"
    },
    {
        "name": "Shoranur",
        "value": "Shoranur"
    },
    {
        "name": "Shorapur",
        "value": "Shorapur"
    },
    {
        "name": "Shri Mahavirji",
        "value": "Shri Mahavirji"
    },
    {
        "name": "Shrigonda",
        "value": "Shrigonda"
    },
    {
        "name": "Shrirampur",
        "value": "Shrirampur"
    },
    {
        "name": "Shrirangapattana",
        "value": "Shrirangapattana"
    },
    {
        "name": "Shujalpur",
        "value": "Shujalpur"
    },
    {
        "name": "Shutayil",
        "value": "Shutayil"
    },
    {
        "name": "Shyamnagar",
        "value": "Shyamnagar"
    },
    {
        "name": "Sibkund",
        "value": "Sibkund"
    },
    {
        "name": "Sibsagar",
        "value": "Sibsagar"
    },
    {
        "name": "Siddapur",
        "value": "Siddapur"
    },
    {
        "name": "Siddarampuram",
        "value": "Siddarampuram"
    },
    {
        "name": "Siddhapur",
        "value": "Siddhapur"
    },
    {
        "name": "Siddipet",
        "value": "Siddipet"
    },
    {
        "name": "Sidhap Kalan",
        "value": "Sidhap Kalan"
    },
    {
        "name": "Sidhapa",
        "value": "Sidhapa"
    },
    {
        "name": "Sidhauli",
        "value": "Sidhauli"
    },
    {
        "name": "Sidhi",
        "value": "Sidhi"
    },
    {
        "name": "Sidhwan",
        "value": "Sidhwan"
    },
    {
        "name": "Sidlaghatta",
        "value": "Sidlaghatta"
    },
    {
        "name": "Sigli",
        "value": "Sigli"
    },
    {
        "name": "Sihali Jagir",
        "value": "Sihali Jagir"
    },
    {
        "name": "Sihaul",
        "value": "Sihaul"
    },
    {
        "name": "Sihma",
        "value": "Sihma"
    },
    {
        "name": "Sihor",
        "value": "Sihor"
    },
    {
        "name": "Sihora",
        "value": "Sihora"
    },
    {
        "name": "Sijua",
        "value": "Sijua"
    },
    {
        "name": "Sikandarabad",
        "value": "Sikandarabad"
    },
    {
        "name": "Sikandarpur",
        "value": "Sikandarpur"
    },
    {
        "name": "Sikandra",
        "value": "Sikandra"
    },
    {
        "name": "Sikandra Rao",
        "value": "Sikandra Rao"
    },
    {
        "name": "Sikar",
        "value": "Sikar"
    },
    {
        "name": "Sikat",
        "value": "Sikat"
    },
    {
        "name": "Sikka",
        "value": "Sikka"
    },
    {
        "name": "Siktiahi",
        "value": "Siktiahi"
    },
    {
        "name": "Siladon",
        "value": "Siladon"
    },
    {
        "name": "Silaiyampatti",
        "value": "Silaiyampatti"
    },
    {
        "name": "Silamalai",
        "value": "Silamalai"
    },
    {
        "name": "Silao",
        "value": "Silao"
    },
    {
        "name": "Silappadi",
        "value": "Silappadi"
    },
    {
        "name": "Silchar",
        "value": "Silchar"
    },
    {
        "name": "Sillod",
        "value": "Sillod"
    },
    {
        "name": "Silvani",
        "value": "Silvani"
    },
    {
        "name": "Silvarpatti",
        "value": "Silvarpatti"
    },
    {
        "name": "Silvassa",
        "value": "Silvassa"
    },
    {
        "name": "Simarbani",
        "value": "Simarbani"
    },
    {
        "name": "Simaria",
        "value": "Simaria"
    },
    {
        "name": "Simarwara Durgapur",
        "value": "Simarwara Durgapur"
    },
    {
        "name": "Simdega",
        "value": "Simdega"
    },
    {
        "name": "Simrahi",
        "value": "Simrahi"
    },
    {
        "name": "Simri",
        "value": "Simri"
    },
    {
        "name": "Simri Bakhriarpur",
        "value": "Simri Bakhriarpur"
    },
    {
        "name": "Simria",
        "value": "Simria"
    },
    {
        "name": "Simrol",
        "value": "Simrol"
    },
    {
        "name": "Sindalakkundu",
        "value": "Sindalakkundu"
    },
    {
        "name": "Sindgi",
        "value": "Sindgi"
    },
    {
        "name": "Sindhnur",
        "value": "Sindhnur"
    },
    {
        "name": "Singalandapuram",
        "value": "Singalandapuram"
    },
    {
        "name": "Singampunari",
        "value": "Singampunari"
    },
    {
        "name": "Singapperumalkovil",
        "value": "Singapperumalkovil"
    },
    {
        "name": "Singarayakonda",
        "value": "Singarayakonda"
    },
    {
        "name": "Singhana",
        "value": "Singhana"
    },
    {
        "name": "Singhanwala",
        "value": "Singhanwala"
    },
    {
        "name": "Singhara Buzurg",
        "value": "Singhara Buzurg"
    },
    {
        "name": "Singhbari",
        "value": "Singhbari"
    },
    {
        "name": "Singhwara",
        "value": "Singhwara"
    },
    {
        "name": "Singia",
        "value": "Singia"
    },
    {
        "name": "Singoli",
        "value": "Singoli"
    },
    {
        "name": "Singrauliya",
        "value": "Singrauliya"
    },
    {
        "name": "Singur",
        "value": "Singur"
    },
    {
        "name": "Singura",
        "value": "Singura"
    },
    {
        "name": "Sinha",
        "value": "Sinha"
    },
    {
        "name": "Sini",
        "value": "Sini"
    },
    {
        "name": "Sinnar",
        "value": "Sinnar"
    },
    {
        "name": "Sinor",
        "value": "Sinor"
    },
    {
        "name": "Sipalakottai",
        "value": "Sipalakottai"
    },
    {
        "name": "Sira",
        "value": "Sira"
    },
    {
        "name": "Sirali",
        "value": "Sirali"
    },
    {
        "name": "Siralkoppa",
        "value": "Siralkoppa"
    },
    {
        "name": "Sirdala",
        "value": "Sirdala"
    },
    {
        "name": "Sirgora",
        "value": "Sirgora"
    },
    {
        "name": "Sirhali Kalan",
        "value": "Sirhali Kalan"
    },
    {
        "name": "Siriari",
        "value": "Siriari"
    },
    {
        "name": "Sirigeri",
        "value": "Sirigeri"
    },
    {
        "name": "Sirikonda",
        "value": "Sirikonda"
    },
    {
        "name": "Siripur",
        "value": "Siripur"
    },
    {
        "name": "Sirkhandi Bhitha",
        "value": "Sirkhandi Bhitha"
    },
    {
        "name": "Sirmatpur",
        "value": "Sirmatpur"
    },
    {
        "name": "Sirmaur",
        "value": "Sirmaur"
    },
    {
        "name": "Sirnia",
        "value": "Sirnia"
    },
    {
        "name": "Siroda",
        "value": "Siroda"
    },
    {
        "name": "Sirohi",
        "value": "Sirohi"
    },
    {
        "name": "Sironj",
        "value": "Sironj"
    },
    {
        "name": "Sirpanandal",
        "value": "Sirpanandal"
    },
    {
        "name": "Sirpur",
        "value": "Sirpur"
    },
    {
        "name": "Sirsa",
        "value": "Sirsa"
    },
    {
        "name": "Sirsaganj",
        "value": "Sirsaganj"
    },
    {
        "name": "Sirsi",
        "value": "Sirsi"
    },
    {
        "name": "Sirsia Hanumanganj",
        "value": "Sirsia Hanumanganj"
    },
    {
        "name": "Sirsilla",
        "value": "Sirsilla"
    },
    {
        "name": "Sirugamani",
        "value": "Sirugamani"
    },
    {
        "name": "Sirugudi",
        "value": "Sirugudi"
    },
    {
        "name": "Siruguppa",
        "value": "Siruguppa"
    },
    {
        "name": "Sirumugai",
        "value": "Sirumugai"
    },
    {
        "name": "Sirur",
        "value": "Sirur"
    },
    {
        "name": "Sirur Tajband",
        "value": "Sirur Tajband"
    },
    {
        "name": "Siruvachchur",
        "value": "Siruvachchur"
    },
    {
        "name": "Siruvalur",
        "value": "Siruvalur"
    },
    {
        "name": "Sirvar",
        "value": "Sirvar"
    },
    {
        "name": "Sirvel",
        "value": "Sirvel"
    },
    {
        "name": "Sisai",
        "value": "Sisai"
    },
    {
        "name": "Sisauna",
        "value": "Sisauna"
    },
    {
        "name": "Sisia",
        "value": "Sisia"
    },
    {
        "name": "Siswa",
        "value": "Siswa"
    },
    {
        "name": "Siswar",
        "value": "Siswar"
    },
    {
        "name": "Sitalkuchi",
        "value": "Sitalkuchi"
    },
    {
        "name": "Sitalpur",
        "value": "Sitalpur"
    },
    {
        "name": "Sitamarhi",
        "value": "Sitamarhi"
    },
    {
        "name": "Sitamau",
        "value": "Sitamau"
    },
    {
        "name": "Sitanagaram",
        "value": "Sitanagaram"
    },
    {
        "name": "Sitapur",
        "value": "Sitapur"
    },
    {
        "name": "Sitarganj",
        "value": "Sitarganj"
    },
    {
        "name": "Siur",
        "value": "Siur"
    },
    {
        "name": "Siuri",
        "value": "Siuri"
    },
    {
        "name": "Sivaganga",
        "value": "Sivaganga"
    },
    {
        "name": "Sivagiri",
        "value": "Sivagiri"
    },
    {
        "name": "Sivakasi",
        "value": "Sivakasi"
    },
    {
        "name": "Sivalarkulam",
        "value": "Sivalarkulam"
    },
    {
        "name": "Sivamalai",
        "value": "Sivamalai"
    },
    {
        "name": "Sivandipuram",
        "value": "Sivandipuram"
    },
    {
        "name": "Sivapuram",
        "value": "Sivapuram"
    },
    {
        "name": "Siwan",
        "value": "Siwan"
    },
    {
        "name": "Siyana",
        "value": "Siyana"
    },
    {
        "name": "Soalkuchi",
        "value": "Soalkuchi"
    },
    {
        "name": "Soanpeta",
        "value": "Soanpeta"
    },
    {
        "name": "Sobhapur",
        "value": "Sobhapur"
    },
    {
        "name": "Sobraon",
        "value": "Sobraon"
    },
    {
        "name": "Sodag",
        "value": "Sodag"
    },
    {
        "name": "Sogam",
        "value": "Sogam"
    },
    {
        "name": "Sograha",
        "value": "Sograha"
    },
    {
        "name": "Sohagpur",
        "value": "Sohagpur"
    },
    {
        "name": "Sohana",
        "value": "Sohana"
    },
    {
        "name": "Sohna",
        "value": "Sohna"
    },
    {
        "name": "Sohta",
        "value": "Sohta"
    },
    {
        "name": "Sohtha",
        "value": "Sohtha"
    },
    {
        "name": "Sohwal",
        "value": "Sohwal"
    },
    {
        "name": "Sojat",
        "value": "Sojat"
    },
    {
        "name": "Sojitra",
        "value": "Sojitra"
    },
    {
        "name": "Sokhodewara",
        "value": "Sokhodewara"
    },
    {
        "name": "Solan",
        "value": "Solan"
    },
    {
        "name": "Solapur",
        "value": "Solapur"
    },
    {
        "name": "Solapuram",
        "value": "Solapuram"
    },
    {
        "name": "Solim",
        "value": "Solim"
    },
    {
        "name": "Solindabad",
        "value": "Solindabad"
    },
    {
        "name": "Somandepalle",
        "value": "Somandepalle"
    },
    {
        "name": "Somarasampettai",
        "value": "Somarasampettai"
    },
    {
        "name": "Somavarappatti",
        "value": "Somavarappatti"
    },
    {
        "name": "Somireddipalle",
        "value": "Somireddipalle"
    },
    {
        "name": "Somnaha",
        "value": "Somnaha"
    },
    {
        "name": "Sompeta",
        "value": "Sompeta"
    },
    {
        "name": "Somvarpet",
        "value": "Somvarpet"
    },
    {
        "name": "Sonabedha",
        "value": "Sonabedha"
    },
    {
        "name": "Sonada",
        "value": "Sonada"
    },
    {
        "name": "Sonaimukh",
        "value": "Sonaimukh"
    },
    {
        "name": "Sonakhal",
        "value": "Sonakhal"
    },
    {
        "name": "Sonamukhi",
        "value": "Sonamukhi"
    },
    {
        "name": "Sonapur",
        "value": "Sonapur"
    },
    {
        "name": "Sonari",
        "value": "Sonari"
    },
    {
        "name": "Sonbari",
        "value": "Sonbari"
    },
    {
        "name": "Sonbarsa",
        "value": "Sonbarsa"
    },
    {
        "name": "Sondho Dullah",
        "value": "Sondho Dullah"
    },
    {
        "name": "Sondiha",
        "value": "Sondiha"
    },
    {
        "name": "Sonepur",
        "value": "Sonepur"
    },
    {
        "name": "Songadh",
        "value": "Songadh"
    },
    {
        "name": "Sonhauli",
        "value": "Sonhauli"
    },
    {
        "name": "Sonipat",
        "value": "Sonipat"
    },
    {
        "name": "Sonkach",
        "value": "Sonkach"
    },
    {
        "name": "Sonpur",
        "value": "Sonpur"
    },
    {
        "name": "Sontha",
        "value": "Sontha"
    },
    {
        "name": "Sonupur",
        "value": "Sonupur"
    },
    {
        "name": "Sonwan",
        "value": "Sonwan"
    },
    {
        "name": "Sopur",
        "value": "Sopur"
    },
    {
        "name": "Sorab",
        "value": "Sorab"
    },
    {
        "name": "Sorada",
        "value": "Sorada"
    },
    {
        "name": "Sorala",
        "value": "Sorala"
    },
    {
        "name": "Soro",
        "value": "Soro"
    },
    {
        "name": "Soron",
        "value": "Soron"
    },
    {
        "name": "Sosale",
        "value": "Sosale"
    },
    {
        "name": "Sothgaon",
        "value": "Sothgaon"
    },
    {
        "name": "Sottaiyampalaiyam",
        "value": "Sottaiyampalaiyam"
    },
    {
        "name": "Sowan",
        "value": "Sowan"
    },
    {
        "name": "Soyagaon",
        "value": "Soyagaon"
    },
    {
        "name": "Sri Madhopur",
        "value": "Sri Madhopur"
    },
    {
        "name": "Srikhanda",
        "value": "Srikhanda"
    },
    {
        "name": "Srikrishnapur",
        "value": "Srikrishnapur"
    },
    {
        "name": "Srikurmam",
        "value": "Srikurmam"
    },
    {
        "name": "Srimushnam",
        "value": "Srimushnam"
    },
    {
        "name": "Srinagar",
        "value": "Srinagar"
    },
    {
        "name": "Srinivaspur",
        "value": "Srinivaspur"
    },
    {
        "name": "Sriperumbudur",
        "value": "Sriperumbudur"
    },
    {
        "name": "Sripur",
        "value": "Sripur"
    },
    {
        "name": "Sriramapuram",
        "value": "Sriramapuram"
    },
    {
        "name": "Srirampuram",
        "value": "Srirampuram"
    },
    {
        "name": "Srirangapur",
        "value": "Srirangapur"
    },
    {
        "name": "Srisailain",
        "value": "Srisailain"
    },
    {
        "name": "Srivaikuntam",
        "value": "Srivaikuntam"
    },
    {
        "name": "Srivardhan",
        "value": "Srivardhan"
    },
    {
        "name": "Srivilliputtur",
        "value": "Srivilliputtur"
    },
    {
        "name": "Srungavarapukota",
        "value": "Srungavarapukota"
    },
    {
        "name": "Srvanampatti",
        "value": "Srvanampatti"
    },
    {
        "name": "Suar",
        "value": "Suar"
    },
    {
        "name": "Suchindram",
        "value": "Suchindram"
    },
    {
        "name": "Suganwan",
        "value": "Suganwan"
    },
    {
        "name": "Sugaon",
        "value": "Sugaon"
    },
    {
        "name": "Sugauna",
        "value": "Sugauna"
    },
    {
        "name": "Sughrain",
        "value": "Sughrain"
    },
    {
        "name": "Suhagi",
        "value": "Suhagi"
    },
    {
        "name": "Suhiya",
        "value": "Suhiya"
    },
    {
        "name": "Sujangarh",
        "value": "Sujangarh"
    },
    {
        "name": "Sujanpur",
        "value": "Sujanpur"
    },
    {
        "name": "Sujapur",
        "value": "Sujapur"
    },
    {
        "name": "Sujnipur",
        "value": "Sujnipur"
    },
    {
        "name": "Sukand",
        "value": "Sukand"
    },
    {
        "name": "Sukhasan",
        "value": "Sukhasan"
    },
    {
        "name": "Sukhsena",
        "value": "Sukhsena"
    },
    {
        "name": "Sukkampatti",
        "value": "Sukkampatti"
    },
    {
        "name": "Sukma",
        "value": "Sukma"
    },
    {
        "name": "Suknadanga",
        "value": "Suknadanga"
    },
    {
        "name": "Sukurhutu",
        "value": "Sukurhutu"
    },
    {
        "name": "Sulagiri",
        "value": "Sulagiri"
    },
    {
        "name": "Sulahpet",
        "value": "Sulahpet"
    },
    {
        "name": "Suleswaranpatti",
        "value": "Suleswaranpatti"
    },
    {
        "name": "Sulibele",
        "value": "Sulibele"
    },
    {
        "name": "Sulleru",
        "value": "Sulleru"
    },
    {
        "name": "Sultanabad",
        "value": "Sultanabad"
    },
    {
        "name": "Sultanganj",
        "value": "Sultanganj"
    },
    {
        "name": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Sultanpur Mazra",
        "value": "Sultanpur Mazra"
    },
    {
        "name": "Suluru",
        "value": "Suluru"
    },
    {
        "name": "Sulya",
        "value": "Sulya"
    },
    {
        "name": "Sumbal",
        "value": "Sumbal"
    },
    {
        "name": "Sumbha",
        "value": "Sumbha"
    },
    {
        "name": "Sunadkuppi",
        "value": "Sunadkuppi"
    },
    {
        "name": "Sunam",
        "value": "Sunam"
    },
    {
        "name": "Sundapalaiyam",
        "value": "Sundapalaiyam"
    },
    {
        "name": "Sundarapandiyam",
        "value": "Sundarapandiyam"
    },
    {
        "name": "Sundararaopeta",
        "value": "Sundararaopeta"
    },
    {
        "name": "Sundargarh",
        "value": "Sundargarh"
    },
    {
        "name": "Sundarnagar",
        "value": "Sundarnagar"
    },
    {
        "name": "Sundarpur",
        "value": "Sundarpur"
    },
    {
        "name": "Sundarsi",
        "value": "Sundarsi"
    },
    {
        "name": "Sundekuppam",
        "value": "Sundekuppam"
    },
    {
        "name": "Sungal",
        "value": "Sungal"
    },
    {
        "name": "Sunkarevu",
        "value": "Sunkarevu"
    },
    {
        "name": "Supaul",
        "value": "Supaul"
    },
    {
        "name": "Sur Singh",
        "value": "Sur Singh"
    },
    {
        "name": "Surajgarha",
        "value": "Surajgarha"
    },
    {
        "name": "Surajpura",
        "value": "Surajpura"
    },
    {
        "name": "Suramala",
        "value": "Suramala"
    },
    {
        "name": "Surampatti",
        "value": "Surampatti"
    },
    {
        "name": "Surandai",
        "value": "Surandai"
    },
    {
        "name": "Surappalli",
        "value": "Surappalli"
    },
    {
        "name": "Surat",
        "value": "Surat"
    },
    {
        "name": "Suratgarh",
        "value": "Suratgarh"
    },
    {
        "name": "Surendranagar",
        "value": "Surendranagar"
    },
    {
        "name": "Suriapet",
        "value": "Suriapet"
    },
    {
        "name": "Surir",
        "value": "Surir"
    },
    {
        "name": "Surla",
        "value": "Surla"
    },
    {
        "name": "Suroth",
        "value": "Suroth"
    },
    {
        "name": "Surpur",
        "value": "Surpur"
    },
    {
        "name": "Sursand",
        "value": "Sursand"
    },
    {
        "name": "Susari",
        "value": "Susari"
    },
    {
        "name": "Susner",
        "value": "Susner"
    },
    {
        "name": "Suthalia",
        "value": "Suthalia"
    },
    {
        "name": "Sutihar",
        "value": "Sutihar"
    },
    {
        "name": "Suttamalli",
        "value": "Suttamalli"
    },
    {
        "name": "Suwasra",
        "value": "Suwasra"
    },
    {
        "name": "Swamimalai",
        "value": "Swamimalai"
    },
    {
        "name": "Swarna",
        "value": "Swarna"
    },
    {
        "name": "Tabhka Khas",
        "value": "Tabhka Khas"
    },
    {
        "name": "Tadangam",
        "value": "Tadangam"
    },
    {
        "name": "Tadapurambakkam",
        "value": "Tadapurambakkam"
    },
    {
        "name": "Tadas",
        "value": "Tadas"
    },
    {
        "name": "Tadepalle",
        "value": "Tadepalle"
    },
    {
        "name": "Tadepallegudem",
        "value": "Tadepallegudem"
    },
    {
        "name": "Tadhwa Nandpur",
        "value": "Tadhwa Nandpur"
    },
    {
        "name": "Tadigadapa",
        "value": "Tadigadapa"
    },
    {
        "name": "Tadikalapudi",
        "value": "Tadikalapudi"
    },
    {
        "name": "Tadikombu",
        "value": "Tadikombu"
    },
    {
        "name": "Tadikonda",
        "value": "Tadikonda"
    },
    {
        "name": "Tadinada",
        "value": "Tadinada"
    },
    {
        "name": "Tadpatri",
        "value": "Tadpatri"
    },
    {
        "name": "Tagazhi",
        "value": "Tagazhi"
    },
    {
        "name": "Taisar",
        "value": "Taisar"
    },
    {
        "name": "Taiyur",
        "value": "Taiyur"
    },
    {
        "name": "Tajpur",
        "value": "Tajpur"
    },
    {
        "name": "Takhatgarh",
        "value": "Takhatgarh"
    },
    {
        "name": "Taki",
        "value": "Taki"
    },
    {
        "name": "Takkali",
        "value": "Takkali"
    },
    {
        "name": "Takkolam",
        "value": "Takkolam"
    },
    {
        "name": "Tala",
        "value": "Tala"
    },
    {
        "name": "Talainayar Agraharam",
        "value": "Talainayar Agraharam"
    },
    {
        "name": "Talaivasal",
        "value": "Talaivasal"
    },
    {
        "name": "Talaja",
        "value": "Talaja"
    },
    {
        "name": "Talakad",
        "value": "Talakad"
    },
    {
        "name": "Talakkad",
        "value": "Talakkad"
    },
    {
        "name": "Talakulam",
        "value": "Talakulam"
    },
    {
        "name": "Talapalli",
        "value": "Talapalli"
    },
    {
        "name": "Talavadi",
        "value": "Talavadi"
    },
    {
        "name": "Talayazham",
        "value": "Talayazham"
    },
    {
        "name": "Talayolaparambu",
        "value": "Talayolaparambu"
    },
    {
        "name": "Talbahat",
        "value": "Talbahat"
    },
    {
        "name": "Talcher",
        "value": "Talcher"
    },
    {
        "name": "Talegaon Dabhade",
        "value": "Talegaon Dabhade"
    },
    {
        "name": "Talegaon Dhamdhere",
        "value": "Talegaon Dhamdhere"
    },
    {
        "name": "Talen",
        "value": "Talen"
    },
    {
        "name": "Talevad",
        "value": "Talevad"
    },
    {
        "name": "Talikota",
        "value": "Talikota"
    },
    {
        "name": "Talipparamba",
        "value": "Talipparamba"
    },
    {
        "name": "Taloda",
        "value": "Taloda"
    },
    {
        "name": "Talsur",
        "value": "Talsur"
    },
    {
        "name": "Talugai",
        "value": "Talugai"
    },
    {
        "name": "Talukkara",
        "value": "Talukkara"
    },
    {
        "name": "Talupula",
        "value": "Talupula"
    },
    {
        "name": "Talwandi Bhai",
        "value": "Talwandi Bhai"
    },
    {
        "name": "Talwandi Chaudhrian",
        "value": "Talwandi Chaudhrian"
    },
    {
        "name": "Talwandi Sabo",
        "value": "Talwandi Sabo"
    },
    {
        "name": "Talwara",
        "value": "Talwara"
    },
    {
        "name": "Talya",
        "value": "Talya"
    },
    {
        "name": "Tamar",
        "value": "Tamar"
    },
    {
        "name": "Tamaraikkulam",
        "value": "Tamaraikkulam"
    },
    {
        "name": "Tamarakulam",
        "value": "Tamarakulam"
    },
    {
        "name": "Tamarankottai",
        "value": "Tamarankottai"
    },
    {
        "name": "Tamba",
        "value": "Tamba"
    },
    {
        "name": "Tambaram",
        "value": "Tambaram"
    },
    {
        "name": "Tamganj",
        "value": "Tamganj"
    },
    {
        "name": "Tamluk",
        "value": "Tamluk"
    },
    {
        "name": "Tammampatti",
        "value": "Tammampatti"
    },
    {
        "name": "Tanakallu",
        "value": "Tanakallu"
    },
    {
        "name": "Tanakkangulam",
        "value": "Tanakkangulam"
    },
    {
        "name": "Tanakpur",
        "value": "Tanakpur"
    },
    {
        "name": "Tandarampattu",
        "value": "Tandarampattu"
    },
    {
        "name": "Tandur",
        "value": "Tandur"
    },
    {
        "name": "Tandwa",
        "value": "Tandwa"
    },
    {
        "name": "Tanguturu",
        "value": "Tanguturu"
    },
    {
        "name": "Tanichchiyam",
        "value": "Tanichchiyam"
    },
    {
        "name": "Tanippadi",
        "value": "Tanippadi"
    },
    {
        "name": "Tanjore",
        "value": "Tanjore"
    },
    {
        "name": "Tankara",
        "value": "Tankara"
    },
    {
        "name": "Tansandra",
        "value": "Tansandra"
    },
    {
        "name": "Tanuku",
        "value": "Tanuku"
    },
    {
        "name": "Tapa",
        "value": "Tapa"
    },
    {
        "name": "Tarabha",
        "value": "Tarabha"
    },
    {
        "name": "Tarakeswar",
        "value": "Tarakeswar"
    },
    {
        "name": "Taramangalam",
        "value": "Taramangalam"
    },
    {
        "name": "Taran",
        "value": "Taran"
    },
    {
        "name": "Tarana",
        "value": "Tarana"
    },
    {
        "name": "Taranagar",
        "value": "Taranagar"
    },
    {
        "name": "Tarar",
        "value": "Tarar"
    },
    {
        "name": "Tarauna",
        "value": "Tarauna"
    },
    {
        "name": "Tarawan",
        "value": "Tarawan"
    },
    {
        "name": "Tarazu",
        "value": "Tarazu"
    },
    {
        "name": "Tarichar Kalan",
        "value": "Tarichar Kalan"
    },
    {
        "name": "Tarikere",
        "value": "Tarikere"
    },
    {
        "name": "Tarkeshwar",
        "value": "Tarkeshwar"
    },
    {
        "name": "Tarlapalli",
        "value": "Tarlapalli"
    },
    {
        "name": "Tarn Taran",
        "value": "Tarn Taran"
    },
    {
        "name": "Tarur",
        "value": "Tarur"
    },
    {
        "name": "Tarwara",
        "value": "Tarwara"
    },
    {
        "name": "Tasgaon",
        "value": "Tasgaon"
    },
    {
        "name": "Tati",
        "value": "Tati"
    },
    {
        "name": "Tattamangalam",
        "value": "Tattamangalam"
    },
    {
        "name": "Taulaha",
        "value": "Taulaha"
    },
    {
        "name": "Tawargeri",
        "value": "Tawargeri"
    },
    {
        "name": "Tazhakara",
        "value": "Tazhakara"
    },
    {
        "name": "Tazhakudi",
        "value": "Tazhakudi"
    },
    {
        "name": "Tazhava",
        "value": "Tazhava"
    },
    {
        "name": "Teghra",
        "value": "Teghra"
    },
    {
        "name": "Teghra English",
        "value": "Teghra English"
    },
    {
        "name": "Tehata",
        "value": "Tehata"
    },
    {
        "name": "Tehri",
        "value": "Tehri"
    },
    {
        "name": "Tekanpur",
        "value": "Tekanpur"
    },
    {
        "name": "Tekari",
        "value": "Tekari"
    },
    {
        "name": "Tekkalakote",
        "value": "Tekkalakote"
    },
    {
        "name": "Tekkali",
        "value": "Tekkali"
    },
    {
        "name": "Tekkampatti",
        "value": "Tekkampatti"
    },
    {
        "name": "Tekkattur",
        "value": "Tekkattur"
    },
    {
        "name": "Tekkebhagam",
        "value": "Tekkebhagam"
    },
    {
        "name": "Tekkekara",
        "value": "Tekkekara"
    },
    {
        "name": "Tekkekara Kizhakku",
        "value": "Tekkekara Kizhakku"
    },
    {
        "name": "Tekkumbagam",
        "value": "Tekkumbagam"
    },
    {
        "name": "Tekpanja",
        "value": "Tekpanja"
    },
    {
        "name": "Telaprolu",
        "value": "Telaprolu"
    },
    {
        "name": "Telkap",
        "value": "Telkap"
    },
    {
        "name": "Telkapalli",
        "value": "Telkapalli"
    },
    {
        "name": "Telkathu",
        "value": "Telkathu"
    },
    {
        "name": "Tellar",
        "value": "Tellar"
    },
    {
        "name": "Tellicherry",
        "value": "Tellicherry"
    },
    {
        "name": "Telmar",
        "value": "Telmar"
    },
    {
        "name": "Telnal",
        "value": "Telnal"
    },
    {
        "name": "Telpur",
        "value": "Telpur"
    },
    {
        "name": "Telsang",
        "value": "Telsang"
    },
    {
        "name": "Telua",
        "value": "Telua"
    },
    {
        "name": "Telwa",
        "value": "Telwa"
    },
    {
        "name": "Tenali",
        "value": "Tenali"
    },
    {
        "name": "Tenambakkam",
        "value": "Tenambakkam"
    },
    {
        "name": "Tendukheda",
        "value": "Tendukheda"
    },
    {
        "name": "Tengampudur",
        "value": "Tengampudur"
    },
    {
        "name": "Tenkasi",
        "value": "Tenkasi"
    },
    {
        "name": "Tenmalai",
        "value": "Tenmalai"
    },
    {
        "name": "Tennala",
        "value": "Tennala"
    },
    {
        "name": "Teonthar",
        "value": "Teonthar"
    },
    {
        "name": "Tepperumalnallur",
        "value": "Tepperumalnallur"
    },
    {
        "name": "Teranikallu",
        "value": "Teranikallu"
    },
    {
        "name": "Terdal",
        "value": "Terdal"
    },
    {
        "name": "Terku Narippaiyur",
        "value": "Terku Narippaiyur"
    },
    {
        "name": "Terku Valliyur",
        "value": "Terku Valliyur"
    },
    {
        "name": "Terkuvenganallur",
        "value": "Terkuvenganallur"
    },
    {
        "name": "Tetagunta",
        "value": "Tetagunta"
    },
    {
        "name": "Tetari",
        "value": "Tetari"
    },
    {
        "name": "Tettu",
        "value": "Tettu"
    },
    {
        "name": "Tettuppatti",
        "value": "Tettuppatti"
    },
    {
        "name": "Teus",
        "value": "Teus"
    },
    {
        "name": "Tevaram",
        "value": "Tevaram"
    },
    {
        "name": "Tezpur",
        "value": "Tezpur"
    },
    {
        "name": "Tezu",
        "value": "Tezu"
    },
    {
        "name": "Thair",
        "value": "Thair"
    },
    {
        "name": "Thakraha",
        "value": "Thakraha"
    },
    {
        "name": "Thakurainia",
        "value": "Thakurainia"
    },
    {
        "name": "Than",
        "value": "Than"
    },
    {
        "name": "Thana Bhawan",
        "value": "Thana Bhawan"
    },
    {
        "name": "Thandewala",
        "value": "Thandewala"
    },
    {
        "name": "Thandla",
        "value": "Thandla"
    },
    {
        "name": "Thane",
        "value": "Thane"
    },
    {
        "name": "Thanesar",
        "value": "Thanesar"
    },
    {
        "name": "Thara",
        "value": "Thara"
    },
    {
        "name": "Tharad",
        "value": "Tharad"
    },
    {
        "name": "Tharial",
        "value": "Tharial"
    },
    {
        "name": "Tharike",
        "value": "Tharike"
    },
    {
        "name": "Thatha",
        "value": "Thatha"
    },
    {
        "name": "Thathupur",
        "value": "Thathupur"
    },
    {
        "name": "Thatri",
        "value": "Thatri"
    },
    {
        "name": "Thazhamel",
        "value": "Thazhamel"
    },
    {
        "name": "Thepaha Raja Ram",
        "value": "Thepaha Raja Ram"
    },
    {
        "name": "Thevur",
        "value": "Thevur"
    },
    {
        "name": "Thikri",
        "value": "Thikri"
    },
    {
        "name": "Thikriwala",
        "value": "Thikriwala"
    },
    {
        "name": "Thillangeri",
        "value": "Thillangeri"
    },
    {
        "name": "Thimiri",
        "value": "Thimiri"
    },
    {
        "name": "Thiruvananthapuram",
        "value": "Thiruvananthapuram"
    },
    {
        "name": "Thogaduru",
        "value": "Thogaduru"
    },
    {
        "name": "Thogapalle",
        "value": "Thogapalle"
    },
    {
        "name": "Thol",
        "value": "Thol"
    },
    {
        "name": "Tholikuzhi",
        "value": "Tholikuzhi"
    },
    {
        "name": "Thondiamannu",
        "value": "Thondiamannu"
    },
    {
        "name": "Thoubal",
        "value": "Thoubal"
    },
    {
        "name": "Tibba",
        "value": "Tibba"
    },
    {
        "name": "Tibbar",
        "value": "Tibbar"
    },
    {
        "name": "Tibri",
        "value": "Tibri"
    },
    {
        "name": "Tigaon",
        "value": "Tigaon"
    },
    {
        "name": "Tigrana",
        "value": "Tigrana"
    },
    {
        "name": "Tikamgarh",
        "value": "Tikamgarh"
    },
    {
        "name": "Tikar",
        "value": "Tikar"
    },
    {
        "name": "Tikota",
        "value": "Tikota"
    },
    {
        "name": "Tilari",
        "value": "Tilari"
    },
    {
        "name": "Tilhar",
        "value": "Tilhar"
    },
    {
        "name": "Tillaivilagam",
        "value": "Tillaivilagam"
    },
    {
        "name": "Tillor Khurd",
        "value": "Tillor Khurd"
    },
    {
        "name": "Tilothu",
        "value": "Tilothu"
    },
    {
        "name": "Tilvalli",
        "value": "Tilvalli"
    },
    {
        "name": "Timmapur",
        "value": "Timmapur"
    },
    {
        "name": "Timmapuram",
        "value": "Timmapuram"
    },
    {
        "name": "Timmarasanayakkanur",
        "value": "Timmarasanayakkanur"
    },
    {
        "name": "Timri",
        "value": "Timri"
    },
    {
        "name": "Timurni",
        "value": "Timurni"
    },
    {
        "name": "Tindivanam",
        "value": "Tindivanam"
    },
    {
        "name": "Tindwara",
        "value": "Tindwara"
    },
    {
        "name": "Tinkhang",
        "value": "Tinkhang"
    },
    {
        "name": "Tinkoni",
        "value": "Tinkoni"
    },
    {
        "name": "Tinnanur",
        "value": "Tinnanur"
    },
    {
        "name": "Tinnevelly",
        "value": "Tinnevelly"
    },
    {
        "name": "Tinsukia",
        "value": "Tinsukia"
    },
    {
        "name": "Tiorpara",
        "value": "Tiorpara"
    },
    {
        "name": "Tiptur",
        "value": "Tiptur"
    },
    {
        "name": "Tiri",
        "value": "Tiri"
    },
    {
        "name": "Tirkadavur",
        "value": "Tirkadavur"
    },
    {
        "name": "Tirkakara",
        "value": "Tirkakara"
    },
    {
        "name": "Tirkarur",
        "value": "Tirkarur"
    },
    {
        "name": "Tirkha",
        "value": "Tirkha"
    },
    {
        "name": "Tirmaigiri",
        "value": "Tirmaigiri"
    },
    {
        "name": "Tirmalgiri",
        "value": "Tirmalgiri"
    },
    {
        "name": "Tirodi",
        "value": "Tirodi"
    },
    {
        "name": "Tirora",
        "value": "Tirora"
    },
    {
        "name": "Tirthahalli",
        "value": "Tirthahalli"
    },
    {
        "name": "Tirubhuvane",
        "value": "Tirubhuvane"
    },
    {
        "name": "Tiruchchuli",
        "value": "Tiruchchuli"
    },
    {
        "name": "Tiruchendur",
        "value": "Tiruchendur"
    },
    {
        "name": "Tiruchengodu",
        "value": "Tiruchengodu"
    },
    {
        "name": "Tirukkalikkunram",
        "value": "Tirukkalikkunram"
    },
    {
        "name": "Tirukkattuppalli",
        "value": "Tirukkattuppalli"
    },
    {
        "name": "Tirukkoyilur",
        "value": "Tirukkoyilur"
    },
    {
        "name": "Tirumakudal Narsipur",
        "value": "Tirumakudal Narsipur"
    },
    {
        "name": "Tirumala",
        "value": "Tirumala"
    },
    {
        "name": "Tirumalaippatti",
        "value": "Tirumalaippatti"
    },
    {
        "name": "Tirumalaiyampalaiyam",
        "value": "Tirumalaiyampalaiyam"
    },
    {
        "name": "Tirumangalam",
        "value": "Tirumangalam"
    },
    {
        "name": "Tirumayam",
        "value": "Tirumayam"
    },
    {
        "name": "Tirumuruganpundi",
        "value": "Tirumuruganpundi"
    },
    {
        "name": "Tirunageswaram",
        "value": "Tirunageswaram"
    },
    {
        "name": "Tirupati",
        "value": "Tirupati"
    },
    {
        "name": "Tiruppachur",
        "value": "Tiruppachur"
    },
    {
        "name": "Tiruppalai",
        "value": "Tiruppalai"
    },
    {
        "name": "Tiruppalaikudi",
        "value": "Tiruppalaikudi"
    },
    {
        "name": "Tiruppanandal",
        "value": "Tiruppanandal"
    },
    {
        "name": "Tirupparangunram",
        "value": "Tirupparangunram"
    },
    {
        "name": "Tiruppattur",
        "value": "Tiruppattur"
    },
    {
        "name": "Tirupporur",
        "value": "Tirupporur"
    },
    {
        "name": "Tiruppur",
        "value": "Tiruppur"
    },
    {
        "name": "Tiruppuvanam",
        "value": "Tiruppuvanam"
    },
    {
        "name": "Tirur",
        "value": "Tirur"
    },
    {
        "name": "Tiruttangal",
        "value": "Tiruttangal"
    },
    {
        "name": "Tiruttani",
        "value": "Tiruttani"
    },
    {
        "name": "Tirutturaippundi",
        "value": "Tirutturaippundi"
    },
    {
        "name": "Tiruvadanai",
        "value": "Tiruvadanai"
    },
    {
        "name": "Tiruvadi",
        "value": "Tiruvadi"
    },
    {
        "name": "Tiruvaduturai",
        "value": "Tiruvaduturai"
    },
    {
        "name": "Tiruvalam",
        "value": "Tiruvalam"
    },
    {
        "name": "Tiruvalanjuli",
        "value": "Tiruvalanjuli"
    },
    {
        "name": "Tiruvalla",
        "value": "Tiruvalla"
    },
    {
        "name": "Tiruvallur",
        "value": "Tiruvallur"
    },
    {
        "name": "Tiruvalur",
        "value": "Tiruvalur"
    },
    {
        "name": "Tiruvambadi",
        "value": "Tiruvambadi"
    },
    {
        "name": "Tiruvambalapuram",
        "value": "Tiruvambalapuram"
    },
    {
        "name": "Tiruvankod",
        "value": "Tiruvankod"
    },
    {
        "name": "Tiruvannamalai",
        "value": "Tiruvannamalai"
    },
    {
        "name": "Tiruvarpu",
        "value": "Tiruvarpu"
    },
    {
        "name": "Tiruvasaladi",
        "value": "Tiruvasaladi"
    },
    {
        "name": "Tiruvattar",
        "value": "Tiruvattar"
    },
    {
        "name": "Tiruvegapra",
        "value": "Tiruvegapra"
    },
    {
        "name": "Tiruvengadam",
        "value": "Tiruvengadam"
    },
    {
        "name": "Tiruvennanallur",
        "value": "Tiruvennanallur"
    },
    {
        "name": "Tiruverumbur",
        "value": "Tiruverumbur"
    },
    {
        "name": "Tiruvottiyur",
        "value": "Tiruvottiyur"
    },
    {
        "name": "Tiruvur",
        "value": "Tiruvur"
    },
    {
        "name": "Tirwa",
        "value": "Tirwa"
    },
    {
        "name": "Titagarh",
        "value": "Titagarh"
    },
    {
        "name": "Titara",
        "value": "Titara"
    },
    {
        "name": "Titlagarh",
        "value": "Titlagarh"
    },
    {
        "name": "Tittachcheri",
        "value": "Tittachcheri"
    },
    {
        "name": "Tittagudi",
        "value": "Tittagudi"
    },
    {
        "name": "Tivim",
        "value": "Tivim"
    },
    {
        "name": "Toda Bhim",
        "value": "Toda Bhim"
    },
    {
        "name": "Toda Rai Singh",
        "value": "Toda Rai Singh"
    },
    {
        "name": "Todupulai",
        "value": "Todupulai"
    },
    {
        "name": "Togamalai",
        "value": "Togamalai"
    },
    {
        "name": "Tohana",
        "value": "Tohana"
    },
    {
        "name": "Tokatippa",
        "value": "Tokatippa"
    },
    {
        "name": "Tokkavadi",
        "value": "Tokkavadi"
    },
    {
        "name": "Tola Khadda",
        "value": "Tola Khadda"
    },
    {
        "name": "Toluprpatti",
        "value": "Toluprpatti"
    },
    {
        "name": "Tonakkal",
        "value": "Tonakkal"
    },
    {
        "name": "Tondangi",
        "value": "Tondangi"
    },
    {
        "name": "Tondi",
        "value": "Tondi"
    },
    {
        "name": "Tonk",
        "value": "Tonk"
    },
    {
        "name": "Tonk Khurd",
        "value": "Tonk Khurd"
    },
    {
        "name": "Tonse East",
        "value": "Tonse East"
    },
    {
        "name": "Tonse West",
        "value": "Tonse West"
    },
    {
        "name": "Tora",
        "value": "Tora"
    },
    {
        "name": "Torpa",
        "value": "Torpa"
    },
    {
        "name": "Tottiyam",
        "value": "Tottiyam"
    },
    {
        "name": "Tovala",
        "value": "Tovala"
    },
    {
        "name": "Tranquebar",
        "value": "Tranquebar"
    },
    {
        "name": "Trichinopoly",
        "value": "Trichinopoly"
    },
    {
        "name": "Trichur",
        "value": "Trichur"
    },
    {
        "name": "Trikarpur North",
        "value": "Trikarpur North"
    },
    {
        "name": "Trikkunnapuzha",
        "value": "Trikkunnapuzha"
    },
    {
        "name": "Trikodi",
        "value": "Trikodi"
    },
    {
        "name": "Trikonavattam",
        "value": "Trikonavattam"
    },
    {
        "name": "Triprangottur",
        "value": "Triprangottur"
    },
    {
        "name": "Tripunittura",
        "value": "Tripunittura"
    },
    {
        "name": "Tripurantakam",
        "value": "Tripurantakam"
    },
    {
        "name": "Trisshileri",
        "value": "Trisshileri"
    },
    {
        "name": "Trovagunta",
        "value": "Trovagunta"
    },
    {
        "name": "Tsallagundla",
        "value": "Tsallagundla"
    },
    {
        "name": "Tsrar Sharif",
        "value": "Tsrar Sharif"
    },
    {
        "name": "Tsundupalle",
        "value": "Tsundupalle"
    },
    {
        "name": "Tudiyalur",
        "value": "Tudiyalur"
    },
    {
        "name": "Tuensang",
        "value": "Tuensang"
    },
    {
        "name": "Tufanganj",
        "value": "Tufanganj"
    },
    {
        "name": "Tulin",
        "value": "Tulin"
    },
    {
        "name": "Tuljapur",
        "value": "Tuljapur"
    },
    {
        "name": "Tullukuttinayakkanur",
        "value": "Tullukuttinayakkanur"
    },
    {
        "name": "Tulshia",
        "value": "Tulshia"
    },
    {
        "name": "Tulsipur",
        "value": "Tulsipur"
    },
    {
        "name": "Tumberi",
        "value": "Tumberi"
    },
    {
        "name": "Tumbippadi",
        "value": "Tumbippadi"
    },
    {
        "name": "Tuminkatti",
        "value": "Tuminkatti"
    },
    {
        "name": "Tumkur",
        "value": "Tumkur"
    },
    {
        "name": "Tummalacheruvu",
        "value": "Tummalacheruvu"
    },
    {
        "name": "Tummalapenta",
        "value": "Tummalapenta"
    },
    {
        "name": "Tummanatti",
        "value": "Tummanatti"
    },
    {
        "name": "Tummapala",
        "value": "Tummapala"
    },
    {
        "name": "Tundhul",
        "value": "Tundhul"
    },
    {
        "name": "Tundla",
        "value": "Tundla"
    },
    {
        "name": "Tuneri",
        "value": "Tuneri"
    },
    {
        "name": "Tungavi",
        "value": "Tungavi"
    },
    {
        "name": "Tuni",
        "value": "Tuni"
    },
    {
        "name": "Tupran",
        "value": "Tupran"
    },
    {
        "name": "Tura",
        "value": "Tura"
    },
    {
        "name": "Turaiyur",
        "value": "Turaiyur"
    },
    {
        "name": "Turbihal",
        "value": "Turbihal"
    },
    {
        "name": "Turhapatti",
        "value": "Turhapatti"
    },
    {
        "name": "Turkaguda",
        "value": "Turkaguda"
    },
    {
        "name": "Turkauliya",
        "value": "Turkauliya"
    },
    {
        "name": "Turki",
        "value": "Turki"
    },
    {
        "name": "Turori",
        "value": "Turori"
    },
    {
        "name": "Turuttikkara",
        "value": "Turuttikkara"
    },
    {
        "name": "Turuttiyad",
        "value": "Turuttiyad"
    },
    {
        "name": "Turuvanur",
        "value": "Turuvanur"
    },
    {
        "name": "Turuvekere",
        "value": "Turuvekere"
    },
    {
        "name": "Tuticorin",
        "value": "Tuticorin"
    },
    {
        "name": "Tuvagudi",
        "value": "Tuvagudi"
    },
    {
        "name": "Tuvur",
        "value": "Tuvur"
    },
    {
        "name": "Tyagadurgam",
        "value": "Tyagadurgam"
    },
    {
        "name": "Tyamagondal",
        "value": "Tyamagondal"
    },
    {
        "name": "Ubaidullahganj",
        "value": "Ubaidullahganj"
    },
    {
        "name": "Uchchangidurgam",
        "value": "Uchchangidurgam"
    },
    {
        "name": "Uchen",
        "value": "Uchen"
    },
    {
        "name": "Uchti",
        "value": "Uchti"
    },
    {
        "name": "Udaipur",
        "value": "Udaipur"
    },
    {
        "name": "Udaipur Bithwar",
        "value": "Udaipur Bithwar"
    },
    {
        "name": "Udaipura",
        "value": "Udaipura"
    },
    {
        "name": "Udala",
        "value": "Udala"
    },
    {
        "name": "Udalguri",
        "value": "Udalguri"
    },
    {
        "name": "Udamalpet",
        "value": "Udamalpet"
    },
    {
        "name": "Udarband",
        "value": "Udarband"
    },
    {
        "name": "Udawantnagar",
        "value": "Udawantnagar"
    },
    {
        "name": "Udayagiri",
        "value": "Udayagiri"
    },
    {
        "name": "Udayendram",
        "value": "Udayendram"
    },
    {
        "name": "Udburu",
        "value": "Udburu"
    },
    {
        "name": "Udhampur",
        "value": "Udhampur"
    },
    {
        "name": "Udipi",
        "value": "Udipi"
    },
    {
        "name": "Udiyavara",
        "value": "Udiyavara"
    },
    {
        "name": "Udumanthala",
        "value": "Udumanthala"
    },
    {
        "name": "Ugamedi",
        "value": "Ugamedi"
    },
    {
        "name": "Ughara",
        "value": "Ughara"
    },
    {
        "name": "Ujhana",
        "value": "Ujhana"
    },
    {
        "name": "Ujjain",
        "value": "Ujjain"
    },
    {
        "name": "Ujre",
        "value": "Ujre"
    },
    {
        "name": "Ukal",
        "value": "Ukal"
    },
    {
        "name": "Ukhai Purbari Patti",
        "value": "Ukhai Purbari Patti"
    },
    {
        "name": "Ukkayapalle",
        "value": "Ukkayapalle"
    },
    {
        "name": "Ukwa",
        "value": "Ukwa"
    },
    {
        "name": "Ulao",
        "value": "Ulao"
    },
    {
        "name": "Ulatu",
        "value": "Ulatu"
    },
    {
        "name": "Ulhasnagar",
        "value": "Ulhasnagar"
    },
    {
        "name": "Ulipuram",
        "value": "Ulipuram"
    },
    {
        "name": "Ullal",
        "value": "Ullal"
    },
    {
        "name": "Ulliyeri",
        "value": "Ulliyeri"
    },
    {
        "name": "Ulliyil",
        "value": "Ulliyil"
    },
    {
        "name": "Ullur",
        "value": "Ullur"
    },
    {
        "name": "Uluberiya",
        "value": "Uluberiya"
    },
    {
        "name": "Umarga",
        "value": "Umarga"
    },
    {
        "name": "Umargam",
        "value": "Umargam"
    },
    {
        "name": "Umaria",
        "value": "Umaria"
    },
    {
        "name": "Umarkhed",
        "value": "Umarkhed"
    },
    {
        "name": "Umarkot",
        "value": "Umarkot"
    },
    {
        "name": "Umga",
        "value": "Umga"
    },
    {
        "name": "Ummannur",
        "value": "Ummannur"
    },
    {
        "name": "Umrapur",
        "value": "Umrapur"
    },
    {
        "name": "Umrat",
        "value": "Umrat"
    },
    {
        "name": "Umred",
        "value": "Umred"
    },
    {
        "name": "Umreth",
        "value": "Umreth"
    },
    {
        "name": "Umri",
        "value": "Umri"
    },
    {
        "name": "Una",
        "value": "Una"
    },
    {
        "name": "Unagatla",
        "value": "Unagatla"
    },
    {
        "name": "Unao",
        "value": "Unao"
    },
    {
        "name": "Unchagao",
        "value": "Unchagao"
    },
    {
        "name": "Unchahra",
        "value": "Unchahra"
    },
    {
        "name": "Undavalli",
        "value": "Undavalli"
    },
    {
        "name": "Undi",
        "value": "Undi"
    },
    {
        "name": "Undrajavaram",
        "value": "Undrajavaram"
    },
    {
        "name": "Unguturu",
        "value": "Unguturu"
    },
    {
        "name": "Unhel",
        "value": "Unhel"
    },
    {
        "name": "Unjha",
        "value": "Unjha"
    },
    {
        "name": "Unnao",
        "value": "Unnao"
    },
    {
        "name": "Upleta",
        "value": "Upleta"
    },
    {
        "name": "Uppada",
        "value": "Uppada"
    },
    {
        "name": "Uppalaguptam",
        "value": "Uppalaguptam"
    },
    {
        "name": "Uppalapadu",
        "value": "Uppalapadu"
    },
    {
        "name": "Upperu",
        "value": "Upperu"
    },
    {
        "name": "Uppidamangalam",
        "value": "Uppidamangalam"
    },
    {
        "name": "Uppugunduru",
        "value": "Uppugunduru"
    },
    {
        "name": "Uppukkottai",
        "value": "Uppukkottai"
    },
    {
        "name": "Uppur",
        "value": "Uppur"
    },
    {
        "name": "Uran",
        "value": "Uran"
    },
    {
        "name": "Uranganpatti",
        "value": "Uranganpatti"
    },
    {
        "name": "Uravakonda",
        "value": "Uravakonda"
    },
    {
        "name": "Urlaha",
        "value": "Urlaha"
    },
    {
        "name": "Urpaar",
        "value": "Urpaar"
    },
    {
        "name": "Urun-Islampur",
        "value": "Urun-Islampur"
    },
    {
        "name": "Usgao",
        "value": "Usgao"
    },
    {
        "name": "Usia",
        "value": "Usia"
    },
    {
        "name": "Usilampatti",
        "value": "Usilampatti"
    },
    {
        "name": "Usmanpur",
        "value": "Usmanpur"
    },
    {
        "name": "Usuppur",
        "value": "Usuppur"
    },
    {
        "name": "Utehia",
        "value": "Utehia"
    },
    {
        "name": "Utnur",
        "value": "Utnur"
    },
    {
        "name": "Utraula",
        "value": "Utraula"
    },
    {
        "name": "Uttamapalaiyam",
        "value": "Uttamapalaiyam"
    },
    {
        "name": "Uttaramerur",
        "value": "Uttaramerur"
    },
    {
        "name": "Uttarkashi",
        "value": "Uttarkashi"
    },
    {
        "name": "Uttarpara",
        "value": "Uttarpara"
    },
    {
        "name": "Uttukkottai",
        "value": "Uttukkottai"
    },
    {
        "name": "Uttukkuli",
        "value": "Uttukkuli"
    },
    {
        "name": "Vadacheri",
        "value": "Vadacheri"
    },
    {
        "name": "Vadakadu",
        "value": "Vadakadu"
    },
    {
        "name": "Vadakakarai",
        "value": "Vadakakarai"
    },
    {
        "name": "Vadakarai Kil Pidagai",
        "value": "Vadakarai Kil Pidagai"
    },
    {
        "name": "Vadakethara",
        "value": "Vadakethara"
    },
    {
        "name": "Vadakkanandal",
        "value": "Vadakkanandal"
    },
    {
        "name": "Vadakkangara",
        "value": "Vadakkangara"
    },
    {
        "name": "Vadakku Ariyanayakipuram",
        "value": "Vadakku Ariyanayakipuram"
    },
    {
        "name": "Vadakku Valliyur",
        "value": "Vadakku Valliyur"
    },
    {
        "name": "Vadakku Viravanallur",
        "value": "Vadakku Viravanallur"
    },
    {
        "name": "Vadakkum",
        "value": "Vadakkum"
    },
    {
        "name": "Vadakkumbagam",
        "value": "Vadakkumbagam"
    },
    {
        "name": "Vadamadurai",
        "value": "Vadamadurai"
    },
    {
        "name": "Vadamugam Vellodu",
        "value": "Vadamugam Vellodu"
    },
    {
        "name": "Vadapalanji",
        "value": "Vadapalanji"
    },
    {
        "name": "Vadasikarambattu",
        "value": "Vadasikarambattu"
    },
    {
        "name": "Vadasinor",
        "value": "Vadasinor"
    },
    {
        "name": "Vadasseri",
        "value": "Vadasseri"
    },
    {
        "name": "Vadavalam",
        "value": "Vadavalam"
    },
    {
        "name": "Vadavalli",
        "value": "Vadavalli"
    },
    {
        "name": "Vaddadi",
        "value": "Vaddadi"
    },
    {
        "name": "Vaddapalli",
        "value": "Vaddapalli"
    },
    {
        "name": "Vaddepalli",
        "value": "Vaddepalli"
    },
    {
        "name": "Vadigenhalli",
        "value": "Vadigenhalli"
    },
    {
        "name": "Vadippatti",
        "value": "Vadippatti"
    },
    {
        "name": "Vadnagar",
        "value": "Vadnagar"
    },
    {
        "name": "Vadodara",
        "value": "Vadodara"
    },
    {
        "name": "Vadugapatti",
        "value": "Vadugapatti"
    },
    {
        "name": "Vadugappatti",
        "value": "Vadugappatti"
    },
    {
        "name": "Vaghodia",
        "value": "Vaghodia"
    },
    {
        "name": "Vaijapur",
        "value": "Vaijapur"
    },
    {
        "name": "Vaikam",
        "value": "Vaikam"
    },
    {
        "name": "Vaikuntam",
        "value": "Vaikuntam"
    },
    {
        "name": "Vairampatti",
        "value": "Vairampatti"
    },
    {
        "name": "Vairichettipalaiyam",
        "value": "Vairichettipalaiyam"
    },
    {
        "name": "Vaisampakkal",
        "value": "Vaisampakkal"
    },
    {
        "name": "Vakkam",
        "value": "Vakkam"
    },
    {
        "name": "Valabhipur",
        "value": "Valabhipur"
    },
    {
        "name": "Valaiyampattu",
        "value": "Valaiyampattu"
    },
    {
        "name": "Valambur",
        "value": "Valambur"
    },
    {
        "name": "Valangiman",
        "value": "Valangiman"
    },
    {
        "name": "Valantaravai",
        "value": "Valantaravai"
    },
    {
        "name": "Valaparla",
        "value": "Valaparla"
    },
    {
        "name": "Valasa",
        "value": "Valasa"
    },
    {
        "name": "Valatt",
        "value": "Valatt"
    },
    {
        "name": "Valattur",
        "value": "Valattur"
    },
    {
        "name": "Valavakattumula",
        "value": "Valavakattumula"
    },
    {
        "name": "Valavanur",
        "value": "Valavanur"
    },
    {
        "name": "Valayam",
        "value": "Valayam"
    },
    {
        "name": "Valiyakumaramangalam",
        "value": "Valiyakumaramangalam"
    },
    {
        "name": "Valkurti",
        "value": "Valkurti"
    },
    {
        "name": "Vallabh Vidyanagar",
        "value": "Vallabh Vidyanagar"
    },
    {
        "name": "Vallahbhapuram",
        "value": "Vallahbhapuram"
    },
    {
        "name": "Vallam",
        "value": "Vallam"
    },
    {
        "name": "Vallapuram",
        "value": "Vallapuram"
    },
    {
        "name": "Vallikunnam",
        "value": "Vallikunnam"
    },
    {
        "name": "Valljkkod",
        "value": "Valljkkod"
    },
    {
        "name": "Vallur",
        "value": "Vallur"
    },
    {
        "name": "Valluvandad",
        "value": "Valluvandad"
    },
    {
        "name": "Valparai",
        "value": "Valparai"
    },
    {
        "name": "Valpoy",
        "value": "Valpoy"
    },
    {
        "name": "Valsad",
        "value": "Valsad"
    },
    {
        "name": "Valtoha",
        "value": "Valtoha"
    },
    {
        "name": "Vamanapuram",
        "value": "Vamanapuram"
    },
    {
        "name": "Vammanal",
        "value": "Vammanal"
    },
    {
        "name": "Vanavasi",
        "value": "Vanavasi"
    },
    {
        "name": "Vandalur",
        "value": "Vandalur"
    },
    {
        "name": "Vandamettu",
        "value": "Vandamettu"
    },
    {
        "name": "Vandikarai",
        "value": "Vandikarai"
    },
    {
        "name": "Vandiperiyar",
        "value": "Vandiperiyar"
    },
    {
        "name": "Vandithavalam",
        "value": "Vandithavalam"
    },
    {
        "name": "Vandiyur",
        "value": "Vandiyur"
    },
    {
        "name": "Vanduvancheri",
        "value": "Vanduvancheri"
    },
    {
        "name": "Vanimel",
        "value": "Vanimel"
    },
    {
        "name": "Vanipenta",
        "value": "Vanipenta"
    },
    {
        "name": "Vaniyambadi",
        "value": "Vaniyambadi"
    },
    {
        "name": "Vannikkonendal",
        "value": "Vannikkonendal"
    },
    {
        "name": "Vannivedu",
        "value": "Vannivedu"
    },
    {
        "name": "Vanthli",
        "value": "Vanthli"
    },
    {
        "name": "Vanukuru",
        "value": "Vanukuru"
    },
    {
        "name": "Varadarajampettai",
        "value": "Varadarajampettai"
    },
    {
        "name": "Varanasi",
        "value": "Varanasi"
    },
    {
        "name": "Varandarapilli",
        "value": "Varandarapilli"
    },
    {
        "name": "Varapatti",
        "value": "Varapatti"
    },
    {
        "name": "Varatanapalli",
        "value": "Varatanapalli"
    },
    {
        "name": "Vardannapet",
        "value": "Vardannapet"
    },
    {
        "name": "Vardhamankota",
        "value": "Vardhamankota"
    },
    {
        "name": "Vargaur",
        "value": "Vargaur"
    },
    {
        "name": "Varidhanam",
        "value": "Varidhanam"
    },
    {
        "name": "Varisshiyakuni",
        "value": "Varisshiyakuni"
    },
    {
        "name": "Varkkallai",
        "value": "Varkkallai"
    },
    {
        "name": "Vasa",
        "value": "Vasa"
    },
    {
        "name": "Vasad",
        "value": "Vasad"
    },
    {
        "name": "Vasai-Virar",
        "value": "Vasai-Virar"
    },
    {
        "name": "Vasco Da Gama",
        "value": "Vasco Da Gama"
    },
    {
        "name": "Vasudevanallur",
        "value": "Vasudevanallur"
    },
    {
        "name": "Vatakemuri",
        "value": "Vatakemuri"
    },
    {
        "name": "Vatluru",
        "value": "Vatluru"
    },
    {
        "name": "Vattalkundu",
        "value": "Vattalkundu"
    },
    {
        "name": "Vavur",
        "value": "Vavur"
    },
    {
        "name": "Vavveru",
        "value": "Vavveru"
    },
    {
        "name": "Vayakkalattu",
        "value": "Vayakkalattu"
    },
    {
        "name": "Vayalar",
        "value": "Vayalar"
    },
    {
        "name": "Vayalpad",
        "value": "Vayalpad"
    },
    {
        "name": "Vayanur",
        "value": "Vayanur"
    },
    {
        "name": "Vazhani",
        "value": "Vazhani"
    },
    {
        "name": "Vechur",
        "value": "Vechur"
    },
    {
        "name": "Vedappatti",
        "value": "Vedappatti"
    },
    {
        "name": "Vedaranniyam",
        "value": "Vedaranniyam"
    },
    {
        "name": "Vedasandur",
        "value": "Vedasandur"
    },
    {
        "name": "Vedurupavaluru",
        "value": "Vedurupavaluru"
    },
    {
        "name": "Vegarai",
        "value": "Vegarai"
    },
    {
        "name": "Velair",
        "value": "Velair"
    },
    {
        "name": "Velakalnattam",
        "value": "Velakalnattam"
    },
    {
        "name": "Velakkuttai",
        "value": "Velakkuttai"
    },
    {
        "name": "Velampalaiyam",
        "value": "Velampalaiyam"
    },
    {
        "name": "Velampatti",
        "value": "Velampatti"
    },
    {
        "name": "Velanganni",
        "value": "Velanganni"
    },
    {
        "name": "Velappadi",
        "value": "Velappadi"
    },
    {
        "name": "Veldurti",
        "value": "Veldurti"
    },
    {
        "name": "Velim",
        "value": "Velim"
    },
    {
        "name": "Velivennu",
        "value": "Velivennu"
    },
    {
        "name": "Veliyangod",
        "value": "Veliyangod"
    },
    {
        "name": "Vellakkinar",
        "value": "Vellakkinar"
    },
    {
        "name": "Vellakkovil",
        "value": "Vellakkovil"
    },
    {
        "name": "Vellalapuram",
        "value": "Vellalapuram"
    },
    {
        "name": "Vellallur",
        "value": "Vellallur"
    },
    {
        "name": "Vellalur",
        "value": "Vellalur"
    },
    {
        "name": "Vellanad",
        "value": "Vellanad"
    },
    {
        "name": "Vellar",
        "value": "Vellar"
    },
    {
        "name": "Vellarivalli",
        "value": "Vellarivalli"
    },
    {
        "name": "Vellaturu",
        "value": "Vellaturu"
    },
    {
        "name": "Vellavadanparappu",
        "value": "Vellavadanparappu"
    },
    {
        "name": "Vellikulangara",
        "value": "Vellikulangara"
    },
    {
        "name": "Vellipalaiyam",
        "value": "Vellipalaiyam"
    },
    {
        "name": "Vellithiruthi",
        "value": "Vellithiruthi"
    },
    {
        "name": "Velliyod",
        "value": "Velliyod"
    },
    {
        "name": "Vellodu",
        "value": "Vellodu"
    },
    {
        "name": "Vellore",
        "value": "Vellore"
    },
    {
        "name": "Vellur",
        "value": "Vellur"
    },
    {
        "name": "Velpuru",
        "value": "Velpuru"
    },
    {
        "name": "Velugodu",
        "value": "Velugodu"
    },
    {
        "name": "Velur",
        "value": "Velur"
    },
    {
        "name": "Vemalwada",
        "value": "Vemalwada"
    },
    {
        "name": "Vembaditalam",
        "value": "Vembaditalam"
    },
    {
        "name": "Vembarpatti",
        "value": "Vembarpatti"
    },
    {
        "name": "Vembur",
        "value": "Vembur"
    },
    {
        "name": "Vempalle",
        "value": "Vempalle"
    },
    {
        "name": "Vempatti",
        "value": "Vempatti"
    },
    {
        "name": "Vemuladivi",
        "value": "Vemuladivi"
    },
    {
        "name": "Vemulanarva",
        "value": "Vemulanarva"
    },
    {
        "name": "Vemulapudi",
        "value": "Vemulapudi"
    },
    {
        "name": "Vemuluru",
        "value": "Vemuluru"
    },
    {
        "name": "Vendram",
        "value": "Vendram"
    },
    {
        "name": "Venganellur",
        "value": "Venganellur"
    },
    {
        "name": "Vengapalli",
        "value": "Vengapalli"
    },
    {
        "name": "Vengat",
        "value": "Vengat"
    },
    {
        "name": "Vengattur",
        "value": "Vengattur"
    },
    {
        "name": "Vengavasal",
        "value": "Vengavasal"
    },
    {
        "name": "Vengikkal",
        "value": "Vengikkal"
    },
    {
        "name": "Vengur",
        "value": "Vengur"
    },
    {
        "name": "Venkatadripalem",
        "value": "Venkatadripalem"
    },
    {
        "name": "Venkatagiri",
        "value": "Venkatagiri"
    },
    {
        "name": "Venkatagirikota",
        "value": "Venkatagirikota"
    },
    {
        "name": "Venkatapuram",
        "value": "Venkatapuram"
    },
    {
        "name": "Venkidanga",
        "value": "Venkidanga"
    },
    {
        "name": "Venmani",
        "value": "Venmani"
    },
    {
        "name": "Venmani Padinjara",
        "value": "Venmani Padinjara"
    },
    {
        "name": "Vennandur",
        "value": "Vennandur"
    },
    {
        "name": "Veppattur",
        "value": "Veppattur"
    },
    {
        "name": "Veraval",
        "value": "Veraval"
    },
    {
        "name": "Vernag",
        "value": "Vernag"
    },
    {
        "name": "Verukulambu",
        "value": "Verukulambu"
    },
    {
        "name": "Vesala",
        "value": "Vesala"
    },
    {
        "name": "Vetapalem",
        "value": "Vetapalem"
    },
    {
        "name": "Vettaikkaraniruppu",
        "value": "Vettaikkaraniruppu"
    },
    {
        "name": "Vettakkaranpudur",
        "value": "Vettakkaranpudur"
    },
    {
        "name": "Vettam",
        "value": "Vettam"
    },
    {
        "name": "Vettavalam",
        "value": "Vettavalam"
    },
    {
        "name": "Vettikattiri",
        "value": "Vettikattiri"
    },
    {
        "name": "Vettikkavala",
        "value": "Vettikkavala"
    },
    {
        "name": "Vettur",
        "value": "Vettur"
    },
    {
        "name": "Vidapanakallu",
        "value": "Vidapanakallu"
    },
    {
        "name": "Vidisha",
        "value": "Vidisha"
    },
    {
        "name": "Vijapur",
        "value": "Vijapur"
    },
    {
        "name": "Vijayapati",
        "value": "Vijayapati"
    },
    {
        "name": "Vijayapuri North",
        "value": "Vijayapuri North"
    },
    {
        "name": "Vijayawada",
        "value": "Vijayawada"
    },
    {
        "name": "Vikarabad",
        "value": "Vikarabad"
    },
    {
        "name": "Vikasnagar",
        "value": "Vikasnagar"
    },
    {
        "name": "Vikramasingapuram",
        "value": "Vikramasingapuram"
    },
    {
        "name": "Vikravandi",
        "value": "Vikravandi"
    },
    {
        "name": "Vikrutamala",
        "value": "Vikrutamala"
    },
    {
        "name": "Vilachcheri",
        "value": "Vilachcheri"
    },
    {
        "name": "Vilakkudi",
        "value": "Vilakkudi"
    },
    {
        "name": "Vilandai",
        "value": "Vilandai"
    },
    {
        "name": "Vilangudi",
        "value": "Vilangudi"
    },
    {
        "name": "Vilangurichchi",
        "value": "Vilangurichchi"
    },
    {
        "name": "Vilattikulam",
        "value": "Vilattikulam"
    },
    {
        "name": "Vilavur",
        "value": "Vilavur"
    },
    {
        "name": "Villanchirai",
        "value": "Villanchirai"
    },
    {
        "name": "Villasagar",
        "value": "Villasagar"
    },
    {
        "name": "Villupuram",
        "value": "Villupuram"
    },
    {
        "name": "Vilpatti",
        "value": "Vilpatti"
    },
    {
        "name": "Vinjam",
        "value": "Vinjam"
    },
    {
        "name": "Vinjamur",
        "value": "Vinjamur"
    },
    {
        "name": "Vinnamala",
        "value": "Vinnamala"
    },
    {
        "name": "Vinukonda",
        "value": "Vinukonda"
    },
    {
        "name": "Vipparla",
        "value": "Vipparla"
    },
    {
        "name": "Viraganur",
        "value": "Viraganur"
    },
    {
        "name": "Viraghattam",
        "value": "Viraghattam"
    },
    {
        "name": "Virakeralam",
        "value": "Virakeralam"
    },
    {
        "name": "Viralimalai",
        "value": "Viralimalai"
    },
    {
        "name": "Viralippatti",
        "value": "Viralippatti"
    },
    {
        "name": "Viramgam",
        "value": "Viramgam"
    },
    {
        "name": "Virapalle",
        "value": "Virapalle"
    },
    {
        "name": "Virapandi",
        "value": "Virapandi"
    },
    {
        "name": "Virapandiyanpattanam",
        "value": "Virapandiyanpattanam"
    },
    {
        "name": "Virappanchathiram",
        "value": "Virappanchathiram"
    },
    {
        "name": "Virapperumanallur",
        "value": "Virapperumanallur"
    },
    {
        "name": "Virarajendrapet",
        "value": "Virarajendrapet"
    },
    {
        "name": "Viravada",
        "value": "Viravada"
    },
    {
        "name": "Virudunagar",
        "value": "Virudunagar"
    },
    {
        "name": "Vishakhapatnam",
        "value": "Vishakhapatnam"
    },
    {
        "name": "Visnagar",
        "value": "Visnagar"
    },
    {
        "name": "Vissannapeta",
        "value": "Vissannapeta"
    },
    {
        "name": "Viswanathaperi",
        "value": "Viswanathaperi"
    },
    {
        "name": "Vite",
        "value": "Vite"
    },
    {
        "name": "Vittal",
        "value": "Vittal"
    },
    {
        "name": "Vitthalapuram",
        "value": "Vitthalapuram"
    },
    {
        "name": "Viyapuram",
        "value": "Viyapuram"
    },
    {
        "name": "Vizianagaram",
        "value": "Vizianagaram"
    },
    {
        "name": "Vodurivandlagudem",
        "value": "Vodurivandlagudem"
    },
    {
        "name": "Vontimitta",
        "value": "Vontimitta"
    },
    {
        "name": "Vorkadi",
        "value": "Vorkadi"
    },
    {
        "name": "Vriddhachalam",
        "value": "Vriddhachalam"
    },
    {
        "name": "Vrindavan",
        "value": "Vrindavan"
    },
    {
        "name": "Vubatalai",
        "value": "Vubatalai"
    },
    {
        "name": "Vuliyattara",
        "value": "Vuliyattara"
    },
    {
        "name": "Vutukuru",
        "value": "Vutukuru"
    },
    {
        "name": "Vuyyuru",
        "value": "Vuyyuru"
    },
    {
        "name": "Vyara",
        "value": "Vyara"
    },
    {
        "name": "Wabagai",
        "value": "Wabagai"
    },
    {
        "name": "Wadegaon",
        "value": "Wadegaon"
    },
    {
        "name": "Wadgira",
        "value": "Wadgira"
    },
    {
        "name": "Wadi",
        "value": "Wadi"
    },
    {
        "name": "Wadlakonda",
        "value": "Wadlakonda"
    },
    {
        "name": "Waghai",
        "value": "Waghai"
    },
    {
        "name": "Wai",
        "value": "Wai"
    },
    {
        "name": "Walajabad",
        "value": "Walajabad"
    },
    {
        "name": "Walajapet",
        "value": "Walajapet"
    },
    {
        "name": "Walur",
        "value": "Walur"
    },
    {
        "name": "Wandiwash",
        "value": "Wandiwash"
    },
    {
        "name": "Wangi",
        "value": "Wangi"
    },
    {
        "name": "Wani",
        "value": "Wani"
    },
    {
        "name": "Wankaner",
        "value": "Wankaner"
    },
    {
        "name": "Wanparti",
        "value": "Wanparti"
    },
    {
        "name": "Wapi",
        "value": "Wapi"
    },
    {
        "name": "Warangal",
        "value": "Warangal"
    },
    {
        "name": "Waraseoni",
        "value": "Waraseoni"
    },
    {
        "name": "Wardha",
        "value": "Wardha"
    },
    {
        "name": "Wargal",
        "value": "Wargal"
    },
    {
        "name": "Waris Aliganj",
        "value": "Waris Aliganj"
    },
    {
        "name": "Warkan",
        "value": "Warkan"
    },
    {
        "name": "Warni",
        "value": "Warni"
    },
    {
        "name": "Warora",
        "value": "Warora"
    },
    {
        "name": "Warud",
        "value": "Warud"
    },
    {
        "name": "Washim",
        "value": "Washim"
    },
    {
        "name": "Wasi",
        "value": "Wasi"
    },
    {
        "name": "Watrap",
        "value": "Watrap"
    },
    {
        "name": "Wellington",
        "value": "Wellington"
    },
    {
        "name": "Wepangandla",
        "value": "Wepangandla"
    },
    {
        "name": "Wokha",
        "value": "Wokha"
    },
    {
        "name": "Yadavolu",
        "value": "Yadavolu"
    },
    {
        "name": "Yadiki",
        "value": "Yadiki"
    },
    {
        "name": "Yadwad",
        "value": "Yadwad"
    },
    {
        "name": "Yairipok",
        "value": "Yairipok"
    },
    {
        "name": "Yalamakuru",
        "value": "Yalamakuru"
    },
    {
        "name": "Yalluru",
        "value": "Yalluru"
    },
    {
        "name": "Yamkanmardi",
        "value": "Yamkanmardi"
    },
    {
        "name": "Yamunanagar",
        "value": "Yamunanagar"
    },
    {
        "name": "Yanam",
        "value": "Yanam"
    },
    {
        "name": "Yandrapalle",
        "value": "Yandrapalle"
    },
    {
        "name": "Yaragol",
        "value": "Yaragol"
    },
    {
        "name": "Yargatti",
        "value": "Yargatti"
    },
    {
        "name": "Yavatmal",
        "value": "Yavatmal"
    },
    {
        "name": "Yedapalli",
        "value": "Yedapalli"
    },
    {
        "name": "Yedappalli",
        "value": "Yedappalli"
    },
    {
        "name": "Yeddumailaram",
        "value": "Yeddumailaram"
    },
    {
        "name": "Yedtare",
        "value": "Yedtare"
    },
    {
        "name": "Yekambarakuppam",
        "value": "Yekambarakuppam"
    },
    {
        "name": "Yelahanka",
        "value": "Yelahanka"
    },
    {
        "name": "Yelandur",
        "value": "Yelandur"
    },
    {
        "name": "Yeldurti",
        "value": "Yeldurti"
    },
    {
        "name": "Yellanda",
        "value": "Yellanda"
    },
    {
        "name": "Yellandu",
        "value": "Yellandu"
    },
    {
        "name": "Yellapur",
        "value": "Yellapur"
    },
    {
        "name": "Yellareddi",
        "value": "Yellareddi"
    },
    {
        "name": "Yellayapalem",
        "value": "Yellayapalem"
    },
    {
        "name": "Yelmalla",
        "value": "Yelmalla"
    },
    {
        "name": "Yelpur",
        "value": "Yelpur"
    },
    {
        "name": "Yelur",
        "value": "Yelur"
    },
    {
        "name": "Yenkuvarigudem",
        "value": "Yenkuvarigudem"
    },
    {
        "name": "Yenmangandla",
        "value": "Yenmangandla"
    },
    {
        "name": "Yeola",
        "value": "Yeola"
    },
    {
        "name": "Yercaud",
        "value": "Yercaud"
    },
    {
        "name": "Yermal",
        "value": "Yermal"
    },
    {
        "name": "Yerrapalem",
        "value": "Yerrapalem"
    },
    {
        "name": "Zafarabad",
        "value": "Zafarabad"
    },
    {
        "name": "Zafargarh",
        "value": "Zafargarh"
    },
    {
        "name": "Zahirabad",
        "value": "Zahirabad"
    },
    {
        "name": "Zaidpur",
        "value": "Zaidpur"
    },
    {
        "name": "Zaladanki",
        "value": "Zaladanki"
    },
    {
        "name": "Zamania",
        "value": "Zamania"
    },
    {
        "name": "Zangareddigudem",
        "value": "Zangareddigudem"
    },
    {
        "name": "Zawal",
        "value": "Zawal"
    },
    {
        "name": "Zerakpur",
        "value": "Zerakpur"
    },
    {
        "name": "Zira",
        "value": "Zira"
    },
    {
        "name": "Zirapur",
        "value": "Zirapur"
    },
    {
        "name": "Ziro",
        "value": "Ziro"
    },
    {
        "name": "Zulakallu",
        "value": "Zulakallu"
    },
    {
        "name": "Zunheboto",
        "value": "Zunheboto"
    },
    {
        "name": "Zuvvaladinne",
        "value": "Zuvvaladinne"
    }
];

export { cleanData, clusteredData };
