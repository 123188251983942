import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';
import { updateCandidateWorkExperienceGap } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuthContext';
import { updateUser } from 'api/users';
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  // backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));


const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{ '&:hover': { bgcolor: 'transparent' } }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}


const CareerBreaksIdentifiedDialog = ({ gap, onClose, onWorkExperienceDataChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateContext } = useAuth();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    console.log('hello');
  });

  const methods = useForm({
    defaultValues: {
      gap_title: "",
      reasons: "",
      showInResume: false,
    },
  });

  const { handleSubmit, control, formState: { errors }, clearErrors, setError } = methods;

  const onSubmit = (data) => {
    setIsUpdating(true);
    const { gap_title, reasons, showInResume } = data;
    const formattedData = reasons.map((reason, index) => ({
      gap_explaination: reason,
      gap_title: gap_title[index],
      show_in_resume: showInResume[index],
      work_history_from: gap[index].startDate,
      work_history_to: gap[index].endDate,
      organization_name: 'Work Gap',
      highest_designation: `Career Break ${index + 1}`,
    }));
    addGap(formattedData);
  };

  const addGap = async (formattedData) => {
    try {
      const response = await updateCandidateWorkExperienceGap(formattedData);
      if (response) {
        enqueueSnackbar(response.message, { variant: 'success' });
        onWorkExperienceDataChange();
        onUpdate();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdate = async () => {
    if (user?.stages <= 3) {
      try {
        const response = await updateUser();
        if (response && response?.data) {
          updateContext({ stages: response?.data?.stages });
        }
      } catch (error) {
        console.error(error);
      }
    }
    onClose();
    // navigate('/job-preferences');
  }

  const wordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleCancelClick = () => {
    onClose();
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box mb={3}>
          <Typography mb={1} className="main-title">
            Career break(s) identified during your work experience
          </Typography>
          <Typography className="main-info">
            Please provide the reasons for these {gap.length > 1 ? 'breaks' : 'break'}. Companies & recruiters need this information to understand you better.
          </Typography>
        </Box>

        <FormProvider {...methods}>
          <Box className="career-breaks-container">
            {gap.map((item, index) => (
              <Box key={index} >
                <Stack spacing={1} className="break-row">
                  <Typography className="title">Break {index + 1}</Typography>
                  <Typography className="break-gap-text">
                    {dayjs(item.startDate).format('MMM YYYY')} - {dayjs(item.endDate).format('MMM YYYY')}
                  </Typography>
                  <FormControl fullWidth sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    position: 'relative',
                    minWidth: '0px',
                    padding: '0px',
                    margin: '0px',
                    border: '0px',
                    verticalAlign: 'top',
                    width: '100%',
                    height: '60px',
                  }}>
                    <Controller
                      name={`gap_title[${index}]`}
                      control={control}
                      rules={{
                        required: 'Please enter the title for the gap'
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          // placeholder="Title"
                          label="Title"
                          variant="outlined"
                          error={!!errors.gap_title && !!errors.gap_title[index]}
                          helperText={errors.gap_title && errors.gap_title[index] ? errors.gap_title[index].message : ''}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      name={`reasons[${index}]`}
                      control={control}
                      rules={{
                        required: 'Please enter the reason for the gap',
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          size="small"
                          // placeholder="Reason within 25 words"
                          label="Reason"
                          variant="outlined"
                          error={!!errors.reasons && !!errors.reasons[index]}
                          helperText={
                            errors.reasons?.[index]?.message ||
                            `${field.value?.trim()?.split(/\s+/)?.length || 0} / 25 words`
                          }
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const words = inputValue.trim().split(/\s+/);
                            if (words.length <= 25) {
                              field.onChange(inputValue);
                              clearErrors(`reasons.${index}`);
                            } else {
                              setError(`reasons.${index}`, {
                                type: 'manual',
                                message: 'Maximum 25 words allowed.',
                              });
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ marginLeft: '5px!important' }}>
                    <Controller
                      name={`showInResume[${index}]`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel sx={{ fontSize: '14px' }}
                          control={
                            <BpCheckbox
                              {...field}
                              name={`showInResume[${index}]`}
                              defaultChecked={false}
                            />
                          }
                          label="Show this break in my resume also"
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

              </Box>
            ))}
          </Box>
          <Box className="career-breaks-footer">
            <Stack direction="row" spacing={4}>
              <Button className="cbi-cancel-btn" size="medium" onClick={handleCancelClick}>Cancel</Button>
              <Button
                className="cbi-update-btn"
                variant="contained"
                size="medium"
                onClick={handleSubmit(onSubmit)}
                disabled={isUpdating}
              >
                {isUpdating ? 'Updating...' : 'Update'}
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default CareerBreaksIdentifiedDialog;
