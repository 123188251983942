import React from 'react';
import {
  Box,
  Dialog,
  Card,
  CardContent,
  Link,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Container,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { CandidateEducationSchema } from 'models/CandidateProfileSchema';
import { updateCandidateEducation } from 'api/users';
import dayjs from 'dayjs';
import DynamicTextField from 'components/DynamicTextField';
import { endpoints } from 'utils/axios';
import { useState, useEffect, useRef } from 'react';
import AddSkills from '../basic-profile/AddSkills';
import { updateCandidateCertificate } from 'api/users';
import { changeNameFormat } from 'utils/Utils';

const CreateUpdateCertificate = ({ certificateData, closeDialog, onDataChange, certificateInstituteData, certificateTitleData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isEditMode = !!certificateData._id;
  const navigate = useNavigate();
  const { user, updateUnsaved } = useAuth();
  const [showFlag, setShowFlag] = useState(false);
  const [educationFrom, setEducationFrom] = useState(dayjs(certificateData?.education_from) || null);
  const [selectedChips, setSelectedChips] = useState(certificateData?.skills || []);
  const [open, setOpen] = React.useState(false);
  const [addMoreSkills, setAddMoreSkills] = useState(certificateData?.all_skills || []);
  const [chipData, setChipData] = useState(certificateData?.skills?.length > 0 ? certificateData?.skills : certificateData?.all_skills || []);

  const defaultValues = {
    education_from: null,
    education_to: null,
    education_institute_name: '',
    certificate_received: '',
    learning_format: 'Self Study',
    field_of_study: '',
    ...certificateData,
  };
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CandidateEducationSchema('certificate')),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    watch,
    trigger,
  } = methods;

  const handleEducationFromChange = async (date) => {
    if (date) {
      const firstDayOfMonth = date.startOf('month');
      setEducationFrom(firstDayOfMonth);
      setValue('education_from', firstDayOfMonth?.$d);
      trigger('education_from');
    }
  };  

  const handleEducationtoChange = (date) => {
    if (date) {
      const lastDayOfMonth = date.endOf('month');
      // setEducationTo(lastDayOfMonth);
      setValue('education_to', lastDayOfMonth?.$d);
      trigger('education_to');
    }
  };

  const format = [{ label: 'Full time - offline' }, { label: 'Full time - online' }, { label: 'Part time - offline' }, { label: 'Part time - online' }, { label: 'Self Study' }];

  const onSubmit = handleSubmit(async (formData) => {
    if (isEditMode) {
      formData.certificationId = certificateData._id;
    }
    if (!validateSkills(selectedChips)) {
      return;
    } else {
      setShowFlag('');
    }
    formData.skills = selectedChips;
    formData.all_skills = chipData;
    const response = await updateCandidateCertificate(formData);
    if (response?.success === true && response.data) {
      onDataChange();
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  });

  const handleClick = (label) => {
    let totalSkillls;
    if (selectedChips.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
    } else {
      if (selectedChips.length >= 8) {
        setShowFlag('You can select up to 8 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 3) {
      setShowFlag('Please select at least 3 skills.');
      return false;
    } else if (totalSkillls?.length > 8) {
      setShowFlag('You can select up to 8 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddMoreSkills = (moreSkills) => {
    if (moreSkills?.length > 0) {
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
    }
  };

  const handleCancel = () => {
    reset();
  };
  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-header">
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <IconButton aria-label="delete" onClick={closeDialog}>
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </IconButton>

            <Box>
              <Typography className="title" sx={{ marginBottom: '0!important' }}>
                {isEditMode ? 'Update Certificate' : 'New Certificate'}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box className="drawer-content">
          <Box className="education-details-form">
            <Typography className="main-title" variant="h6">
              {watch('certificate_received') ? `${watch('certificate_received')} at ` : ''}
              {watch('education_institute_name') ? watch('education_institute_name') : 'Unnamed'}
            </Typography>
            <Stack spacing={2}>
              {/* institute name */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="education_institute_name"
                  defaultValue={[defaultValues?.education_institute_name || '']}
                  render={({ field }) => (
                    <TypeAhead
                      {...field}
                      {...register('education_institute_name')}
                      data={certificateInstituteData}
                      multiple={false}
                      fullWidth
                      label="Institution"
                      placeholder="Institution Name"
                      freeSolo={true}
                      onInputChange={(e, value) => {
                        setValue("education_institute_name", value, { shouldDirty: true });
                      }}
                    />
                  )}
                />
              </FormControl>
              {/* certificate received */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="certificate_received"
                  defaultValue={defaultValues?.certificate_received || ''}
                  render={({ field }) => (
                    <TypeAhead
                      {...field}
                      {...register('certificate_received')}
                      data={certificateTitleData}
                      label="Certification Received"
                      displayTextKey="name"
                      freeSolo={true}
                      onInputChange={(e, value) => {
                        setValue("certificate_received", value, { shouldDirty: true });
                      }}
                      onChange={(e, value) => {
                        setValue('all_skills', value?.skills);
                        setChipData(value?.skills);
                        setAddMoreSkills(value?.skills);
                        setSelectedChips([]);
                      }}
                    />
                  )}
                />
              </FormControl>
              {/* Specialization */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="field_of_study"
                  defaultValue={defaultValues?.field_of_study || []}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      {...register('field_of_study')}
                      label="Specialization"
                      variant="outlined"
                      fullWidth
                      error={!!errors.field_of_study}
                      helperText={errors.field_of_study ? errors.field_of_study.message : ''}
                      onChange={(e) =>
                        setValue("field_of_study", changeNameFormat(e.target.value), { shouldDirty: true })
                      }
                    />
                  )}
                />
              </FormControl>
              {/* learning format */}
              <FormControl fullWidth size="small">
                <InputLabel id="learning_format">Learning Format</InputLabel>
                <Controller
                  control={control}
                  name="learning_format"
                  defaultValue={defaultValues?.learning_format || ''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="learning_format"
                      error={!!errors.learning_format}
                      helperText={errors.learning_format?.message}
                      label="Learning Format"
                      variant="outlined"
                      fullWidth
                    >
                      {format?.map((item) =>
                        <MenuItem value={item?.label}>{item?.label}</MenuItem>
                      )}
                    </Select>
                  )}
                />
                <Typography variant="caption" color="error">
                  {errors.learning_format?.message}
                </Typography>
              </FormControl>
              {/* start date */}
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="education_from"
                  defaultValue={defaultValues?.education_from}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={dayjs(field?.value)}
                      format="MM/YYYY"
                      views={['year', 'month']}
                      label="Start Date"
                      disableFuture
                      placeholderText="Start Date"
                      onChange={(date) => handleEducationFromChange(date)}
                      closeOnSelect={true}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!errors?.education_from,
                          helperText: errors?.education_from?.message,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              {/* end date */}
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="education_to"
                  defaultValue={defaultValues?.education_to}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={dayjs(field?.value)}
                      autoOk={true}
                      format="MM/YYYY"
                      views={['year', 'month']}
                      label="End Date"
                      onChange={(date) => handleEducationtoChange(date)}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!errors?.education_to,
                          helperText: errors?.education_to?.message,
                        },
                      }}
                      disableFuture
                      closeOnSelect={true}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack spacing={2} mt={2}>
              <Box className="besic-det-skills-wrapper">
                <Box mb={2}>
                  <Typography className="title" variant="body2" gutterBottom>
                    Skills
                  </Typography>
                  <Typography className="info-txt" variant="body2" gutterBottom>
                    3 to 8 skills can be selected
                  </Typography>
                </Box>
                <Stack direction="row" flexWrap="wrap" >
                  {chipData?.map((label, index) => (
                    <Chip
                      key={index}
                      label={label}
                      size="small"
                      clickable
                      variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                      style={{
                        color: selectedChips.includes(label) ? 'white' : 'black',
                        backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                        border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                      }}
                      onClick={() => handleClick(label)}
                    />
                  ))}
                </Stack>
                {showFlag && (
                  <Typography style={{ color: 'red', fontSize: 'small' }}>
                    {showFlag}
                  </Typography>
                )}
                <Link size="small" className="add-more-skills-link" underline="none" onClick={handleOpenOtpDialog}>
                  Add More Skills
                </Link>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              fullWidth
              className="update-btn"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
              size="large"
            >
              {isEditMode ? 'Update' : 'Done'}
            </Button>
          </Stack>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <AddSkills
            onClose={handleClose}
            skills={addMoreSkills}
            selectedskills={selectedChips}
            onAddSkills={handleAddMoreSkills}
          />
        </Dialog>
      </FormProvider>
    </Box>
  );
};

export default CreateUpdateCertificate;
