import { passwordReset } from 'api/users';
import { useState, useEffect } from 'react';
import {
  AppBar, Autocomplete, InputAdornment, Box, Breadcrumbs, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, MenuItem, Radio, RadioGroup, TextField, Toolbar, Typography, Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import { Link, Link as RouterLink } from "react-router-dom";
import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { updateBasicProfile, uploadProfilePic, getBasicProfileDetails, deleteResume } from 'api/users';
import { Avatar, Input, Alert } from '@mui/material';
import { endpoints } from 'utils/axios';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, register, watch, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { PasswordResetSchema } from 'models/ChangePasswordSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import SchoolIcon from '@mui/icons-material/School';
import { date } from 'yup';
import TypeAhead from 'components/TypeAhead';
import DeleteIcon from '@mui/icons-material/Delete';
import languages from '../../data/language';



const PasswordReset = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [isFetched, setIsFetched] = useState(true);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const defaultValues = {
    full_name: user?.name || '',
    email: user?.email || '',
    phone_number: user?.phone || '',
    old_password: '',
    password: '',
    confirm_password: ''
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(PasswordResetSchema)
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (formData) => {
    const response = await passwordReset(formData);
    if (response?.success === true) {
      enqueueSnackbar(response.message, { variant: 'success' })
      logout();
      // navigate('/');
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' })
    }
  })

  return (
    <Box style={{ marginTop: '80px' }}>
      <Container>
        {!isFetched ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
            <CircularProgress />
          </Box>) :
          (<FormProvider methods={methods} onSubmit={onSubmit}>
            <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
              <SchoolIcon /> Change password
            </Typography>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '2rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      id='full_name'
                      name='full_name'
                      label="Name"
                      value={defaultValues.full_name}
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('full_name')}
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      id='email'
                      name='email'
                      label="Email Id"
                      value={defaultValues.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('email')}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '2rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      id='phone_number'
                      name='phone_number'
                      label="Mobile Number"
                      value={defaultValues.phone_number}
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('phone_number')}
                      error={!!errors.phone_number}
                      helperText={errors.phone_number?.message}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      name='old_password'
                      label="Old Password"
                      variant="outlined"
                      autoComplete="old_password"
                      type={showOldPassword ? 'text' : 'password'}
                      fullWidth
                      {...register('old_password')}
                      error={!!errors.old_password}
                      helperText={errors.old_password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowOldPassword(!showOldPassword)}>
                            {!showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '2rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      name='password'
                      label="New Password"
                      variant="outlined"
                      autoComplete="password"
                      type={showNewPassword ? 'text' : 'password'}
                      fullWidth
                      {...register('password')}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowNewPassword(!showNewPassword)}>
                            {!showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <TextField
                      name='confirm_password'
                      label="Confirm Password"
                      variant="outlined"
                      autoComplete="confirm_password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      fullWidth
                      {...register('confirm_password')}
                      error={!!errors.confirm_password}
                      helperText={errors.confirm_password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {!showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </InputAdornment>
                        ),
                      }} />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Box textAlign={'center'} sx={{ marginTop: '2rem' }}>
              <LoadingButton variant="contained" size='large' loading={isSubmitting}
                // fullWidth size="medium"
                type="submit"
              >save</LoadingButton>
            </Box>
          </FormProvider>)
        }
      </Container>
    </Box>
  )
};

export default PasswordReset;
