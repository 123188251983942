import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Chip, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material';
import { setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';


const ExternalJobStatus = ({ onClose, title, jobId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyUrl = () => {
    const url = `${window?.location?.host}/jobs/${jobId}`;
    navigator.clipboard.writeText(url).then(() => {
      enqueueSnackbar("Job link copied.", { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar('Failed to copy job link.', { variant: 'error' });
    });
  };

  return (
    <Box>
      <Stack justifyContent={'center'}>
        <Box sx={{ textAlign: 'center' }} mb={3}>
          <Typography className="title" mb={1}>Job Title: {title}</Typography>
          <Typography className="info-text">Job Id: {jobId}</Typography>
        </Box>
        <Stack direction="row" alignItems={'center'} spacing={2.5}>
          <Button fullWidth variant="outlined" size="medium" onClick={onClose}>
            Return to Home
          </Button>
          <Button fullWidth variant="contained" size="medium" onClick={handleCopyUrl}>
            Copy URL
            &nbsp;
            <SvgIcon sx={{ width: '13px', height: '13px', spacing: '2px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7936 9.98322L15.8093 7.96767C18.346 5.43097 16.5855 1 12.9208 1C11.8735 1 10.8263 1.3971 10.0325 2.19088L6.1115 6.11171C4.09234 8.13087 4.74197 11.553 7.2992 12.7136L8.78896 11.2238C6.90337 11.0471 6.09224 8.74536 7.4188 7.4188L11.3394 3.49796C12.7322 2.10515 15.1544 3.078 15.1544 5.07917C15.1544 6.2076 14.4339 6.72832 13.6598 7.50241C13.9153 8.30423 13.9604 9.16066 13.7936 9.98322ZM4.34021 10.4976L3.49839 11.3394C2.1043 12.7335 3.08118 15.1544 5.07959 15.1544V15.1529C5.65323 15.1529 6.22665 14.9358 6.66058 14.5018L10.5812 10.5812C11.0151 10.1473 11.2323 9.57385 11.2323 9H11.2338C11.2338 7.84363 10.3609 6.88369 9.21104 6.77594L10.7006 5.28639C13.2606 6.44933 13.9058 9.87083 11.8883 11.8883L7.96767 15.8091C7.17389 16.6029 6.12674 17 5.07938 17C1.41488 17 -0.346033 12.5692 2.19088 10.0323L4.20644 8.01677C4.03964 8.83976 4.08494 9.69556 4.34021 10.4976Z" fill="white" stroke="white" stroke-width="0.5" />
              </svg>
            </SvgIcon>
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ExternalJobStatus;
