import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  FormControl,
  Grid,
  IconButton, InputAdornment,
  Link,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Popover,
  FormGroup,
  Checkbox,
  Drawer
} from '@mui/material';
import JobsMarketsFullDetails from './JobsMarketsFullDetails';
import { useSnackbar } from 'components/snackbar';
import { getJobList, setHideJob } from 'api/job';
import { useAuth } from 'hooks/useAuthContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getJobPreferanceDetails } from 'api/users';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { SplashScreen } from 'components/loading-screen';
import { setApplication, saveJobApplication } from 'api/application';
import EmptyState from './EmptyState';
import { debounce } from 'lodash';
import { cleanData, clusteredData } from 'data/city.js';
import { SuitableChip, ModerateChip, NotSuitableChip } from '../icon/icon';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const JobsMarket = ({ jobData, changeTabForDownload, showExternalStatus }) => {

  const { enqueueSnackbar } = useSnackbar();
  const { jobApply, setJobApply } = useAuth();

  const [jobs, setJobs] = useState(jobApply?.job ? [jobApply?.job] : []);
  const [data, setData] = useState(jobData || jobApply?.job || null);
  const [newJobData, setNewJobData] = useState(jobData || jobApply?.job);
  const [setActiveId, setSetActiveId] = useState(jobData?._id || jobApply?.job?._id || null);
  const [loading, setLoading] = useState(true);
  const [savedJob, setSavedJob] = useState('');
  const [unsaveJob, setUnsaveJob] = useState('');
  const [isJobPresent, setIsJobPresent] = useState(false);
  const [upperLimit, setUpperLimit] = useState(5);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [hasMoreJobs, setHasMoreJobs] = useState(true);

  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showExternalJobStatus, setShowExternalJobStatus] = useState(showExternalStatus || '');

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState({
    location: [],
    work_format: [],
    job_type: []
  });

  const [sortCriteria, setSortCriteria] = useState({});

  const [jobTypes, setJobTypes] = useState([]);
  const [preferedLocation, setPreferedLocation] = useState([]);

  const containerRef = React.useRef(null);
  const [showFilter, setShowFilter] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [hidingJob, setHidingJob] = useState('');

  const inputRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const cardRefs = useRef({});

  useEffect(() => {
    if (setActiveId && cardRefs.current[setActiveId]) {
      scrollContainerRef.current.scrollTo({
        top: cardRefs.current[setActiveId].offsetTop - scrollContainerRef.current.clientHeight / 2 + cardRefs.current[setActiveId].offsetHeight / 2,
        behavior: 'smooth',
      });
    }
  }, [setActiveId]);

  const handleShowSearch = () => {
    setShowSearch(true);
    setShowFilter(false);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  const handleShowHide = () => {
    setShowSearch(false);
    setShowFilter(true);
    handleCloseSearchJob();
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (isCancel) => {
    setAnchorEl(null);
    const isFilterApplied = (filterCriteria?.location?.length || filterCriteria?.work_format?.length || filterCriteria?.job_type?.length);
    const isSortingApplied = Object?.keys(sortCriteria)?.length;
    if (isCancel && (isFilterApplied || isSortingApplied || filterApplied)) {
      setFilterApplied(false);
      setSortCriteria({});
      setFilterCriteria({
        location: [],
        work_format: [],
        job_type: []
      })
      fetchJobs(0, 5, null, '', '', {}, true);
      setFilterLoading(true);
    }
  };

  const open = Boolean(anchorEl);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchJobs = async (lower, upper, from = null, search, flag, filters = {}, reload = false) => {
    try {
      setLoading(true);
      const response = await getJobList({ lower, upper, search, filters, sort: sortCriteria });
      if (response?.success && response?.data) {
        const jobs = response.data?.jobs ? response.data?.jobs : [];
        setHasMoreJobs(jobs.length === 5);
        setIsJobPresent(response.data?.isJobPresent);
        const updateJobs = (prevJobs, newJobs) => {
          const combinedJobs = [...prevJobs, ...newJobs];
          return Array.from(new Map(combinedJobs.map((job) => [job._id, job])).values());
        };

        if (from === 'scroll') {
          setJobs((prevJobs) => updateJobs(prevJobs, jobs));
        } else if (jobApply?.job && !reload) {
          setJobs((prevJobs) => updateJobs(prevJobs, jobs));
        } else {
          setJobs(jobs);
        }
        if (flag) {
          if (from != 'scroll') setData(jobs[0]);
          setSetActiveId(jobs[0]._id);
        } else if (!newJobData && jobs.length > 0) {
          if (from != 'scroll') {
            setData(jobs[0]);
            setSetActiveId(jobs[0]._id);
          }
        }
        setNewJobData("");
      } else {
        // enqueueSnackbar('Failed to fetch jobs', { variant: 'error' });
        console.error('Failed to fetch jobs:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      // enqueueSnackbar('Error fetching jobs', { variant: 'error' });
    } finally {
      setLoading(false);
      setFilterLoading(false);
      setFetchingMore(false);
    }
  };

  const fetchJobPreferences = async () => {
    try {
      setLoading(true);
      const response = await getJobPreferanceDetails();
      if (response?.success && response.data) {
        setJobTypes(response?.data?.job_type || []);
        const location = response.data?.prefered_location?.filter((loc) => loc !== "Any");
        setPreferedLocation(location || []);
      } else {
        // enqueueSnackbar('Failed to fetch job preferences', { variant: 'error' });
        console.error('Failed to fetch job preferences', response?.message);
      }
    } catch (error) {
      console.error('Error fetching job preferences:', error);
      // enqueueSnackbar('Error fetching job preferences', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobs(lowerLimit, upperLimit);
      await fetchJobPreferences();
    };

    fetchData();
  }, []);

  const handleJobData = (job) => {
    setJobApply(null);
    setData(job);
    setShowExternalJobStatus('');
  };

  const handleRemoveJob = () => {
    setJobApply(null);
    setShowExternalJobStatus('');
    const flag = 'applied';
    setLowerLimit(0);
    setUpperLimit(5);
    fetchJobs(0, 5, null, '', flag, {}, true);
  };

  const recommendedChip = (score) => score >= 3 ? <SuitableChip /> : score >= 2 ? <ModerateChip /> : <NotSuitableChip />;

  const handleHideJob = async (job) => {
    if (!job || !job._id) {
      enqueueSnackbar('Invalid job data.', { variant: 'error' });
      return;
    }
    const jobId = job?._id;
    setHidingJob(jobId);
    try {
      const response = await setHideJob({ jobId });

      if (response && response?.success && response?.data) {
        enqueueSnackbar('Job successfully hidden.', { variant: 'success' });
        setLowerLimit(0);
        setUpperLimit(5);
        fetchJobs(0, 5, null, '', '', {}, true);
      } else {
        enqueueSnackbar('Failed to remove job. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error hiding job:', error);
      enqueueSnackbar('An error occurred while hiding the job. Please try again.', { variant: 'error' });
    } finally {
      setHidingJob('');
    }
  };

  const handleSaveOrUnsaveJob = (jobId, actionType) => {
    if (actionType === 'saveJob') {
      setSavedJob(jobId);
    } else if (actionType === 'unsaveJob') {
      setUnsaveJob(jobId);
    }
    setShowExternalJobStatus('');
  };

  const handleChangeSaveOrUnsaveButton = async (actionType) => {
    if (actionType === 'saveJob') {
      setSavedJob('');
    } else if (actionType === 'unsaveJob') {
      setUnsaveJob('');
    }
  };

  const handleCardSaveOrUnsaveJob = async (job, actionType) => {
    if (actionType === 'saveJob') {
      setSavedJob(job?._id);
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'saved' });
      if (response?.success && response?.data != null) {
        job.application_saved = response?.data?.status === 'saved';
        setSavedJob('');
        enqueueSnackbar(response?.message, { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } else if (actionType === 'unsaveJob') {
      setUnsaveJob(job?._id);
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'unsaved' });
      if (response?.success) {
        job.application_saved = false
        setUnsaveJob('');
        enqueueSnackbar(response?.message, { variant: 'success' });
      }
    }
  }

  const handleDoubleClick = (event) => {
    const { name, value } = event.target;

    setSortCriteria((prev) => ({
      ...prev,
      [name]: prev[name] === value ? "" : value,
    }));
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, clientHeight, scrollHeight } = container;
    const scrollPosition = scrollTop + clientHeight;

    const threshold = 50;

    if (scrollHeight - scrollPosition <= threshold && !fetchingMore && hasMoreJobs) {
      setFetchingMore(true);
      setLowerLimit((prev) => prev + 5);
      setUpperLimit((prev) => prev + 5);
      fetchJobs(lowerLimit + 5, upperLimit + 5, 'scroll', searchValue, '', filterCriteria);
    }
  };

  const handleSearchJob = () => {
    setOpenSearch(true);
  };

  const handleCloseSearchJob = () => {
    if (searchValue) {
      setLowerLimit(0);
      setUpperLimit(5);
      setOpenSearch(false);
      setSearchValue('');
      setFilterLoading(true);
      fetchJobs(0, 5, null, '', '', filterCriteria, true);
    }
  };
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      setFilterLoading(true);
      setLowerLimit(0);
      setUpperLimit(5);
      await fetchJobs(0, 5, null, value, '', filterCriteria, true);
    }, 500),
    []
  );
  const handleSearchInputChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
    // setFilterLoading(true);
  };
  const handleDownloadResume = (jobInprogessData) => {
    changeTabForDownload(jobInprogessData, '2');
  };

  const handleApplyFilter = async () => {
    if (!sortCriteria && filterCriteria?.location?.length === 0 && filterCriteria?.work_format?.length === 0 && filterCriteria?.job_type?.length === 0) {
      return;
    }
    setFilterLoading(true);
    setFilterApplied(true);
    setLowerLimit(0);
    setUpperLimit(5);
    fetchJobs(0, 5, 'apply', searchValue, '', filterCriteria, true);
    handlePopoverClose();
  };

  const handleSortChange = (event) => {
    setLowerLimit(0);
    setUpperLimit(5);
    const { name, value } = event.target;
    setSortCriteria({
      ...sortCriteria,
      [name]: value,
    });
  };

  const handleChangeFilterCriteria = (event, value) => {
    const { name } = event.target;
    setFilterCriteria((prevCriteria) => ({
      ...prevCriteria,
      [name]: prevCriteria[name].includes(value)
        ? prevCriteria[name]?.filter((item) => item !== value)
        : [...prevCriteria[name], value],
    }));
  };

  const handleJobPreferences = async () => {
    await fetchJobPreferences();
  };
  const handleUpdateTjScore = (TjScore) => {
    setJobs((prevJobs) =>
      prevJobs.map((job) =>
        job._id === data._id
          ? { ...job, tj_score: TjScore }
          : job
      )
    );
  };


  if (!jobs?.length && loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  if (jobs.length === 0 && !isJobPresent) {
    return (< EmptyState tab={'job-market'} />);
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100vh - 110px)',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box className="active-jobs-list">
            <Box className="header-wrapper" ref={containerRef}>
              {/* <Slide in={showFilter} container={containerRef.current}> */}
              <Box className="filter-wrapper" mb={2}>
                <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                  <Typography className="head-text" variant="p">
                    {jobs?.length > 0 ? `${jobs?.length} Active Jobs` : 'Result Not Found'}
                  </Typography>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="update"
                        aria-owns={open || filterApplied ? 'filter-by' : undefined}
                        aria-haspopup="true"
                        onClick={handlePopoverOpen}
                      >
                        {filterApplied && <SvgIcon className="ellipse-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <circle cx="6" cy="6.5" r="6" fill="#C12929" />
                          </svg>
                        </SvgIcon>}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                          <path file-rule="evenodd" clipRule="evenodd" d="M7.0923 12.3074H16.3846C16.7243 12.3074 17 12.0317 17 11.692C17 11.3523 16.7243 11.0766 16.3846 11.0766H7.0923C6.80677 9.67293 5.56431 8.61508 4.07692 8.61508C2.37846 8.61508 1 9.99355 1 11.692C1 13.3905 2.37846 14.7689 4.07692 14.7689C5.56431 14.7689 6.80677 13.7111 7.0923 12.3074ZM4.07692 9.84585C5.096 9.84585 5.92308 10.6729 5.92308 11.692C5.92308 12.7111 5.096 13.5382 4.07692 13.5382C3.05785 13.5382 2.23077 12.7111 2.23077 11.692C2.23077 10.6729 3.05785 9.84585 4.07692 9.84585ZM10.9077 3.69201H1.61538C1.27569 3.69201 1 3.9677 1 4.30739C1 4.64708 1.27569 4.92278 1.61538 4.92278H10.9077C11.1932 6.32647 12.4357 7.38432 13.9231 7.38432C15.6215 7.38432 17 6.00585 17 4.30739C17 2.60893 15.6215 1.23047 13.9231 1.23047C12.4357 1.23047 11.1932 2.28832 10.9077 3.69201ZM13.9231 2.46124C14.9422 2.46124 15.7692 3.28832 15.7692 4.30739C15.7692 5.32647 14.9422 6.15355 13.9231 6.15355C12.904 6.15355 12.0769 5.32647 12.0769 4.30739C12.0769 3.28832 12.904 2.46124 13.9231 2.46124Z" fill="#9CA3AF" stroke="white" strokeWidth="0.5" />
                        </svg>
                      </IconButton>
                      <IconButton className={showSearch ? "search-active" : ""} aria-label="update" onClick={handleShowSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            file-rule="evenodd"
                            clipRule="evenodd"
                            d="M1.23254 7.20043C1.23254 3.9044 3.9045 1.23242 7.20052 1.23242C10.4965 1.23242 13.1685 3.9044 13.1685 7.20043C13.1685 10.4964 10.4965 13.1684 7.20052 13.1684C3.90449 13.1684 1.23254 10.4964 1.23254 7.20043ZM7.20052 0.232422C3.35221 0.232422 0.232544 3.35212 0.232544 7.20043C0.232544 11.0487 3.35221 14.1684 7.20052 14.1684C8.94474 14.1684 10.5393 13.5275 11.7615 12.4684L14.9139 15.6208C15.1092 15.8161 15.4258 15.8161 15.621 15.6208C15.8163 15.4255 15.8163 15.1089 15.621 14.9137L12.4686 11.7613C13.5277 10.5391 14.1685 8.94459 14.1685 7.20043C14.1685 3.35212 11.0488 0.232422 7.20052 0.232422Z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      </IconButton>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              {/* </Slide> */}

              {/* <Slide direction="up" in={showSearch} container={containerRef.current}> */}
              {showSearch &&
                <Box className="search-wrapper">
                  <Stack direction="row" alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                    <TextField id="searching" value={searchValue} inputRef={inputRef} size="small" variant="outlined" placeholder="Enter designation / companies / location" sx={{ background: '#ffffff', borderRadius: '12px', width: '100%' }}
                      onChange={(e) => handleSearchInputChange(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          filterLoading && (
                            <InputAdornment position="end">
                              <CircularProgress size={20} />
                            </InputAdornment>
                          )
                        )
                      }}
                    />
                    <Link onClick={handleShowHide} sx={{ 'cursor': 'pointer' }} className='cancel-btn' underline="none">Cancel</Link>
                  </Stack>
                </Box>
                // </Slide>
              }
            </Box>
            <Box className="active-jobs-container" ref={scrollContainerRef} onScroll={handleScroll}>
              {jobs &&
                jobs?.length > 0 &&
                jobs?.map((job, index) => (
                  <Card
                    ref={(el) => (cardRefs.current[job?._id] = el)}
                    className={`job-card-view ${setActiveId && job._id === setActiveId
                      ? 'first-job-item'
                      : !setActiveId && index === 0
                        ? 'first-job-item'
                        : ''
                      }`}
                    variant="outlined"
                    sx={{ marginBottom: '12px' }}
                    key={index}
                    onClick={() => {
                      handleJobData(job);
                      setSetActiveId(job._id);
                    }}
                  >
                    <CardContent sx={{ padding: '16px!important', paddingBottom: '0.7rem!important' }}>
                      <Box>{recommendedChip(job.tj_score)}</Box>
                      <Box className="active-jobs-info">
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Typography className="post-title">{job?.title}</Typography>
                            {job?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{job?.company ? toTitleCase(job?.company) : ''}</Typography>}
                            <Box mt={0.5} mb={1} className="work-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                <Typography variant="p" className="city">{job?.location}</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="city">{job?.job_type}</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="city">{job?.work_mode}</Typography>
                              </Stack>
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Box className="ctc-range">
                              {/* <Typography variant="p" className="bim-tag">
                            Best in Market
                          </Typography> */}
                              {job?.is_compensation_confidential ? <>
                                <Typography className="text-1" >Not Disclosed</Typography>
                                <Typography className="text-2">Compensation</Typography></> : <>
                                <Typography className="text-1">₹{formatNumber(job?.salary)}</Typography>
                                <Typography className="text-2">{job?.compensation}</Typography></>}
                            </Box>
                          </Grid>
                        </Grid>

                        <Box sx={{ marginTop: '8px' }}>
                          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="p" className="posted-date">
                              {job?.updatedAt ? getTimeDifferenceString(job?.updatedAt, "Posted") : 'NA'}
                            </Typography>
                            <Box className="save-hide-job">
                              <Stack direction="row" alignItems={'center'} spacing={1}>
                                <Box>
                                  {job?.application_saved ? (
                                    unsaveJob === job._id ? (
                                      <Button className="save-job-btn" disabled>
                                        Unsaving...
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="text"
                                        size="small"
                                        className="saved-btn"
                                        sx={{ width: '80px' }}
                                        onClick={(e) => {
                                          if (setActiveId && job._id === setActiveId) {
                                            handleSaveOrUnsaveJob(job._id, 'unsaveJob')
                                          } else {
                                            e.stopPropagation();
                                            handleCardSaveOrUnsaveJob(job, 'unsaveJob');
                                          }
                                        }}
                                        startIcon={
                                          <SvgIcon style={{ width: '12px', height: '12px' }}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="8"
                                              height="6"
                                              viewBox="0 0 8 6"
                                              fill="none"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                                fill="white"
                                              />
                                            </svg>
                                          </SvgIcon>
                                        }
                                      >
                                        Job saved
                                      </Button>
                                    )
                                  ) : savedJob === job._id ? (
                                    <Button className="save-job-btn" disabled>
                                      Saving...
                                    </Button>
                                  ) : (
                                    <Button
                                      className="save-job-btn"
                                      startIcon={<AddCircleIcon />}
                                      onClick={(e) => {
                                        if (setActiveId && job._id === setActiveId) {
                                          handleSaveOrUnsaveJob(job._id, 'saveJob')
                                        } else {
                                          e.stopPropagation();
                                          handleCardSaveOrUnsaveJob(job, 'saveJob');
                                        }
                                      }}
                                    >
                                      Save Job
                                    </Button>
                                  )}
                                </Box>
                                <Box>
                                  <Tooltip title={'This job will be permanently removed from your view'} arrow placement='top'>
                                    <Button
                                      disableRipple
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleHideJob(job);
                                      }}
                                      className={`hide-job-btn ${(job?.application_saved || hidingJob === job?._id) ? 'disabled' : ''}`}
                                      disabled={job?.application_saved}
                                      startIcon={
                                        <SvgIcon>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="16"
                                            viewBox="0 0 18 16"
                                            fill="none"
                                          >
                                            <path
                                              file-rule="evenodd"
                                              clipRule="evenodd"
                                              d="M12.3306 3.83292L15.8719 0L16.8888 1.10074L3.12329 16L2.1063 14.8993L4.39451 12.4226C2.50583 11.2629 1.053 9.71004 0.399235 8.92382C0.163146 8.64862 0.163146 8.21618 0.399235 7.94107C1.52517 6.5848 4.92116 2.9484 8.95275 2.9484C10.1151 2.9484 11.2591 3.30221 12.3306 3.83292ZM9.11208 7.18513C9.15257 7.18976 9.19091 7.19413 9.22519 7.19413L10.9686 5.30713C10.4055 4.89435 9.73363 4.63882 8.9891 4.63882C7.06408 4.63882 5.50229 6.32924 5.50229 8.4128C5.50229 9.21867 5.73837 9.94596 6.11974 10.5553L7.84501 8.688C7.82679 8.60933 7.82679 8.51102 7.82679 8.4128C7.82679 7.7248 8.33532 7.17449 8.97088 7.17449C9.01884 7.17449 9.0668 7.17996 9.11208 7.18513ZM14.8699 5.71415C16.1939 6.6132 17.253 7.60405 17.8014 8.15454C18.0662 8.42974 18.0662 8.83338 17.8014 9.12698C16.6099 10.393 13.0732 13.7142 8.91252 13.7142C8.21278 13.7142 7.5319 13.6224 6.88887 13.4573L8.28843 12.0995C8.49643 12.1361 8.70452 12.1545 8.91252 12.1545C10.9172 12.1545 12.5437 10.5766 12.5437 8.6316C12.5437 8.42974 12.5248 8.22796 12.487 8.02609L14.8699 5.71415Z"
                                              fill={job?.application_saved ? 'grey' : 'black'}
                                            />
                                          </svg>
                                        </SvgIcon>
                                      }
                                    >
                                      {hidingJob === job?._id ? 'Removing...' : 'Remove'}
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              {jobs?.length && loading ?
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <CircularProgress size="sm" sx={{ width: 15, height: 15, marginRight: '5px' }} />  Loading...
                </Box> : null}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8}>
          {/* job full details */}
          {jobs?.length > 0 && data && (
            <JobsMarketsFullDetails
              data={data}
              onApplied={handleRemoveJob}
              savedJob={savedJob}
              unsaveJob={unsaveJob}
              handleChangeSaveOrUnsaveButton={handleChangeSaveOrUnsaveButton}
              handleSaveOrUnsaveJob={handleSaveOrUnsaveJob}
              downloadResume={handleDownloadResume}
              showExternalJobStatus={showExternalJobStatus}
              onJobPreferencesChange={handleJobPreferences}
              updateTJScore={handleUpdateTjScore}
            />
          )}
        </Grid>
      </Grid>
      <Popover elevation={3}
        id="filter-by"
        className="filter-by-overlay"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={(e) => handlePopoverClose(false)}
        sx={{
          top: '15px',
          left: '-150px'
        }}
        disableRestoreFocus
      >
        <Box
          sx={{
            position: "relative",
            "&::before": {
              backgroundColor: "white",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -8,
              transform: "rotate(45deg)",
              left: "calc(25% - 7px)",
              borderTop: '1px solid #D2D5DB',
              borderLeft: '1px solid #D2D5DB'
            }
          }}
        />
        <Box sx={{ height: 400, width: 600 }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={5} className="filter-by-tabs-item">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ height: '100%' }}
              >
                <Tab label="Sort By" disableRipple {...a11yProps(0)} />
                <Tab label="Location" disableRipple {...a11yProps(1)} />
                <Tab label="Job Suitability" disableRipple {...a11yProps(2)} />
                <Tab label="Job Type" disableRipple {...a11yProps(3)} />
              </Tabs>
            </Grid>

            <Grid item xs={7} className="filter-by-tabs-content">
              <TabPanel value={value} index={0}>
                <Box>
                  <Typography className="title">Sort By</Typography>
                </Box>
                <Box>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-1"
                      name="salary"
                      value={sortCriteria.salary || ""}
                      onChange={handleSortChange}
                    >
                      <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (High to Low)" />
                      <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (Low to High)" />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-2"
                      name="updatedAt"
                      value={sortCriteria.updatedAt || ""}
                      onChange={handleSortChange}
                    >
                      <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Latest First)" />
                      <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Oldest First)" />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Typography className="title">Location</Typography>
                <FormControl>
                  <FormGroup>
                    {[...preferedLocation]?.map((data, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={filterCriteria?.location?.some((fl) => (clusteredData?.find(ct => ct?.value === fl)?.name === data || fl === data))}
                            onChange={(e) => handleChangeFilterCriteria(e, clusteredData?.find(ct => ct?.name === data)?.value || data)}
                            name="location"
                          />
                        }
                        label={data}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography className="title">Job Suitability</Typography>
                <FormControl>
                  <FormGroup>
                    {["Highly Suitable", "Moderately Suitable", "Not Suitable"].map((data, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name="work_format"
                            checked={filterCriteria?.work_format?.includes(data)}
                            onChange={(e) => handleChangeFilterCriteria(e, data)}
                            value={data}
                          />
                        }
                        label={data} />
                    ))}
                  </FormGroup>
                </FormControl>
              </TabPanel>

              <TabPanel value={value} index={3}>
                <FormControl>
                  <FormGroup>
                    <Typography className="title">Job Type</Typography>
                    {["Full time", "Part time", "Temporary", "Internship", "Trainee", "Freelancer (Self Employed)"]?.map((jobType, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={filterCriteria.job_type.includes(jobType)}
                            onChange={(e) => handleChangeFilterCriteria(e, jobType)}
                            name="job_type"
                          />
                        }
                        label={jobType}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </TabPanel>

              <Box className="filter-by-actions-btn">
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" fullWidth onClick={() => { handlePopoverClose(true) }}>{filterApplied ? "Clear Filter" : "Cancel"}</Button>
                  <Button variant="contained" fullWidth onClick={() => { handleApplyFilter() }} disabled={filterLoading}>{filterLoading ? 'Applying...' : 'Apply'}</Button>
                </Stack>
              </Box>

            </Grid>
          </Grid>
        </Box>
      </Popover>
      <Drawer open={filterLoading}>
        <div className="loading-overlay">
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default JobsMarket;
