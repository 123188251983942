import { Avatar, Box, Button, Card, CardContent, Chip, Checkbox, CircularProgress, Divider, InputAdornment, TextField, FormControl, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemText, Popover, Radio, RadioGroup, Stack, SvgIcon, ToggleButton, AppBar, Toolbarup, Typography, ToggleButtonGroup, ListItemButton, ListItemIcon, Drawer, Dialog, DialogContent, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { jobDetails } from 'api/job';
import { useAuth } from 'hooks/useAuthContext';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { SplashScreen } from 'components/loading-screen';
import { getApplicationApplyDetails } from 'api/application';
import { saveJobApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import JobDetails from './JobDetails';
import JobDescription from './JobDescription';
import { getJobsForExternalUse } from 'api/job';
import { debounce } from 'lodash';
import { getJobPreferanceDetails } from 'api/users';
import EmptyState from './EmptyState';
import { cleanData, clusteredData } from 'data/city.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const InternalJobFromExternal = () => {
  const { user, updateContext } = useAuth();
  const [userId, setUserId] = useState(user?.id || null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  // const [id, setId] = useState('66e7fed080daf6b4dd381815' || null);

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { jobApply, setJobApply } = useAuth();
  const [setActiveId, setSetActiveId] = useState(null);

  const [filterApplied, setFilterApplied] = useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortCriteria, setSortCriteria] = useState({});
  const [preferedLocation, setPreferedLocation] = useState([]);
  const jobTypes = ['Full time', 'Part time', 'Temporary', 'Internship', 'Trainee', 'Freelancer (Self employed)'];
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({
    location: [],
    work_format: [],
    job_type: []
  });

  const open = Boolean(anchorEl);
  const [value, setValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef(null);
  const [showFilter, setShowFilter] = React.useState(true);
  const [applyButton, setApplyButton] = useState(false);
  const [isJobPresent, setIsJobPresent] = useState(false);
  const cardRefs = useRef({});
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchJob();
    };
    fetchData();
  }, []);
  useEffect(() => {
    setUserId(user?.id || null);
    const fetchPreferences = async () => {
      if (user) {
        await fetchJobPreferences();
      }
    };
    fetchPreferences();
  }, []); //demo }, [user]);
  useEffect(() => {
    if (setActiveId && cardRefs.current[setActiveId]) {
      scrollContainerRef.current.scrollTo({
        top: cardRefs.current[setActiveId].offsetTop - scrollContainerRef.current.clientHeight / 2 + cardRefs.current[setActiveId].offsetHeight / 2,
        behavior: 'smooth',
      });
    }
  }, [setActiveId, loading]);

  const fetchJob = async (search, filters, reload = false) => {
    let selectedId = null;
    try {
      const response = await getJobsForExternalUse({ search, filters, sort: sortCriteria });
      if (response && response?.success && response?.data && response?.data?.job) {
        setJobs(response?.data?.job);
        setIsJobPresent(response.data?.isJobPresent);
        if (reload) {
          selectedId = response?.data?.job[0]?._id;
        } else if (jobApply?.job?._id) {
          const selectedJob = response?.data?.job.find((job) => job._id === jobApply?.job?._id);
          selectedId = selectedJob?._id || response?.data?.job[0]?._id;
        } else if (id) {
          const highlightedJob = response?.data?.job.find((job) => job.jobid === id);
          selectedId = highlightedJob?._id || response?.data?.job[0]?._id;
        } else {
          selectedId = response?.data?.job[0]?._id;
        }
      } else {
        setJobs([]);
      }
      setSetActiveId(selectedId);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setFilterLoading(false);
      if (user) await fetchJobDetails(selectedId);
    }
  };

  const fetchJobPreferences = async () => {
    try {
      const response = await getJobPreferanceDetails();
      if (response?.success && response.data) {
        const location = response.data.prefered_location.filter((loc) => loc !== "Any");
        setPreferedLocation(location || []);
      } else {
        console.error('Failed to fetch job preferences', response?.message);
      }
    } catch (error) {
      console.error('Error fetching job preferences:', error);
    }
  };

  const handleApplyFilter = async () => {
    if (!sortCriteria && filterCriteria?.location?.length === 0 && filterCriteria?.work_format?.length === 0 && filterCriteria?.job_type?.length === 0) {
      return;
    }
    setFilterLoading(true);
    setFilterApplied(true);
    fetchJob(searchValue, filterCriteria, true);
    handlePopoverClose();
  };
  const handleJobData = (job) => {
    setJobApply(null);
    setSetActiveId(job?._id);
    // setShowExternalJobStatus('');
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShowSearch = () => {
    setShowSearch(true);
    setShowFilter(false);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };
  const handlePopoverClose = (isCancel) => {
    setAnchorEl(null);
    const isFilterApplied = (filterCriteria?.location?.length || filterCriteria?.work_format?.length || filterCriteria?.job_type?.length);
    const isSortingApplied = Object?.keys(sortCriteria)?.length;
    if (isCancel && (isFilterApplied || isSortingApplied || filterApplied)) {
      setFilterApplied(false);
      setSortCriteria({});
      setFilterCriteria({
        location: [],
        work_format: [],
        job_type: []
      })
      fetchJob('', '', true);
      setFilterLoading(true);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSortChange = (event) => {
    const { name, value } = event.target;
    setSortCriteria({
      ...sortCriteria,
      [name]: value,
    });
  };
  const handleDoubleClick = (event) => {
    const { name, value } = event.target;

    setSortCriteria((prev) => ({
      ...prev,
      [name]: prev[name] === value ? "" : value,
    }));
  };
  const handleShowHide = async () => {
    if (searchValue) {
      setShowSearch(false);
      setShowFilter(true);
      setSearchValue('');
      fetchJob('', filterCriteria, true);
    } else {
      setShowSearch(false);
      setShowFilter(true);
      fetchJob('', filterCriteria, true);
    }
  };
  const handleSearchInputChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value, filterCriteria);
    // setFilterLoading(true);
  };
  const debouncedSearch = useCallback(
    debounce(async (value, flc) => {
      setFilterLoading(true);
      await fetchJob(value, flc, true);
    }, 500),
    []
  );
  const handleChangeFilterCriteria = (event, value) => {
    const { name } = event.target;
    setFilterCriteria((prevCriteria) => ({
      ...prevCriteria,
      [name]: prevCriteria[name].includes(value)
        ? prevCriteria[name].filter((item) => item !== value)
        : [...prevCriteria[name], value],
    }));
  };

  const fetchJobDetails = async (selectedId) => {
    if (!selectedId) return;
    let jobStatus;
    let jobData;
    let jobApplication;
    const response = await jobDetails({ id: selectedId, userId: user?.id });
    if (response?.success) {
      if (response?.data?.job) {
        jobData = response?.data?.job;
        jobApplication = response?.data?.jobIdInCandidate;
        jobStatus = response?.data?.jobIdInCandidate?.status;
      }
    } else console.error("Failed to fetch job info:", response.message);
    handleNavigation(jobData, jobStatus, jobApplication);
  };
  const handleNavigation = (jobData, jobStatus, jobApplication) => {
    const status = jobStatus || null;
    // console.log(status);
    if (!user?.stages || user?.stages < 4) {
      handleSaveJob();
    } else {
      setJobApply({
        job: ((status === 'saved' || status === null) ? jobData : jobApplication),
        openChatBot: (applyButton && (status === 'saved' || status === null) ? true : false),
        resume: null, answer: null,
        tab: ((status === 'saved' || status === null) ? '2' : '3'),
        activeTab: status === 'application pending' ? 'saved-jobs' : 'applied-jobs',
        filter: id
      });
    }
    navigate('/');
  }
  const handleSaveJob = async () => {
    try {
      const job = jobs.find((job) => job._id === setActiveId);
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'saved' });
      if (response?.success && response?.data != null) {
        enqueueSnackbar(response?.message, { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } catch (e) {
      console.error('Error saving for later:', e);
    }
  }


  if (!jobs?.length && loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  if (jobs.length === 0 && !isJobPresent) {
    return (
      <Box marginTop={5}>
        < EmptyState tab={'job-market'} />
      </Box>
    );
  }

  return (
    <Box className="job-full-details" sx={{ padding: '50px 10px 0px 10px !important', overflow: 'hidden' }} >
      <Grid container spacing={2} sx={{ backgroundColor: '#f1f5f7' }}>
        <Grid item xs={4}>
          <Box className="active-jobs-list">
            <Box className="header-wrapper" >
              <Box className="filter-wrapper" mb={2}>
                <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                  <Typography className="head-text" variant="p">
                    {jobs?.length > 0 ? `${jobs?.length} Active Jobs` : 'Result Not Found'}
                  </Typography>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="update"
                        aria-owns={open || filterApplied ? 'filter-by' : undefined}
                        aria-haspopup="true"
                        onClick={handlePopoverOpen}
                      >
                        {filterApplied && <SvgIcon className="ellipse-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <circle cx="6" cy="6.5" r="6" fill="#C12929" />
                          </svg>
                        </SvgIcon>}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                          <path file-rule="evenodd" clipRule="evenodd" d="M7.0923 12.3074H16.3846C16.7243 12.3074 17 12.0317 17 11.692C17 11.3523 16.7243 11.0766 16.3846 11.0766H7.0923C6.80677 9.67293 5.56431 8.61508 4.07692 8.61508C2.37846 8.61508 1 9.99355 1 11.692C1 13.3905 2.37846 14.7689 4.07692 14.7689C5.56431 14.7689 6.80677 13.7111 7.0923 12.3074ZM4.07692 9.84585C5.096 9.84585 5.92308 10.6729 5.92308 11.692C5.92308 12.7111 5.096 13.5382 4.07692 13.5382C3.05785 13.5382 2.23077 12.7111 2.23077 11.692C2.23077 10.6729 3.05785 9.84585 4.07692 9.84585ZM10.9077 3.69201H1.61538C1.27569 3.69201 1 3.9677 1 4.30739C1 4.64708 1.27569 4.92278 1.61538 4.92278H10.9077C11.1932 6.32647 12.4357 7.38432 13.9231 7.38432C15.6215 7.38432 17 6.00585 17 4.30739C17 2.60893 15.6215 1.23047 13.9231 1.23047C12.4357 1.23047 11.1932 2.28832 10.9077 3.69201ZM13.9231 2.46124C14.9422 2.46124 15.7692 3.28832 15.7692 4.30739C15.7692 5.32647 14.9422 6.15355 13.9231 6.15355C12.904 6.15355 12.0769 5.32647 12.0769 4.30739C12.0769 3.28832 12.904 2.46124 13.9231 2.46124Z" fill="#9CA3AF" stroke="white" strokeWidth="0.5" />
                        </svg>
                      </IconButton>
                      <IconButton className={showSearch ? "search-active" : ""} aria-label="update" onClick={handleShowSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            file-rule="evenodd"
                            clipRule="evenodd"
                            d="M1.23254 7.20043C1.23254 3.9044 3.9045 1.23242 7.20052 1.23242C10.4965 1.23242 13.1685 3.9044 13.1685 7.20043C13.1685 10.4964 10.4965 13.1684 7.20052 13.1684C3.90449 13.1684 1.23254 10.4964 1.23254 7.20043ZM7.20052 0.232422C3.35221 0.232422 0.232544 3.35212 0.232544 7.20043C0.232544 11.0487 3.35221 14.1684 7.20052 14.1684C8.94474 14.1684 10.5393 13.5275 11.7615 12.4684L14.9139 15.6208C15.1092 15.8161 15.4258 15.8161 15.621 15.6208C15.8163 15.4255 15.8163 15.1089 15.621 14.9137L12.4686 11.7613C13.5277 10.5391 14.1685 8.94459 14.1685 7.20043C14.1685 3.35212 11.0488 0.232422 7.20052 0.232422Z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      </IconButton>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              {/* </Slide> */}

              {/* <Slide direction="up" in={showSearch} container={containerRef.current}> */}
              {showSearch &&
                <Box className="search-wrapper">
                  <Stack direction="row" alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                    <TextField id="searching" value={searchValue} inputRef={inputRef} size="small" variant="outlined" placeholder="Enter designation / companies / location" sx={{ background: '#ffffff', borderRadius: '12px', width: '100%' }}
                      onChange={(e) => handleSearchInputChange(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          filterLoading && (
                            <InputAdornment position="end">
                              <CircularProgress size={20} />
                            </InputAdornment>
                          )
                        )
                      }}
                    />
                    <Link onClick={handleShowHide} href="#" className='cancel-btn' underline="none">Cancel</Link>
                  </Stack>

                </Box>
                // </Slide>
              }
            </Box>


            <Box className="active-jobs-container" ref={scrollContainerRef} sx={{ overflow: 'auto', height: 'calc(100vh - 120px) !important' }}>
              {jobs &&
                jobs?.length > 0 && (
                  jobs?.map((job, index) => (
                    <Card
                      ref={(el) => (cardRefs.current[job?._id] = el)}
                      className={`job-card-view ${setActiveId && job._id === setActiveId
                        ? 'first-job-item'
                        : !setActiveId && index === 0
                          ? 'first-job-item'
                          : ''
                        }`}
                      variant="outlined"
                      sx={{ marginBottom: '1rem' }}
                      key={job?._id}
                      onClick={() => {
                        handleJobData(job);
                        setSetActiveId(job._id);
                      }}
                    >
                      <CardContent sx={{ padding: '16px!important' }}>
                        {/* <Box>{recommendedChip(job.tj_score)}</Box> */}
                        <Box className="active-jobs-info">
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              <Typography className="post-title">{job?.title}</Typography>
                              {job?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{job?.company ? toTitleCase(job?.company) : ''}</Typography>}
                              <Box mt={0.5} mb={1} className="work-type">
                                <Stack direction="row" flexWrap="wrap" alignItems={'center'} spacing={0.5}>
                                  <Typography variant="p" className="city">{job?.location}</Typography>
                                  <Typography variant="body1">•</Typography>
                                  <Typography variant="p" className="city">{job?.job_type}</Typography>
                                  <Typography variant="body1">•</Typography>
                                  <Typography variant="p" className="city">{job?.work_mode}</Typography>
                                </Stack>
                              </Box>
                            </Grid>
                            <Grid item xs={5}>
                              <Box className="ctc-range">
                                {/* <Typography variant="p" className="bim-tag">
                            Best in Market
                          </Typography> */}
                                {job?.is_compensation_confidential ? <>
                                  <Typography className="text-1" >Not Disclosed</Typography>
                                  <Typography className="text-2">Compensation</Typography></> : <>
                                  <Typography className="text-1">₹{formatNumber(job?.salary)}</Typography>
                                  <Typography className="text-2">{job?.compensation}</Typography></>}
                              </Box>
                            </Grid>
                          </Grid>
                          <Box mt={1}>
                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                              <Typography variant="p" className="posted-date">
                                {job?.updatedAt ? getTimeDifferenceString(job?.updatedAt, "Posted") : 'NA'}
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8}>
          {/* job full details */}
          {setActiveId && userId && (user && jobs && user?.stages > 3)
            ?
            <JobDescription jobId={setActiveId} applyButton={applyButton} setApplyButton={setApplyButton} /> :
            <JobDetails jobId={setActiveId} setApplyButton={setApplyButton} applyButton={applyButton} />
          }
        </Grid>
      </Grid>
      <Popover elevation={3}
        id="filter-by"
        className="filter-by-overlay"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={(e) => handlePopoverClose(false)}
        sx={{
          top: '15px',
          left: '-150px'
        }}
        disableRestoreFocus
      >
        <Box
          sx={{
            position: "relative",
            "&::before": {
              backgroundColor: "white",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -8,
              transform: "rotate(45deg)",
              left: "calc(25% - 7px)",
              borderTop: '1px solid #D2D5DB',
              borderLeft: '1px solid #D2D5DB'
            }
          }}
        />
        <Box sx={{ height: 400, width: 600 }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={5} className="filter-by-tabs-item">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ height: '100%' }}
              >
                <Tab label="Sort By" disableRipple {...a11yProps(0)} />
                <Tab label="Location" disableRipple {...a11yProps(1)} />
                {/* <Tab label="Job Suitability" disableRipple {...a11yProps(2)} /> */}
                <Tab label="Job Type" disableRipple {...a11yProps(2)} />
              </Tabs>
            </Grid>

            <Grid item xs={7} className="filter-by-tabs-content">
              <TabPanel value={value} index={0}>
                <Box>
                  <Typography className="title">Sort By</Typography>
                </Box>
                <Box>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-1"
                      name="salary"
                      value={sortCriteria.salary || ""}
                      onChange={handleSortChange}
                    >
                      <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (High to Low)" />
                      <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (Low to High)" />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-2"
                      name="updatedAt"
                      value={sortCriteria.updatedAt || ""}
                      onChange={handleSortChange}
                    >
                      <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Latest First)" />
                      <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Oldest First)" />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Typography className="title">Location</Typography>
                {preferedLocation && preferedLocation.length > 0 ? (
                  <FormControl>
                    <FormGroup>
                      {[...preferedLocation]?.map((data, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={filterCriteria?.location?.some((fl) => (clusteredData?.find(ct => ct?.value === fl)?.name === data || fl === data))}
                              onChange={(e) => handleChangeFilterCriteria(e, clusteredData?.find(ct => ct?.name === data)?.value || data)}
                              name="location"
                            />
                          }
                          label={data}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                ) : (
                  <Typography>Please log in to view location preferences.</Typography>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <FormControl>
                  <FormGroup>
                    <Typography className="title">Job Type</Typography>
                    {jobTypes?.map((jobType, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={filterCriteria?.job_type?.includes(jobType) || false}
                            onChange={(e) => handleChangeFilterCriteria(e, jobType)}
                            name="job_type"
                          />
                        }
                        label={jobType}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </TabPanel>

              <Box className="filter-by-actions-btn">
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" fullWidth onClick={() => handlePopoverClose(true)}>{filterApplied ? "Clear Filter" : "Cancel"}</Button>
                  <Button variant="contained" fullWidth onClick={() => handleApplyFilter()} disabled={filterLoading}>{filterLoading ? 'Applying...' : 'Apply'}</Button>
                </Stack>
              </Box>

            </Grid>
          </Grid>
        </Box>
      </Popover>
      <Drawer open={filterLoading}>
        <div className="loading-overlay">
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default InternalJobFromExternal;
