import React, { useEffect, useState, useRef } from 'react';
import { Link, CardContent, OutlinedInput, InputLabel, FormControl, SvgIcon, Card, Dialog, Box, Button, Container, Stack, Divider, Grid, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
// import Header from '../header/Header';
import ContactDetails from '../../components/basic-profile/ContactDetails';
import PersonalDetails from '../../components/basic-profile/PersonalDetails';
import EducationSection from '../../components/basic-profile/EducationSection';
import CertificationSection from '../../components/basic-profile/CertificationSection';
import WorkExperienceDetails from '../../components/basic-profile/WorkExperienceSection';
import ProfessionalSummary from '../../components/basic-profile/ProfessionalSummary';
import CustomSection from '../../components/basic-profile/CustomSection';
import UploadProfile from 'components/basic-profile/UploadProfile';
import CandidateResume from '../../components/basic-profile/CandidateResume';
import FinanceTechnicalCompetencies from '../../components/basic-profile/FinanceTechnicalCompetencies'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import { useAuth } from 'hooks/useAuthContext';
import {getBasicProfileDetails, getAllEducationDetails, getAllCertificationDetails, updateUser} from 'api/users';
import { SplashScreen } from 'components/loading-screen';
import CareerBreaksIdentifiedDialog from '../../components/basic-profile/CareerBreaksIdentifiedDialog';

const BasicDetails = () => {
  const { user, updateContext } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const personalDetailRef = useRef(null);
  const [contactDetailsError, setContactDetailsError] = useState();

  const [customSection, setCustomSection] = useState([]);
  const [profile, setProfile] = useState();
  const [educationData, setEducationData] = useState([]);
  const [profilephoto, setProfilePhoto] = useState([]);
  const [workExperienceData, setWorkHistory] = useState();
  const [certificateData, setCertificate] = useState();
  const [summaryData, setSummary] = useState();
  const [templateType, setTemplateType] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [expandedSection, setExpandedSection] = useState({ section: null, index: null }); const { enqueueSnackbar } = useSnackbar();
  const [workExperienceGap, setGap] = useState([]);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(true);
  const [financeTechologiesData, setfinanceTechologiesData] = useState([]);
  const [nextPage, setNextPage] = useState(false);
  const scrollableContainerRef = useRef(null);

  const fetchData = async () => {
    try {
      setUpdating(true);
      const [profileResponse, educationAndWorkResponse, certificateResponse] = await Promise.all([
        getBasicProfileDetails(),
        getAllEducationDetails(),
        getAllCertificationDetails(),
      ]);
      if (profileResponse?.success && profileResponse.data) {
        const profileData = profileResponse.data.candidate ?? {};
        // const prevPath = localStorage.getItem('prevPath');
        // if (prevPath === '/login' && profileData?.stages === 3) {
        //   localStorage.removeItem('prevPath');
        //   navigate('/job-application');
        // }
        setProfile(profileResponse.data);
        setSummary(profileData.summary || '');
        if (profileData.custom_section) {
          setCustomSection(profileData.custom_section);
        }
        if (profileData.template) {
          setTemplateType(profileData.template);
        }
        if (profileData.finance_technologies) {
          setfinanceTechologiesData(profileData.finance_technologies);
        }
      }
      if (educationAndWorkResponse?.success && educationAndWorkResponse.data) {
        const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
        const workUnsavedData = localStorage.getItem('workExperience') ? JSON.parse(localStorage.getItem('workExperience')) : null;
        setEducationData([...educationAndWorkResponse.data?.educationList || [], ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])]);
        setWorkHistory([...educationAndWorkResponse.data?.workHistoryList || [], ...(workUnsavedData && !checkLocalDataIsEmpty(workUnsavedData) ? [{ ...workUnsavedData }] : [])]);
      }
      if (certificateResponse?.success && certificateResponse.data) {
        const certificateUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
        setCertificate([...certificateResponse.data || [], ...(certificateUnsavedData && !checkLocalDataIsEmpty(certificateUnsavedData) ? [{ ...certificateUnsavedData }] : [])]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setUpdating(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEducationDataChange = () => {
    setExpandedSection({ section: null, index: null });
    fetchData();
  };

  const handleSummaryDataChange = (data) => {
    setSummary(data || '');
  };

  const handleProfilePhotoChange = () => {
    fetchData();
  };

  const handleCertificateDataChange = () => {
    setExpandedSection({ section: null, index: null });
    fetchData();
  };

  const handleWorkExperienceDataChange = () => {
    setExpandedSection({ section: null, index: null });
    fetchData();
  };

  const handleFinanceDataChange = () => {
    fetchData();
  };

  const handleCustomSectionDataChange = async () => {
    setFlag(false);
    fetchData();
  };

  const handleNext = () => {
    if (user?.name && user.city && user.sex && user?.date_of_birth && workExperienceData && workExperienceData.length && educationData && educationData.length && user?.name && user?.sex && user?.date_of_birth && user?.city) {
      navigate('/job-preferences')
    } else {
      enqueueSnackbar("please enter atleast one work history and one education", { variant: 'error' });
    }
  };

  const handleExpand = (section, index) => {
    setExpandedSection((prev) => {
      if (!(prev.section === section && prev.index === index)) {
        if (prev?.section === "education") {
          const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
          setEducationData([...educationData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
        }
        if (prev?.section === "certificate") {
          const eduUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
          setCertificate([...certificateData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
        }
        if (prev?.section === "work") {
          const eduUnsavedData = localStorage.getItem("workExperience") ? JSON.parse(localStorage.getItem("workExperience")) : null;
          setWorkHistory([...workExperienceData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
        }
        return { section, index }
      } else {
        return { section: null, index: null }
      }
    });
  };

  const checkLocalDataIsEmpty = (data) => {
    if (data) {
      return Object.values(data)?.every(value =>
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      );
    }
  };

  const handleCancelEducation = () => {
    setExpandedSection((prev) => {
      if (prev?.section === "education") {
        const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
        setEducationData([...educationData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
      }
      if (prev?.section === "certificate") {
        const eduUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
        setCertificate([...certificateData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
      }
      if (prev?.section === "work") {
        const eduUnsavedData = localStorage.getItem("workExperience") ? JSON.parse(localStorage.getItem("workExperience")) : null;
        setWorkHistory([...workExperienceData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
      }
      return { section: null, index: null }
    });
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  const validateAndProceed = async () => {
    const isPersonalDetailValid = await personalDetailRef.current.triggerValidation();
    if (!user?.phone) {
      setContactDetailsError(true);
    } else {
      setContactDetailsError(false);
    }
    if (user && isPersonalDetailValid && user?.phone && user?.email && educationData && educationData?.length > 0 && workExperienceData && workExperienceData?.length > 0) {
      const gap = gapWorkExperience(workExperienceData?.filter(item => item?._id), educationData, certificateData);
      if (gap && gap.length > 0) {
        setOpen(true);
      } else {
        if (user?.stages <= 3) {
          setNextPage(true);
          try {
            const response = await updateUser();
            if (response && response?.data) {
              updateContext({ stages: response?.data?.stages });
              updateContext({ stages: 3 });
            }
          } catch (error) {
            console.error(error);
          } finally {
            setNextPage(false);
          }
        }
        navigate('/job-preferences');
      }
    } else {
      const personalInfo = isPersonalDetailValid ? '' : "personal details";
      const contactInfo = user?.phone && user?.email ? '' : "contact details";
      const education = educationData && educationData?.length > 0 ? '' : "education";
      const workHistory = workExperienceData && workExperienceData?.length > 0 ? '' : "work history";
      const missingFields = [
        personalInfo,
        contactInfo,
        education,
        workHistory
      ].filter(field => field !== '');
      if (missingFields?.length > 0) {
        const missingFieldsString = missingFields?.join(', ')?.replace(/,([^,]*)$/, ' and$1');
        enqueueSnackbar(`Please fill out ${missingFieldsString}.`, { variant: 'error' });
      }
    }
  };

  const gapWorkExperience = (workExperienceList, educationData, certificateData) => {

    let gaps = [];
    const calculate = (startDate, endDate) => {
      const timeDifference = startDate - endDate;
      const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
      const differenceInDays = Math.floor(differenceInHours / 24);
      console.log(differenceInDays);
      return differenceInDays;
    };

    const checkEndDate = (index, startDate, gapDays, isupdated = false) => {
      let AddGap = {};
      for (let i = index + 1; i < workExperienceList.length; i++) {
        const endDate = new Date(workExperienceList[i].work_history_to);
        let gap = calculate(startDate, endDate);
        if (gap <= gapDays) {
          gapDays = gap;
          if (gap >= 90) {
            const gapStartDate = new Date(startDate);
            const gapEndDate = new Date(endDate);

            gapStartDate.setDate(gapStartDate.getDate() - 1);
            gapEndDate.setDate(gapEndDate.getDate() + 1);
            AddGap = {
              endDate: gapStartDate,
              startDate: gapEndDate,
            };
            return { AddGap, isupdated: true };
          } else {
            isupdated = true;
          }
        }
      }
      return { AddGap: {}, isupdated: isupdated };
    }

    if (workExperienceList?.length > 0 && !workExperienceList[0]?.present) {
      const startDate = new Date();
      const endDate = new Date(workExperienceList[0].work_history_to);
      if (calculate(startDate, endDate) >= 90) {
        const gapStartDate = new Date(startDate);
        const gapEndDate = new Date(endDate);

        gapEndDate.setDate(gapEndDate.getDate() + 1);

        gaps.push({
          endDate: gapStartDate,
          startDate: gapEndDate,
        });

      }
    }

    if (workExperienceList.length > 1) {
      for (let i = 1; i < workExperienceList.length; i++) {
        const startDate = new Date(workExperienceList[i - 1].work_history_from);
        const endDate = new Date(workExperienceList[i].work_history_to);
        const gapDays = calculate(startDate, endDate);
        let { AddGap, isupdated } = checkEndDate(i, startDate, gapDays);
        if (AddGap?.endDate && AddGap?.startDate) gaps.push(AddGap);
        if (gapDays >= 90 && !isupdated) {
          const gapStartDate = new Date(startDate);
          const gapEndDate = new Date(endDate);

          gapStartDate.setDate(gapStartDate.getDate() - 1);
          gapEndDate.setDate(gapEndDate.getDate() + 1);

          gaps.push({
            endDate: gapStartDate,
            startDate: gapEndDate,
          });
        }
      }
    }

    let result
    if (gaps && gaps?.length > 0 && (educationData && educationData?.length > 0 || certificateData && certificateData?.length > 0)) {
      result = adjustGapsForPeriods(gaps, educationData, certificateData);
      gaps = result;
    }
    setGap(gaps);
    return gaps;
  };

  const adjustGapsForPeriods = (gaps, educationData, certificateData) => {
    const combinedData = [...educationData, ...certificateData];
    const filteredData = combinedData.filter(item =>
      item.learning_format === "Full time - offline" || item.learning_format === "Full time - online"
    );

    const periods = filteredData.map(item => ({
      from: new Date(item.education_from),
      to: new Date(item.education_to)
    }));

    const adjustedGaps = [];

    for (const gap of gaps) {
      let gapStart = new Date(gap.startDate);
      let gapEnd = new Date(gap.endDate);

      gapStart.setMonth(gapStart.getMonth());
      gapStart.setDate(1);
      gapEnd = new Date(gapEnd.getFullYear(), gapEnd.getMonth() + 1, 0);

      let hasOverlap = false;

      for (const period of periods) {
        const periodStart = new Date(period.from);
        const periodEnd = new Date(period.to);

        if (gapStart <= periodEnd && gapEnd >= periodStart) {
          hasOverlap = true;

          if (gapStart < periodStart) {
            const beforeGapEnd = new Date(periodStart);
            beforeGapEnd.setDate(beforeGapEnd.getDate() - 1);
            const beforeGapDuration = (beforeGapEnd - gapStart) / (1000 * 60 * 60 * 24);
            if (beforeGapDuration > 90) {
              adjustedGaps.push({
                startDate: new Date(gapStart),
                endDate: new Date(beforeGapEnd)
              });
            }
          }

          if (gapEnd > periodEnd) {
            const afterGapStart = new Date(periodEnd);
            afterGapStart.setMonth(afterGapStart.getMonth() + 1);
            afterGapStart.setDate(1);
            const afterGapDuration = (gapEnd - afterGapStart) / (1000 * 60 * 60 * 24);
            if (afterGapDuration > 90) {
              adjustedGaps.push({
                startDate: new Date(afterGapStart),
                endDate: new Date(gapEnd)
              });
            }
          }

          break;
        }
      }

      if (!hasOverlap) {
        const gapDuration = (gapEnd - gapStart) / (1000 * 60 * 60 * 24);
        if (gapDuration > 88) {
          adjustedGaps.push({
            startDate: new Date(gapStart),
            endDate: new Date(gapEnd)
          });
        }
      }
    }

    return adjustedGaps;
  };

  const calculateWorkGap = (gaps, educationData, certificateData) => {
     const CertificateAndEducationData = [...educationData, ...certificateData];
     const filteredData = CertificateAndEducationData.filter(item =>
      item.learning_format === "Full time - offline" || item.learning_format === "Full time - online"
    );
    const sortedData = filteredData.sort((a, b) => 
      new Date(a.education_from || a.start_date) - new Date(b.education_from || b.start_date)
    );
    
    const processedGaps = gaps.filter(gap => {
      const gapStart = new Date(gap.startDate);
      const gapEnd = new Date(gap.endDate);

      // Check for overlaps with education data
      const hasEducationOverlap = sortedData.some(iten => {
          const eduStart = new Date(iten.education_from);
          const eduEnd = new Date(iten.education_to);
          // return (eduStart >= gapStart || eduStart <= gapStart) && (eduEnd <= gapEnd || eduEnd >= gapEnd);
          const startDifference = Math.abs(gapStart - eduStart);
          const startDateDifference = Math.ceil(startDifference / (1000 * 60 * 60 * 24));
          
          const endDifference = Math.abs(eduEnd - gapEnd);
          const endDateDifference = Math.ceil(endDifference / (1000 * 60 * 60 * 24));

          if(startDateDifference>90) {

          }
          if(endDateDifference > 0) {

          }
      });
      return !(hasEducationOverlap);
    });

    return processedGaps;
  }
  

  const handleClose = () => {
    setOpen(false);
  };

  const scrollToChild = (targetId) => {
    if (scrollableContainerRef.current) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  return (
    <Box>
      <Box>
        <Container maxWidth="xl" disableGutters>
          <Grid container>
            <Grid item xs={7}>
              <Box className="candidate-details-content">
                <Box ref={scrollableContainerRef} className="candidate-det-wrapper" >
                  <PersonalDetails profileData={profile} ref={personalDetailRef} setUpdating={setUpdating} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <UploadProfile profilephoto={profilephoto} setUpdating={setUpdating} onProfilePhotoChange={handleProfilePhotoChange} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <ContactDetails contactDetailsError={contactDetailsError} setUpdating={setUpdating} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <EducationSection scrollToChild={scrollToChild} educationData={educationData} setUpdating={setUpdating} onCancel={handleCancelEducation} onEducationDataChange={handleEducationDataChange} expandedSection={expandedSection} handleExpand={handleExpand} certificateData={certificateData} workExperienceData={workExperienceData} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <CertificationSection scrollToChild={scrollToChild} setUpdating={setUpdating} certificateData={certificateData} onCancel={handleCancelEducation} onCertificateDataChange={handleCertificateDataChange} expandedSection={expandedSection} handleExpand={handleExpand} educationData={educationData} workExperienceData={workExperienceData} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <WorkExperienceDetails scrollToChild={scrollToChild} setUpdating={setUpdating} workExperienceData={workExperienceData} onCancel={handleCancelEducation} onWorkExperienceDataChange={handleWorkExperienceDataChange} expandedSection={expandedSection} handleExpand={handleExpand} certificateData={certificateData} educationData={educationData} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <ProfessionalSummary summaryData={summaryData} setUpdating={setUpdating} onSummaryDataChange={handleSummaryDataChange} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <FinanceTechnicalCompetencies financeTechologiesData={financeTechologiesData} setUpdating={setUpdating} onFinanceDataChange={handleFinanceDataChange} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <CustomSection customSectionData={customSection} flag={flag} setUpdating={setUpdating} onCustomDataChange={handleCustomSectionDataChange} />
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1.5rem', marginBottom: '1.5rem' }}
                  />
                  <Button className="next-btn" variant="contained" size="large" onClick={validateAndProceed} disabled={nextPage}>
                    Next
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ marginTop: '3rem' }}>
              <CandidateResume
                updating={updating}
                setUpdating={setUpdating}
                templateType={templateType}
                profile={user}
                summaryData={summaryData}
                educationData={educationData}
                workExperienceData={workExperienceData}
                certificateData={certificateData}
                financeTechologiesData={financeTechologiesData}
                customSection={customSection}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="career-breaks-dialog"
      >
        <CareerBreaksIdentifiedDialog gap={workExperienceGap} onClose={handleClose} onWorkExperienceDataChange={handleWorkExperienceDataChange} />
      </Dialog>
    </Box>
  );
};

export default BasicDetails;
