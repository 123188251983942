import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { useAuth } from 'hooks/useAuthContext';
//  ----------------------------------------------------------------------



const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 10;

const StyledRoot = styled('div')(({ theme, isDrawer }) => ({
  display: isDrawer ? 'flex' : '',
  minHeight: '100%',
  overflow: 'hidden',
  height: '100%',
}));

const Main = styled('div')(({ theme, isDrawer }) => ({
  ...(isDrawer && {
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE,
    marginBottom: 3,
    // [theme.breakpoints.up('lg')]: {
    //   paddingTop: APP_BAR_DESKTOP,
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2),
    // }
  })
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({isDrowerShow}) {

  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  return (
    <StyledRoot isDrawer={ (user && user?.user_type !== 'user' ? true : false) }>
      <Header onOpenNav={() => setOpen(true)} />
      {(user?.user_type !== 'user' && isDrowerShow ) ? <Nav openNav={open} onCloseNav={() => setOpen(false)} /> : ''}
      <Main isDrawer={ (user && user?.user_type !== 'user' ? true : false) }>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
