import { Avatar, Box, Chip, Stack, SvgIcon } from '@mui/material';

const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;

export const CheckIcon = () => (
    <Stack>
        <Box className="match-icon">
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83402 1.78254L2.59366 5.0229C2.37351 5.24305 2.00888 5.24305 1.78185 5.0229L0.165114 3.40616C-0.0550379 3.18601 -0.0550379 2.82138 0.165114 2.59435C0.385265 2.3742 0.749891 2.3742 0.976923 2.59435L2.19464 3.81207L5.02909 0.977614C5.24924 0.757462 5.61386 0.757462 5.8409 0.977614C6.05417 1.19777 6.05417 1.56239 5.83402 1.78254Z" fill="white" />
                </svg>
            </SvgIcon>
        </Box>
    </Stack>
);
export const CrossIcon = () => (
    <Stack>
        <Box className="error-match-icon">
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                    <path d="M0.850569 4.67256L2.52313 3L0.850569 1.32744C0.818857 1.2962 0.793644 1.259 0.776383 1.21797C0.759123 1.17694 0.750157 1.13289 0.750002 1.08838C0.749847 1.04387 0.758508 0.999764 0.775483 0.958614C0.792458 0.917465 0.817413 0.880083 0.848907 0.848626C0.880401 0.817169 0.917812 0.792258 0.958982 0.775332C1.00015 0.758405 1.04426 0.749797 1.08878 0.750004C1.13329 0.750211 1.17732 0.759229 1.21833 0.776538C1.25934 0.793847 1.29652 0.819104 1.32772 0.850853L2.99972 2.52341L4.67228 0.850853C4.70348 0.819104 4.74065 0.793847 4.78166 0.776538C4.82267 0.759229 4.8667 0.750211 4.91122 0.750004C4.95573 0.749797 4.99984 0.758405 5.04101 0.775332C5.08218 0.792258 5.11959 0.817169 5.15109 0.848626C5.18258 0.880083 5.20754 0.917465 5.22451 0.958614C5.24149 0.999764 5.25015 1.04387 5.24999 1.08838C5.24984 1.13289 5.24087 1.17694 5.22361 1.21797C5.20635 1.259 5.18114 1.2962 5.14943 1.32744L3.47631 3L5.14886 4.67256C5.18058 4.7038 5.20579 4.741 5.22305 4.78203C5.24031 4.82306 5.24928 4.86711 5.24943 4.91162C5.24959 4.95613 5.24093 5.00024 5.22395 5.04139C5.20697 5.08253 5.18202 5.11992 5.15053 5.15137C5.11903 5.18283 5.08162 5.20774 5.04045 5.22467C4.99928 5.24159 4.95517 5.2502 4.91066 5.25C4.86614 5.24979 4.82211 5.24077 4.7811 5.22346C4.74009 5.20615 4.70291 5.1809 4.67171 5.14915L2.99972 3.47659L1.32716 5.14915C1.26378 5.21158 1.17831 5.24643 1.08935 5.24612C1.00039 5.24581 0.915157 5.21036 0.852216 5.1475C0.789275 5.08463 0.753726 4.99944 0.753313 4.91048C0.752899 4.82152 0.787654 4.73601 0.850007 4.67256H0.850569Z" fill="white" />
                </svg>
            </SvgIcon>
        </Box>
    </Stack>
);
export const WarningIcon = () => (
    <Stack>
        <Box className="warning-icon">
            <SvgIcon>
                <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.46307 0.272727L2.35227 6.49006H1.17188L1.06108 0.272727H2.46307ZM1.7642 9.08097C1.52841 9.08097 1.3267 8.99858 1.15909 8.83381C0.991477 8.66619 0.909091 8.46449 0.911932 8.22869C0.909091 7.99574 0.991477 7.79687 1.15909 7.6321C1.3267 7.46449 1.52841 7.38068 1.7642 7.38068C1.99432 7.38068 2.19318 7.46449 2.3608 7.6321C2.52841 7.79687 2.61364 7.99574 2.61648 8.22869C2.61364 8.38494 2.57244 8.52841 2.4929 8.65909C2.41619 8.78693 2.31392 8.8892 2.18608 8.96591C2.05824 9.04261 1.91761 9.08097 1.7642 9.08097Z" fill="white" />
                </svg>

            </SvgIcon>
        </Box>
    </Stack>
);
export const SuitableChip = () => (
    <Chip
        className="chip-job-matching chip-suitable"
        avatar={<Avatar alt="Natacha" src={suitableIcon} />}
        label="Highly Suitable Job For You"
        size="small"
    />
);
export const ModerateChip = () => (
    <Chip
        className="chip-job-matching chip-mod-suitable"
        avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
        label="Moderately Suitable For You"
        size="small"
    />
);
export const NotSuitableChip = () => (
    <Chip
        className="chip-job-matching chip-not-matching"
        avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
        label="Job Not Matching Your Profile"
        size="small"
    />
);