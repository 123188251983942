import { AccountCircle } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Chip,
  SvgIcon
} from '@mui/material';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import AllApplicantsList from './AllApplicantsList';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { jobDetails } from 'api/job';
import ApplicantProfile from './ApplicantProfile';
import { SplashScreen } from 'components/loading-screen';
import { useSnackbar } from 'components/snackbar';

const dayjs = require('dayjs');

const JobApplicants = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobDetail, setJobDetail] = useState([]);
  const { id } = useParams();
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const showMoreButton = jobDetail?.description && jobDetail?.description.length > 300;
  const [showApplicantProfile, setShowApplicantProfile] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const applicantProfileRef = useRef(null);
  const fullContainer = useRef(null);
  
  useLayoutEffect(() => {
    if (fullContainer?.current && applicantProfileRef?.current){
    fullContainer.current.scrollTo({
      top: applicantProfileRef.current.offsetTop,
      block: 'center',
      behavior: 'smooth',
    });
  }
  }, [showApplicantProfile, selectedApplicant]);

  const handleSelectApplicant = (applicant) => {
    // Toggle the visibility of the ApplicantProfile component
    setShowApplicantProfile(!showApplicantProfile);
    setSelectedApplicant(applicant);
  };

  const handleGoBack = () => {
    setShowApplicantProfile(false);
    // You can add your logic for navigating back to the previous screen here
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jobDetails({ id });
        if (response?.success && response.data) {
          setJobDetail(response.data || []);
        } else {
          console.error('Failed to fetch job info:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching job info:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleCopyUrl = (jobId) => {
    const url = `${window?.location?.host}/jobs/${jobId}`;
    navigator.clipboard.writeText(url).then(() => {
      enqueueSnackbar("Job link copied.", { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar('Failed to copy job link.', { variant: 'error' });
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <SplashScreen />
      </Box>
    );
  }

  const truncatedDescription = showMore ? jobDetail?.description : jobDetail?.description.substring(0, 250) + (jobDetail?.description.length > 250 ? '...' : '');

  return (
    <Box>
      <Container style={{ marginTop: '80px' }}>
        <Box sx={{ marginBottom: '2rem' }}>
          <Container >
            {/* jobDetails */}
            <Box sx={{ marginBottom: '2rem' }}>
              <Container ref={fullContainer}>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                  <Box sx={{ marginBottom: '1rem' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <Box>
                        {/* job title & company name */}
                        <Typography variant="h6" component="div">
                          {jobDetail?.title || 'N/A'}, {jobDetail?.company || 'N/A'}
                        </Typography>
                      </Box>
                      <Box>
                        {/* job id */}
                        <Typography variant="p" component="div" sx={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
                          <Box sx={{ marginRight: '4px' }}>Job Id: <b>{jobDetail?.jobid}</b></Box> |
                           <Typography
                                  variant="body2"
                                  sx={{
                                    cursor: 'pointer',
                                    color: '#1575C6',
                                    marginLeft: '4px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  onClick={ () => handleCopyUrl(jobDetail?.jobid)}
                                >
                                  Copy URL
                                    &nbsp;
                                  <SvgIcon sx={{ width: '13px', height: '13px', spacing : '2px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7936 9.98322L15.8093 7.96767C18.346 5.43097 16.5855 1 12.9208 1C11.8735 1 10.8263 1.3971 10.0325 2.19088L6.1115 6.11171C4.09234 8.13087 4.74197 11.553 7.2992 12.7136L8.78896 11.2238C6.90337 11.0471 6.09224 8.74536 7.4188 7.4188L11.3394 3.49796C12.7322 2.10515 15.1544 3.078 15.1544 5.07917C15.1544 6.2076 14.4339 6.72832 13.6598 7.50241C13.9153 8.30423 13.9604 9.16066 13.7936 9.98322ZM4.34021 10.4976L3.49839 11.3394C2.1043 12.7335 3.08118 15.1544 5.07959 15.1544V15.1529C5.65323 15.1529 6.22665 14.9358 6.66058 14.5018L10.5812 10.5812C11.0151 10.1473 11.2323 9.57385 11.2323 9H11.2338C11.2338 7.84363 10.3609 6.88369 9.21104 6.77594L10.7006 5.28639C13.2606 6.44933 13.9058 9.87083 11.8883 11.8883L7.96767 15.8091C7.17389 16.6029 6.12674 17 5.07938 17C1.41488 17 -0.346033 12.5692 2.19088 10.0323L4.20644 8.01677C4.03964 8.83976 4.08494 9.69556 4.34021 10.4976Z" fill="#1575C6" stroke="white" stroke-width="0.5" />
                                    </svg>
                                  </SvgIcon>
                                </Typography>
                        </Typography>
                        {/* job created date */}
                        <Typography variant="p" component="div" sx={{ fontSize: '0.8rem' }}>
                          Post Date: {jobDetail?.updatedAt ? dayjs(jobDetail?.updatedAt).format('DD/MM/YYYY') : 'N/A'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Typography variant="h6" component="div" sx={{ marginBottom: '1rem' }}>
                      Jobs Description
                    </Typography>
                    {/* job description */}
                    <div>
                      <Typography className="info-text list-style">
                        <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                      </Typography>
                      {jobDetail?.description.length > 250 && (
                        <Link component="button" onClick={() => setShowMore(!showMore)}>
                          {showMore ? 'Show less' : 'Show more'}
                        </Link>
                      )}
                    </div>
                  </Box>
                  {/* job details (qualification, work mode, location, start date) */}
                  
    {/* 'Job Location',
    'Job Format',
    'Job Type',
    'Job Shift',
    'Notice Period', */}
                  <Box sx={{ margin: '2rem 0' }}>
                    <Typography variant="p" component="div" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                      Requirements
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                          <TableBody>
                            <TableRow
                              key={jobDetail?.qualifications}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {' '}
                                Qualifications{' '}
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.qualifications?.join(' | ')}
                              </TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.work_mode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {' '}
                                Job Format
                              </TableCell>
                              <TableCell align="left">{jobDetail?.work_mode || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.location} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {' '}
                                Job Location{' '}
                              </TableCell>
                              <TableCell align="left">{jobDetail?.location || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.job_type} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                              Job Type
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.job_type || 'N/A'}{' '}
                              </TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.job_shift} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                Job Shift
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.job_shift || 'N/A'}{' '}
                              </TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.notice_period} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                Notice Period
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.notice_period ? ((jobDetail?.notice_period === '0 days (immediate)' || jobDetail?.notice_period === 'No preference') ? `${jobDetail?.notice_period}` : `Upto ${jobDetail?.notice_period} days`) : 'NA'}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Box>
                </Paper>
              </Container>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Container>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                  <AllApplicantsList setSelectedApplicant={handleSelectApplicant} jobDetails={jobDetail} />
                </Paper>
              </Container>
            </Box>
            <Box ref={applicantProfileRef}>
            {showApplicantProfile && selectedApplicant && (
              <Box sx={{ marginBottom: '1rem' }} >
                <Container>
                  <Paper elevation={3} sx={{ padding: '1rem' }}>
                    <ApplicantProfile selectedApplicant={selectedApplicant} onGoBack={handleGoBack} />
                  </Paper>
                </Container>
              </Box>
            )}
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default JobApplicants;
