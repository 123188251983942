import { Button, Box, Container, Paper, Checkbox, InputAdornment, IconButton, Collapse, TextField, Stack, List, ListItem, ListItemText, Table, TableBody, TableCell, Grid, CircularProgress, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCandidateList } from 'api/admin';
import exportFromJSON from 'export-from-json';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSnackbar } from 'components/snackbar';
import SearchIcon from '@mui/icons-material/Search';

const CandidatesList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [candidateList, setCandidateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchParameter, setSearchParameter] = useState(false);

  const defaultValues = {
    candidateName: "",
    skills: [],
    functions: [],
    role: [],
    education: [],
    location: [],
    currentSalary: '',
    desiredSalary: '',
    desiredLocation: [],
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { control, register, handleSubmit, setValue, watch, trigger, getValues, reset, formState: { errors, isDirty, isValid, dirtyFields, isSubmitting }, } = methods;
  useEffect(() => {
    const fetchCandidateList = async () => {
      try {
        const response = await getCandidateList();
        if (response?.success && response.data != null) {
          setCandidateList(response.data);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
      setLoading(false);
    };
    fetchCandidateList();
  }, []);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  const downloadSelectedCandidatesData = () => {

    const selectedCandidatesData = candidateList.filter(candidate => selectedCandidates.includes(candidate?.id));

    const CandidatesList = selectedCandidatesData.map(candidate => ({
      'ID': candidate?.id,
      'Email': candidate?.email,
      'Phone': candidate?.phone,
      'Candidate Name': candidate?.full_name,
      'Designation': candidate?.designation,
      'Qualifications': candidate?.educations,
      'Location': candidate?.location,
      'Salary': candidate?.salary,
    }));

    const fileName = `candidateList_${Date.now()}`;
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: CandidatesList, fileName, exportType });

  }

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleSearch = () => {
    setSearchParameter(prevState => !prevState);
    if (searchParameter) {
      reset(defaultValues);
    }
  };

  const handleRowClick = (userId) => {
    handleNavigation(`/candidate-applications/${userId}`);
  };

  const handleCheckboxChange = (event, candidateId) => {
    if (event.target.checked) {
      setSelectedCandidates([...selectedCandidates, candidateId]);
    } else {
      setSelectedCandidates(selectedCandidates.filter(id => id !== candidateId));
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedCandidates(candidateList?.map(candidate => candidate?.id));
    } else {
      setSelectedCandidates([]);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {

    try {
      const response = await getCandidateList(formData);
      if (response?.success && response.data != null) {
        setCandidateList(response.data);
      }
      else if (response?.success === false) {
        enqueueSnackbar('Failed to search', { variant: 'error' });
        console.error('Failed to search:', response.message);
      }
    } catch (error) {
      console.error('Error fetching candidates:', error);
      enqueueSnackbar('Failed to fetch candidates', { variant: 'error' });
    }
  });

  return (
    <FormProvider {...methods}>
      <Container style={{ marginTop: '80px', marginRight:'20px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
            Candidate List
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Button variant="contained" onClick={handleSearch}>
            {searchParameter ? "close search" : "search candidate"}
          </Button>
          <Button variant="contained" onClick={downloadSelectedCandidatesData} disabled={selectedCandidates.length === 0}>
            Download List
          </Button>
        </Stack>
        <Grid container spacing={2}>
          {searchParameter &&
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: '1rem' }}>
                <Typography variant="h6" sx={{ marginBottom: '0.5rem' }}>Search Parameters</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <Button type="submit" variant="contained" disabled={isSubmitting}  ><SearchIcon /></Button>
                    <Controller
                      control={control}
                      name="candidateName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Candidate Name"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="skills"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.skills}`}
                          multiple={true}
                          fullWidth
                          displayTextKey="name"
                          label="Select skills"
                          placeholder="Select skills"
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    {/* <Controller
                      control={control}
                      name="functions"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.function}`}
                          multiple={true}
                          fullWidth
                          displayTextKey="name"
                          label="Select functions"
                          placeholder="Select functions"
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    /> */}
                    <Controller
                      control={control}
                      name="role"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.role}`}
                          label="Role/Desgination"
                          placeholder="Select Role/Desgination"
                          multiple={true}
                          fullWidth
                          displayTextKey='name'
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="education"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.degree}`}
                          label="Education"
                          placeholder="Select Qualification"
                          multiple={true}
                          fullWidth
                          displayTextKey='name'
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="location"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                          label="Location"
                          placeholder="Select locations"
                          displayTextKey='name'
                          multiple={true}
                          fullWidth
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="desiredSalary"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Min Desired Salary "
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="currentSalary"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Min Current Salary"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="desiredLocation"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                          label="Desired Location"
                          placeholder="Desired Location"
                          displayTextKey='name'
                          multiple={true}
                          fullWidth
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Stack>
                </form>
              </Paper>
            </Grid>}
          <Grid item xs={12} md={searchParameter ? 8 : 12}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Paper elevation={3}>
                <TableContainer sx={{ maxHeight: '100%' }}> 
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />
                        </TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role/Designation</TableCell>
                        <TableCell>Education </TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Salary</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {candidateList.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7}>No candidate available.</TableCell>
                        </TableRow>
                      ) : (
                        candidateList?.map((candidate, index) => (
                          <TableRow key={candidate?.id} onClick={() => handleRowClick(candidate?.id)}>
                            <TableCell onClick={(event) => event.stopPropagation()}>
                              <Checkbox
                                checked={selectedCandidates.includes(candidate?.id)}
                                onChange={(event) => handleCheckboxChange(event, candidate?.id)}
                              />
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{candidate?.full_name}</TableCell>
                            <TableCell>{candidate?.email}</TableCell>
                            <TableCell>{candidate?.designation || 'N/A'}</TableCell>
                            <TableCell>{candidate?.educations || 'N/A'}</TableCell>
                            <TableCell>{candidate?.location || 'N/A'}</TableCell>
                            <TableCell>{candidate?.salary || 'N/A'}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
};

export default CandidatesList;