import React from 'react';
import {
  Box,
  Dialog,
  Card,
  CardContent,
  Link,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Container,
  IconButton,
  FormControlLabel,
  Switch,
  styled,
  OutlinedInput,
  FormHelperText
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { CandidateWorkHistorySchema, CandidateWorkHistoryForGapSchema } from 'models/CandidateProfileSchema';
import { updateCandidateWorkExperience } from 'api/users';
import dayjs from 'dayjs';
import DynamicTextField from 'components/DynamicTextField';
import { endpoints } from 'utils/axios';
import { useState, useEffect, useRef } from 'react';
import AddSkills from '../basic-profile/AddSkills';
import { changeNameFormat, toTitleCase } from 'utils/Utils';
import { cleanData } from 'data/city.js';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  top: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CreateUpdateWorkExperience = ({ workExperienceData, currentCompany, onDataChange, closeDialog, roleData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isEditMode = !!workExperienceData._id;
  const navigate = useNavigate();
  const { user, updateUnsaved } = useAuth();
  const [error, setError] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [educationFrom, setEducationFrom] = useState(dayjs(workExperienceData?.education_from) || null);
  const [selectedChips, setSelectedChips] = useState(workExperienceData?.skills || []);
  const [keyAccomplishments, setKeyAccomplishments] = useState(
    workExperienceData?.key_accomplishments?.length > 0 ? [...workExperienceData.key_accomplishments, ''] : ['']
  );
  const [explaination, setExplaination] = useState(workExperienceData?.gap_explaination ? true : false);
  const [from, setFrom] = useState(dayjs(workExperienceData?.work_history_from) || null);
  const [present, setPresent] = useState(workExperienceData?.present ? true : false);
  const [open, setOpen] = React.useState(false);
  const focusRef = useRef(null);
  const [chipData, setChipData] = useState(workExperienceData?.skills?.length > 0 ? workExperienceData?.skills : workExperienceData?.all_skills || []);
  const [addMoreSkills, setAddMoreSkills] = useState(workExperienceData?.all_skills || []);

  const jobType = [{ label: 'Full time' }, { label: 'Part time' }, { label: 'Temporary' }, { label: 'Internship' }, { label: 'Trainee' }, { label: 'Freelancer (Self employed)' }];
  const [openRemove, setOpenRemove] = useState(false);
  const defaultValues = {
    work_history_from: null,
    work_history_to: null,
    work_history_location: '',
    organization_name: '',
    organization_details: null,
    present: false,
    last_job_function: '',
    highest_designation: '',
    job_type: 'Full time',
    team_size_managed: 'Individual contributor (IC)',
    work_oriented_skills: [],
    gap_explaination: '',
    // gap_start_date: '',
    key_accomplishments: [],
    ...workExperienceData
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(explaination ? CandidateWorkHistoryForGapSchema(present) : CandidateWorkHistorySchema(explaination, present)),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    watch,
    trigger,
  } = methods;
  const updateWorkToDate = (date) => {
    if (date) {
      const lastDayOfMonth = date.endOf('month');
      setValue('work_history_to', lastDayOfMonth?.$d);
      trigger('work_history_to');
    }
  };
  const updateWorkFromDate = async (date) => {
    if (date) {
      const firstDayOfMonth = date.startOf('month');
      setFrom(date);
      setValue('work_history_from', firstDayOfMonth?.$d);
      trigger('work_history_from');
    }
  };

  const handleAccomplishmentChange = (index, value) => {
    const newAccomplishments = [...keyAccomplishments];
    newAccomplishments[index] = value;
    setKeyAccomplishments(newAccomplishments);

    if (index === keyAccomplishments.length - 1 && value.trim() !== '') {
      setKeyAccomplishments([...newAccomplishments, '']);
    } else if (value.trim() === '') {
      newAccomplishments.splice(index, 1);
    }
  };

  const handlePresentDateChange = (event) => {
    const isChecked = event.target.checked;
    if (currentCompany && (isEditMode ? !workExperienceData.present : true)) {
      setError(true);
    } else {
      setPresent(isChecked);
      setValue('present', isChecked);
      if (isChecked) {
        setValue('work_history_to', null);
      }
    }
  };
  const onSubmit = handleSubmit(async (formData) => {
    if (isEditMode) {
      formData.workId = workExperienceData._id;
    }
    if (!validateSkills(selectedChips)) {
      if (!explaination) {
        return;
      }
      setShowFlag('');
    } else {
      setShowFlag('');
    }
    formData.skills = selectedChips;
    formData.all_skills = chipData;
    formData.key_accomplishments = keyAccomplishments.filter((accomplishment) => accomplishment.trim() !== '');
    const response = await updateCandidateWorkExperience(formData);
    if (response?.success === true && response?.data) {
      onDataChange();
    } else if (response?.success === false) {
      enqueueSnackbar(response?.message, { variant: 'error' });
    }
  });

  const handleClick = (label) => {
    let totalSkillls;
    if (selectedChips.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
    } else {
      if (selectedChips.length >= 8) {
        setShowFlag('You can select up to 8 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 3) {
      setShowFlag('Please select at least 3 skills.');
      return false;
    } else if (totalSkillls?.length > 8) {
      setShowFlag('You can select up to 8 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemove(false);
  };
  const handleAddMoreSkills = (moreSkills) => {
    if (moreSkills?.length > 0) {
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
    }
  };

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-header">
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <IconButton aria-label="delete" onClick={closeDialog}>
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </IconButton>

            <Box>
              <Typography className="title" sx={{ marginBottom: '0!important' }}>
                {isEditMode ? 'Update Work Experience' : 'New Work Experience'}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box className="drawer-content">
          <Box className="work-exp-details-form">
            <Typography className="main-title" variant="h6">
                {
                watch('organization_name') === 'Work Gap'
                  ? (watch('gap_title') ? `Career Break: ${toTitleCase(watch('gap_title'))}` : 'Career Break')
                  : (watch('highest_designation') ? toTitleCase(`${watch('highest_designation')} `) + 'at ' : '') +
                  (watch('organization_name') ? toTitleCase(`${watch('organization_name')}`) : 'Work Experience Details')
              }
            </Typography>

            {workExperienceData?.organization_name == 'Work Gap' ? (
              <Stack spacing={2}>
                <Typography className="info-wrapper" variant="body2" gutterBottom mb={3}>
                  {workExperienceData?.work_history_from
                    ? `${dayjs(workExperienceData?.work_history_from).format('MMM YYYY')} - ${workExperienceData?.work_history_to && dayjs(workExperienceData?.work_history_to).format('MMM YYYY')
                    }`
                    : ''}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="gap_title"
                    defaultValue={defaultValues?.gap_title || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Explain gap in employment"
                        error={!!errors.gap_title}
                        helperText={errors.gap_title ? errors.gap_title.message : ''}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="gap_explaination"
                    defaultValue={defaultValues?.gap_explaination || ''}
                    render={({ field }) => (
                      <TextField
                        multiline
                        rows={2}
                        {...field}
                        size="small"
                        label="Explain gap in employment"
                        error={!!errors.gap_explaination}
                        helperText={errors.gap_explaination ? errors.gap_explaination.message : ''}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            ) : (
              <>
                <Stack spacing={2}>
                  {/* company name */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="organization_name"
                      defaultValue={defaultValues?.organization_name || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('organization_name')}
                          ref={focusRef}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.company}`}
                          fullWidth
                          label="Company"
                          placeholder="Enter your company name"
                          minInputLength={3}
                          displayTextKey="name"
                          freeSolo={true}
                          onInputChange={(e, value) => {
                            setValue("organization_name", value, { shouldDirty: true });
                          }}
                          onChange={(e, value) => {
                            setValue('organization_details', value);
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  {/* designation */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="highest_designation"
                      defaultValue={defaultValues?.highest_designation || []}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          {...field}
                          {...register('highest_designation')}
                          label="Designation"
                          variant="outlined"
                          fullWidth
                          error={!!errors.highest_designation}
                          helperText={errors.highest_designation ? errors.highest_designation.message : ''}
                          onChange={(e) =>
                            setValue("highest_designation", toTitleCase(e.target.value), { shouldDirty: true })
                          }
                        />
                      )}
                    />
                  </FormControl>
                  {/* job function */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="last_job_function"
                      defaultValue={defaultValues?.last_job_function || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('last_job_function')}
                          data={roleData}
                          freeSolo={true}
                          label="Job Function"
                          size="small"
                          displayTextKey="name"
                          onInputChange={(e, value) => {
                            setValue("last_job_function", value, { shouldDirty: true });
                          }}
                          onChange={(e, value) => {
                            field.onChange(value?.name);
                            setValue('all_skills', value?.skills);
                            setChipData(value?.skills);
                            setAddMoreSkills(value?.skills);
                            setSelectedChips([]);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  {/* job type */}
                  <FormControl fullWidth size="small">
                    <InputLabel id="job_type">Job Type</InputLabel>
                    <Controller
                      control={control}
                      name="job_type"
                      defaultValue={defaultValues?.job_type || ''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          {...register('job_type')}
                          labelId="job_type"
                          error={!!errors.job_type}
                          helperText={errors.job_type?.message}
                          label="Job Type"
                          variant="outlined"
                          fullWidth
                        >
                          {jobType?.map((item) =>
                            <MenuItem value={item?.label}>{item?.label}</MenuItem>
                          )}
                        </Select>
                      )}
                    />
                    <Typography variant="caption" color="error">
                      {errors.job_type?.message}
                    </Typography>
                  </FormControl>
                  {/* location */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="work_history_location"
                      defaultValue={defaultValues?.work_history_location || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('work_history_location')}
                          data={cleanData}
                          label="Location"
                          displayTextKey="name"
                          disableClearable={true}
                          onChange={(e, value) => field.onChange(value)}
                          onInputChange={(e, value) => {
                            setValue("work_history_location", value, { shouldDirty: true });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  {/* team size managed */}                  
                  <FormControl fullWidth size="small" error={!!errors?.team_size_managed}>
                    <InputLabel id="team_size_managed">People Managed (Direct Reportees)</InputLabel>
                    <Controller
                      control={control}
                      name="team_size_managed"
                      defaultValue={defaultValues?.team_size_managed || ''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          {...register('team_size_managed')}
                          labelId="team_size_managed"
                          label="People Managed (Direct Reportees)"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="Individual contributor (IC)">Individual contributor (IC)</MenuItem>
                          <MenuItem value="1-4">1-4</MenuItem>
                          <MenuItem value="5-10">5-10</MenuItem>
                          <MenuItem value="11-25">11-25</MenuItem>
                          <MenuItem value="26-50">26-50</MenuItem>
                          <MenuItem value="51-100">51-100</MenuItem>
                          <MenuItem value="100+">100+</MenuItem>
                        </Select>
                      )}
                    />
                    {errors?.team_size_managed && <FormHelperText error>{errors?.team_size_managed?.message}</FormHelperText>}
                  </FormControl>
                  <Box mb={2}>
                    <Card className="disability-card" variant="outlined">
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Stack direction="row" spacing={1} alignItems="start">
                              <AntSwitch
                                checked={present}
                                onChange={handlePresentDateChange}
                                inputProps={{ 'aria-label': 'ant design' }}
                              />
                              <Box>
                                <Typography className="heading" variant="p" gutterBottom>
                                  You are currently working with {watch('organization_name') ? toTitleCase(watch('organization_name')) : ' this company'} as{' '}
                                  {watch('highest_designation') ? toTitleCase(watch('highest_designation')) : ' your current role'}
                                </Typography>
                                <Typography className="info" variant="body2" gutterBottom>
                                  Please select this if you are still working in this company & current role
                                </Typography>
                              </Box>
                            </Stack>
                          }
                        />
                      </CardContent>
                    </Card>
                    {error && (
                      <Typography className="error-text" component="p" style={{ color: 'red' }}>
                        You are currently working with {currentCompany}.
                      </Typography>
                    )}
                  </Box>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="work_history_from"
                      defaultValue={defaultValues?.work_history_from}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          id="work_history_from"
                          {...register('work_history_from')}
                          format="MM/YYYY"
                          views={['year', 'month']}
                          disableFuture
                          label="Start Date"
                          sx={{ width: '100%' }}
                          value={dayjs(field?.value)}
                          onChange={(date) => updateWorkFromDate(date)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!errors?.work_history_from,
                              helperText: errors?.work_history_from?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="work_history_to"
                      defaultValue={defaultValues?.work_history_to}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          id="work_history_to"
                          format="MM/YYYY"
                          views={['year', 'month']}
                          disableFuture
                          sx={{ width: '100%' }}
                          disabled={present}
                          // minDate={from}
                          label="End Date"
                          value={dayjs(field?.value)}
                          onChange={(date) => updateWorkToDate(date)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!errors?.work_history_to,
                              helperText: errors?.work_history_to?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={2} mt={2}>
                  <Box className="besic-det-skills-wrapper">
                    <Box mb={2}>
                      <Typography className="title" variant="body2" gutterBottom>
                        Skills
                      </Typography>
                      <Typography className="info-txt" variant="body2" gutterBottom>
                        3 to 8 skills can be selected.
                      </Typography>
                    </Box>
                    <Stack direction="row" flexWrap="wrap">
                      {chipData?.map((label, index) => (
                        <Chip
                          key={index}
                          label={label}
                          size="small"
                          clickable
                          variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                          style={{
                            color: selectedChips.includes(label) ? 'white' : 'black',
                            backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                            border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                          }}
                          onClick={() => handleClick(label)}
                        />
                      ))}
                    </Stack>
                    {showFlag && (
                      <Typography style={{ color: 'red', fontSize: 'small' }}>
                        {showFlag}
                      </Typography>
                    )}
                    <Link className="add-more-skills-link" underline="none" size="small" onClick={handleOpenOtpDialog}>
                      Add More Skills
                    </Link>
                  </Box>
                  <Box className="accomplishments" mt={3} mb={2}>
                    <Typography className="title" variant="body2" gutterBottom>
                      Accomplishments
                    </Typography>
                    <Typography className="info-txt" variant="body2" gutterBottom>
                      Well structured accomplishments help recruiters get visibility on your strengths
                    </Typography>
                    {keyAccomplishments.map((accomplishment, index) => (
                      <Box key={index} mt={2}>
                        <FormControl fullWidth>
                          <DynamicTextField
                            label="Key Accomplishments"
                            rowsMax={10}
                            value={accomplishment}
                            onChange={(value) => handleAccomplishmentChange(index, value)}
                          />
                        </FormControl>
                      </Box>
                    ))}
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        </Box>
        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              fullWidth
              className="update-btn"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
              size="large"
            >
              {isEditMode ? 'Update' : 'Done'}
            </Button>
          </Stack>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <AddSkills
            onClose={handleClose}
            skills={addMoreSkills}
            selectedskills={selectedChips}
            onAddSkills={handleAddMoreSkills}
          />
        </Dialog>
      </FormProvider>
    </Box>
  );
};

export default CreateUpdateWorkExperience;
