import React, { useState, useEffect } from 'react';
import { Box, Container, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Header from '../header/Header'
import JobsMarket from './JobsMarket';
import MyJobs from './MyJobs';
import MyResumes from './MyResumes';
import { useNavigate, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useAuth } from 'hooks/useAuthContext';

const JobApplication = () => {
    const { jobApply, setJobApply } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { tab, data } = location.state || { tab: '1', data: null };

    const [value, setValue] = useState(jobApply?.tab || tab || '1');
    const [jobData, setJobData] = useState(jobApply?.job || data || '');
    const [activeTab, setActiveTab] = useState(jobApply?.activeTab || 'saved-jobs');
    const [tabForRedirectBack, setTabForRedirectBack] = useState(jobApply?.tabForRedirectBack || '');
    const [showExternalStatus, setShowExternalStatus] = useState('');
    const [externalJob, setExternalJob] = useState('');

    useEffect(() => {
        if (jobApply?.tab === '1') {
            setValue('1');
        }
    }, [jobApply]);

    const handleChange = (event, newValue) => {
        setJobApply(null);
        setValue(newValue);
        setJobData('');
        setShowExternalStatus('');
        setExternalJob('');
        navigate(location.pathname, { replace: true, state: { tab: newValue, data: null } });
    };
    const handleChangeTab = (tab, job, activeTab, flag) => {
        setValue(tab);
        setJobData(job);
        setActiveTab(activeTab);
        setExternalJob(flag);
    };
    const handleDownloadResumeForApplication = (jobInprogessData, tabForRedirectBack) => {
        setValue('4');
        setTabForRedirectBack(tabForRedirectBack);
        setJobData(jobInprogessData);
    };
    const handleRedirectToExternalJob = (applicationData) => {
        setValue('3');
        setShowExternalStatus('showExternalStatus');
        setJobData(applicationData);
        setActiveTab('saved-jobs');
        // setActiveTab('applied-jobs');
    };

    return (
        <Box className="job-app-page-wrapper">
            <Box sx={{ width: '100%' }} >
                <TabContext value={value}>
                    <Box className="tab-header">
                        <Container maxWidth="xl">
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Dashboard" value="1" />
                                <Tab label="Jobs Market" value="2" />
                                <Tab label="My Jobs" value="3" />
                                <Tab label="My Resumes" value="4" />
                            </TabList>
                        </Container>
                    </Box>

                    <Box className="job-app-page-content">
                        <TabPanel value="1" sx={{ padding: 0 }}>
                            <Dashboard onSelectTab={handleChangeTab} />
                        </TabPanel>

                        <TabPanel value="2" sx={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
                            <JobsMarket jobData={jobData} changeTabForDownload={handleDownloadResumeForApplication} showExternalStatus={showExternalStatus} />
                        </TabPanel>

                        <TabPanel value="3" sx={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
                            <MyJobs jobData={jobData} tab={activeTab} changeTabForDownload={handleDownloadResumeForApplication} onSelectTab={handleChangeTab} showExternalStatus={showExternalStatus} externalJob={externalJob} />
                        </TabPanel>

                        <TabPanel value="4" sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
                            <MyResumes jobData={jobData} tabForRedirectBack={tabForRedirectBack} redirectToExternalJob={handleRedirectToExternalJob} />
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </Box>
    )
}

export default JobApplication
