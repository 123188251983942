import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  SvgIcon,
  Drawer,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import JobFullDetails from './JobFullDetails';
import { getApplicationList, setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { SplashScreen } from 'components/loading-screen';
import FilterSearch from './FilterSearch';
import EmptyState from './EmptyState';
import { useAuth } from 'hooks/useAuthContext';
import { SuitableChip, ModerateChip, NotSuitableChip } from '../icon/icon';

const MyJobs = ({ jobData, tab, changeTabForDownload, showExternalStatus, externalJob, onSelectTab }) => {
  const { jobApply, setJobApply } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
  const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
  const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
  const [value, setValue] = React.useState(tab || 'saved-jobs');
  const [setActiveId, setSetActiveId] = useState(jobData?._id || value === 'saved-jobs' && jobApply?.job?._id || null);
  const [loading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(jobData || value === 'saved-jobs' && jobApply?.job || null);
  const [savedApplications, setSavedApplications] = useState([]);
  const [appliedApplications, setAppliedApplications] = useState([]);
  const [showExternalJobStatus, setShowExternalJobStatus] = useState(showExternalStatus || '');
  const [alignment, setAlignment] = React.useState('all');
  const [showApplication, setShowApplication] = useState([]);
  const [showExternalJob, setShowExternalJob] = useState(externalJob === 'external' ? true : false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [isSavedPresent, setIsSavedPresent] = useState(false);
  const [isAppliedPresent, setIsAppliedPresent] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [categoryFilter, setCategory] = useState(externalJob ? externalJob : '');

  const cardRefs = useRef({});
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setFilterApplied(externalJob ? true : false);
    setCategory(externalJob);
    fetchData('', false, null, {}, null, null, externalJob);
  }, []);
  useEffect(() => {
    if (value === 'saved-jobs') {
      setDataAvailable(isSavedPresent);
    } else {
      if (value === 'applied-jobs')
        setDataAvailable(isAppliedPresent);
    }
  }, [value]);
  useEffect(() => {
    if (setActiveId && cardRefs.current[setActiveId]) {
      scrollContainerRef.current.scrollTo({
        top: cardRefs.current[setActiveId].offsetTop - scrollContainerRef.current.clientHeight / 2 + cardRefs.current[setActiveId].offsetHeight / 2,
        behavior: 'smooth',
      });
    }
  }, [setActiveId, loading]);

  const fetchData = async (flag, isStatusChanged = false, search = null, filters = {}, sort, tabs, category, reload = false) => {
    try {
      // console.log(category, externalJob);
      let tabValue = value;
      const response = await getApplicationList({ search, filters, sort, tab: tabs, category });
      if (response?.success && response?.data) {
        const savedApps = response?.data?.savedApplication;
        let appliedApps = response?.data?.nonSavedApplication;

        // appliedApps?.map((app) => {
        //   console.log(app?.job_id?.salary);
        // })
        // console.log(appliedApps);

        setIsSavedPresent(response.data?.isSavedPresent);
        setIsAppliedPresent(response.data?.isNonSavedPresent);
        setSavedApplications(savedApps);
        setAppliedApplications(appliedApps);
        setShowApplication(appliedApps);
        if (reload) {
          const app = value === 'saved-jobs' ? savedApps[0] || null : appliedApps?.[0] || null;
          setSelectedApplication(app);
          setSetActiveId(app?._id || null);
          setShowEmptyState(!app && !isSavedPresent);
        }
        if (!isStatusChanged) {
          if (flag) {
            const app = value === 'saved-jobs' && savedApps?.[0] ? savedApps[0] : appliedApps?.[0] || savedApps?.[0] || null;
            setSelectedApplication(app);
            setSetActiveId(app?._id || null);
            setShowEmptyState(!app && !isSavedPresent);
            tabValue = app && savedApps?.[0] === app ? 'saved-jobs' : 'applied-jobs';
          } else if (jobData) {
            setSelectedApplication(jobData);
            setSetActiveId(jobData?._id);
            tabValue = tab;
          } else if (!selectedApplication) {
            const app = savedApps?.[0] || appliedApps?.[0];
            if (app) {
              setSelectedApplication(app);
              setSetActiveId(app._id);
              tabValue = savedApps?.length > 0 ? 'saved-jobs' : 'applied-jobs';
              setShowEmptyState(false);
            } else {
              setShowEmptyState((!isSavedPresent || !isAppliedPresent));
            }
          }
        }
        // if (showExternalJob) {
        //   const externaljob = response?.data?.externalJobs;
        //   setAppliedApplications(appliedApps);
        //   setShowApplication(externaljob);
        //   setSelectedApplication(externaljob[0]);
        //   setSetActiveId(externaljob[0]?._id);
        //   tabValue = 'saved-jobs';
        //   setShowEmptyState(appliedApps?.length === 0 && !isAppliedPresent);
        // }
        setValue(tabValue);
        if (tabValue === 'applied-jobs') {
          setShowEmptyState(appliedApps && appliedApps?.length === 0 && !isAppliedPresent);
        } else {
          setShowEmptyState(savedApps && savedApps?.length === 0 && !isSavedPresent);
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
      setFilterLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setJobApply(null);
    setValue(newValue);
    setShowExternalJobStatus('');
    if (showExternalJob) {
      setShowExternalJob(false);
    }
    if (newValue === 'applied-jobs') {
      setShowEmptyState(appliedApplications?.length === 0 ? !isAppliedPresent ? true : false : false);
      setSelectedApplication(appliedApplications[0]);
      setSetActiveId(appliedApplications[0]?._id);
    } else {
      setShowEmptyState(savedApplications?.length === 0 ? !isSavedPresent ? true : false : false);
      setSelectedApplication(savedApplications[0]);
      setSetActiveId(savedApplications[0]?._id);
      setAlignment('all');
    }
    if (filterApplied || openSearch) fetchApplicationData(newValue);
  };

  const fetchApplicationData = async (tab) => {
    try {
      setFilterLoading(true);
      const response = await getApplicationList();
      if (response?.success && response?.data) {
        const savedApps = response?.data?.savedApplication;
        let appliedApps = response?.data?.nonSavedApplication;
        let externalApps = response?.data?.externalJobs;
        setSavedApplications(savedApps);
        setAppliedApplications(appliedApps);
        setShowApplication(appliedApps);
        if (tab === 'applied-jobs') {
          setShowEmptyState(appliedApps && appliedApps?.length === 0 && !isAppliedPresent);
        } else {
          setShowEmptyState(savedApps && savedApps?.length === 0 && !isSavedPresent);
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setFilterLoading(false);
      setFilterApplied(false);
      setOpenSearch(false);
    }
  };

  const handleSelectedApplication = () => {
    fetchData(false, true);
  };

  const handleApplicationClick = (application) => {
    setJobApply(null);
    setShowExternalJobStatus('');
    setSelectedApplication(application);
    setSetActiveId(application?._id);
  };

  const handleAppliedJob = () => {
    setShowExternalJobStatus('');
    const flag = 'applied';
    fetchData(flag);
  };

  const recommendedChip = (score) => score >= 3 ? <SuitableChip /> : score >= 2 ? <ModerateChip /> : <NotSuitableChip />;

  const handleDownloadResume = (applicationDataForDownloadResume) => {
    changeTabForDownload(applicationDataForDownloadResume, '3');
  };

  const handleTabToMyJob = () => {
    onSelectTab('2');
  };
  const handleUpdateTjScore = (TjScore) => {
    setSavedApplications((prevJobs) =>
      prevJobs.map((job) =>
        job._id === selectedApplication._id
          ? { ...job, tj_score: TjScore }
          : job
      )
    );
  };

  if (!(appliedApplications?.length || appliedApplications?.length) && loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100vh - 110px)',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TabContext value={value}>
            <Box className="my-jobs-tabs">
              <Container maxWidth="xl">
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label={`Saved Jobs (${savedApplications?.length || 0})`} value="saved-jobs" />
                  <Tab label={`Applied Jobs (${showExternalJob ? showApplication?.length || 0 : appliedApplications?.length || 0})`} value="applied-jobs" />
                </TabList>
              </Container>
            </Box>
            {!showEmptyState &&
              <Box>
                <TabPanel value="saved-jobs" sx={{ padding: 0 }}>
                  <Box className="active-jobs-list">
                    {/* Filter / Search Component */}
                    <FilterSearch fetchJobs={fetchData} activeTab={value} loading={filterLoading} setFilterLoading={setFilterLoading} setOpenSearch={setOpenSearch} openSearch={openSearch} filterApplied={filterApplied} setFilterApplied={setFilterApplied} showTitleSearchBar={isSavedPresent} length={savedApplications?.length} categoryFilter={categoryFilter} />

                    <Box className="active-jobs-container" ref={scrollContainerRef} sx={{ paddingBottom: '6rem!important' }}>
                      {savedApplications?.length > 0 && (
                        savedApplications?.map((application, index) => (
                          <Card
                            ref={(el) => (cardRefs.current[application?._id] = el)}
                            key={index}
                            className={`job-card-view ${setActiveId && application?._id === setActiveId
                              ? 'first-job-item'
                              : !setActiveId && index === 0
                                ? 'first-job-item'
                                : ''
                              }`}
                            variant="outlined"
                            sx={{ marginBottom: '1rem' }}
                            onClick={() => {
                              handleApplicationClick(application);
                              setSetActiveId(application?._id);
                            }}
                          >
                            <CardContent sx={{ padding: '16px!important', paddingBottom: '0.7rem!important' }}>
                              <Box>{recommendedChip(application?.tj_score)}</Box>
                              <Box className="active-jobs-info">
                                <Grid container spacing={2}>
                                  <Grid item xs={7}>
                                    <Typography className="post-title">{application?.job_id?.title}</Typography>
                                    {application?.job_id?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{application?.job_id?.company ? toTitleCase(application?.job_id?.company) : ''}</Typography>}
                                    <Box mt={0.5} mb={1} className="work-type">
                                      <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                        <Typography variant="p" className="city">{application?.job_id?.location}</Typography>
                                        <Typography variant="body1">•</Typography>
                                        <Typography variant="p" className="city">{application?.job_id?.job_type}</Typography>
                                        <Typography variant="body1">•</Typography>
                                        <Typography variant="p" className="city">{application?.job_id?.work_mode}</Typography>
                                      </Stack>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Box className="ctc-range">
                                      {application?.job_id?.is_compensation_confidential ? <>
                                        <Typography className="text-1" >Not Disclosed</Typography>
                                        <Typography className="text-2">Compensation</Typography></> :
                                        <><Typography className="text-1">
                                          ₹{formatNumber(application?.job_id?.salary)}
                                        </Typography><Typography className="text-2">{application?.job_id?.compensation}</Typography></>}
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box mt={1}>
                                  <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="p" className="posted-date">
                                      {application?.job_id?.updatedAt
                                        ? getTimeDifferenceString(application?.job_id?.updatedAt, 'Posted')
                                        : 'N/A'}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        ))
                      )}
                    </Box>
                  </Box>
                </TabPanel>

                <TabPanel value="applied-jobs" sx={{ padding: 0 }}>
                  <Box className="active-jobs-list">
                    {/* Filter / Search Component */}
                    <FilterSearch fetchJobs={fetchData} activeTab={value} loading={filterLoading} setFilterLoading={setFilterLoading} filterApplied={filterApplied} setFilterApplied={setFilterApplied} setOpenSearch={setOpenSearch} openSearch={openSearch} showTitleSearchBar={isAppliedPresent} length={appliedApplications?.length} />

                    <Box className="active-jobs-container" ref={scrollContainerRef} sx={{ paddingBottom: '6rem!important' }} >
                      {showApplication?.length > 0 && (
                        showApplication?.map((application, index) => (
                          <Card
                            ref={(el) => (cardRefs.current[application?._id] = el)}
                            className={`job-card-view ${setActiveId && application?._id === setActiveId
                              ? 'first-job-item'
                              : !setActiveId && index === 0
                                ? 'first-job-item'
                                : ''
                              }`}
                            variant="outlined"
                            sx={{ marginBottom: '1rem' }}
                            onClick={() => {
                              handleApplicationClick(application);
                              setSetActiveId(application?._id);
                            }}
                          >
                            <CardContent sx={{ padding: '16px!important' }}>
                              <Box>
                                <Chip
                                  className={`job-${application.status === 'rejected' ? "rejected" : "applied"}`}
                                  label={changeNameFormat(application.status)}
                                  size="small"
                                />
                              </Box>
                              <Box className="active-jobs-info">
                                <Grid container spacing={2}>
                                  <Grid item xs={7}>
                                    <Typography className="post-title">{application?.job_id?.title}</Typography>
                                    {application?.job_id?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{application?.job_id?.company}</Typography>}
                                    <Box mt={0.5} mb={1} className="work-type">
                                      <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                        <Typography variant="p" className="city">{application?.job_id?.location}</Typography>
                                        <Typography variant="body1">•</Typography>
                                        <Typography variant="p" className="city">{application?.job_id?.job_type}</Typography>
                                        <Typography variant="body1">•</Typography>
                                        <Typography variant="p" className="city">{application?.job_id?.work_mode}</Typography>
                                      </Stack>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Box className="ctc-range">
                                      {application?.job_id?.is_compensation_confidential ? <>
                                        <Typography className="text-1" >Not Disclosed</Typography>
                                        <Typography className="text-2">Compensation</Typography></> :
                                        <><Typography className="text-1">
                                          ₹{formatNumber(application?.job_id?.salary)}
                                        </Typography><Typography className="text-2">{application?.job_id?.compensation}</Typography></>}
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Typography variant="p" className="posted-date">
                                  {application?.applied_at
                                    ? getTimeDifferenceString(application?.applied_at, 'Applied')
                                    : 'N/A'}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        ))
                      )}
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
            }
          </TabContext>
        </Grid>
        <Grid item xs={8}>
          {/* job full details */}
          {!showEmptyState && selectedApplication && (
            (value === 'saved-jobs' && savedApplications?.length > 0) ||
            (value === 'applied-jobs' && showApplication?.length > 0)
          ) && (
              < JobFullDetails
                application={selectedApplication}
                onStatusChange={handleSelectedApplication}
                onApplied={handleAppliedJob}
                downloadResume={handleDownloadResume}
                showExternalJobStatus={showExternalJobStatus}
                updateTJScore={handleUpdateTjScore}
              />
            )}
        </Grid>

        <Grid item xs={12}>
          {showEmptyState && !dataAvailable && <EmptyState tab={value} onChangeTab={handleTabToMyJob} />}
        </Grid>
      </Grid>
      <Drawer open={filterLoading}>
        <div className="loading-overlay">
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        </div>
      </Drawer>
    </Box >
  );
};

export default MyJobs;
